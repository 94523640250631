import React, {useEffect, useState} from 'react'
import Logo from '../../../assets/images/logos/logo.svg';
import { X,CheckCircle,Check } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate,useLocation } from 'react-router-dom';
import { Personal, Security, Notifications, Updates, System } from './SubPages';
import { useSelector, useDispatch } from 'react-redux'; 
import { setUserInfo } from '../../../redux/reducers/clientReducer';

export default function Settings(){
    const dispatch = useDispatch(); 
    const user = useSelector(state => state.user.userInfo); 

    const [nextStep, setNextStep] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const [invite, setInvite] = useState({
        name: null,
        surname: null,
        email: null,
        role: null,
        birthDate: null,
        phone: null,
        gender: null,
        pass: null,
        iban: null,
        active: null
    });

    useEffect(() => {
        document.body.classList.remove("overflow-y-scroll");
        document.documentElement.classList.remove("overflow-y-scroll");
    }, [])

    const Render = (key) => {

        switch (key) {
            case "/Settings":
                return <Personal user={user} />
                break;
            case "/Settings/Security":
                return <Security />
                break;
            case "/Settings/Notifications":
                return <Notifications />
                break;
            case "/Settings/Updates":
                return <Updates />
                break;
            case "/Settings/System":
                return <System />
                break;
            default:
                return <Personal />
                break;
        }
    }



    return <div className="fixed top-0 left-0 bg-white dark:bg-slate-900 w-full h-full transition-all z-[12] overflow-y-auto">
        <div className="container w-11/12 md:w-3/4">
            <div className='flex w-full items-center justify-between py-5'>
                <div>
                    {/* <img src={Logo} className='w-9' alt="" /> */}
                    <Logo className="fill-primary dark:fill-slate-700 transition-all duration-500" w="40px " h="40px " />
                </div>
                <div className='text-lg font-medium text-slate-700 dark:text-slate-200'>Impostazioni</div>
                <div>
                    <Link to="/" className='flex flex-col relative'>
                        <X size="30" weight='bold' className='dark:text-slate-400' />
                        <span className='text-sm text-slate-400  text-center justify-center content-center'>Back</span>
                    </Link>
                </div>
            </div>
            <div className='w-full pb-20 pt-16 grid gap-4 md:gap-10 md:grid-cols-12'>

                <div className='md:col-span-3 lg:col-span-3 border-slate-200 border-0 md:border-r dark:border-slate-800 h-full md:overflow-clip overflow-auto pb-2 md:pb-0'>
                    <ul role="list" className="flex gap-4 md:block sticky md:top-32 top-10 -me-0.5">
                        <li className="gap-x-2 flex relative items-center">
                            <p className="text-gray-500 items-center text-md pb-0.5 flex-auto">
                                <Link to="/Settings" className={["font-normal dark:text-slate-100 font-heading border-b-[3px] md:border-b-[0px] flex w-full cursor-pointer py-2 transition-all duration-300", location.pathname === "/Settings" ? "text-slate-900 border-r-0 border-primary md:border-r-[3px] " : ""].join(" ")}>Personale</Link> 
                            </p>
                        </li>
                        <li className="gap-x-2 flex relative items-center">
                            <p className="text-gray-500 items-center text-md pb-0.5 flex-auto">
                                <Link to="/Settings/Security" className={[" font-normal dark:text-slate-100 font-heading border-b-[3px] md:border-b-[0px] flex w-full cursor-pointer py-2 transition-all duration-300", location.pathname === "/Settings/Security" ? "text-slate-900 border-r-0 border-primary md:border-r-[3px]" : ""].join(" ")}>Sicurezza</Link> 
                            </p>
                        </li>
                        <li className="gap-x-2 flex relative items-center">
                            <p className="text-gray-500 items-center text-md pb-0.5 flex-auto">
                                <Link to="/Settings/Notifications" className={[" font-normal dark:text-slate-100 font-heading border-b-[3px] md:border-b-[0px] flex w-full cursor-pointer py-2  transition-all duration-300", location.pathname === "/Settings/Notifications" ? "text-slate-900 border-r-0 border-primary md:border-r-[3px]" : ""].join(" ")}>Notifiche</Link> 
                            </p>
                        </li>
                        <li className="gap-x-2 flex relative items-center">
                            <p className="text-gray-500 items-center text-md pb-0.5 flex-auto">
                                <Link to="/Settings/System" className={[" font-normal dark:text-slate-100 font-heading border-b-[3px] md:border-b-[0px] flex w-full cursor-pointer py-2 transition-all duration-300", location.pathname === "/Settings/System" ? "text-slate-900 border-r-0 border-primary md:border-r-[3px]" : ""].join(" ")}>Sistema</Link> 
                            </p>
                        </li>
                        <li className="gap-x-2 flex relative items-center">
                            <p className="text-gray-500 items-center text-md pb-0.5 flex-auto">
                                <Link to="/Settings/Updates" className={[" font-normal dark:text-slate-100 font-heading border-b-[3px] md:border-b-[0px] flex w-full cursor-pointer py-2 transition-all duration-300", location.pathname === "/Settings/Updates" ? "text-slate-900 border-r-0 border-primary md:border-r-[3px]" : ""].join(" ")}>Aggiornamenti</Link> 
                            </p>
                        </li>
                    </ul>
                </div>
                <div className='md:col-span-9 lg:col-span-9'>
                    {Render(location.pathname)}
                </div>
            </div>
        </div>
        <Toaster />
    </div>
}