import {client} from '../../config.apollo';
// import { useNavigate } from 'react-router-dom';
import { StoreMenu, 
        DeleteMenu as TypedefsDelete, 
        UpdateMenu, 
        DeleteCatMenu as TypedefsDeleteCatMenu, 
        StoreCatMenu as TypeDefsStoreCatMenu, 
        UpdateCatMenu as TypeDefsUpdateCatMenu ,
        DeleteArticle as TypedefsDeleteArticle,
        StoreArticle as TypeDefsStoreArticle,
        UpdateArticle as TypeDefsUpdateArticle
    } from '../Query';
import {X} from '@phosphor-icons/react'
import {LogOut} from '../../components/RequireAuth';
import toast, { Toaster } from 'react-hot-toast';
import { clearUserInfo } from '../../redux/reducers/clientReducer';

const fetchData = (dispatch, variables, skip = null) => {
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        try {
            const { loading, error, data } = await client.mutate({ mutation: StoreMenu, 
                variables: {
                    storeMenu: {
                        nome: variables.nome,
                        attivo: variables.attivo,
                        prezzo_cost: parseFloat(variables.prezzo_cost),
                        prezzo_menu: variables.prezzo_menu
                    }
                }
            });
            myResolve(data.menuStore); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export default fetchData;

export function DeleteMenu(dispatch, variables, skip = null){
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        try {
            const { loading, error, data } = await client.mutate({ mutation: TypedefsDelete, 
                variables: {
                    menuId: variables._id
                }
            });
            myResolve(data.menuStore); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export function MenuUpdateMutation(dispatch, variables, skip = null){
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        try {
            const { loading, error, data } = await client.mutate({ mutation: UpdateMenu, 
                variables: {
                    updateMenu: {
                        _id: variables._id,
                        nome: variables.nome,
                        attivo: variables.attivo,
                        prezzo_cost: parseFloat(variables.prezzo_cost),
                        prezzo_menu: variables.prezzo_menu
                    }
                }
            });
            myResolve(data.menuUpdate); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export function DeleteCatMenu(dispatch, id, skip = null){
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        try {
            const { loading, error, data } = await client.mutate({ mutation: TypedefsDeleteCatMenu, 
                variables: {
                    catMenuId: id
                }
            });
            myResolve(data.menuStore); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export function StoreCatMenu(dispatch, variables, skip = null){
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }
        
        try {
            const { loading, error, data } = await client.mutate({ mutation: TypeDefsStoreCatMenu, 
                variables: {
                    storeCatMenu: {
                        image: variables.image,
                        nome: variables.nome,
                        order: variables.order,
                        ref_menu: variables.ref_menu,
                    }
                }
            });
            myResolve(data.menuStore); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};
export function UpdateCatMenu(dispatch, variables, skip = null){
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }
        
        try {
            const { loading, error, data } = await client.mutate({ mutation: TypeDefsUpdateCatMenu, 
                variables: {
                    updateCatMenu: {
                        image: variables.image,
                        nome: variables.nome,
                        order: variables.order,
                        ref_menu: variables.ref_menu,
                        _id: variables._id
                    }
                }
            });
            myResolve(data.catMenuUpdate); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export function StoreArticle(dispatch, variables, skip = null){
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }
        try {
            const { loading, error, data } = await client.mutate({ mutation: TypeDefsStoreArticle, 
                variables: {
                    storeArticle: {
                        name: variables.nome,
                        price: variables.price,
                        ingredients: variables.ingredients,
                        ingredientsStorage: variables.ingredientsStorage,
                        gluten_free: variables.glutenfree,
                        lactose_free: variables.lactosefree,
                        allergens: variables.allergen,
                        photo: variables.image,
                        Show: variables.visibletoggle,
                        frozen: variables.frozen,
                        vegan_ok: variables.vegan_ok,
                        vegetarian_ok: variables.vegetarian_ok,
                        cat_menu: variables.category,
                        note: variables.note,
                        prodotto_fresco_active: variables.prodotto_fresco_active
                    }
                }
            });
            myResolve(data.menuStore); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export function UpdateArticle(dispatch, variables, skip = null){
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        try {
            const { loading, error, data } = await client.mutate({ mutation: TypeDefsUpdateArticle, 
                variables: {
                    updateArticle: {
                        _id: variables._id,
                        name: variables.nome,
                        price: variables.price,
                        ingredients: variables.ingredients,
                        gluten_free: variables.glutenfree,
                        lactose_free: variables.lactosefree,
                        allergens: variables.allergen,
                        photo: variables.image,
                        Show: variables.visibletoggle,
                        frozen: variables.frozen,
                        vegan_ok: variables.vegan_ok,
                        vegetarian_ok: variables.vegetarian_ok,
                        cat_menu: variables.category,
                        note: variables.note,
                        prodotto_fresco_active: variables.prodotto_fresco_active
                    }
                }
            });
            
            myResolve(data.articleUpdate); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export function DeleteArticle(dispatch, id, skip = null){
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }
        
        try {
            const { loading, error, data } = await client.mutate({ mutation: TypedefsDeleteArticle, 
                variables: {
                    articleId: id
                }
            });
            myResolve(data.articleDelete); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};