import { gql } from '@apollo/client';

export const clientInfoProtected = gql`
    query Query {
        clientInfoProtected {
            _id
            activity_name
            activity_type
            owner
            owner_name
            public_email
            seo_url
            active
            Logo
            storageActive
            teamsManagment
        }
    }`;

export const clientInfoProtectedFull = gql`query Query {
    clientInfoProtected {
      _id
      activity_name
      activity_type
      Logo
      header_cover
      coperto
      wifi_space
      descriptions
      Street
      City
      postal_code
      facebook_link
      tiktok_link
      instagram_link
      owner_name
      public_email
      youtube_link
      delivery
      user_cart_premit
      glutenfree
      user_booking
      lactosefree
      veganfree
      vegetarianfree
      delivery_cost
      glutenfree_cost
      lactosefree_cost
      vegan_cost
      vegetarian_cost
      ratings
      parcheggio
      client_type
      client_type_filter
      pet
      phone
      user_calendar
      seo_url
      createAt
      updateAt
      clienti_calends {
        _id
        giorno
        chiuso
        ora_apert
        ora_chius
        ora_apert_sec_turno
        ora_chius_sec_turno
      }
      gallerys {
        _id
        url
        url_dump
        name
      }
      sponsor {
        _id
        sponsor_name
        sponsor_src
        sponsor_image
        type
        sponsor_image_dump
      }
      active
      coperto_active
      piscina_active
      area_fumatori_active
      area_giochi_active
      footer_present
      descriptions_full
      title_descriptions_full
      max_gallery
      translation
      teamsManagment 
    }
  }`;

export const updateClientInfo = gql`mutation Mutation($storeClientInfoInput: StoreClientInfoInput) {
  clientInfoUpdate(StoreClientInfoInput: $storeClientInfoInput) {
    _id
  }
}`;


export const addClientCalendar = gql`mutation Mutation($storeClientCalendarInput: StoreClientCalendarInput) {
  clientCalendarStore(StoreClientCalendarInput: $storeClientCalendarInput) {
    _id
    cliente
    giorno
    chiuso
    ora_apert
    ora_chius
    ora_apert_sec_turno
    ora_chius_sec_turno
  }
}`;

export const removeClientCalendar = gql`mutation Mutation($storeClientCalendarInput: StoreClientCalendarInput) {
  clientCalendarDelete(StoreClientCalendarInput: $storeClientCalendarInput) {
    _id
  }
}
`;


export const addGalleryImage = gql`mutation Mutation($galleryPush: GalleryPush) {
  clientGalleryStore(GalleryPush: $galleryPush) {
    _id
  }
}`;


export const removeGalleryImage = gql`mutation Mutation($galleryDel: GalleryDel) {
  clientGalleryDelete(GalleryDel: $galleryDel) {
    _id
  }
}`;

export const clientToken = gql`query Query {
  clientToken {
    active
  }
}`;