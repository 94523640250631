import React, { useState } from 'react'
import { Toggle } from '../../../../components/Toggles' 
import { useNavigate } from 'react-router-dom';
import {CurrencyEur,Notepad,Check,X } from '@phosphor-icons/react';
import Loading from '../../../../assets/svgs/loading.jsx';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import MenuMutation from '../../../../GraphQl/Conections/Mutation.Menus.js';
import { useDispatch } from 'react-redux';
// import { useMenu } from './../../../../components/Context.jsx';

export function AddMenu(params) { 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const { setMenus, fetchMenus } = useMenu();
    const [showPrice, setShowPrice] = useState(false);
    const [attivo, setAttivo] = useState(true);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [price, setPrice] = useState(0);

    const handleConferme = () => {

        if(name.length === 0){
            toast.error("Imposta un nome per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        setLoading(true);

        
        MenuMutation(dispatch, {nome: name, prezzo_cost: price, prezzo_menu: showPrice, attivo: attivo}).then(data => { 
            setTimeout(() => {

                toast.success("Menù creato", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: 'dark:bg-slate-800 dark:text-slate-100',
                    // Custom Icon
                    icon: <Check className='text-green-500 w-10' size={20} />,
                });
    
                setLoading(false);
                setShowPrice(false);
                setAttivo(true);
                setName("");
                setPrice(0);
                // setMenus(prevMenus => [...prevMenus, data]);
                // fetchMenus();
                navigate("/Menu");
                // window.location.href = "/Menu"
            }, 1000);
        });

        
    }

    return <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-3'>
        <div className='mx-auto flex w-full max-w-2xl flex-col'>
            <div className="space-y-1 mt-10">
                <fieldset className='relative'>
                    <legend className="mb-6">
                        <p className="text-lg font-medium dark:text-slate-200" tag="h3">Aggiungi un nuovo Menù</p>
                        <span className="text-xs font-normal text-slate-600 dark:text-slate-300">Informazioni del tuo menù</span>
                    </legend>
                </fieldset>
                <div className='grid grid-cols-12 gap-4'>
                    <div className="col-span-12">
                        <label htmlFor="name" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Nome menù
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white sm:max-w-md px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="name"
                                    name="name"
                                    required
                                    onChange={(e) => { setName(e.target.value) }}
                                    type="text"
                                    value={name}
                                    placeholder="Ex: Menù ristorante"
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-slate-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <Notepad className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-y-1 mt-10">
                <fieldset className='relative'>
                    <legend className="mb-6">
                        <p className="text-md font-medium dark:text-slate-200" tag="h3">Visibilità</p>
                    </legend>
                </fieldset>
                <div className='grid grid-cols-12 gap-4'>
                    
                    <div className="col-span-12">
                    <label htmlFor="price" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Vuoi rendere questo menù visibile da subito al pubblico?
                        </label>
                        <div className="mt-2">
                            <Toggle onClick={() => setAttivo(!attivo)} status={attivo}/>
                        </div>
                    </div>                    
                </div>
                
            </div>
            <div className="space-y-1 mt-10">
                <fieldset className='relative'>
                    <legend className="mb-6">
                        <p className="text-md font-medium dark:text-slate-200" tag="h3">Prezzo</p>
                        <span className="text-xs font-normal text-slate-600 dark:text-slate-300">Aggiungi un prezzo fisso per il tuo menù</span>
                    </legend>
                </fieldset>
                <div className='grid grid-cols-12 gap-4'>
                    
                    <div className="col-span-12">
                        <label htmlFor="price" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Vuoi rendere questo menù fisso con un prezzo?
                        </label>
                        <div className="mt-2">
                            <Toggle onClick={() => setShowPrice(!showPrice)} />
                        </div>
                    </div>

                    <div className={["col-span-12 transition-all ", showPrice ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-5"].join(" ")}>
                        <label htmlFor="price" className="block text-sm/6 font-medium text-slate-600">
                            Prezzo del menù
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white sm:max-w-md px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="price"
                                    name="price"
                                    min="0"
                                    onChange={(e) => { setPrice(parseFloat(e.target.value)) }}
                                    max="99"
                                    step={0.01}
                                    required
                                    type="number"
                                    defaultValue={0}
                                    value={price}
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <CurrencyEur className='text-gray-400 peer-focus:text-indigo-600 transition-colors'  size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                
            </div>
            


            <div className="mt-5 grid grid-cols-12 gap-4">
                    <div className="col-span-6">
                        <Link
                            to="/Menu"
                            className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-slate-200 disabled:bg-slate-600/50 px-3 py-3  font-normal leading-6 text-black shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                        >
                            <span className={['transition-all', "translate-y-0 opacity-100"].join(" ")}>Indietro</span>
                        </Link>
                    </div>
                    <div className="col-span-6">
                        <button
                            onClick={handleConferme}
                            disabled={loading}
                            className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-indigo-600 disabled:bg-indigo-600/50 px-3 py-3  font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                            <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Conferma</span>
                        </button>
                    </div>
                </div>
        </div>
        <Toaster />
    </div>
}