import React, { useState,useEffect } from 'react'
import { X,CheckCircle } from '@phosphor-icons/react';
import Loading from '../../../../../assets/svgs/loading.jsx';

export function Step2(props){
    const [ chooseRole, setChooseRole ] = useState(props.data.role);

    const roles = [
        {
            title: "Direttore di sala",
            desc: "Si tratta di una figura manageriale di primo livello, in un’attività ristorativa",
            id: "manager"
        },
        {
            title: "Cameriere",
            desc: "Il cameriere è uno dei volti del ristorante, si occupa della presentazione del menù e della carta dei vini, in assenza del sommelier",
            id: "cameriere"
        },
        {
            title: "Hostess di sala",
            desc: "La figura della hostess, che non deve necessariamente essere femminile, è dedicata all’accoglienza dei clienti, e all’accompagnamento ai tavoli o a eventuali terrazze o piani diversi da quello dell’ingresso. ",
            id: "hostess"
        },
        {
            title: "Barman / Barlady",
            desc: "Il barman o la barlady hanno il compito di miscelare vari ingredienti per creare cocktail, mantenere in ordine la postazione di lavoro e curare la presentazione delle bevande servite.",
            id: "brman_barlady"
        },
    ]

    useEffect(() => { 
        if(props.data.role != null){
            
            const maleInput = document.getElementById(props.data.role); 
            maleInput.checked = true; 
        }

    }, [props.data.role]);
    
    return <div>
            <div className=''>
                <h1 className='text-3xl font-normal'>Assegna un ruolo</h1>
                <p className='text-md font-normal text-slate-500 dark:text-slate-400 max-w-sm mt-2 leading-5'>Assegna il ruolo di un membro del team sul tuo account aziendale</p>
            </div>
            <div>
                <form >
                    <div className="space-y-12">
                        <div className=" my-10 w-full max-w-md ">
                            <fieldset aria-label="Server size" className=''>
                                {
                                    roles.map((item, i) => {

                                        return <div className="my-3 mt-0"  role="radiogroup" key={item.id}>
                                                <label htmlFor={"role["+item.id+"]"} onClick={(e) => setChooseRole("role["+item.id+"]")} aria-label="Hobby" aria-description="8GB, 4 CPUs, 160 GB SSD disk, $40 per month" className="sm:justify-between sm:flex shadow-md py-4 px-6 bg-white border-gray-300 border cursor-pointer relative rounded-lg focus-within:outline-slate-400 transition-all  focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed" id="headlessui-radio-:r1:" role="radio" aria-checked="false" tabIndex="-1" data-headlessui-state="">
                                                    <span className="flex justify-center">
                                                        <span className="text-sm flex justify-center items-center gap-4">
                                                            <div className='flex justify-center items-center '>
                                                                <input
                                                                    id={"role["+item.id+"]"}
                                                                    name={"roles"}
                                                                    value={item.id}
                                                                    type="radio"
                                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 peer/draft"
                                                                />
                                                            </div>
                                                            <div className='flex flex-col peer-checked/draft:hidden'>
                                                                <span className="text-gray-900 font-medium text-base">{item.title}</span>
                                                                <span className="text-gray-500 text-xs">
                                                                    <span className="sm:inline block">{item.desc} </span> 
                                                                </span>
                                                            </div>
                                                        </span>
                                                    </span>
                                                    <span aria-hidden="true" className="border-transparent border-1 rounded-md inset-1 absolute select-none "></span>
                                                </label>

                                                
                                            </div>
                                    })
                                }
                            </fieldset>

                            <div className="mt-2 grid grid-cols-12 gap-4">
                                <div className="col-span-6">
                                    <button
                                        onClick={props.PreviusStep}
                                        
                                        className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-slate-100 disabled:bg-slate-600/50 px-3 py-3  font-normal leading-6 text-black shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                                    >
                                        <span className={['transition-all', "translate-y-0 opacity-100"].join(" ")}>Indietro</span>
                                    </button>
                                </div>
                                <div className="col-span-6">
                                    <button
                                        
                                        onClick={(e) => props.NextStep(e, chooseRole)}
                                        disabled={!chooseRole}
                                        className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-indigo-600 disabled:bg-indigo-600/50 px-3 py-3  font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', false ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                                        <span className={['transition-all', false ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Continua</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    </div>
}