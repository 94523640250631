import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import {Check, User,Briefcase, Phone,City,MapPin,Textbox, X } from '@phosphor-icons/react'
import Editor from 'react-simple-wysiwyg';
import SingleUpload from '../../../../components/Uploader/single';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../../../../assets/svgs/loading.jsx';
import {UpdateClientInfo} from '../../../../GraphQl/Conections/Mutation.ClientInfo.js';
import { useDispatch } from 'react-redux';
import UploadFile from '../../../../GraphQl/Conections/Mutation.UploadFile.jsx';
import isValidURL from '../../../../middleware/isValidUrl.jsx';

const checkChanges = (vars, user, newV = false) => { 
    const changes = {}; 
    for (const key in vars) { 
        if (vars[key] != user[key]) { 
            if(newV){
                changes[key] = vars[key]; 
            }else{
                changes[key] = { 
                    oldValue: vars[key], 
                    newValue: user[key] 
                }; 
            }
        } 
    } return changes; 
};

export function General(props){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [scrollPosition, setScrollPosition] = useState(false);
    const [loading, setLoading] = useState(false);
    const [logo, setAvatar] = useState(props.user.Logo != undefined ? props.user.Logo : "");
    const [header_cover, setCover] = useState(props.user.header_cover != undefined ? props.user.header_cover : "");
    const [owner_name, setUserFullName] = useState(props.user.owner_name != undefined ? props.user.owner_name : "");
    const [activity_name, setActivityName] = useState(props.activity_name != undefined ? props.user.activity_name : "");
    const [activity_type, setActivity] = useState(props.activity_type != undefined ? props.user.activity_type : "");
    const [client_type, setActivitySec] = useState(props.client_type != undefined ? props.user.client_type : []);
    const [phone, setPhone] = useState(props.phone != undefined ? props.user.phone : "");
    const [city, setCity] = useState(props.City != undefined ? props.user.City : "");
    const [postal_code, setCap] = useState(props.postal_code != undefined ? props.user.postal_code : "");
    const [street, setStreet] = useState(props.Street != undefined ? props.user.Street : "");
    const [footer_present, setTitleFooter] = useState(props.footer_present != undefined ? props.user.footer_present : "");
    const [descriptions, setDescriptionFooter] = useState(props.descriptions != undefined ? props.user.descriptions : "");
    const [title_descriptions_full, setTilteDescription] = useState(props.title_descriptions_full != undefined ? props.user.title_descriptions_full : "");
    const [descriptions_full, setDescriptionFull] = useState(props.descriptions_full != undefined ? props.user.descriptions_full : "");
    const [removeAvatar, setRemoveAvatar] = useState(false);
    const [removeCover, setRemoveCover] = useState(false);
    
    const handleScroll = () => { 
        const position = window.pageYOffset;
        const Tpos = document.querySelector('.t-pos');
        const rect = Tpos.getBoundingClientRect();
        
        setScrollPosition(rect.top < 0); 
        
    }; 

    useEffect(() => {
        setAvatar(props.user.Logo != undefined ? props.user.Logo : "");
        setCover(props.user.header_cover != undefined ? props.user.header_cover : "");
        setUserFullName(props.user.owner_name != undefined ? props.user.owner_name : "");
        setActivityName(props.user.activity_name != undefined ? props.user.activity_name : "");
        setActivity(props.user.activity_type != undefined ? props.user.activity_type : "");
        setActivitySec(props.user.client_type != undefined ? props.user.client_type : []);
        setPhone(props.user.phone != undefined ? props.user.phone : "");
        setCity(props.user.City != undefined ? props.user.City : "");
        setCap(props.user.postal_code != undefined ? props.user.postal_code : "");
        setStreet(props.user.Street != undefined ? props.user.Street : "");
        setTitleFooter(props.user.footer_present != undefined ? props.user.footer_present : "");
        setDescriptionFooter(props.user.descriptions != undefined ? props.user.descriptions : "");
        setTilteDescription(props.user.title_descriptions_full != undefined ? props.user.title_descriptions_full : "");
        setDescriptionFull(props.user.descriptions_full != undefined ? props.user.descriptions_full : "");
    }, [props.user]);

    
    useEffect(() => { 
        document.body.addEventListener('scroll', handleScroll); 
        return () => { 
            document.body.removeEventListener('scroll', handleScroll); 
        }; 
    }, []);

    const groupCheck = [
        {
            label: 'Pizzeria',
            value: 'pizzeria',
        },
        {
            label: 'Ristorante',
            value: 'ristorante',
        },
        {
            label: 'Pub',
            value: 'pub',
        },
        {
            label: 'Bar',
            value: 'bar',
        },
        {
            label: 'Sala Ricevimenti',
            value: 'sala ricevimenti',
        },
        {
            label: 'Trattoria',
            value: 'trattoria',
        },
        {
            label: 'Osteria',
            value: 'osteria',
        },
        {
            label: 'Tavola calda',
            value: 'tavola calda',
        },
        {
            label: 'Rosticceria',
            value: 'rosticceria',
        },
        {
            label: 'Caffetteria',
            value: 'caffetteria',
        },
        {
            label: 'Lounge',
            value: 'lounge',
        },
        {
            label: 'Birreria',
            value: 'birreria',
        },
        {
            label: 'Wine bar',
            value: 'wine bar',
        },
        {
            label: 'Steakhouse',
            value: 'steakhouse',
        },
        {
            label: 'Sushi bar',
            value: 'sushi bar',
        },
        {
            label: 'Tavola fredda',
            value: 'tavola fredda',
        },
        {
            label: 'Gelateria',
            value: 'gelateria',
        },
        {
            label: 'Cucina etnica',
            value: 'cucina etnica',
        },
        {
            label: 'Fast food',
            value: 'fast food',
        },
        {
            label: 'Food truck',
            value: 'food truck',
        }
    ];

    const clientTyperFilter = (e) =>{
        const value = e.target.value;
        const checked = e.target.checked;
        if(checked){
            setActivitySec((prevState) => [...prevState, value]);
        }else{
            setActivitySec((prevItems) => prevItems.filter((item) => item !== value));
        }

    }

    const save = () => {
        

        if(owner_name.length === 0){
            toast.error("Imposta un nome completo per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        if(activity_name.length === 0){
            toast.error("Imposta il nome della tua attività per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        if(client_type.length === 0){
            toast.error("Imposta il tipo della tua attività per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        if(descriptions.length === 0){
            toast.error("Inserisci prima un testo da inserire a fondo pagina", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        if(city.length === 0 || postal_code.length === 0 || street.length === 0){
            toast.error("Compila i campi Via, Città e Cap per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }
        
        setLoading(true);

        if(typeof logo == "object" && logo != null || typeof header_cover == "object" && header_cover != null){
                //Per prima cosa invia l'immagine a graphql e poi inserisci nel db tutto il resto.
            if(typeof logo == "object" && logo != null){
                UploadFile(dispatch, logo).then(data => {
                    //Setta categoria nel db 
                    setAvatar(data.uploadFile);
                    handleOk(data.uploadFile, undefined);
                })
            }

            if(typeof header_cover == "object" && header_cover != null){
                UploadFile(dispatch, header_cover).then(data => {
                    //Setta categoria nel db 
                    handleOk(undefined, data.uploadFile);
                    setCover(data.uploadFile);
                })
            }
        }else {
            handleOk();
        }

        

    }

    const handleOk = (imageLogo = logo, imageCover = header_cover) => {
        if(typeof imageLogo == "object"){
            imageLogo = undefined;
        }

        if(typeof imageCover == "object"){
            imageCover = undefined;
        }

        const vars = {Logo: imageLogo,
            header_cover:imageCover,
            owner_name:owner_name,
            activity_name: activity_name,
            activity_type: activity_type,
            client_type: client_type,
            phone: phone,
            City: city,
            postal_code: postal_code,
            Street: street,
            footer_present: footer_present,
            descriptions: descriptions,
            title_descriptions_full: title_descriptions_full,
            descriptions_full: descriptions_full
        };
        const changes = checkChanges(vars, props.user, true); 

        UpdateClientInfo(dispatch,  changes,props.user).then(data => { 
            setTimeout(() => {

                toast.success("Informazioni aggiornate", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: 'dark:bg-slate-800 dark:text-slate-100',
                    // Custom Icon
                    icon: <Check className='text-green-500 w-10' size={20} />,
                });

                setLoading(false);
                // navigate("/Menu/Articles");
            }, 1000);
        });
        
    }
    

    return <div>
        <div className='flex items-center justify-between p-4'>
            <div>
                <p className='text-sm font-medium uppercase dark:text-slate-100'>Informazioni Generali</p>
                <p className="text-slate-400 text-xs  dark:text-slate-300">Modifica le informazioni generali della tua attività</p>
            </div>
            <div className='flex items-center gap-2 t-pos'>
                <button className='rounded-md py-2 px-5 text-slate-800 bg-white border border-slate-300 w-24' onClick={() => navigate('/')}>Cancella</button>
                <button
                    onClick={save}
                    disabled={loading || 
                        owner_name.length == 0 || 
                        activity_name.length == 0 || 
                        client_type.length == 0 || 
                        descriptions.length == 0 || 
                        city.length == 0 || 
                        postal_code.length == 0 || 
                        street.length == 0}
                    className="rounded-md min-w-32 py-2 px-5 text-slate-100 bg-violet-500 border-violet-500 hover:bg-violet-500/50 hover:border-violet-500/50 relative"
                >
                    <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                    <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Salva</span>
                </button>
            </div>
        </div>
        <div className='p-4'>
            <div className='mx-auto max-w-lg space-y-12 py-8'>
                <div className='space-y-5'>
                    <div>
                        <p className="text-md font-medium dark:text-slate-100">
                            Logo Attività
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Inserisci il logo della tua attività</p>
                    </div>
                    <div className='relative flex flex-col items-center justify-center gap-4'>
                        <SingleUpload multiple={false} removeImage={(e) => setRemoveAvatar(e)} files={(file) => setAvatar(file[0])} defaultValue={logo == null ? null : isValidURL(logo) ? logo : process.env.REACT_APP_SERVER_IMAGE_ENDPOINT + "/public/images/dump/small-" + logo} />
                    </div>
                </div>
                <div className='space-y-5'>
                    <div>
                        <p className="text-md font-medium dark:text-slate-100">

                            Cover pagina menù
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Inserisci una cover di presentazione della tua attività</p>
                    </div>
                    <div className='relative flex flex-col items-center justify-center gap-4'>
                        <SingleUpload 
                            multiple={false} 
                            removeImage={(e) => setRemoveCover(e)}
                            files={(file) => setCover(file[0])} 
                            defaultValue={header_cover == null ? null : isValidURL(header_cover) ? header_cover : process.env.REACT_APP_SERVER_IMAGE_ENDPOINT + "/public/images/original/" + header_cover}
                            defaultWatermark={"https://tairo.cssninja.io/img/illustrations/wizard/design.svg"} 
                            className={"w-full"} 
                            classNameParentPreview={"bg-white rounded-lg shadow-lg  transition-all w-full size-full h-40 duration-500"} 
                            classNamePreview="bg-slate-200 select-none dark:bg-slate-700/60 inset-0 w-full h-full rounded-lg object-cover object-center" 
                            classNameButtons="-bottom-2 -right-2"
                            dragAndDrop={false} 
                        />
                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                        <p className="text-md font-medium dark:text-slate-100">

                            Informazioni del Profilo
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Altri meritano di conoscerti di più</p>
                    </div>
                    <div className='relative flex flex-col  gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4'>
                            <div className="col-span-12 sm:col-span-6">
                                <div className='flex relative'>
                                    
                                    <input
                                        id="completeName"
                                        name="completeName"
                                        type="text"
                                        placeholder='Mario Rossi'
                                        defaultValue={owner_name}
                                        value={owner_name}
                                        onChange={(e) => setUserFullName(e.target.value)}
                                        required
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="completeName" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                                        <User className=' absolute top-2 left-2' size="20" weight='duotone' />
                                    </label>
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <div className='flex relative'>
                                    <input
                                        id="activity_name"
                                        name="activity_name"
                                        defaultValue={activity_name}
                                        value={activity_name}
                                        onChange={(e) => setActivityName(e.target.value)}
                                        type="text"
                                        placeholder='Nome Attività'
                                        required
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="activity_name" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                                        <Briefcase className=' absolute top-2 left-2' size="20" weight='duotone' />
                                    </label>
                                </div>
                            </div>

                            <div className="col-span-12 mt-4">
                                <p className="text-slate-400 text-xs mb-2">Tipo attività</p>    
                                <div className="flex gap-4 ">
                                    <label className="space-x-2 flex justify-center items-center">
                                        <input name="activity_type" className="peer " disabled type="radio" value="store" />
                                        <span className='peer-disabled:text-slate-500'>Store</span>
                                    </label>
                                    <label className="space-x-2 flex justify-center items-center">
                                        <input name="activity_type" className="peer" type="radio" value="risto" checked />
                                        <span className='peer-disabled:text-slate-500'>Ristoro</span>
                                    </label>
                                    <label className="space-x-2 flex justify-center items-center">
                                        <input name="activity_type" className="peer" disabled type="radio" value="evento" />
                                        <span className='peer-disabled:text-slate-500'>Evento</span>
                                    </label>
                                </div>
                            </div>

                            <div className="col-span-12 mt-4">
                                <p className="text-slate-400 text-xs mb-2">Attività secondaria</p>    
                                <div className="grid grid-cols-12 gap-4">
                                    {
                                        groupCheck.map(item => {
                                            return <div className='col-span-4 '>
                                                    <label className=" space-x-2 flex  items-center">
                                                        <input name="client_type_filter" className="peer transition-all" onChange={clientTyperFilter} type="checkbox" checked={client_type.filter(e => e == item.value)[0]} value={item.value} />
                                                        <span className='peer-disabled:text-slate-500 dark:text-slate-300'>{item.label}</span>
                                                    </label>
                                                </div>
                                        })
                                    }
                                </div>
                            </div>

                            <div className="col-span-12">
                                <div className='flex relative'>
                                    <input
                                        id="phone"
                                        name="phone"
                                        type="text"
                                        defaultValue={phone}
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder='Tel. Es: 327000000'
                                        required
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="phone" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                        <Phone className=' absolute top-2 left-2' size="20" weight='duotone' />
                                    </label>
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <div className='flex relative'>
                                    <input
                                        id="city"
                                        name="city"
                                        defaultValue={city}
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        type="text"
                                        placeholder='Città della tua attività'
                                        required
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="city" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                        <City className=' absolute top-2 left-2' size="20" weight='duotone' />
                                    </label>
                                </div> 
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <div className='flex relative'>
                                    <input
                                        id="postal_code"
                                        name="postal_code"
                                        defaultValue={postal_code}
                                        value={postal_code}
                                        onChange={(e) => setCap(e.target.value)}
                                        type="number"
                                        placeholder='Cap della tua città'
                                        required
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="postal_code" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                        <City className=' absolute top-2 left-2' size="20" weight='duotone' />
                                    </label>
                                </div>
                            </div>
                            <div className="col-span-12">
                                <div className='flex relative'>
                                    <input
                                        id="Street"
                                        name="Street"
                                        defaultValue={street}
                                        value={street}
                                        onChange={(e) => setStreet(e.target.value)}
                                        type="text"
                                        placeholder='Via della tua attività'
                                        required
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="Street" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                        <MapPin className=' absolute top-2 left-2' size="20" weight='duotone' />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                        <p className="text-md font-medium dark:text-slate-100">

                            Descrizione fondo pagina
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Arrichisci il tuo Menù digitale inserendo la descrizione </p>
                    </div>
                    <div className='relative flex flex-col  gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4'>
                            <div className="col-span-12">
                                <div className='flex relative'>
                                    <input
                                        id="footer_present"
                                        name="footer_present"
                                        type="text"
                                        placeholder='Titolo descrizione footer'
                                        defaultValue={footer_present}
                                        value={footer_present}
                                        onChange={(e) => setTitleFooter(e.target.value)}
                                        required
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="footer_present" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                        <Textbox  className=' absolute top-2 left-2' size="20" weight='duotone' />
                                    </label>
                                </div>
                            </div>
                            <div className="col-span-12">
                                <div className='flex relative'>
                                    <textarea
                                        id="descriptions"
                                        name="descriptions"
                                        defaultValue={descriptions}
                                        value={descriptions}
                                        onChange={(e) => setDescriptionFooter(e.target.value)}
                                        type="text"
                                        placeholder="Breve descrizione dell'attività per il fondo pagina"
                                        required
                                        rows={4}
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="descriptions" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                        <Textbox  className=' absolute top-2 left-2' size="20" weight='duotone' />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                        <p className="text-md font-medium dark:text-slate-100">

                            Descrizione estesa
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Arrichisci il tuo Menù digitale inserendo la descrizione </p>
                    </div>
                    <div className='relative flex flex-col  gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4'>
                            <div className="col-span-12">
                                <div className='flex relative'>
                                    <input
                                        id="title_descriptions_full"
                                        name="title_descriptions_full"
                                        type="text"
                                        defaultValue={title_descriptions_full}
                                        value={title_descriptions_full}
                                        onChange={(e) => setTilteDescription(e.target.value)}
                                        placeholder='Titolo descrizione estesa: Chi siamo'
                                        required
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="title_descriptions_full" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                        <Textbox  className=' absolute top-2 left-2' size="20" weight='duotone' />
                                    </label>
                                </div>
                            </div>
                            <div className="col-span-12">
                                <div className='flex relative'>
                                    {/* <textarea
                                        id="descriptions_full"
                                        name="descriptions_full"
                                        type="text"
                                        placeholder="Desxcrizione estesa"
                                        required
                                        rows={4}
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    /> */}
                                    <Editor className="min-h-[500px]"
                                        onChange={(e) => setDescriptionFull(e.target.value)} value={descriptions_full} name="descriptions_full" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className={[' fixed p-4 left-2/4 -translate-x-2/4  bg-white gap-4 flex border border-slate-200 rounded-lg transition-all shadow-md', scrollPosition ? "opacity-100 bottom-3" : "opacity-0 -bottom-20"].join(" ")}>
                    <button className='rounded-md py-2 px-5 text-slate-800 bg-white border border-slate-300 w-24' onClick={() => navigate('/')}>Cancella</button>
                    <button
                        onClick={save}
                        disabled={loading || 
                            owner_name.length == 0 || 
                            activity_name.length == 0 || 
                            client_type.length == 0 || 
                            descriptions.length == 0 || 
                            city.length == 0 || 
                            postal_code.length == 0 || 
                            street.length == 0}
                        className="rounded-md min-w-32 py-2 px-5 text-slate-100 bg-violet-500 border-violet-500 hover:bg-violet-500/50 hover:border-violet-500/50 relative"
                    >
                        <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                        <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Salva</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
}