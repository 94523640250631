
export function WithDot(props) {
    let type,typeDot;
    if(props.type == "success"){
        type = "bg-green-100 text-green-700 ring-gray-500/0";
        typeDot = "bg-green-500"
    }else if(props.type == "warning"){
        type = "bg-orange-100 text-orange-700 ring-gray-500/0";
        typeDot = "bg-orange-500"
    }else if(props.type == "info"){
        type = "bg-sky-100 text-sky-700 ring-gray-500/0";
        typeDot = "bg-sky-500"
    }else if(props.type == "allert"){
        type = "bg-yellow-100 text-yellow-700 ring-gray-500/0";
        typeDot = "bg-yellow-500"
    }else if(props.type == "error"){
        type = "bg-red-100 text-red-700 ring-gray-500/0";
        typeDot = "bg-red-500"
    }else{
        type = "bg-gray-100 text-gray-700 ring-gray-500/0";
        typeDot = "bg-gray-500"
    }

    return (
        <span className={["inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset ", type, props.className].join(" ")}>
            <span className={["w-1.5 h-1.5 relative block rounded-full mr-2 mt-0.5", typeDot].join(" ")} />
            {props.name}
        </span>
    )
  }
  