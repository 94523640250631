import React, {useState, useEffect} from 'react'
import {Upload, X} from '@phosphor-icons/react';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import MynuLogo from '../../assets/images/previewImage.webp';
import './style.css';

const formatBytes = (bytes, decimals = 2) => { 
    if (bytes === 0) return '0 Bytes'; 
    const k = 1024; 
    const dm = decimals < 0 ? 0 : decimals; 
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; 
    const i = Math.floor(Math.log(bytes) / Math.log(k)); 
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]; 
};

const limits = (n, n2) => {
    return (n + n2);
}

export default function UploadFiles(props){
    const [files, setFiles] = useState([]);
    const [imagesPlus, setImagePlus] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [previewsPlus, setPreviewsPlus] = useState([]);
    const [maxFile, setMaxFile] = useState([]);
    const [removeOldFiles, setRemoveOldFiles] = useState([]);

    const selectFiles = (e) => {
        // setFiles([...files, e.target.files]);
        const selectedFiles = Array.from(e.target.files); // Converte FileList in un array

        // Aggiungi i nuovi file allo stato esistente
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    
        // Genera le anteprime dei file
        const filePreviews = selectedFiles.map((file) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          return new Promise((resolve) => {
            reader.onload = () => resolve(reader.result);
          });
        });
    
        // Aggiorna lo stato delle anteprime
        Promise.all(filePreviews).then((images) => {
          setPreviews((prevPreviews) => [...prevPreviews, ...images]);
        });
    
        // Passa i file selezionati al componente padre (se necessario)
        props.files(selectedFiles);
    }

    const removeItem = (e, index) => { 
        const newSelectedFiles = files.filter((_, i) => i !== index); 
        const newPreviews = previews.filter((_, i) => i !== index); 
        setFiles(newSelectedFiles); 
        setPreviews(newPreviews);
        setMaxFile(files.length + imagesPlus.length - 1);
    }

    const removeItemPlus = (e, index) => { 
        setRemoveOldFiles([...removeOldFiles, imagesPlus.filter((_, i) => i == index)[0]]);
        const newSelectedFiles = imagesPlus.filter((_, i) => i !== index); 
        const newPreviews = previewsPlus.filter((_, i) => i !== index); 
        setImagePlus(newSelectedFiles); 
        setPreviewsPlus(newPreviews);
        setMaxFile(files.length + imagesPlus.length - 1);
    }

    useEffect(() => {
        setMaxFile(limits(imagesPlus.length, props.defaultValue.length));
        if(props.defaultValue.length > 0){
            setImagePlus(props.defaultValue);
            props.defaultValue.forEach(element => {
                setPreviewsPlus([...previews, element.url_dump]);
            });
        }
    }, [props.defaultValue])

    useEffect(() => {
        props.fileDefaultRemoved(removeOldFiles);
    }, [removeOldFiles])

   
    return <div>
            <div className='relative border-slate-300 dark:border-slate-700 hover:border-slate-400 focus:border-slate-400 dark:hover:border-slate-600 dark:focus:border-slate-700 group cursor-pointer rounded-lg border-[3px] border-dashed p-8 transition-colors duration-300'>
                <div className='p-5 text-center flex flex-col space-y-2'>
                    <Upload size={32} weight="duotone" className='text-slate-400 mx-auto' />
                    <h4 className="text-slate-400 font-sans text-sm"> Drop files to upload </h4>
                    <p className='text-slate-400 font-sans text-[0.7rem] font-semibold uppercase'>OR</p>
                    <label for="file" className="text-slate-400 group-hover:text-purple-500 group-focus:text-purple-500 cursor-pointer font-sans text-sm underline underline-offset-4 transition-colors duration-300"> Select files </label>
                </div>
                <input type="file" className='absolute top-0 left-0 w-full h-full opacity-0' onChange={selectFiles} multiple id="file" />
            </div>
            <div className="flex justify-between py-4">
                <div className='text-slate-600 text-sm'>Files</div>
                <div className='text-slate-600 text-sm '>{maxFile}/{props.maxFile}</div>
            </div>
            <div className='relative'>
                <TransitionGroup component="ul" className="mt-6 space-y-2 h-full relative">
                    {files.length > 0 ? files.map((item, index) => { 
                        
                        return <CSSTransition key={index} timeout={300} classNames="fade">
                                <li className='!relative'>
                                    <div className="border-slate-200 dark:border-slate-700 dark:bg-slate-800 relative flex items-center justify-end gap-2 rounded-xl border bg-white p-3">
                                        <div className="flex items-center gap-2">
                                            <div className="shrink-0">
                                                <img src={previews[index] == null ? props.defaultWatermark != undefined ? props.defaultWatermark : MynuLogo : previews[index]} alt="Image preview" className='size-14 rounded-xl object-cover object-center' />
                                            </div>
                                            <div className="font-sans">
                                                <span className="text-slate-800 dark:text-slate-100 line-clamp-1 block text-sm text-clip overflow-hidden ">{item.name}</span>
                                                {item.size ? <span className="text-slate-400 block text-xs">{formatBytes(item.size)}</span> : <></>}
                                            </div>
                                        </div>
                                        <div className="ms-auto w-32 px-4 transition-opacity duration-300 opacity-100">
                                            {
                                                props.startUpload ? 
                                                    <div role="progressbar" aria-valuenow="0" aria-valuemax="100" className="relative h-1 w-full bg-slate-200 rounded-lg overflow-hidden">
                                                        <div className="absolute top-0 left-0 h-1 bg-purple-500 animate-progress scale-x-[100%] w-full "></div> 
                                                        <div className="absolute top-0 left-0 h-1 bg-purple-500 animate-secondaryProgress -translate-x-[1] w-full "></div> 
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <div className="flex gap-2">
                                            <button onClick={(e) => removeItem(e, index)} className="border-slate-200 hover:border-purple-500 text-slate-700 dark:text-slate-200 hover:text-purple-600 dark:border-slate-700 dark:bg-slate-900 dark:hover:border-purple-500 dark:hover:text-purple-600 relative flex size-8 cursor-pointer items-center justify-center rounded-full border bg-white transition-colors duration-300" type="button" tooltip="Remove">
                                                <X size="15" weight='bold' />
                                                <span className="sr-only">Remove</span>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </CSSTransition>
                    }) : <></>}
                    {imagesPlus.length > 0 ? imagesPlus.map((item, index) => { 
                        return <CSSTransition key={index} timeout={300} classNames="fade">
                                <li className='!relative'>
                                    <div className="border-slate-200 dark:border-slate-700 dark:bg-slate-800 relative flex items-center justify-end gap-2 rounded-xl border bg-white p-3">
                                        <div className="flex items-center gap-2">
                                            <div className="shrink-0">
                                                <img src={item.url_dump == null ? props.defaultWatermark != undefined ? props.defaultWatermark : MynuLogo : item.url_dump} alt="Image preview" className='size-14 rounded-xl object-cover object-center' />
                                            </div>
                                            <div className="font-sans">
                                                <span className="text-slate-800 dark:text-slate-100 line-clamp-1 block text-sm text-clip overflow-hidden ">{item.name}</span>
                                            </div>
                                        </div>
                                        <div className="ms-auto w-32 px-4 transition-opacity duration-300 opacity-100">
                                            
                                        </div>
                                        <div className="flex gap-2">
                                            <button onClick={(e) => removeItemPlus(e, index)} className="border-slate-200 hover:border-purple-500 text-slate-700 dark:text-slate-200 hover:text-purple-600 dark:border-slate-700 dark:bg-slate-900 dark:hover:border-purple-500 dark:hover:text-purple-600 relative flex size-8 cursor-pointer items-center justify-center rounded-full border bg-white transition-colors duration-300" type="button" tooltip="Remove">
                                                <X size="15" weight='bold' />
                                                <span className="sr-only">Remove</span>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </CSSTransition>
                    }) : <></>}
                </TransitionGroup>
            </div>
    </div>
}