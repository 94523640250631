import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import {Budges, WithDot} from '../../../components/badgets';
import { Envelope ,Plus, Eye,ChartBar,User,CreditCard,Money  } from '@phosphor-icons/react';
import AreaChart from './Components/Carth/Area';
import GestoreAvatar from '../../../assets/images/avatars/mynu-gestore.png';
import ClientStat from '../../../GraphQl/Conections/Query.ClientStat';
import Modal from '../../../components/Modal';
import { useDispatch } from 'react-redux';
import isValidURL from '../../../middleware/isValidUrl.jsx';
import {Notifics } from '../../../GraphQl/Conections/Query.Notific';

export default function Dashboard(props) {
    const dispatch = useDispatch();
    const [clientStat, setClientStat] = useState({});
    const [notifics, setNotifics] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const saveModal = () => setIsModalOpen(false); 
    const closeModal = () => setIsModalOpen(false);
    const [visualRealTime, setVisualRealTime] = useState(0); 
    const { 
        owner_name = "",
        Logo = false,
        seo_url = "",
      } = props.user || {}; // Fallback a un oggetto vuoto se props.data è null/undefined
  

      useEffect(() => {
        ClientStat(dispatch).then(data => { 
            setClientStat(data);
        });
        Notifics(dispatch).then(data => { 
            setNotifics(data);
        });
      }, [])

      useEffect(() => {
        if(seo_url === undefined) return
        // Connetti al WebSocket server
        const ws = new WebSocket(process.env.REACT_APP_WS_SOCKET);

        // Invia il seo_url per sottoscrivere gli aggiornamenti
        ws.onopen = () => {
            ws.send(JSON.stringify({
                type: 'initialState',
                menuId: seo_url,
            }));
        };

        
        // Ricevi aggiornamenti in tempo reale
        ws.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                if (data.type === 'initialState') {
                    // console.log('Stato iniziale ricevuto:', data);
                    // Visualizza lo stato iniziale nell'interfaccia utente
                    if(data.activeViews === undefined || data.activeViews === null) {
                        setVisualRealTime(0);

                    }else setVisualRealTime(data.activeViews);
                } else if (data.type === 'update') {
                    // console.log(`Aggiornamento ricevuto: Menù ${data.menuId}, Visualizzazioni attive: ${data.activeViews}`);

                    // Aggiorna solo il menù specifico nell'interfaccia utente
                    setVisualRealTime(data.activeViews);
                }
            } catch (error) {
                console.error('Errore durante la gestione del messaggio:', error);
            }
        };

        // Gestisci errori
        ws.onerror = (error) => {
            console.error('Errore WebSocket:', error);
            setVisualRealTime("Nan");
        };
      }, [seo_url])
      
      
      //max-w-full mx-2 sm:mx-6 lg:mx-8 py-5 pr-4 pl-4 md:pl-0 
// <div className={['max-w-full px-2 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 auto-cols-max py-5 gap-4', !props.asideNavToggle ? "!pl-4" : "pl-2"].join(" ")}>
    return <div className={['mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-3', /*!props.asideNavToggle ? "!mx-4" : "mx-2"*/].join(" ")}>
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                
                <div className="ltablet:col-span-8 col-span-12 lg:col-span-8">
                    <div className='flex flex-col gap-6'>
                        {/* Welcome card */}
                        <div className="w-full bg-violet-700 px-10 py-8 rounded-xl text-white flex flex-col items-center gap-y-4 md:flex-row">
                            <div className="flex flex-1 flex-col px-4 items-center md:items-start">
                                <div className="rounded-full overflow-hidden w-16 h-16 outline-slate-300/50 outline outline-offset-4 outline-4 mb-4">
                                    <img className="" src={Logo.length == 0 || Logo == null ? GestoreAvatar : isValidURL(Logo) ? Logo : process.env.REACT_APP_SERVER_IMAGE_ENDPOINT + "/public/images/dump/small-" + Logo} alt="" />
                                </div>
                                <h2 className="text-3xl font-semibold">Bentornato, {owner_name.length > 0 ? owner_name.split(' ')[0] : ""}.</h2>
                            </div>
                            <div className="flex min-w-64 h-full flex-1 flex-col px-4 sm:px-6 w-full">
                                <div className="w-full px-2">
                                    <h2 className="text-base font-medium">Il mio Team</h2>
                                    <p className="text-xs mt-1">Visualizza il tuo team al completo visualizza orari completi</p>
                                    <div className="flex mt-3 gap-2">
                                        {/* <div className="mask-avatar mask relative inline-flex justify-center w-10 h-10">
                                            <img className="w-full h-full" src="https://tairo.cssninja.io/img/avatars/3.svg" alt="" />
                                        </div>
                                        <div className="mask-avatar mask relative inline-flex justify-center w-10 h-10">
                                            <img className="w-full h-full" src="https://tairo.cssninja.io/img/avatars/4.svg" alt="" />
                                        </div>
                                        <div className="mask-avatar mask relative inline-flex justify-center w-10 h-10">
                                            <img className="w-full h-full" src="https://tairo.cssninja.io/img/avatars/5.svg" alt="" />
                                        </div> */}
                                        <a href="#" onClick={() => setIsModalOpen(!isModalOpen)} className="focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed focus-within:outline-slate-100 transition-all rounded-lg bg-slate-100 text-slate-900 relative justify-center w-10 h-10 flex items-center ">
                                            <Plus />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full px-4 sm:px-6 md:border-l border-0 border-slate-100/50">
                                <div className="px-2">
                                    <h2 className="text-base font-medium">Team a lavoro</h2>
                                    <p className="text-xs mt-1 text-wrap">Imposta il lavoro al tuo team, visualizza chi è attivo in questo momento.</p>
                                    <div className="flex mt-3 gap-2">
                                        <a onClick={() => setIsModalOpen(!isModalOpen)} className="w-full cursor-pointer focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed focus-within:outline-slate-100 transition-all rounded-lg bg-slate-100 text-slate-900 relative justify-center w-10 h-10 flex items-center ">
                                            Pianifica Lavoro
                                        </a>

                                        <Modal isOpen={isModalOpen} onClose={closeModal} onSave={saveModal} title={'In arrivo'}>
                                            <h3 className='text-center text-lg'>Resta aggiornato</h3>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="flex flex-col items-center justify-between gap-6 sm:flex-row">
                            <h2 className="text-slate-800 dark:text-slate-100  font-semibold text-lg">Ultimi messaggi</h2>
                        </div>
                        <div className="mt-6 grid grid-cols-12 gap-x-6 gap-y-12">
                            <div className="col-span-12 sm:col-span-5">
                                <div className="col-span-12 sm:col-span-5">
                                    <div className="flex h-full max-w-[240px] flex-col justify-center gap-2">
                                        <h3 className="text-slate-800 dark:text-slate-100 font-normal text-md mb-1">
                                            <span>Notifiche</span>
                                        </h3>
                                        <p className="leading-none">
                                            <span className="text-slate-500 dark:text-slate-400  text-xs">Controlla le ultime notifiche e i messaggi ricevuti per rimanere aggiornato su tutte le attività recenti. Visualizza rapidamente le comunicazioni importanti e le novità direttamente dalla tua dashboard.</span>
                                        </p>
                                        <a aria-current="page" href="/#" className="router-link-active router-link-exact-active text-violet-500 font-sans text-xs underline underline-offset-4"><span>Mostra altro</span></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-7">
                                <div className="space-y-2">
                                    {notifics.length > 0 ? notifics.map((notific, index) => {
                                        <div className="cursor-pointer select-none bg-white dark:bg-slate-800 dark:ring-0 rounded-md w-full relative py-6 sm:py-2 ring-1 ring-slate-200 shadow-md">
                                            <div className="flex w-full flex-col sm:flex-row sm:items-center relative ">
                                                    <div className="relative mb-4 flex grow items-center gap-2 px-6 sm:mb-0 sm:px-2 h-10">
                                                        <span className="text-slate-400 absolute font-sans text-xs font-medium uppercase sm:-top-10 sm:start-2 hidden">type</span>
                                                        <div className=" rounded-md bg-violet-500/20 text-violet-500 w-10 h-10 flex justify-center items-center" icon="ph:envelope-duotone">
                                                            <Envelope weight="duotone" size={20} />
                                                        </div>
                                                        <div>
                                                            <h4 className="text-md font-medium leading-tight text-slate-700 dark:text-slate-100">
                                                                <span>Messages</span>
                                                            </h4>
                                                            <p className="text-xs font-normal leading-tight text-slate-500 dark:text-slate-400">
                                                                <span>Inbox messages</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col gap-2 sm:flex-row sm:items-center">
                                                        <div className="relative flex h-8 items-center justify-end px-6 sm:h-10 sm:justify-center sm:px-2 w-full sm:w-10">
                                                            <span className="text-slate-400 absolute start-8 top-1/2 mx-auto -translate-y-1/2 text-center font-sans text-xs font-medium uppercase sm:inset-x-0 sm:-top-10 sm:translate-y-0 md:hidden">count</span>
                                                            <span className="text-slate-500 dark:text-slate-400 font-sans text-sm">5</span>
                                                        </div>
                                                        <div className="relative flex h-8 items-center justify-end px-6 sm:h-10 sm:justify-center sm:px-2 w-full sm:w-16">
                                                            <span className="text-slate-400 absolute start-8 top-1/2 mx-auto -translate-y-1/2 text-center font-sans text-xs font-medium uppercase sm:inset-x-0 sm:-top-10 sm:translate-y-0 md:hidden">status</span>
                                                            {/* <span className="nui-tag nui-tag-sm nui-tag-rounded-full nui-tag-pastel nui-tag-success">news</span> */}
                                                            <Budges className="dark:bg-gray-800/50 dark:text-gray-400 !rounded-full">pending</Budges>
                                                        </div>
                                                        <div className="relative flex h-8 items-center justify-end px-6 sm:h-10 sm:justify-center sm:px-2">
                                                            <span className="text-slate-400 absolute start-8 top-1/2 mx-auto -translate-y-1/2 text-center font-sans text-xs font-medium uppercase sm:inset-x-0 sm:-top-10 sm:translate-y-0 md:hidden">action</span>
                                                            <span className="text-violet-500 font-sans text-xs sm:pe-2">Visualizza </span>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    }) : <div className='text-slate-500 dark:text-slate-400 text-xs text-center my-0 md:my-16'>Non ci sono notifiche disponibili</div> }
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col items-center justify-between gap-6 md:flex-row mt-6 ">
                            <div className='bg-white dark:bg-slate-800 rounded-xl w-full relative ring-1 ring-slate-200 dark:ring-slate-800 shadow-md py-4 px-6 items-center'>
                                <h2 className='text-slate-500 uppercase text-sm font-medium'>Transazioni recenti</h2>

                                <div className='mt-4 overflow-x-auto '>
                                    <table className="w-full whitespace-nowrap ">
                                        <thead>
                                            <tr>
                                                <th className="w-1/5"></th>
                                                <th className="w-2/5"></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr tabIndex="0">
                                                <td className="xs:pe-6 py-2">
                                                    <span className=" text-sm font-medium text-slate-400">May 26, 2023</span>
                                                </td>
                                                <td className="py-2">
                                                    <span className="text-sm font-medium  text-slate-600 dark:text-slate-300">Victoria's Treats</span>
                                                </td>
                                                <td className="px-4 py-2">
                                                    <span className=" text-sm font-bold  text-slate-800 dark:text-slate-100">$52.14</span>
                                                </td>
                                                <td className="px-4 py-2">
                                                    <span className=" text-sm font-medium  text-slate-400">**** 7623</span>
                                                </td>
                                                <td className="px-4 py-2">
                                                    <Budges className="bg-sky-50 text-sky-700 ring-sky-600/20 !rounded-full">processing</Budges>
                                                </td>
                                                <td className="px-4 py-2">
                                                    <div className="text-slate-400 flex items-center gap-2">
                                                        <CreditCard size="20" weight='duotone' />
                                                        <span className=" text-sm font-medium  text-slate-400 -mt-1">credit card</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr tabIndex="0">
                                                <td className="xs:pe-6 py-2">
                                                    <span className=" text-sm font-medium text-slate-400">May 26, 2023</span>
                                                </td>
                                                <td className="py-2">
                                                    <span className="text-sm font-medium  text-slate-600 dark:text-slate-300">Victoria's Treats</span>
                                                </td>
                                                <td className="px-4 py-2">
                                                    <span className=" text-sm font-bold  text-slate-800 dark:text-slate-100">$52.14</span>
                                                </td>
                                                <td className="px-4 py-2">
                                                    <span className=" text-sm font-medium  text-slate-400"></span>
                                                </td>
                                                <td className="px-4 py-2">
                                                    <Budges className="bg-green-50 text-green-700 ring-green-600/20 !rounded-full">complete</Budges>
                                                </td>
                                                <td className="px-4 py-2">
                                                    <div className="text-slate-400 flex items-center gap-2">
                                                        <Money size="20" weight='duotone' />
                                                        <span className=" text-sm font-medium  text-slate-400 -mt-1">liquidi</span>
                                                    </div>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ltablet:col-span-4 col-span-12 lg:col-span-4">
                    <div className='grid grid-cols-2 gap-4 grid-rows-2'>
                        <div className=' bg-white dark:bg-slate-800 rounded-xl w-full relative ring-1 dark:ring-slate-800 ring-slate-200 shadow-md p-4 min-h-24 flex items-center'>
                            <div className="flex">
                                <div className="rounded-full bg-sky-100 p-2 text-sky-500 ring-1 ring-sky-100 dark:bg-sky-500/20 dark:text-sky-400 dark:border-sky-500 dark:ring-0 dark:border-2"><Eye size="20" weight="duotone"  /></div>
                                <div className='inline-table leading-1 ml-2'>
                                    <h2 className='font-semibold text-sm text-slate-800 dark:text-white'>{clientStat.views}</h2>
                                    <p className='text-xs font-normal text-slate-500 '>Visualizzazioni</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white dark:bg-slate-800 rounded-xl w-full relative ring-1 dark:ring-slate-800 ring-slate-200 shadow-md p-4 min-h-24 flex items-center'>
                            <div className="flex">
                                <div className="rounded-full bg-red-100 p-2 text-red-500 ring-1 ring-red-100 dark:bg-red-500/20 dark:text-red-400 dark:border-red-500 dark:ring-0 dark:border-2"><Eye size="20" weight="duotone"  /></div>
                                <div className='inline-table leading-1 ml-2'>
                                    <h2 className='font-semibold text-sm text-slate-800 dark:text-white'>{visualRealTime}</h2>
                                    <p className='text-xs font-normal text-slate-500 dark:text-slate-400'>In tempo reale</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white dark:bg-slate-800 rounded-xl w-full relative ring-1 dark:ring-slate-800 ring-slate-200 shadow-md p-4 min-h-24 flex items-center'>
                            <div className="flex">
                                <div className="rounded-full bg-purple-100 p-2 text-purple-500 ring-1 ring-purple-100 dark:bg-purple-500/20 dark:text-purple-400 dark:border-purple-500 dark:ring-0 dark:border-2"><User size="20" weight="duotone"  /></div>
                                <div className='inline-table leading-1 ml-2'>
                                    <h2 className='font-semibold text-sm text-slate-800 dark:text-white'>{clientStat.reservations}</h2>
                                    <p className='text-xs font-normal text-slate-500 dark:text-slate-400'>Prenotazioni tot.</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white dark:bg-slate-800 rounded-xl w-full relative ring-1 dark:ring-slate-800 ring-slate-200 shadow-md p-4 min-h-24 flex items-center'>
                            <div className="flex">
                                <div className="rounded-full bg-orange-100 p-2 text-orange-500 ring-1 ring-orange-100 dark:bg-orange-500/20 dark:text-orange-400 dark:border-orange-500 dark:ring-0 dark:border-2"><ChartBar size="20" weight="duotone"  /></div>
                                <div className='inline-table leading-1 ml-2'>
                                    <h2 className='font-semibold text-sm text-slate-800 dark:text-white'>{clientStat.orders}</h2>
                                    <p className='text-xs font-normal text-slate-500 dark:text-slate-400'>Ordini in App</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='bg-slate-200 dark:bg-slate-950/50 rounded-xl w-full relative p-6  mt-4'>
                        <h2 className='text-lg font-light text-slate-800 dark:text-slate-100'>Passa a PRO</h2>
                        <p className='text-slate-500 text-xs mt-1 dark:text-slate-400'>Passa al Piano Pro di MynuApp per gestire il tuo menù digitale con funzionalità avanzate a soli 25€ al mese.</p>
                        <a href="https://www.mynuapp.it/#plans" target="_blank" className='mt-4 relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-violet-600 disabled:bg-violet-600/50 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600'>
                            Aggiorna ora
                        </a>
                    </div>

                    <div className='bg-white dark:bg-slate-800 rounded-xl w-full relative mt-4 shadow-md'>
                        <div className='flex flex-col gap-4 px-8 pt-8 text-center'>
                            <h2 className='text-slate-500 uppercase text-sm font-medium'>Bilancio attività</h2>
                            <p>
                                <span className="text-slate-800 font-sans text-4xl font-medium before:text-xl before:content-['$'] dark:text-white">0</span>
                            </p>
                        </div>
                        <AreaChart 
                            title={""} 
                            height={150}
                            width={'auto'}
                            data={{tiles: ['Jan', 'Feb', 'Mar'], data: [0, 0, 0]}} 
                            className='-mx-3'
                        />
                    </div>
                </div>

            </div>
        </div>
}