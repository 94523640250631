import React, {useState} from 'react'
import image404 from '../../assets/images/dog4.png';
import { Link } from 'react-router-dom';
import { Toggle } from '../../components/Toggles';
import {Budges} from '../../components/badgets';
import { SealWarning  } from '@phosphor-icons/react';

export default function Error404(props){
    const [ toggle, setToggle ] = useState(false);
    
    return <div className=''>
        <div className='max-w-[28rem] block mx-auto'>
            <img src={image404} className='relative z-20 w-full max-w-lg mx-auto' alt="" />
            <div className='text-slate-400/20 dark:text-slate-400/10 absolute h-full text-center inset-x-0 top-1/3 -translate-y-1/2 text-[13rem] font-bold sm:text-[20rem]'>
                <span>500</span>
            </div>
            <p className='text-xl text-center font-semibold text-slate-800 dark:text-slate-200 -mt-5'>Opps... Qualcosa è andato storto</p>
            <p className='text-md font-normal text-center text-slate-400 dark:slate-400 mt-3'>Si è verficato un errore. Se il problema persiste, <br /> contattare l'amministrazione o prova in un secondo momento.</p>
            <Link to="/" className="text-primary mt-3 text-center block relative py-2 -x-4 hover:bg-slate-200 rounded-md transition-all duration-300" >Torna indietro</Link>

            <label htmlFor="" className='flex justify-center items-center space-x-4 my-4'>
                <Toggle peerChecked="bg-red-600" onClick={() => setToggle(!toggle)} />
                <span className='text-slate-600 text-sm'>{!toggle ? "Mostra" : "Nascondi"} errore</span>
            </label>
        </div>
        <div className={['bg-white rounded-xl group relative mx-auto my-6 max-w-3xl hover:outline-2 hover:outline-offset-4 hover:outline-slate-400/50 hover:outline-dashed border-2 p-8 hover:border-slate-400 transition-all duration-300', toggle ? "" : "hidden"].join(" ")}>
            <Budges className="bg-red-400 text-slate-100 ring-red-600/20 rounded-full">500</Budges>

            <div className='flex py-8 space-x-4'>
                <div className='rounded-full bg-red-400 text-white w-10 h-10 flex justify-center items-center'>
                    <SealWarning size="35"/>
                </div>
                <div className='flex justify-center items-center'>
                    <h2 className='text-xl text-red-600 font-semibold'>Si è verificato un errore interno del server</h2>
                </div>
            </div>

            <pre className={['mt-0 overflow-auto whitespace-pre p-2 font-mono text-sm opacity-60 transition-all duration-300 group-hover:opacity-100 group-focus:opacity-100'].join(" ")}>
                <code>
                    {props.error.stack}
                </code>
            </pre>
        </div>
    </div>
}