import { gql } from '@apollo/client';

export const Menus = gql`query Query($menuPagination: MenuPagination, $filterName: String) {
  menusGestional(MenuPagination: $menuPagination, filterName: $filterName) {
    menus {
      _id
      cliente
      nome
      attivo
      prezzo_cost
      prezzo_menu
      createAt
      updateAt
    }
    totalCount
    hasNextPage
  }
}`;

export const Menu = gql`query Menus($menuId: String) {
  menuGestional(menu_id: $menuId) {
    _id
    cliente
    nome
    attivo
    prezzo_cost
    prezzo_menu
    createAt
    updateAt
  }
}`;

export const StoreMenu = gql`mutation MenuUpdate($storeMenu: StoreMenu) {
  menuStore(StoreMenu: $storeMenu) {
    _id
    cliente
    nome
    attivo
    prezzo_cost
    prezzo_menu
    createAt
  }
}`;


export const DeleteMenu = gql`mutation MenuUpdate($menuId: String) {
  menuDelete(menu_id: $menuId) {
    _id
  }
}
`;

export const UpdateMenu = gql`mutation Mutation($updateMenu: UpdateMenu) {
  menuUpdate(UpdateMenu: $updateMenu) {
    _id
    cliente
    nome
    attivo
    prezzo_cost
    prezzo_menu
    createAt
    updateAt
  }
}
`;

export const Cat_menuGestional = gql`query Query($categoryMenuPagination: CategoryMenuPagination, $filterCatMenu: FilterCatMenu) {
  cat_menuGestional(CategoryMenuPagination: $categoryMenuPagination, FilterCatMenu: $filterCatMenu) {
    cat_menu {
      _id
      nome
      ref_menu {
        _id
        nome
      }
      utente
      order
      image
      createAt
      updateAt
    }
    totalCount
    hasNextPage
  }
}
`;

export const DeleteCatMenu = gql`mutation Mutation($catMenuId: String) {
  catMenuDelete(cat_menu_id: $catMenuId) {
    _id
  }
}
`;

export const StoreCatMenu = gql`mutation Mutation($storeCatMenu: StoreCatMenu) {
  catMenuStore(StoreCatMenu: $storeCatMenu) {
    _id
  }
}
`;

export const UpdateCatMenu = gql`mutation Mutation($updateCatMenu: UpdateCatMenu) {
  catMenuUpdate(UpdateCatMenu: $updateCatMenu) {
    _id
    nome
    ref_menu
    utente
    order
    image
    createAt
    updateAt
  }
}

`;

export const cat_menuFromId = gql`query Query($catMenuId: String, $menuPagination: MenuPagination) {
  cat_menuFromId(cat_menu_id: $catMenuId) {
    _id
    nome
    ref_menu {
      nome
      _id
    }
    utente
    order
    image
    createAt
    updateAt
  }
  menusGestional(MenuPagination: $menuPagination) {
    menus {
      _id
      cliente
      nome
      attivo
      prezzo_cost
      prezzo_menu
    }
  }
}

`;

export const articleFromId = gql`query Query($articleId: String) {
  articleGestional(article_id: $articleId) {
    _id
    name
    price
    ingredients
    ingredientsStorage {
      _id
      name
      quantity
    }
    gluten_free
    lactose_free
    allergens {
      _id
    }
    photo
    Show
    frozen
    vegan_ok
    vegetarian_ok
    cat_menu {
      _id
      nome
      ref_menu
    }
    utente
    note
    prodotto_fresco_active
  }
}
`;

export const articlesGestional = gql`query ArticleGestional($clientId: String, $articlePagination: ArticlePagination, $filterName: String, $filterCat: String, $filterMenu: String) {
  articlesGestional(
    client_id: $clientId
    ArticlePagination: $articlePagination
    filterName: $filterName
    filterCat: $filterCat
    filterMenu: $filterMenu
  ) {
    articles {
      _id
      name
      photo
      Show
      cat_menu {
        _id
        nome
        ref_menu {
          _id
          nome
        }
      }
      utente
    }
    totalCount
    hasNextPage
  }
}
`;

export const StoreArticle = gql`mutation Mutation($storeArticle: StoreArticle) {
  articleStore(StoreArticle: $storeArticle) {
    _id
  }
}
`;

export const UpdateArticle = gql`mutation Mutation($updateArticle: UpdateArticle) {
  articleUpdate(UpdateArticle: $updateArticle) {
    _id
  }
}
`;

export const DeleteArticle = gql`mutation Mutation($articleId: String) {
  articleDelete(article_id: $articleId) {
    _id
  }
}
`;