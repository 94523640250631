import React, { useState, useEffect } from 'react'
import { useNavigate,useParams } from 'react-router-dom';
import {Textbox ,Notepad,Check,X,CurrencyEur,Plus,Chalkboard } from '@phosphor-icons/react';
import { Budges } from '../../../../components/badgets/normal.jsx';
import {Toggle} from '../../../../components/Toggles';
import Loading from '../../../../assets/svgs/loading.jsx';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Select from '../../../../components/Select';
import SingleUpload from '../../../../components/Uploader/single.jsx';
import { useDispatch } from 'react-redux';
import Menus, {Cat_menuGestional, ArticleFromId} from '../../../../GraphQl/Conections/Query.Menus';
import {UpdateArticle as MutationUpdateArticle} from '../../../../GraphQl/Conections/Mutation.Menus.js';

import Allergens from '../../../../GraphQl/Conections/Query.Allergens';
import {StoreArticle} from '../../../../GraphQl/Conections/Mutation.Menus.js';
import UploadFile from '../../../../GraphQl/Conections/Mutation.UploadFile.jsx';
import ReactSelect from 'react-select'
import {TagsComplete} from '../../../../components/badgets';
import makeAnimated from 'react-select/animated';
import Autocomplete from '../../../../components/Autocomplete';
import Modal from '../../../../components/Modal';

const options = [
  { value: 'apple', label: 'Apple' },
  { value: 'banana', label: 'Banana' },
  { value: 'cherry', label: 'Cherry' },
  { value: 'date', label: 'Date' },
  { value: 'elderberry', label: 'Elderberry' },
  // Aggiungi altre opzioni qui
];

function isValidURL(text) {
    try {
        new URL(text);
        return true;
    } catch (e) {
        return false;
    }
}


const IngredientsStorage = ({id, options, setPrice, removeItem}) => {
    return <>
                <div className='col-span-6'>
                    <label htmlFor="ingredients2" className="text-sm/6 font-medium text-slate-600 dark:text-slate-200 flex justify-between items-center">
                        <span>Ingrediente</span>
                    </label>
                    <div className="mt-2">
                        {/* <ReactSelect 
                            className='bg-white px-1 py-1 transition-all dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:text-sm '
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[options[1], options[2]]}
                            isMulti
                            options={options}                          
                        /> */}
                        <Autocomplete options={options} defaults={[]} />
                    </div>
                </div>
                <div className='col-span-6 grid grid-cols-12 gap-4'>
                    <div className='col-span-5'>
                        <label htmlFor="ingredients2" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Quantità
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="price"
                                    name="price"
                                    required
                                    onChange={(e) => { setPrice(e.target.value) }}
                                    type="number"
                                    min="0"
                                    max="99"
                                    placeholder="0"
                                    defaultValue={0}
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <Chalkboard  className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                    <div className='col-span-6'>
                        <label htmlFor="ingredients2" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Unità
                        </label>
                        <div className="mt-2">
                            <Select list={[{
                                id: "pz",
                                name: "Pezzo"
                            },{
                                id: "kg",
                                name: "KG"
                            }, {
                                id: "lt",
                                name: "Litri"
                            }]} name="menu_select" placeholder="Seleziona Unità" onChange={(e) => console.log(e)} defaultValue={null} />
                        </div>
                    </div>
                    <div className='col-span-1'>
                        <label htmlFor="ingredients2" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            &nbsp;
                        </label>
                        <div className="flex justify-center items-center mt-[10px]">
                            <button onClick={() => removeItem(id)} className={[id == 0 ? "hidden" : "", "flex justify-center items-center px-2 py-2"].join(" ")}><X className='text-primary' weigth="bold" data-tooltip-id="tooltip" data-tooltip-content="Rimuovi ingrediente dalla lista" /></button>
                        </div>
                    </div>
                </div>
            </>
}

export function UpdateArticle(params) { 
    const dispatch = useDispatch();    
    const navigate = useNavigate();
    const {id} = useParams();

    const animatedComponents = makeAnimated();
    

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [getAllergens, setAllergens] = useState([]);
    const [allergenList, setAllergenList] = useState([]);
    const [menu, setMenu] = useState("");
    const [category, setCategory] = useState( "");
    const [note, setNote] = useState("");
    const [order, setOrder] = useState(null);
    const [price, setPrice] = useState("");
    const [image, setImage] = useState();
    const [menuList, setMenuList] = useState([]);
    const [categoryList, setCategoriList] = useState([]);
    const [ingredientsStorage, setIngredientsStorage] = useState([{
        id: 0
    }]);
    const [glutenfree, setGluten] = useState(false);
    const [lactosefree, setLactose] = useState(false);
    const [vegan_ok, setVegan] = useState(false);
    const [vegetarian_ok, setVegetarian] = useState(false);
    const [frozen, setFrozen] = useState(false);
    const [prodotto_fresco_active, setFresco] = useState(false);
    const [visibletoggle, setVisibleToggle] = useState(false);
    const [ingredients, setIngredients] = useState([]);
    const [modal, setModal] = useState(false);
    const [removeImage_, setRemoveImage] = useState(false);

    useEffect(() => {
        
        ArticleFromId(dispatch, id).then((data) => {   
            
            Menus(dispatch, 0, 99, null).then((menus) => {   
                let items = [];
                menus.menus.forEach(element => {
                    items.push({
                        id: element._id,
                        name: element.nome
                    });
                    if(data.cat_menu.ref_menu == element._id){
                        setMenu(data.cat_menu ? {id: data.cat_menu.ref_menu, name: element.nome} : "");
                    }
                });
                setMenuList(items);
            });
            setName(data.name);

            const all = [];
            data.allergens.forEach(element => {
                all.push(element._id);
                setAllergenList(all);
            });
            setIngredients(data.ingredients);
            
            setCategory(data.cat_menu._id);
            setNote(data.note);
            setPrice(data.price);
            setImage(data.photo);
            // setIngredientsStorage(data.);
            setGluten(data.gluten_free);
            setLactose(data.lactose_free);
            setVegan(data.vegan_ok);
            setVegetarian(data.vegetarian_ok);
            setFrozen(data.frozen);
            setFresco(data.prodotto_fresco_active);
            setVisibleToggle(data.Show);

            Allergens(dispatch, null).then((data) => {   
                let items = [];
                setAllergens(data);
            });

        });
        
        
    }, [])


    useEffect(() => {
        
    }, [menu])

    useEffect(() => {
        if(menu){
            Cat_menuGestional(dispatch, 0, 99, {ref_menu: menu.name}).then((data) => {   
                let items = [];
               
                data.cat_menu.forEach(element => {
                    items.push({
                        id: element._id,
                        name: element.nome,
                        image: element.image
                    });

                    if(category == element._id){
                        setCategory({name: element.nome, image: element.image, id: category});
                    }
                });
                setCategoriList(items);
            });
        }
    }, [menu])

    const handleConferme = () => {

        if(name.length === 0){
            toast.error("Imposta un nome per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        if(menu.length === 0 && category.length === 0){
            toast.error("Imposta un menù ed una categoria per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        setLoading(true);

        if(typeof image == "object" && image != null){
            //Per prima cosa invia l'immagine a graphql e poi inserisci nel db tutto il resto.
           UploadFile(dispatch, image).then(data => {
               //Setta categoria nel db 
               handleOk(data.uploadFile);
           })
       }else{
           handleOk(removeImage_ ? "" : image);
       }
       
       
    }

    const handleOk = (image) => {

        MutationUpdateArticle(dispatch, {nome: name, menu: menu.id, 
                                    category:category.id, 
                                    note: note, 
                                    allergen: allergenList, 
                                    price: parseFloat(price), 
                                    ingredients: ingredients,
                                    glutenfree: glutenfree,
                                    lactosefree: lactosefree,
                                    vegan_ok: vegan_ok,
                                    vegetarian_ok: vegetarian_ok,
                                    frozen: frozen,
                                    prodotto_fresco_active: prodotto_fresco_active,
                                    visibletoggle: visibletoggle, 
                                    image: image,
                                    _id: id
                                }).then(data => { 
            setTimeout(() => {

                toast.success("Articolo aggiornato", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: 'dark:bg-slate-800 dark:text-slate-100',
                    // Custom Icon
                    icon: <Check className='text-green-500 w-10' size={20} />,
                });

                setLoading(false);
                navigate("/Menu/Articles");
            }, 1000);
        });
    }

    const handleAllergens = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setAllergenList((prevItems) => [...prevItems, value]);
        } else {
            setAllergenList((prevItems) => prevItems.filter((item) => item !== value));
        }
    };

    const handleAddIngredientStorage = () => {
        const newStorage = { id: ingredientsStorage.length + 1 };
        setIngredientsStorage((prevStorage) => [...prevStorage, newStorage]);
    }
    const removeIngredientStorage = (id) => {
        setIngredientsStorage((prevStorage) =>
            prevStorage.filter((item) => item.id !== id)
        );
    }

    const handleIngredients = (e) => {
        setIngredients(e);
    }


    return <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-3'>
        <div className='mx-auto flex w-full max-w-2xl flex-col'>
            <div className='space-y-5 mt-10'>
                <div>
                    <p className="text-lg font-medium dark:text-slate-200">
                        Foto 
                    </p>
                    <p className="text-xs font-normal text-slate-600 dark:text-slate-300">Arricchisci il tuo menù aggiungendo una foto al tuo articolo</p>
                </div>
                <div className='relative flex flex-col items-center justify-center gap-4'>
                    <SingleUpload multiple={false} removeImage={(e) => setRemoveImage(e)} files={(file) => setImage(file[0])} defaultValue={image == null ? null : isValidURL(image) ? image : process.env.REACT_APP_SERVER_IMAGE_ENDPOINT + "/public/images/dump/small-" + image} />
                </div>
            </div>
            <div className="space-y-1 mt-10">
                <fieldset className='relative'>
                    <legend className="mb-6">
                        <p className="text-lg font-medium dark:text-slate-200" tag="h3">Aggiungi un nuovo articolo</p>
                        <span className="text-xs font-normal text-slate-600 dark:text-slate-300">Inserisci i dettagli del tuo articolo</span>
                    </legend>
                </fieldset>
                <div className='grid grid-cols-12 gap-4'>
                    <div className="col-span-12">
                        <label htmlFor="name" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Nome Articolo
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="name"
                                    name="name"
                                    required
                                    onChange={(e) => { setName(e.target.value) }}
                                    defaultValue={name}
                                    value={name}
                                    type="text"
                                    placeholder="Ex: Pizze"
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-slate-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <Notepad className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6">
                        <label htmlFor="menu_select" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Menù
                        </label>
                        <div className="mt-2">
                            <Select list={menuList} name="menu_select" onChange={setMenu} defaultValue={menu.id} />
                        </div>
                    </div>
                    <div className="col-span-6">
                        <label htmlFor="cat_select" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Categoria
                        </label>
                        <div className="mt-2">
                            <Select list={categoryList} name="cat_select" disabled={!menu} onChange={setCategory} defaultValue={category.id} />
                        </div>
                    </div>

                    <div className="col-span-12">
                        <label htmlFor="order" className="flex text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            <span>Note Prodotto</span>
                        </label>
                        <div className="mt-2">
                            <div className='flex relative'>
                                <textarea
                                    id="descriptions"
                                    name="descriptions"
                                    type="text"
                                    placeholder="Breve descrizione del prodotto (opzionale)"
                                    required
                                    onChange={(e) => setNote(e.target.value)}
                                    rows={4}
                                    className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    defaultValue={note}
                                    value={note}
                                />
                                <label htmlFor="descriptions" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                    <Textbox  className=' absolute top-2 left-2' size="20" weight='duotone' />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-12 mt-4">
                        <label htmlFor="order" className="flex text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            <span>Allergeni</span>
                        </label> 
                        <div className="grid grid-cols-12 gap-2 mt-2">
                            {
                                getAllergens.map(item => { 
                                    return <div className='col-span-4 '>
                                            <label className=" space-x-2 flex  items-center">
                                                <input name="client_type_filter" onChange={handleAllergens} defaultChecked={allergenList ? allergenList.filter(e => e == item._id).length > 0 : false} className="peer transition-all dark:text-slate-600 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed rounded-sm border-0 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600" type="checkbox" value={item._id} />
                                                <span className='peer-disabled:text-slate-500 dark:text-slate-300'>{item.title}</span>
                                            </label>
                                        </div>
                                })
                            }
                        </div>
                    </div>
                    <div className="col-span-12">
                        <label htmlFor="price" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Prezzo Articolo
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="price"
                                    name="price"
                                    required
                                    onChange={(e) => { setPrice(e.target.value) }}
                                    type="number"
                                    min="0"
                                    step=".01"
                                    max="99"
                                    placeholder="0"
                                    defaultValue={price}
                                    value={price}
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 dark:text-slate-200 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <CurrencyEur className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 grid grid-cols-12 gap-4">
                        <div className='col-span-12 flex justify-between'>
                            <div  className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                Ingredienti
                            </div>
                        </div>
                        <TagsComplete tags={handleIngredients} setTags={ingredients} />
                    </div>
                    {/* <div className="col-span-12 grid grid-cols-12 gap-4">
                        <div className='col-span-12 flex justify-between'>
                            <div  className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                Ingredienti da magazzino
                            </div>
                            <div className="text-sm/6 font-medium text-slate-600 dark:text-slate-200 flex justify-center items-center">
                                <button onClick={handleAddIngredientStorage} className='hover:text-indigo-600' data-tooltip-id="tooltip" data-tooltip-content="Clicca per aggiungere ingredienti dal tuo magazzino"> 
                                    <Plus size={20} className />
                                </button>
                            </div>
                        </div>
                        
                        {
                            ingredientsStorage.map((item, index) => <IngredientsStorage id={item.id} options={options} setPrice={setPrice} removeItem={removeIngredientStorage} />)
                        }
                    </div> */}
                    <div className="col-span-12 space-y-1 mt-10">
                        <fieldset className='relative'>
                            <legend className="mb-6">
                                <p className="text-lg font-medium dark:text-slate-200" tag="h3">Toggles</p>
                                <span className="text-xs font-normal text-slate-600 dark:text-slate-300">Attiva servizi supplementari</span>
                            </legend>
                        </fieldset>
                        <div className="grid grid-cols-12">
                            <div className='col-span-4'>
                                <label htmlFor="glutenfree" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Senza glutine
                                </label>
                                <div className="mt-2">
                                    <Toggle name="glutenfree" status={glutenfree} onClick={(e) => setGluten(e.target.checked)} />
                                </div>
                            </div>
                            <div className='col-span-4'>
                                <label htmlFor="lactosefree" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Senza lattosio
                                </label>
                                <div className="mt-2">
                                    <Toggle name="lactosefree" status={lactosefree} onClick={(e) => setLactose(e.target.checked)} />
                                </div>
                            </div>
                            <div className='col-span-4'>
                                <label htmlFor="vegan_ok" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Prodotto vegano
                                </label>
                                <div className="mt-2">
                                    <Toggle name="vegan_ok" status={vegan_ok} onClick={(e) => setVegan(e.target.checked)} />
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12">
                            <div className='col-span-4'>
                                <label htmlFor="vegetarian_ok" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Prodotto Vegetariano
                                </label>
                                <div className="mt-2">
                                    <Toggle name="vegetarian_ok" status={vegetarian_ok} onClick={(e) => setVegetarian(e.target.checked)} />
                                </div>
                            </div>
                            <div className='col-span-4'>
                                <label htmlFor="frozen" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Prodotto congelato
                                </label>
                                <div className="mt-2">
                                    <Toggle name="frozen" status={frozen} onClick={(e) => setFrozen(e.target.checked)} />
                                </div>
                            </div>
                            <div className='col-span-4'>
                                <label htmlFor="prodotto_fresco_active" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Prodotto fresco
                                </label>
                                <div className="mt-2">
                                    <Toggle name="prodotto_fresco_active" status={prodotto_fresco_active} onClick={(e) => setFresco(e.target.checked)} />
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12">
                            <div className='col-span-12'>
                                <label htmlFor="visibletoggle" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Rendi visibile articolo nel menù
                                </label>
                                <div className="mt-2">
                                    <Toggle name="visibletoggle" status={visibletoggle} onClick={(e) => setVisibleToggle(e.target.checked)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="mt-10 grid grid-cols-12 gap-4">
                    <div className="col-span-6">
                        <Link   
                            to="/Menu/Articles"
                            className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-slate-200 disabled:bg-slate-600/50 px-3 py-3  font-normal leading-6 text-black shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                        >
                            <span className={['transition-all', "translate-y-0 opacity-100"].join(" ")}>Indietro</span>
                        </Link>
                    </div>
                    <div className="col-span-6">
                        <button
                            onClick={handleConferme}
                            disabled={loading || name.length == 0 || menu.length == 0}
                            className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-indigo-600 disabled:bg-indigo-600/50 px-3 py-3  font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                            <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Aggiorna</span>
                        </button>
                    </div>
                </div>
        </div>
        <Toaster />
    </div>
}