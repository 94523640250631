import React, {useState, useEffect} from 'react'
import {CurrencyEur, Check, X,WifiHigh  } from '@phosphor-icons/react'
import {Toggle} from '../../../../components/Toggles';
import {useNavigate} from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../../../../assets/svgs/loading.jsx';
import {UpdateClientInfo} from '../../../../GraphQl/Conections/Mutation.ClientInfo.js';
import { useDispatch } from 'react-redux';

const checkChanges = (vars, user, newV = false) => { 
    const changes = {}; 
    for (const key in vars) { 
        if (vars[key] != user[key]) { 
            if(newV){
                changes[key] = vars[key]; 
            }else{
                changes[key] = { 
                    oldValue: vars[key], 
                    newValue: user[key] 
                }; 
            }
        } 
    } return changes; 
};

export function Services(props){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(false);
    
    const [delivery, setToggleDelivery] = useState(false);
    const [lactosefree, setToggleLactoseFree] = useState(false);
    const [glutenfree, setToggleGlutenFree] = useState(false);
    const [coperto_active, setToggleCoperto] = useState(false);
    const [coperto, setCoperto] = useState(0);
    const [veganfree, setToggleVegan] = useState(false);
    const [vegetarianfree, setToggleVegetarian] = useState(false);
    const [parcheggio, setToggleParcheggio] = useState(false);
    const [pet, setTogglePet] = useState(false);
    const [delivery_cost, setDeliveryCost] = useState(0);
    const [wifiToggle, setToggleWifi] = useState(false);
    const [wifi_space, setWifi] = useState("");
    const [piscina_active, setTogglePiscina] = useState(false);
    const [area_fumatori_active, setToggleSmoke] = useState(false);
    const [area_giochi_active, setToggleActivityGame] = useState(false);
    const [glutenfree_cost, setGlutenFreeCost] = useState(0);
    const [lactosefree_cost, setLactoseFreeCost] = useState(0);
    const [vegan_cost, setVeganFreeCost] = useState(0);
    const [vegetarian_cost, setVgetarianFreeCost] = useState(0);
    
    useEffect(() => { 
        setToggleDelivery(props.user.delivery != undefined ? props.user.delivery : false);
        setToggleLactoseFree(props.user.lactosefree != undefined ? props.user.lactosefree : false);
        setToggleGlutenFree(props.user.glutenfree != undefined ? props.user.glutenfree : false);
        setToggleCoperto(props.user.coperto_active != undefined ? props.user.coperto_active : false);
        setCoperto(props.user.coperto != undefined ? props.user.coperto : 0);
        setToggleVegan(props.user.veganfree != undefined ? props.user.veganfree : false);
        setToggleVegetarian(props.user.vegetarianfree != undefined ? props.user.vegetarianfree : false);
        setToggleParcheggio(props.user.parcheggio != undefined ? props.user.parcheggio : false);
        setTogglePet(props.user.pet != undefined ? props.user.pet : false);
        setDeliveryCost(props.user.delivery_cost != undefined ? props.user.delivery_cost : 0);
        setToggleWifi(props.user.wifi_space != undefined ? props.user.wifi_space : false);
        setWifi(props.user.wifi_space != undefined ? props.user.wifi_space : "");
        setTogglePiscina(props.user.piscina_active != undefined ? props.user.piscina_active : false);
        setToggleSmoke(props.user.area_fumatori_active != undefined ? props.user.area_fumatori_active : false);
        setToggleActivityGame(props.user.area_giochi_active != undefined ? props.user.area_giochi_active : false);
        setGlutenFreeCost(props.user.glutenfree_cost != undefined ? props.user.glutenfree_cost : 0);
        setLactoseFreeCost(props.user.lactosefree_cost != undefined ? props.user.lactosefree_cost : 0);
        setVeganFreeCost(props.user.vegan_cost != undefined ? props.user.vegan_cost : 0);
        setVgetarianFreeCost(props.user.vegetarian_cost != undefined ? props.user.vegetarian_cost : 0);
    }, [props.user]);
    
    const handleScroll = () => { 
        const position = window.pageYOffset;
        const Tpos = document.querySelector('.t-pos');
        const rect = Tpos.getBoundingClientRect();
        
        setScrollPosition(rect.top < 0); 
        
    }; 
    
    useEffect(() => { 
        document.body.addEventListener('scroll', handleScroll); 
        return () => { 
            document.body.removeEventListener('scroll', handleScroll); 
        }; 
    }, []);


    const save = () => {

        setLoading(true);


        const vars = {
            coperto:parseFloat(coperto),
            wifi_space:wifi_space,
            delivery:delivery,
            lactosefree: lactosefree,
            glutenfree:glutenfree,
            veganfree:veganfree,
            delivery_cost:parseFloat(delivery_cost),
            glutenfree_cost:parseFloat(glutenfree_cost),
            lactosefree_cost:parseFloat(lactosefree_cost),
            vegetarianfree:vegetarianfree,
            vegan_cost:parseFloat(vegan_cost),
            vegetarian_cost:parseFloat(vegetarian_cost),
            parcheggio:parcheggio,
            pet:pet,
            coperto_active:coperto_active,
            piscina_active:piscina_active,
            area_fumatori_active:area_fumatori_active,
            area_giochi_active:area_giochi_active
        };
        const changes = checkChanges(vars, props.user, true); 

        
        UpdateClientInfo(dispatch, changes, props.user).then(data => { 
            setTimeout(() => {

                toast.success("Servizi aggiornati", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: 'dark:bg-slate-800 dark:text-slate-100',
                    // Custom Icon
                    icon: <Check className='text-green-500 w-10' size={20} />,
                });

                setLoading(false);
                // navigate("/Menu/Articles");
            }, 1000);
        });

    }



    return <div>
        <div className='flex items-center justify-between p-4'>
            <div>
                <p className='text-sm font-medium uppercase dark:text-slate-100'>I tuoi servizi</p>
                <p className="text-slate-400 text-xs dark:text-slate-300">Abilita i servizi della tua attività</p>
            </div>
            <div className='flex items-center gap-2 t-pos'>
                <button onClick={() => navigate('/Profile')} className='rounded-md py-2 px-5 text-slate-800 bg-white border border-slate-300 w-24'>Cancella</button>
                <button
                    onClick={save}
                    disabled={loading}
                    className="rounded-md min-w-32 py-2 px-5 text-slate-100 bg-violet-500 border-violet-500 hover:bg-violet-500/50 hover:border-violet-500/50 relative"
                >
                    <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                    <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Salva</span>
                </button>
            </div>
        </div>
        <div className='p-4'>
            <div className='mx-auto max-w-lg space-y-12 py-8'>
                
                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Delivery
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Offri un servizio di delivery?</p>
                    </div>
                    <div className='relative flex flex-col gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center">
                                <div className='flex relative'>
                                    <Toggle status={delivery} onClick={() => setToggleDelivery(!delivery)} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex relative'>
                                    
                                    <input
                                        id="delivery_cost"
                                        name="delivery_cost"
                                        type="number"
                                        min={0}
                                        max={99}
                                        step={.5}
                                        defaultValue={delivery_cost}
                                        value={delivery_cost}
                                        onChange={(e) => setDeliveryCost(e.target.value)}
                                        placeholder='Costo servizio'
                                        required
                                        disabled={!delivery}
                                        className="disabled:bg-slate-100 peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="delivery_cost" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                                        <CurrencyEur className=' absolute top-2 left-2' size="20"  />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Alimenti senza lattosio
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Il tuo servizio prevede alimenti senza lattosio?</p>
                    </div>
                    <div className='relative flex flex-col gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center">
                                <div className='flex relative'>
                                    <Toggle status={lactosefree} onClick={() => setToggleLactoseFree(!lactosefree)} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex relative'>
                                    <input
                                        id="lactosefree_cost"
                                        name="lactosefree_cost"
                                        type="number"
                                        min={0}
                                        max={99}
                                        step={.5}
                                        placeholder='Costo servizio'
                                        defaultValue={lactosefree_cost}
                                        value={lactosefree_cost}
                                        onChange={(e) => setLactoseFreeCost(e.target.value)}
                                        required
                                        disabled={!lactosefree}
                                        className="disabled:bg-slate-100 peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="lactosefree_cost" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                                        <CurrencyEur className=' absolute top-2 left-2' size="20"  />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                
                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Alimenti senza glutine
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Il tuo servizio prevede alimenti senza glutine?</p>
                    </div>
                    <div className='relative flex flex-col gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center">
                                <div className='flex relative'>
                                    <Toggle status={glutenfree} onClick={() => setToggleGlutenFree(!glutenfree)} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex relative'>
                                    <input
                                        id="glutenfree_cost"
                                        name="glutenfree_cost"
                                        defaultValue={glutenfree_cost}
                                        value={glutenfree_cost}
                                        onChange={(e) => setGlutenFreeCost(e.target.value)}
                                        type="number"
                                        placeholder='Costo servizio'
                                        min={0}
                                        max={99}
                                        step={.5}
                                        required
                                        disabled={!glutenfree}
                                        className="disabled:bg-slate-100 peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="glutenfree_cost" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                                        <CurrencyEur className=' absolute top-2 left-2' size="20"  />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Coperto
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Il tuo servizio prevede consumazione al coperto?</p>
                    </div>
                    <div className='relative flex flex-col gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center">
                                <div className='flex relative'>
                                    <Toggle status={coperto_active} onClick={() => setToggleCoperto(!coperto_active)} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex relative'>
                                    <input
                                        id="coperto"
                                        name="coperto"
                                        type="number"
                                        placeholder='Costo servizio'
                                        defaultValue={coperto}
                                        value={coperto}
                                        onChange={(e) => setCoperto(e.target.value)}
                                        min={0}
                                        max={99}
                                        step={.5}
                                        required
                                        disabled={!coperto_active}
                                        className="disabled:bg-slate-100 peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="coperto" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                                        <CurrencyEur className=' absolute top-2 left-2' size="20"  />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Alimenti vegani
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Il tuo servizio prevede alimenti vagani?</p>
                    </div>
                    <div className='relative flex flex-col gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center">
                                <div className='flex relative'>
                                    <Toggle status={veganfree} onClick={() => setToggleVegan(!veganfree)} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex relative'>
                                    <input
                                        id="vegan_cost"
                                        name="vegan_cost"
                                        type="number"
                                        placeholder='Costo servizio'
                                        defaultValue={vegan_cost}
                                        value={vegan_cost}
                                        onChange={(e) => setVeganFreeCost(e.target.value)}
                                        min={0}
                                        max={99}
                                        step={.5}
                                        required
                                        disabled={!veganfree}
                                        className="disabled:bg-slate-100 peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="vegan_cost" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                                        <CurrencyEur className=' absolute top-2 left-2' size="20"  />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Alimenti vegetariani
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Il tuo servizio prevede alimenti vegetariani?</p>
                    </div>
                    <div className='relative flex flex-col gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center">
                                <div className='flex relative'>
                                    <Toggle status={vegetarianfree} onClick={() => setToggleVegetarian(!vegetarianfree)} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex relative'>
                                    <input
                                        id="vegetarian_cost"
                                        name="vegetarian_cost"
                                        type="number"
                                        defaultValue={vegetarian_cost}
                                        value={vegetarian_cost}
                                        onChange={(e) => setVgetarianFreeCost(e.target.value)}
                                        placeholder='Costo servizio'
                                        min={0}
                                        max={99}
                                        step={.5}
                                        required
                                        disabled={!vegetarianfree}
                                        className="disabled:bg-slate-100 peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="vegetarian_cost" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                                        <CurrencyEur className=' absolute top-2 left-2' size="20"  />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Servizio Wifi
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Offri connessione Wifi?</p>
                    </div>
                    <div className='relative flex flex-col gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center">
                                <div className='flex relative'>
                                    <Toggle status={wifi_space} onClick={() => setToggleWifi(!wifiToggle)} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex relative'>
                                    <input
                                        id="wifi_space"
                                        name="wifi_space"
                                        type="text"
                                        placeholder='Password Wifi'
                                        defaultValue={wifi_space}
                                        value={wifi_space}
                                        onChange={(e) => setWifi(e.target.value)}
                                        required
                                        disabled={!wifiToggle}
                                        className="disabled:bg-slate-100 peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="wifi_space" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                                        <WifiHigh className=' absolute top-2 left-2' size="20"  />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Animali domestici
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">La tua attività accetta animali domestici?</p>
                    </div>
                    <div className='relative flex flex-col gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center">
                                <div className='flex relative'>
                                    <Toggle status={pet} onClick={() => setTogglePet(!pet)} />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Parcheggio vetture
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">La tua attività offre parcheggio per vetture?</p>
                    </div>
                    <div className='relative flex flex-col gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center">
                                <div className='flex relative'>
                                    <Toggle status={parcheggio} onClick={() => setToggleParcheggio(!parcheggio)} />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Hai una piscina
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">La tua attività ha una piscina?</p>
                    </div>
                    <div className='relative flex flex-col gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center">
                                <div className='flex relative'>
                                    <Toggle status={piscina_active} onClick={() => setTogglePiscina(!piscina_active)} />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Area fumatori
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">La tua attività ha un'area per i fumatori?</p>
                    </div>
                    <div className='relative flex flex-col gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center">
                                <div className='flex relative'>
                                    <Toggle status={area_fumatori_active} onClick={() => setToggleSmoke(!area_fumatori_active)} />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Area giochi
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">La tua attività ha un'area per i bimbi?</p>
                    </div>
                    <div className='relative flex flex-col gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center">
                                <div className='flex relative'>
                                    <Toggle status={area_giochi_active} onClick={() => setToggleActivityGame(!area_giochi_active)} />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                
                <div className={[' fixed p-4 left-2/4 -translate-x-2/4 bg-white gap-4 flex border border-slate-200 rounded-lg transition-all shadow-md', scrollPosition ? "opacity-100 bottom-3" : "opacity-0 -bottom-20"].join(" ")}>
                    <button onClick={() => navigate('/Profile')} className='rounded-md py-2 px-5 text-slate-800 bg-white border border-slate-300 w-24'>Cancella</button>
                    <button
                        onClick={save}
                        disabled={loading}
                        className="rounded-md min-w-32 py-2 px-5 text-slate-100 bg-violet-500 border-violet-500 hover:bg-violet-500/50 hover:border-violet-500/50 relative"
                    >
                        <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                        <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Salva</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
}