import React, {useState, useEffect} from 'react'
import UploadFiles from '../../../../components/Uploader';
import {X, Check } from '@phosphor-icons/react'
import {useNavigate} from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../../../../assets/svgs/loading.jsx';
import { useDispatch } from 'react-redux';
import {AddGalleryImage, RemoveGalleryImage} from '../../../../GraphQl/Conections/Mutation.ClientInfo.js';
import UploadFile from '../../../../GraphQl/Conections/Mutation.UploadFile.jsx';

export function Gallery(props){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [scrollPosition, setScrollPosition] = useState(false);
    const [limitImage, setLimitImage] = useState(false);
    const [startUpload, setStartUpload] = useState(false);
    const [gallery, setGallery] = useState([]);
    const [removedFiles, setRemovedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesuploaded, setFilesUploaded] = useState([]);
    const [editSave, setEditSave] = useState(false); 
    
    const handleScroll = () => { 
        const position = window.pageYOffset;
        const Tpos = document.querySelector('.t-pos');
        const rect = Tpos.getBoundingClientRect();
        
        setScrollPosition(rect.top < 0); 
        
    }; 

    useEffect(() => {
        setLimitImage(props.user.max_gallery != undefined ? props.user.max_gallery : 5);
        setGallery(props.user.gallerys != undefined ? props.user.gallerys : 5);
        console.log(props.user);
    }, [props.user])
    
    useEffect(() => { 
        document.body.addEventListener('scroll', handleScroll); 
        return () => { 
            document.body.removeEventListener('scroll', handleScroll); 
        }; 
    }, []);

    const save = () => {
        setStartUpload(true);
        let countupload = 0;
        //File da rimuovere
        // console.log("File Rimossi:", removedFiles);
        removedFiles.forEach(image => {
            RemoveGalleryImage(dispatch, image._id).then(data => { 
                setTimeout(() => {
    
                    toast.success("Immagine rimossa dalla galleria", {
                        duration: 4000,
                        position: 'top-center',
                        // Styling
                        style: {},
                        className: 'dark:bg-slate-800 dark:text-slate-100',
                        // Custom Icon
                        icon: <Check className='text-green-500 w-10' size={20} />,
                    });
    
                    // navigate("/Menu/Articles");
                }, 1000);
            });
        });
        
        //File da caricare 
        // console.log("File da caricare:", files);
        files.forEach(file => {
            
            UploadFile(dispatch, file).then(data => {
                //Setta categoria nel db 
                
                AddGalleryImage(dispatch, {
                    "url": process.env.REACT_APP_SERVER_IMAGE_ENDPOINT+"/public/images/original/" + data.uploadFile,
                    "url_dump": process.env.REACT_APP_SERVER_IMAGE_ENDPOINT+"/public/images/dump/small-" + data.uploadFile,
                    "name": data.uploadFile
                }).then(data => { 
                    setFilesUploaded([...filesuploaded, file]);
                    
                    toast.success("Immagine aggiunta nella galleria", {
                        duration: 4000,
                        position: 'top-center',
                        // Styling
                        style: {},
                        className: 'dark:bg-slate-800 dark:text-slate-100',
                        // Custom Icon
                        icon: <Check className='text-green-500 w-10' size={20} />,
                    });
                    countupload ++;
                    setLoading(false);
                    if(countupload == files.length){
                        // navigate("/Profile/Gallery");
                    }
                });
            })
        });

        
        setEditSave(false);
    }

    const fileDefaultRemoved = (e) => {
        if(e.length == 0) return
        setRemovedFiles(e);
        setEditSave(true);
        console.log(e);
    }

    const removeEdits = () => {
        navigate("/Profile/Calendar");
        setEditSave(false)
    }

    return <div>
        <div className='flex items-center justify-between p-4 relative'>
            <div>
                <p className='text-sm font-medium uppercase dark:text-slate-100'>Galleria immagini</p>
                <p className="text-slate-400 text-xs dark:text-slate-300">Arricchisci la il tuo menù con delle immagini della tua attività.</p>
            </div>
            <div className='flex items-center gap-2 t-pos'>
                <button disabled={!editSave} onClick={removeEdits} className='disabled:bg-slate-100 disabled:border-slate-100 rounded-md py-2 px-5 text-slate-800 bg-white border border-slate-300 w-24'>Cancella</button>
                <button
                    onClick={save}
                    disabled={loading || !editSave}
                    className="disabled:bg-violet-200 disabled:border-violet-200 rounded-md min-w-32 py-2 px-5 text-slate-100 bg-violet-500 border-violet-500 hover:bg-violet-500/50 hover:border-violet-500/50 relative"
                >
                    <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                    <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Salva</span>
                </button>
            </div>
        </div>
        <div className='p-4'>
            <div className='mb-4 text-green-700 text-xs flex items-center space-x-1'>
                {
                    editSave ? 
                    <>
                        <Check /> <span>Galleria modificata, salva per confermare le modifiche oppure annulla le modifiche</span>
                    </>
                        :
                        <>&nbsp;</>
                }
            </div>
            <div className="pb-2">
                <div className='mx-auto space-y-12 py-0 relative transition-none duration-300'>
                    <UploadFiles maxFile={limitImage} defaultValue={gallery} filesUploadedSuccess={filesuploaded} fileDefaultRemoved={fileDefaultRemoved} files={(e) => {setEditSave(true);setFiles(e);}} startUpload={startUpload} />
                </div>
            </div>
        </div>
        <div className={[' fixed p-4 left-2/4 -translate-x-2/4 bg-white gap-4 flex border border-slate-200 rounded-lg transition-all shadow-md', scrollPosition ? "opacity-100 bottom-3" : "opacity-0 -bottom-20"].join(" ")}>
            <button disabled={!editSave} onClick={removeEdits} className='disabled:bg-slate-100 disabled:border-slate-100 rounded-md py-2 px-5 text-slate-800 bg-white border border-slate-300 w-24'>Cancella</button>
            <button
                onClick={save}
                disabled={loading || !editSave}
                className="disabled:bg-violet-200 disabled:border-violet-200 rounded-md min-w-32 py-2 px-5 text-slate-100 bg-violet-500 border-violet-500 hover:bg-violet-500/50 hover:border-violet-500/50 relative"
            >
                <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Salva</span>
            </button>
        </div>

    </div>
}