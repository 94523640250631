import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import {Toggle} from '../../../../components/Toggles';
import {updateSettingsNotific } from '../../../../GraphQl/Conections/Mutation.Notifics';
import {notificSettings} from '../../../../GraphQl/Conections/Query.Notific';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Timer, Check } from '@phosphor-icons/react';
import toast, { Toaster } from 'react-hot-toast';

export function System(){
    const dispatch = useDispatch();
    const location = useLocation();
    const [changePasswordToggle, setChangePasswordToggle] = useState(false);
    const [loadingToggle, setLoadingToggle] = useState(false);
    const [loadingAnim, setLoadingAnim] = useState(0);
    const animCookie = Cookies.get("cookie_animation") === 'true' || false;
    const animTimeCookie = Cookies.get("cookie_animation_time") || 3000;

    useEffect(() => {
        setLoadingToggle(animCookie);
    }, [animCookie]);

    const handleTransitionPayEndToggle = () => {
        Cookies.set("cookie_animation", !loadingToggle, { expires: 365 });
        setLoadingToggle(!loadingToggle);
        toast.success("Impostazione Aggiornata", {
            duration: 4000,
            position: 'top-center',
            // Styling
            style: {},
            className: 'dark:bg-slate-800 dark:text-slate-100',
            // Custom Icon
            icon: <Check className='text-green-500 w-10' size={20} />,
        });
    }
    const handleTimeChange = (e) => {
        const value = e.target.value;
        setLoadingAnim(value);
    }
    const handleChangeAnim = () => {
        Cookies.set("cookie_animation_time", loadingAnim, { expires: 365 });
        toast.success("Impostazione Aggiornata", {
            duration: 4000,
            position: 'top-center',
            // Styling
            style: {},
            className: 'dark:bg-slate-800 dark:text-slate-100',
            // Custom Icon
            icon: <Check className='text-green-500 w-10' size={20} />,
        });
    }
    

    return <div className='divide-slate-200 dark:divide-slate-800 space-y-20 py-6'>
        
        <div className="grid gap-8 md:grid-cols-12">
            <div className="md:col-span-4">
                <h3 className='text-md font-medium text-slate-800 dark:text-slate-100 mb-1'>Sistema</h3>
                <p className="text-xs font-normal text-slate-500 dark:text-slate-400">Gestisci le impostazioni di sistema del tuo pannello di controllo.</p>
            </div>
            <div className="md:col-span-8 space-y-5">
                <div className=' space-y-5 divide-y divide-slate-200 dark:divide-slate-800'>
                    <div className='md:flex relative px-4'>
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center order-last md:order-none">
                                <div className='flex relative'>
                                    <Toggle onClick={handleTransitionPayEndToggle} status={loadingToggle} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex flex-col relative end'>
                                    <h3 className="text-xs font-medium text-slate-400 mb-0">
                                        Animazione
                                    </h3>
                                    <p className="text-md text-slate-600 dark:text-slate-400">
                                        Animazione di caricamento
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='md:flex relative px-4 py-4'>
                        <div className='w-full'>
                            <div>
                                <h3 className="text-xs font-medium text-slate-400 mb-2">
                                    Durata animazione
                                </h3>
                            </div>
                            <div className='flex relative'>
                                
                                <input
                                    id="completeName"
                                    name="completeName"
                                    type="number"
                                    disabled = {!loadingToggle}
                                    onChange={handleTimeChange}
                                    defaultValue={animTimeCookie}
                                    placeholder='3000'
                                    required
                                    className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <label htmlFor="completeName" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                                    <Timer className=' absolute top-2 left-2' size="20"  />
                                </label>
                            </div>

                            <div className='flex relative justify-between items-center my-4'>
                                <div></div>
                                <button
                                    onClick={handleChangeAnim}
                                    disabled = {!loadingToggle}
                                    className="disabled:bg-violet-200 disabled:border-violet-200 rounded-md min-w-32 py-1 px-5 text-slate-100 bg-violet-500 border-violet-500 hover:bg-violet-500/50 hover:border-violet-500/50 relative transition-colors duration-300"
                                >
                                    <span className={["transition-all translate-y-0 opacity-100"].join(" ")}>Salva</span>
                                </button>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

    </div>
}