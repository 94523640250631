import React, {useEffect, useState} from 'react'
import CalendarWeek from '../../../components/Calendar/testWeekCalendar';
import AppStacked from '../../../components/Layout/Main/app-stacked';
import {X } from '@phosphor-icons/react'
import moment from "moment";
import 'moment/locale/it';


export default function Calendar(props) {
    const [calendar, setCalendar] = useState(false);
    const [cardEventInfo, setCardEventInfo] = useState(false);
    const [eventInfo, setEventInfo] = useState(null);
    const [removeEvent, setRemoveEvent] = useState(null);
    const [eventInfoEdited, setEventInfoEdited] = useState(null);
    const [calendarMinH, setCalendarMinH] = useState(0);
    const [calendarMaxH, setCalendarMaxH] = useState(23);

    useEffect(() => {
        if(eventInfo) {
            setCardEventInfo(true)
        }
    }, [eventInfo])

    const handleRemoveEvent = () => {
        setEventInfo(null);
        setCardEventInfo(false);
        setRemoveEvent(eventInfo);
    }


    return <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 !pr-0'>
        <div className="grid grid-cols-12 ">
            <div className='col-span-10'>
                <CalendarWeek 
                    minH={calendarMinH} 
                    maxH={calendarMaxH} 
                    eventSelected={setEventInfo}
                    eventChange={eventInfoEdited}
                    eventRemove={removeEvent}
                />
            </div>
            <div className='col-span-2 border-l-2 border-slate-200 dark:border-slate-800'>
                <AppStacked data={props.user} setCalendar={() => setCalendar(!calendar)} />
                <div className='border-t-2 border-slate-200 dark:border-slate-800 relative'>
                    <div className={[calendar ? "block" : "hidden", "grid grid-cols-12 gap-2 mt-6 pl-4 "].join(' ')}> 
                        <div className='col-span-6 '>
                            <div>
                                <h3 className="text-xs font-medium text-slate-400 mb-2">
                                    Aperto dalle
                                </h3>
                            </div>
                            <div className='flex relative'>
                                <input
                                    name="minH"
                                    type="number"
                                    defaultValue={0}
                                    value={calendarMinH}
                                    min={0}
                                    max={calendarMaxH}
                                    onChange={(e) => {
                                        if(parseInt(e.target.value) > parseInt(calendarMaxH) || parseInt(e.target.value) < 0) return;
                                        setCalendarMinH(e.target.value)
                                    }}
                                    className="peer transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className='col-span-6'>
                            <div>
                                <h3 className="text-xs font-medium text-slate-400 mb-2">
                                    Chiude alle
                                </h3>
                            </div>
                            <div className='flex relative'>
                                <input
                                    name="maxH"
                                    type="number"
                                    min={calendarMinH}
                                    max={23}
                                    defaultValue={23}
                                    value={calendarMaxH}
                                    onChange={(e) => { 
                                        if(parseInt(e.target.value) < parseInt(calendarMinH) || parseInt(e.target.value) > 23) return;
                                        setCalendarMaxH(e.target.value)
                                    }}
                                    className="peer transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>
                    <div className={[' pl-6 py-4 overflow-y-auto overflow-x-hidden relative', cardEventInfo ? "" : "hidden"].join(" ")}>
                        <div onClick={() => setCalendar(!calendar)} className={['absolute inset-0 z-50 cursor-pointer backdrop-blur-[2px] bg-gradient-to-b from-slate-200/20 to-slate-300/20 dark:from-slate-800/0 dark:to-slate-900/0' , calendar ? "" : "hidden"].join(" ")}></div>
                        <div className='flex items-center justify-between mb-4'>
                            <p className='font-medium text-slate-400 dark:text-slate-500 uppercase'>Info Evento</p>
                            <button onClick={() => {
                                    setEventInfo(null)
                                    setCardEventInfo(false)}
                                } type="button" className=" rounded-full hover:bg-slate-100 p-2 text-slate-700 outline-slate-400 outline-2 outline-offset-2 outline-dashed">
                                <X size="16" weight='bold' />
                            </button>
                        </div>
                        <div className='grid grid-cols-12 space-y-4'>
                            <div className='col-span-12 '>
                                <div>
                                    <h3 className="text-xs font-medium text-slate-400 mb-2">
                                        Titolo
                                    </h3>
                                </div>
                                <div className='flex relative'>
                                    <input
                                        name="title"
                                        type="text"
                                        defaultValue={eventInfo?.title}
                                        onChange={(e) => {
                                            setEventInfoEdited({...eventInfo, title: e.target.value})
                                        }}
                                        className="peer transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className='col-span-12 '>
                                <div>
                                    <h3 className="text-xs font-medium text-slate-400 mb-2">
                                        Apertura
                                    </h3>
                                </div>
                                <div className='flex relative'>
                                    <input
                                        name="title"
                                        type="time"
                                        value={new Date(eventInfoEdited?.start == null ? eventInfo?.start : eventInfoEdited?.start).toLocaleTimeString('it-IT', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        })}
                                        // defaultValue={new Date(eventInfo?.start).toLocaleTimeString('it-IT', {
                                        //     hour: '2-digit',
                                        //     minute: '2-digit',
                                        //     hour12: false
                                        // })}
                                        // step="1800"
                                        onChange={(e) => {
                                            setEventInfoEdited({...eventInfo, start: moment(eventInfo.start)
                                                .set('hour', moment(e.target.value, 'HH:mm').hours())
                                                .set('minute', moment(e.target.value, 'HH:mm').minutes())
                                                .toDate()})
                                        }}
                                        className="peer transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className='col-span-12 '>
                                <div>
                                    <h3 className="text-xs font-medium text-slate-400 mb-2">
                                        Chiusura
                                    </h3>
                                </div>
                                <div className='flex relative'>
                                    <input
                                        name="title"
                                        type="time"
                                        value={new Date(eventInfoEdited?.end == null ? eventInfo?.end : eventInfoEdited?.end).toLocaleTimeString('it-IT', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        })}
                                        // defaultValue={new Date(eventInfo?.end).toLocaleTimeString('it-IT', {
                                        //     hour: '2-digit',
                                        //     minute: '2-digit',
                                        //     hour12: false
                                        // })}
                                        // step="1800"
                                        onChange={(e) => {
                                            setEventInfoEdited({...eventInfo, end: moment(eventInfo.end)
                                                .set('hour', moment(e.target.value, 'HH:mm').hours())
                                                .set('minute', moment(e.target.value, 'HH:mm').minutes())
                                                .toDate()})
                                        }}
                                        className="peer transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="col-span-12">
                                <button onClick={handleRemoveEvent} className='mt-4 relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-violet-600 disabled:bg-violet-600/50 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600'>Elimina</button>
                            </div>
                        </div>
                    </div>

                    {
                        cardEventInfo ? 
                            null
                        : 
                            <div className='px-4 py-4 text-xs text-center flex justify-center items-center w-full h-full text-slate-600'>
                                <span>
                                    Doppio click nel calendario per aggiungere un evento mentre per rimuoverlo seleziona l'evento e clicca su "Elimina" oppure basta cliccare sulla tastiera il tasto "Canc"
                                </span>
                            </div>
                    }
                </div>
            </div>
        </div>
        
    </div>
}
