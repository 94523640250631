import { gql } from '@apollo/client';


export const TeamMemberList = gql`query Query {
    usersTeam {
      _id
      client
      avatar
      name
      surname
      email
      role
      date_of_birth
      phone
      residence
      gender
      iban
      active
      createAt
      updateAt
    }
  }`;

export const TeamMember = gql`query Query($userId: ID) {
    userTeamFromId(user_id: $userId) {
      _id
      client
      avatar
      name
      surname
      email
      role
      date_of_birth
      residence
      phone
      gender
      iban
      active
      createAt
      updateAt
    }
  }`;

export const UpdateMeMber = gql`mutation Mutation($updateUserTeam: UpdateUserTeam) {
  userTeamUpdate(UpdateUserTeam: $updateUserTeam) {
    _id
    client
    avatar
    name
    surname
    email
    role
    date_of_birth
    phone
    gender
    residence
    iban
    active
  }
}`;