import React from 'react'
import Logo from '../../../../assets/images/logos/logo.svg';

export function Updates(){

    return <div className='divide-slate-200 dark:divide-slate-800 space-y-20 py-6'>
                
            <div className="grid gap-8 md:grid-cols-12">
                <div className="col-span-12">
                    <div className="flex space-x-2">
                        <div>
                            <Logo className="fill-primary dark:fill-slate-700 transition-all duration-500" w="40px " h="40px " />
                        </div>
                        <div>
                            <h3 className='text-sm font-medium text-slate-800 dark:text-slate-100'>MynuApp Software</h3>
                            <p className='text-xs text-slate-500 dark:text-slate-400'>Versione {process.env.REACT_APP_VERSION} (Build Ufficiale)</p>
                        </div>
                    </div>
                </div>

                <div className="col-span-12 space-y-2">
                <div className="flex space-x-2">
                        <div className='px-8 py-8 bg-slate-200 rounded-md w-full dark:bg-slate-800'>
                            <h2 className='text-lg font-semibold text-slate-800 dark:text-slate-100'>Versione 1.66</h2>
                            <p className='text-slate-600 dark:text-slate-400'>Miglioramento stabilità con il back, fix generale bug, e rivisitazione calendario.</p>
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div className='px-8 py-8 bg-slate-200 rounded-md w-full dark:bg-slate-800'>
                            <h2 className='text-lg font-semibold text-slate-800 dark:text-slate-100'>Versione 1.26</h2>
                            <p className='text-slate-600 dark:text-slate-400'>Ecco una versione semplificata del testo per gli aggiornamenti della versione 1.26:

Migliorie di sistema e fix generali.

Aggiunta animazione di caricamento.

Nuova scheda sistema nelle impostazioni per abilitare l'animazione e modificare il tempo.

Se hai bisogno di ulteriori modifiche, fammi sapere!.</p>
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div className='px-8 py-8 bg-slate-200 rounded-md w-full dark:bg-slate-800'>
                            <h2 className='text-lg font-semibold text-slate-800 dark:text-slate-100'>Versione 1.25</h2>
                            <p className='text-slate-600 dark:text-slate-400'>La nuova versione 1.25 di MynuApp introduce un sistema di notifiche via email personalizzabile per attività account, accesso account, aggiornamenti e saldo basso. Gestisci le notifiche dalla scheda "Notifiche" nelle impostazioni.</p>
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div className='px-8 py-8 bg-slate-200 rounded-md w-full dark:bg-slate-800'>
                            <h2 className='text-lg font-semibold text-slate-800 dark:text-slate-100'>Versione 1.20</h2>
                            <p className='text-slate-600 dark:text-slate-400'>Aggiunta connessione status del sistema media e sistema generale.</p>
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div className='px-8 py-8 bg-slate-200 rounded-md w-full dark:bg-slate-800'>
                            <h2 className='text-lg font-semibold text-slate-800 dark:text-slate-100'>Versione 1.2.1</h2>
                            <p className='text-slate-600 dark:text-slate-400'>Aggiunta schermata di ricerca rapida globale sulla versione mobile, aggiornamento della versione dark.</p>
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div className='px-8 py-8 bg-slate-200 rounded-md w-full dark:bg-slate-800'>
                            <h2 className='text-lg font-semibold text-slate-800 dark:text-slate-100'>Versione 1.2.0</h2>
                            <p className='text-slate-600 dark:text-slate-400'>Implementazione menù di navigazione , implementazione barra di ricerca rapida globale, fix generali.</p>
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div className='px-8 py-8 bg-slate-200 rounded-md w-full dark:bg-slate-800'>
                            <h2 className='text-lg font-semibold text-slate-800 dark:text-slate-100'>Versione 1.0.0</h2>
                            <p className='text-slate-600 dark:text-slate-400'>Benvenuti sul Gestionale di MynuApp</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
}