import React, { useState, useEffect } from 'react';

import Select from 'react-select';

const customStyles = {
    control: (provided) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      padding: '0px',
      minHeight: '26px'
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: '0px 5px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0px 5px'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: '0px 5px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#f0f0f0' : '#fff',
      color: '#333',
      '&:hover': {
        backgroundColor: '#e0e0e0',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#e0e0e0',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#333',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#333',
      '&:hover': {
        backgroundColor: '#d0d0d0',
        color: '#000',
      },
    }),
    input: (provided) => ({ 
      ...provided, 
      boxShadow: 'none', 
      outline: 'none',
      '&:focus': { 
          boxShadow: 'none ', 
          outline: 'none'
      }, 
      }),
  };
  

export default ({options, defaults, onChange}) => {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);



  return (
    <>
      <Select
        className=" bg-white px-1 py-1 transition-all dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:text-sm "
        classNamePrefix="select"
        defaultValue={defaults}
        isDisabled={false}
        isLoading={false}
        isClearable={true}
        isSearchable={true}
        onChange={(e) => onChange(e)}
        styles={customStyles}
        name="color"
        options={options}
      />
    </>
  );
};