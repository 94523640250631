import React from 'react'
import image404 from '../../assets/images/dog4.png';
import { Link } from 'react-router-dom';

export default function Error404(){

    return <div className=''>
        <div className='max-w-[28rem] block mx-auto'>
            <img src={image404} className='relative z-20 w-full max-w-lg mx-auto' alt="" />
            <div className='text-slate-400/20 dark:text-slate-400/10 absolute h-full text-center inset-x-0 top-1/3 -translate-y-1/2 text-[13rem] font-bold sm:text-[20rem]'>
                <span>404</span>
            </div>
            <p className='text-xl text-center font-semibold text-slate-800 dark:text-slate-200 -mt-5'>Opps... Qualcosa è andato storto</p>
            <p className='text-md font-normal text-center text-slate-400 dark:slate-400 mt-3'>Si è verficato un errore. Se il problema persiste, <br /> contattare l'amministrazione o prova in un secondo momento.</p>
            <Link to="/" className="text-primary mt-3 text-center block relative py-2 -x-4 hover:bg-slate-200 rounded-md transition-all duration-300" >Torna indietro</Link>
        </div>
    </div>
}