import React, {useState, useEffect} from 'react'
import { Dot } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'

export default function DropModal(props){


    return <div className='relative sm:mx-6 !mx-4'>
        <div className={['relative bg-white dark:bg-slate-700/30 dark:backdrop-blur-sm w-full min-h-60 rounded-b-lg shadow-md transition-all duration-300', props.show ? "top-0" : "-top-60"].join(" ")}>
            {/* <div className='flex justify-between px-5 pt-5'>
                <div className=''></div>
                <div className=''>
                    <Link to="" className='text-primary hover:underline'>Segna tutto come già letto</Link>
                </div>
            </div> */}
            <div className="p-5 h-full">
                {
                    [0].map(e => {

                        return <div className="ltablet:after:start-[104px] after:border-slate-300 dark:after:border-slate-700 relative flex items-center gap-4 after:absolute after:start-[8px] after:top-3 after:h-full after:w-px after:border-e-2 after:content-[''] lg:after:start-[108px] [&:not(:first-child)]:pt-3">
                                <div className="ltablet:block hidden w-24 text-right lg:block">
                                    <span className="text-xs font-normal text-slate-400">Mar 2, 2023</span>
                                </div>
                                <div className="dark:bg-slate-800 relative z-10 size-4 shrink-0 rounded-full bg-white">
                                    <div className="size-4 rounded-full border-2 border-current bg-sky-500/20 text-sky-500"></div>
                                </div>
                                <div className="rounded-md p-4 bg-slate-200 dark:bg-slate-800 w-full border border-slate-700">
                                    <div className="flex w-full items-center gap-4">
                                        <Dot size="32" weight="duotone" className='text-purple-800' />
                                        <div className='w-10 h-10'>
                                            <img src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" className="max-w-full rounded-full object-cover shadow-sm dark:border-transparent" alt="" role="none"></img>
                                        </div>
                                        <p className='text-slate-800 dark:text-slate-300'>
                                        made some changes to
                                        </p>
                                        <span className='text-slate-800 dark:text-slate-300 text-sm'>13:44</span>
                                    </div>
                                </div>
                            </div>
                    })
                }
                
                
            </div>
        </div>
    </div>
}