import React from 'react'
import { X, Check } from '@phosphor-icons/react';
import './style.css';

export function Toggle(props){
    const status = props.status;
    const disabled = props.disabled;

    return <label className="relative text-left focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed rounded-full inline-flex">
            <input type="checkbox" onClick={props.onClick} checked={status} disabled={disabled} className="peer sr-only" />
            <div className={[" outline-none ease-in-out duration-200 transition-colors bg-gray-200 dark:bg-slate-600 border-transparent border-2 rounded-full cursor-pointer shrink-0 w-11 h-6 inline-flex relative peer-checked-s ", props.peerChecked ? "peer-checked:" +props.peerChecked : "peer-checked:bg-indigo-600"].join(" ")}>
                <span className="sr-only">Use setting</span>
                <span className="transition ease-in-out duration-200 shadow bg-white dark:bg-slate-400 rounded-full w-5 h-5 inline-block relative pointer-events-none peer-checked:translate-x-5 peer-checked-sibling">
                    <span className="ease-in transition-opacity duration-200 flex justify-center items-center w-full h-full inset-0 absolute peer-checked-child">
                        <X size={10} className="text-gray-400 dark:text-slate-100 w-3 h-3" weight="bold" />
                    </span>
                    <span className="ease-in transition-opacity duration-200 flex justify-center items-center w-full h-full inset-0 absolute opacity-0 peer-checked-child-2">
                        <Check size={32} className="text-indigo-600 w-3 h-3" weight="bold" />
                    </span>
                </span>
            </div>
        </label>
  
  
  
  
}