import { gql } from '@apollo/client';

export const getStorageList = gql`query Query($articlePagination: ArticlePagination, $filterCategory: String, $filterName: String) {
  storageArticles(ArticlePagination: $articlePagination, filterCategory: $filterCategory, filterName: $filterName){
    storage {
      _id
      client
      name
      descriptions
      image
      lotto
      insertion
      expired
      openPackage
      pricePurchase
      priceSelling
      quantity
      maxQuantity
      unity
      frozen
      hccp
      category
      createAt
      updateAt
    }
    totalCount
    hasNextPage
  }
}`;

export const storageArticle = gql`mutation Mutation($storeStorageArticle: StoreStorageArticle) {
  setStorageArticle(StoreStorageArticle: $storeStorageArticle) {
    _id
    name
    descriptions
    image
    lotto
    insertion
    expired
    openPackage
    pricePurchase
    priceSelling
    quantity
    maxQuantity
    unity
    frozen
    hccp
    category
    createAt
    updateAt
  }
}`;
export const storageArticleDelete = gql`mutation Mutation($articleId: String) {
  storageArticleDelete(article_id: $articleId) {
    _id
    client
    name
    descriptions
    image
    lotto
    insertion
    expired
    openPackage
    pricePurchase
    priceSelling
    quantity
    maxQuantity
    unity
    frozen
    hccp
    category
    createAt
    updateAt
  }
}`;
export const storageArticleView = gql`query StorageArticle($storageArticleId: String!) {
  storageArticle(id: $storageArticleId) {
    _id
    name
    descriptions
    image
    lotto
    insertion
    expired
    openPackage
    pricePurchase
    priceSelling
    quantity
    maxQuantity
    unity
    frozen
    hccp
    category
    createAt
    updateAt
  }
}`;
export const storageArticleUpdate = gql`mutation StorageArticleUpdate($updateStorageArticle: UpdateStorageArticle) {
  storageArticleUpdate(UpdateStorageArticle: $updateStorageArticle) {
    _id
    name
    descriptions
    image
    lotto
    insertion
    expired
    openPackage
    pricePurchase
    priceSelling
    quantity
    maxQuantity
    unity
    frozen
    hccp
    category
    createAt
    updateAt
  }
}`;


