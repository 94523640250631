import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_SERVER_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  // Recupera il token di autenticazione dal localStorage o da un'altra fonte
  const token = localStorage.getItem('client');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-apollo-operation-name': 'uploadFile'  // Aggiungi un'intestazione personalizzata per evitare il blocco CSRF
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
});

