import React, { useState } from 'react'
import { X,CheckCircle } from '@phosphor-icons/react';
import Loading from '../../../../../assets/svgs/loading.jsx';

export function Step1(props){
    const [name, setName ] = useState("");
    const [surname, setSurname ] = useState("");
    const [email, setEmail ] = useState("");

    
    return <div>
            <div className=''>
                <h1 className='text-3xl font-normal'>Chi vorresti invitare?</h1>
                <p className='text-md font-normal text-slate-500 dark:text-slate-400 max-w-sm mt-2 leading-5'>Inserisci il nome della persona che vuoi invitare nella tua organizzazione</p>
            </div>
            <div>
                <form onSubmit={(e) => props.NextStep(e, name, surname, email)}>
                    <div className="space-y-12">
                        <div className=" pb-12 w-full max-w-md">

                            <div className="mt-10 grid grid-cols-12 gap-4">
                                <div className="sm:col-span-6">
                                    <label htmlFor="username" className="block text-sm/6 font-medium text-slate-400">
                                        Nome
                                    </label>
                                    <div className="mt-2">
                                        <div className="  sm:max-w-md px-2
                                                        transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed block w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <input
                                                id="name"
                                                name="name"
                                                onChange={(e) => setName(e.currentTarget.value)}
                                                type="text"
                                                required
                                                defaultValue={props.data.name}
                                                placeholder="Ex: Joseph"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label htmlFor="username" className="block text-sm/6 font-medium text-slate-400">
                                        Cognome
                                    </label>
                                    <div className="mt-2">
                                        <div className="  sm:max-w-md px-2
                                                        transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed block w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <input
                                                id="surname"
                                                name="surname"
                                                onChange={(e) => setSurname(e.currentTarget.value)}
                                                type="text"
                                                required
                                                defaultValue={props.data.surname}
                                                placeholder="Ex: Jon"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 grid grid-cols-12 gap-4">
                                <div className="sm:col-span-12">
                                    <label htmlFor="username" className="block text-sm/6 font-medium text-slate-400">
                                        Email
                                    </label>
                                    <div className="mt-2">
                                        <div className="  sm:max-w-md px-2
                                                        transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed block w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <input
                                                id="email"
                                                name="email"
                                                required
                                                defaultValue={props.data.email}
                                                onChange={(e) => setEmail(e.currentTarget.value)}
                                                type="email"
                                                placeholder="Ex: Jon@mail.it"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 grid grid-cols-12">
                                <div className="col-span-12">
                                    <button
                                        type="submit"
                                        onSubmit={props.NextStep}
                                        disabled={false}
                                        className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-indigo-600 disabled:bg-indigo-600/50 px-3 py-3  font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', false ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                                        <span className={['transition-all', false ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Continua</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    </div>
}