import React, { useState } from 'react'
import { ExclamationMark } from '@phosphor-icons/react';
import Loading from '../../../../../assets/svgs/loading.jsx';
import toast, { Toaster } from 'react-hot-toast';

export function Step4(props){
    const [password, setPassword] = useState(null);
    const [repeatPassword, setRepeatPassword] = useState(null);
    
    const generatePass = () => {
        const length = 8; 
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-='; 
        let newPassword = ''; 
        for (let i = 0; i < length; i++) { 
            const randomIndex = Math.floor(Math.random() * charset.length); 
            newPassword += charset[randomIndex]; 
        } 
        setPassword(newPassword);
        document.querySelector('#password').type = "text"
    }

    const check = (e) => {
        if(password != repeatPassword){
            toast.error('Controlla le due password', {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <ExclamationMark className='text-orange-500 w-10' size={20} />,
            });
            return;
        }
        props.NextStep(e, password, repeatPassword);
    }

    return <div>
         <Toaster />
            <div className=''>
                <h1 className='text-3xl font-normal'>Autenticazione</h1>
                <p className='text-md font-normal text-slate-500 dark:text-slate-400 max-w-sm mt-2 leading-5'>Metodo d'accesso per l'autenticazione, setta una password da fornire al tuo operatore.</p>
            </div>
            <div>
                <div >
                    <div className="space-y-12">
                        <div className=" my-10 w-full max-w-md ">
                            <div className="mt-5 grid grid-cols-12 gap-4">
                                <div className="sm:col-span-6">
                                    <label htmlFor="password" className="block text-sm/6 font-medium text-slate-400">
                                        Password
                                    </label>
                                    <div className="mt-2">
                                        <div className="  sm:max-w-md px-2
                                                        transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed block w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <input
                                                id="password"
                                                name="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                                type="password"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label htmlFor="generate" className="block text-sm/6 font-medium text-slate-400">
                                        &nbsp;
                                    </label>
                                    <div className="mt-2">
                                        <button
                                            onClick={generatePass}
                                            className="text-xs relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-orange-600 disabled:bg-orange-600/50 px-3 py-2 h-full font-normal leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                        >
                                            Genera password
                                        </button>
                                    </div>
                                </div>

                                <div className="sm:col-span-12">
                                    <label htmlFor="repeatPassword" className="block text-sm/6 font-medium text-slate-400">
                                        Ripeti Password
                                    </label>
                                    <div className="mt-2">
                                        <div className="  sm:max-w-md px-2
                                                        transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed block w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <input
                                                id="repeatPassword"
                                                name="repeatPassword"
                                                onChange={(e) => setRepeatPassword(e.target.value)}
                                                
                                                required
                                                type="password"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 grid grid-cols-12 gap-4">
                                <div className="col-span-6">
                                    <button
                                        onClick={props.PreviusStep}
                                        
                                        className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-slate-100 disabled:bg-slate-600/50 px-3 py-3  font-normal leading-6 text-black shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                                    >
                                        <span className={['transition-all', "translate-y-0 opacity-100"].join(" ")}>Indietro</span>
                                    </button>
                                </div>
                                <div className="col-span-6">
                                    <button
                                        onClick={check}
                                        disabled={false}
                                        className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-indigo-600 disabled:bg-indigo-600/50 px-3 py-3  font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', false ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                                        <span className={['transition-all', false ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Continua</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
}