import React, {useState, useCallback, useMemo} from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import moment from 'moment'
import 'moment-timezone';
import './style.css'

moment.tz.setDefault('Europe/Rome')


export function CalendarWeek(){
    const [events, setEvents] = useState([]);
    const [copyEvent, setCopyEvent] = useState(true)

    // Configura il calendario con drag and drop 
    const DnDCalendar = withDragAndDrop(Calendar);
    
    // Imposta la cultura in italiano 
    moment.locale('it');

    // Configura il localizzatore di moment 
    const localizer = momentLocalizer(moment); // Definisci gli eventi (puoi aggiungere i tuoi eventi qui) 

    // Configura i formati per l'orario a 24 ore 
    const formats = { 
        timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'H[h]', culture),
        eventTimeRangeFormat: ({ start, end }, culture, localizer) => `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`, 
        agendaTimeFormat: 'HH:mm', 
        dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd', culture), // Formato per i giorni 
        weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture), // Formato per i giorni della settimana 
    };


    // const handleDoubleClick = (slotInfo) => { 
    //     if (events.length >= 2) { 
    //         alert('Puoi inserire solo 2 turni.'); 
    //         return; 
    //     } 
    //     const title = prompt('Inserisci il titolo dell\'evento:'); 
    //     if (title) { 
    //         const newEvent = { 
    //             id: events.length + 1, 
    //             title, 
    //             start: slotInfo.start, 
    //             end: slotInfo.end
    //         }; 
    //         setEvents([...events, newEvent]); 
    //     } 
    // };

    const handleSelectSlot = useCallback(({ start, end }) => { console.log(start , end);
        const title = window.prompt('New Event name')
        if (title) {
            setEvents((prev) => [...prev, { start, end, title }])
        }
        },
        [setEvents]
    )

    const handleSelectEvent = useCallback((event) => window.alert(event.title),[])

    const { defaultDate, scrollToTime } = useMemo(
        () => ({
          defaultDate: new Date(2015, 3, 12),
          scrollToTime: new Date(1970, 1, 1, 6),
        }),
        []
    )

    const handleEventResize = ({ event, start, end }) => {
        const nextEvents = events.map(existingEvent => { 
            return existingEvent.id === event.id ? { ...existingEvent, start, end } : existingEvent; 
        }); 
        setEvents(nextEvents); 
    }; 
    const handleEventDrop = ({ event, start, end }) => { 
        const nextEvents = events.map(existingEvent => { 
            return existingEvent.id === event.id ? { ...existingEvent, start, end } : existingEvent; 
        }); 
        setEvents(nextEvents); 
    };
    

    return <div className=''>
            <div className=''>
                <button className='' onClick={handleSelectSlot}>Aggiungi</button>
            </div>
            <DnDCalendar 
                localizer={localizer} 
                events={events} 
                toolbar={false}
                startAccessor="start" 
                endAccessor="end" 
                defaultView="week" 
                showAllEvents={false} // Disattiva le celle "all-day"
                views={['week']} 
                min={new Date(2023, 0, 1, 1, 0)} // Inizio alle 1:00 
                max={new Date(2023, 0, 1, 23, 0)} // Fine alle 23:00 
                step={15} // Intervallo di 60 minuti 
                timeslots={4} // Un timeslot per ora 
                formats={formats} // Applica i formati personalizzati
                culture="it" // Imposta la cultura in italiano
                style={{ height: 600 }} 
                dayPropGetter={(date) => ({ 
                    className: date.getDay() === 0 ? 'hidden ma-slots' : '', // Nasconde la domenica 
                })}
                onSelectSlot={handleSelectSlot}
                // onSelectEvent={handleSelectEvent}
                // onDoubleClickEvent={handleDoubleClick}
                scrollToTime={scrollToTime}
                showMultiDayTimes={true}
                selectable={true}
                resizable={false}
                onEventResize={handleEventResize} 
                onEventDrop={handleEventDrop}
                // defaultDate={defaultDate}

            />
    </div>
}