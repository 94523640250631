import React, {useEffect, useState} from 'react'
import Logo from '../../../../assets/images/logos/logo.png';
import { X,CheckCircle,Check } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { Step1, Step2,Step3, Step4, Step5, Step6 } from './Steps';
import { motion, AnimatePresence } from 'framer-motion';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export default function AddMember(){
    const [nextStep, setNextStep] = useState(0);
    const navigate = useNavigate();
    const [invite, setInvite] = useState({
        name: null,
        surname: null,
        email: null,
        role: null,
        birthDate: null,
        phone: null,
        gender: null,
        pass: null,
        iban: null,
        active: null
    });

    document.body.classList.remove("overflow-y-scroll");


    const NextStep = (e, name, surname, email) => {
        e.preventDefault();
        
        setInvite(prevInvite => ({ ...prevInvite, name: name, surname: surname, email:email }));
        
        setNextStep(1);
    }

    const NextStep2 = (e, role) => {
        e.preventDefault();
        
        setInvite(prevInvite => ({ ...prevInvite, role: role }));
        setNextStep(2);
    }

    const PreviusStep2 = (e) => {
        console.log(invite);
        e.preventDefault();
        setNextStep(0);
    }

    const NextStep3 = (e, birthDate, phone, gender) => {
        e.preventDefault();
        
        setInvite(prevInvite => ({ ...prevInvite, birthDate: birthDate, phone: phone, gender:gender }));
        setNextStep(3);
    }

    const PreviusStep3 = (e) => {
        e.preventDefault();
        setNextStep(1);
    }

    const NextStep4 = (e, password) => {
        e.preventDefault();
        
        setInvite(prevInvite => ({ ...prevInvite, pass: password }));
        setNextStep(4);
    }

    const PreviusStep4 = (e) => {
        e.preventDefault();
        setNextStep(2);
    }

    const NextStep5 = (e, iban) => {
        e.preventDefault();
        
        setInvite(prevInvite => ({ ...prevInvite, iban:iban }));
        setNextStep(6);
    }

    const PreviusStep5 = (e) => {
        e.preventDefault();
        setNextStep(3);
    }

    const NextStep6 = (e, active) => {
        e.preventDefault();
        setInvite(prevInvite => ({ ...prevInvite, active:active }));
    }

    const PreviusStep6 = (e) => {
        e.preventDefault();
        setNextStep(4);
    }

    const NextStep7 = (e) => {
        e.preventDefault();

        console.log(invite);
        toast.success("Utente Aggiunto", {
            duration: 4000,
            position: 'top-center',
            // Styling
            style: {},
            className: 'dark:bg-slate-800 dark:text-slate-100',
            // Custom Icon
            icon: <Check className='text-green-500 w-10' size={20} />,
        });

        navigate('/Teams');
    }

    const PreviusStep7 = (e) => {
        e.preventDefault();
        setNextStep(4);
    }


    return <div className="fixed top-0 left-0 bg-white w-full h-full transition-all z-30 overflow-y-auto">
        <div className="container w-3/4">
            <div className='flex w-full items-center justify-between py-5'>
                <div>
                    <img src={Logo} className='w-9' alt="" />
                </div>
                <div className='text-lg font-medium text-slate-700'>Invite</div>
                <div>
                    <Link to="/Teams" className='flex flex-col relative'>
                        <X size="30" weight='bold' />
                        <span className='text-sm text-slate-400 text-center justify-center content-center'>Back</span>
                    </Link>
                </div>
            </div>
            <div className='w-full pb-20 pt-16 grid gap-10 md:grid-cols-12'>

                <div className='md:col-span-3 lg:col-span-4 '>
                    <ul role="list" className="acs sticky md:top-32 top-10">
                        <li className="gap-x-2 flex relative items-center">
                            <div className="w-6 left-0 top-0  flex justify-center  -bottom-6 absolute">
                                <div className="w-px bg-gray-200"></div>
                            </div>
                            <div className="bg-white justify-center items-center flex-none flex relative w-6 h-6 ">
                                <CheckCircle size={32} weight="duotone" className='text-indigo-600' />
                            </div>
                            <p className="text-gray-500 items-center text-xs pb-0.5 flex-auto">
                                <Link to="./" className={[" font-semibold", nextStep === 0 ? "text-gray-900" : "hover:underline"].join(" ")}>Email</Link> 
                            </p>
                        </li>
                        <li className="gap-x-2 flex relative items-center">
                            <div className="w-6 left-0 top-0  flex justify-center -bottom-6 absolute">
                                <div className="w-px bg-gray-200"></div>
                            </div>
                            <div className="bg-white justify-center items-center flex-none flex relative w-6 h-6">
                                {
                                    nextStep >= 1 ? 
                                        <CheckCircle size={32} weight="duotone" className='text-indigo-600' />
                                    : 
                                        <div className="ring-gray-300 bg-gray-100 ring-1 rounded-full w-1.5 h-1.5"></div>
                                }
                            </div>
                            <p className="text-gray-500 text-xs pb-0.5 flex-auto">
                                <span className={[" font-semibold", nextStep === 1 ? "text-gray-900" : ""].join(" ")}>Permessi</span>
                            </p>
                        </li>
                        <li className="gap-x-2 flex relative items-center">
                            <div className="w-6 left-0 top-0  flex justify-center -bottom-6 absolute">
                                <div className="w-px bg-gray-200"></div>
                            </div>
                            <div className="bg-white justify-center items-center flex-none flex relative w-6 h-6">
                                {
                                    nextStep >= 2 ? 
                                        <CheckCircle size={32} weight="duotone" className='text-indigo-600' />
                                    : 
                                        <div className="ring-gray-300 bg-gray-100 ring-1 rounded-full w-1.5 h-1.5"></div>
                                }
                            </div>
                            <p className="text-gray-500 text-xs pb-0.5 flex-auto">
                                <span className={[" font-semibold", nextStep === 2 ? "text-gray-900" : ""].join(" ")}>Dati aggiuntivi</span>
                            </p>
                        </li>
                        <li className="gap-x-2 flex relative items-center">
                            <div className="w-6 left-0 top-0  flex justify-center -bottom-6 absolute">
                                <div className="w-px bg-gray-200"></div>
                            </div>
                            <div className="bg-white justify-center items-center flex-none flex relative w-6 h-6">
                                {
                                    nextStep >= 3 ? 
                                        <CheckCircle size={32} weight="duotone" className='text-indigo-600' />
                                    : 
                                        <div className="ring-gray-300 bg-gray-100 ring-1 rounded-full w-1.5 h-1.5"></div>
                                }
                            </div>
                            <p className="text-gray-500 text-xs pb-0.5 flex-auto">
                                <span className={[" font-semibold", nextStep === 3 ? "text-gray-900" : ""].join(" ")}>Accesso</span>
                            </p>
                        </li>
                        <li className="gap-x-2 flex relative items-center">
                            <div className="w-6 left-0 top-0  flex justify-center -bottom-6 absolute">
                                <div className="w-px bg-gray-200"></div>
                            </div>
                            <div className="bg-white justify-center items-center flex-none flex relative w-6 h-6">
                                {
                                    nextStep >= 4 ? 
                                        <CheckCircle size={32} weight="duotone" className='text-indigo-600' />
                                    : 
                                        <div className="ring-gray-300 bg-gray-100 ring-1 rounded-full w-1.5 h-1.5"></div>
                                }
                            </div>
                            <p className="text-gray-500 text-xs pb-0.5 flex-auto">
                                <span className={[" font-semibold", nextStep === 4 ? "text-gray-900" : ""].join(" ")}>Pagamento</span>
                            </p>
                        </li>
                        <li className="gap-x-2 flex relative items-center">
                            <div className="w-6 left-0 top-0  flex justify-center -bottom-6 absolute">
                            </div>
                            <div className="bg-white justify-center items-center flex-none flex relative w-6 h-6">
                                {
                                    nextStep >= 5 ? 
                                        <CheckCircle size={32} weight="duotone" className='text-indigo-600' />
                                    : 
                                        <div className="ring-gray-300 bg-gray-100 ring-1 rounded-full w-1.5 h-1.5"></div>
                                }
                            </div>
                            <p className="text-gray-500 text-xs pb-0.5 flex-auto">
                                <span className={[" font-semibold", nextStep === 5 ? "text-gray-900" : ""].join(" ")}>Riepilogo</span>
                            </p>
                        </li>
                    </ul>
                </div>
                <div className='md:col-span-9 lg:col-span-8'>
                    <AnimatePresence>
                        <motion.div
                            key={nextStep}
                            initial={{ opacity: 0 }} animate={{ opacity: 1 }}
                            transition={{ duration: 0.6 }}
                            className="relative "
                            style={{ position: "relative", top: 0, left: 0, width: "100%" }}
                        >
                            {
                                nextStep === 0 ? 
                                    <Step1 NextStep={NextStep} data={invite} />
                                    :
                                nextStep === 1 ? 
                                    <Step2 NextStep={NextStep2} PreviusStep={PreviusStep2} data={invite} />
                                    : 
                                nextStep === 2 ? 
                                    <Step3 NextStep={NextStep3} PreviusStep={PreviusStep3} data={invite} />
                                    : 
                                nextStep === 3 ? 
                                    <Step4 NextStep={NextStep4} PreviusStep={PreviusStep4} data={invite} />
                                    : 
                                nextStep === 4 ? 
                                    <Step5 NextStep={NextStep5} PreviusStep={PreviusStep5} data={invite} />
                                    : 
                                nextStep === 5 ? 
                                    <Step6 NextStep={NextStep6} PreviusStep={PreviusStep6} data={invite} />
                                    : 
                                nextStep === 6 ? 
                                    <Step6 NextStep={NextStep7} PreviusStep={PreviusStep7} data={invite} />
                                    : ""
                            }
                        </motion.div>
                    </AnimatePresence>
                    
                </div>
            </div>
        </div>
        <Toaster />
    </div>
}