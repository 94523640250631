import React,{ useEffect, useState} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MagnifyingGlass,Check, CaretRight, CaretLeft, X, Eye, Dot,CirclesThreePlus } from '@phosphor-icons/react';
import { DropTable } from  '../../../components/Dropdowns';
import {Budges} from '../../../components/badgets';
import getStorageList from '../../../GraphQl/Conections/Query.Storage.js';
import {DeleteArticle} from '../../../GraphQl/Conections/Mutation.Storage.js';
import { useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import Image from '../../../components/Image';


import Panetteria from '../../../assets/images/categories/bakery.png';
import PesceECarne from '../../../assets/images/categories/fishandMeat.png';
import Frutta from '../../../assets/images/categories/fruit.png';
import Verdura from '../../../assets/images/categories/lettuce.png';
import Carne from '../../../assets/images/categories/meat.png';
import Bevande from '../../../assets/images/categories/soft-drink.png';
import { Categories } from '../Menu/index.jsx';

const CatImage = (image) => {

    switch (parseInt(image)) {
        case 1:
            return Panetteria
            break;
        case 2:
            return PesceECarne
            break;
        case 3:
            return Frutta
            break;
        case 4:
            return Bevande
            break;
        default:
            return Panetteria
            break;
    }
}

export default function Storage(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [itemSel, setItemSel] = useState(0);
    const [pages, setPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [maxPage, setMaxPage] = useState(10);
    const [menus, setMenus] = useState([]);
    const [deletingId, setDeletingId] = useState(null);
    const [filterSearch, setFilterSearch] = useState("");
    const [finalTimeOutKeyDown, setFinalTimeOutKeyDown] = useState(filterSearch);
    const totalPages = Math.ceil((totalCount) / maxPage);
    const [setCategoryName, setCategory] = useState("");
    const [categoryList, setCategoriList] = useState([
            {
              "id": "Carne, Pesci e Uova",
              "name": "Carne, Pesci e Uova",
              "image": ""
            },
            {
              "id": "Latte e Derivati",
              "name": "Latte e Derivati",
              "image": ""
            },
            {
              "id": "Cereali e Derivati, Tuberi",
              "name": "Cereali e Derivati, Tuberi",
              "image": ""
            },
            {
              "id": "Legumi",
              "name": "Legumi",
              "image": ""
            },
            {
              "id": "Grassi e Oli da Condimento",
              "name": "Grassi e Oli da Condimento",
              "image": ""
            },
            {
              "id": "Ortaggi",
              "name": "Ortaggi",
              "image": ""
            },
            {
              "id": "Frutta",
              "name": "Frutta",
              "image": ""
            },
            {
              "id": "Verdura",
              "name": "Verdura",
              "image": ""
            },
            {
                "id": 8,
                "name": "Altro",
                "image": <CirclesThreePlus size={24} weight="duotone" />
            },
            {
                "id": "",
                "name": "Mostra tutto",
                "image": ""
            }
          ]);
    const date = new Date();

    useEffect(() => {
        if(props.user.storageActive == undefined && !props.user.storageActive){
            navigate("/");
            return;
        }
        getStorageList(dispatch, pages, maxPage, filterSearch, setCategoryName).then((data) => { 
            setTotalCount(data.totalCount);
            const updatedData = data.storage.map(element => { 
                return { ...element, checked: false }; 
            }); 
            setMenus(updatedData);
        });
        
    }, [dispatch, pages, maxPage, finalTimeOutKeyDown, setCategoryName])

    useEffect(() => { 
        const handler = setTimeout(() => { 
            setFinalTimeOutKeyDown(filterSearch); 
        }, 1000); 
        // Imposta il timeout a 1 secondo 
        return () => { 
            clearTimeout(handler); 
        }; 
    }, [filterSearch]); 


    const pagination = () => {
        let pag = [];
        for (let n = 0; n <= totalPages -1; n++) {
           pag.push(n);
        }
        
        return pag.map((n) => <Link to={"/Menu#" + n} onClick={(e) => {e.preventDefault(); setPages(n)}} className={[pages == n ? "bg-violet-500 w-9 h-9 rounded-xl text-sm text-white flex justify-center items-center shadow-sm" : "bg-white hover:bg-slate-100 dark:bg-slate-800 dark:border-0 dark:text-slate-400  w-9 h-9 rounded-xl text-sm text-slate-800 flex justify-center items-center border border-slate-200"].join(" ")}>
                    {n+1}
                    </Link>
        )

    }

    const paginationMin = () => {
        if(pages > 0){
            setPages(pages - 1)
        }
    }

    const paginationPlus = () => {
        if(pages <= totalPages -1){
            setPages(pages + 1)
        }
    }

    const handleDelete = (e,item) =>{ 
        e.preventDefault();
        
        DeleteArticle(dispatch, item._id).then(data => { 
            setDeletingId(item._id);
            toast.success("Articolo eliminato", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <Check className='text-green-500 w-10' size={20} />,
            });
            setTimeout(() => {
                setMenus(prevMenus => prevMenus.filter(menu => menu._id !== item._id));    
            }, 300);
    
        });
    }

    const handleFilterCategory = (e) => {
        const value = e.target.value;
        if(value == ""){
            setCategory("")
        }else setCategory(value)
        // setCategory(parseInt(e.target.value))
    }

    return <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-3'>
                <div className='flex justify-between'>
                    <div className='flex items-center space-x-2'>
                        <div className="mt-2 ">
                            <div className="relative mt-2 rounded-md shadow-sm group">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ">
                                    <MagnifyingGlass  className="text-gray-500 group-focus:text-purple-900  sm:text-lg" />
                                </div>
                                <input
                                    id="filter"
                                    name="filter"
                                    value={filterSearch}
                                    onChange={(e) => setFilterSearch(e.target.value)}
                                    type="text"
                                    placeholder="Filtra Magazzino per nome"
                                    className="pl-10 transition-all dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-inset focus:ring-1 focus:ring-slate-300 sm:text-sm sm:leading-6"
                                />
                                {
                                    filterSearch.length > 0 ? 
                                        <button onClick={(e) => setFilterSearch("")} className="hover:bg-slate-200/50 rounded-full cursor-pointer absolute inset-y-0 right-0 flex items-center h-7 w-7 z-10 mt-1 mr-1 justify-center">
                                            <X className="text-gray-500  sm:text-lg" />
                                        </button>
                                    : <></>
                                }
                            </div>
                        </div>
                        <div className='mt-2'>
                            <select 
                                name="" 
                                id=""
                                defaultValue={setCategoryName}
                                onChange={handleFilterCategory}
                                className="mt-2 transition-all dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-inset focus:ring-1 focus:ring-slate-300 sm:text-sm sm:leading-6"
                            >
                                {categoryList.map((item) => {

                                    return <option value={item.id} >{item.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className=''>
                        <div className='flex space-x-2'>
                            <select 
                                name="" 
                                id=""
                                onChange={(e) => setMaxPage(parseInt(e.target.value))}
                                className="mt-2 transition-all dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-inset focus:ring-1 focus:ring-slate-300 sm:text-sm sm:leading-6"
                            >
                                <option value="10">10 per pagina</option>
                                <option value="25">25 per pagina</option>
                                <option value="50">50 per pagina</option>
                                <option value="100">100 per pagina</option>
                            </select>
                            {/* <button className='mt-2 transition-all bg-white dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-2 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-inset focus:ring-1 focus:ring-slate-300 sm:text-sm sm:leading-6'>
                                <Plus  />
                            </button> */}
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-12 gap-4 mt-6'>
                    <div className='w-full col-span-12 mt-6 '>
                        <div className='grid gap-4 sm:grid-cols-2 lg:grid-cols-3'>
                        {
                            menus.map(item => {  
                                const expired = new Date(parseInt(item.expired));

                                const insertion = new Date(parseInt(item.insertion));

                                return <div className='bg-white border border-slate-100 dark:border-0 shadow-md hover:shadow-none transition-all rounded-lg p-3'>
                                    <div className='flex justify-between items-center'>
                                        <div className='flex items-center space-x-2'>
                                            <img src={CatImage(item.image)} style={{ objectFit: 'cover' }}  className='object-cover object-center w-12 h-12 rounded-full' alt="" />
                                            <div className=''>
                                                <p className='text-sm font-medium'>{item.name}</p>
                                                <p className='text-xs text-slate-400 flex items-center justify-normal'>{item.quantity}/{item.maxQuantity} <Dot size={25} className='relative top-[1px] text-slate-800 dark:text-slate-200' /> Scad: <div className={[date > expired ? "text-red-600" : date == expired ? "text-orange-500" : "" ].join(" ")}>{expired.getDate()}/{expired.getMonth() +1}/{expired.getFullYear()}</div></p>
                                            </div>
                                        </div>
                                        <div className='flex justify-end'>
                                            <DropTable childrenBefore={[{to: "/Storage/View/" + item._id, icon: <Eye size="20" weight='duotone' />, title: "Mostra", subtitle: "Mostra dettagli elemento"}]} 
                                            handleDelete={(e) => handleDelete(e, item)} item={item} handleEdit={"/Storage/Update/" + item._id} />
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                        </div>
                    </div>
                </div>
                <div className='flex justify-between mt-6 md:flex-row flex-col space-y-2'>
                    <div>
                        <div className='border border-slate-200 dark:border-slate-600 dark:bg-slate-600 p-1 rounded-xl flex gap-1'>
                            {
                                pagination()
                            }
                        </div>
                    </div>
                    <div>
                        <div className='border border-slate-200 dark:border-slate-600 dark:bg-slate-600 p-1 rounded-xl flex gap-1'>
                            <button onClick={paginationMin} disabled={pages == 0} className=" disabled:cursor-not-allowed bg-white dark:bg-slate-800 dark:border-0 dark:text-slate-400 hover:bg-slate-100 md:w-9 w-full h-9 rounded-xl text-sm text-slate-600 flex justify-center items-center border border-slate-200 ">
                                <CaretLeft />
                            </button>
                            <button onClick={paginationPlus} disabled={pages == totalPages -1} className=" disabled:cursor-not-allowed bg-white dark:bg-slate-800 dark:border-0 dark:text-slate-400 hover:bg-slate-100 md:w-9 w-full h-9 rounded-xl text-sm text-slate-600 flex justify-center items-center border border-slate-200">
                                <CaretRight />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
}