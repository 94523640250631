import { gql } from '@apollo/client';


export const SearchQuery = gql`query Search($input: String!) {
    search(input: $input) {
      input
      menus {
        _id
        nome
        attivo
        prezzo_menu
        prezzo_cost
      }
      cat_menus {
        _id
        nome
        image
      }
      articles {
        _id
        name
        photo
      }
    }
  }`;