import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import {ListNumbers ,Notepad,Check,X,QuestionMark } from '@phosphor-icons/react';
import Loading from '../../../../assets/svgs/loading.jsx';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Select from '../../../../components/Select';
import SingleUpload from '../../../../components/Uploader/single.jsx';
import { useDispatch } from 'react-redux';
import Menus from '../../../../GraphQl/Conections/Query.Menus';
import {StoreCatMenu} from '../../../../GraphQl/Conections/Mutation.Menus.js';
import UploadFile from '../../../../GraphQl/Conections/Mutation.UploadFile.jsx';

export function AddCategory(params) { 
    const dispatch = useDispatch();    
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [menu, setMenu] = useState("");
    const [order, setOrder] = useState(null);
    const [image, setImage] = useState();
    const [menuList, setMenuList] = useState([]);

    useEffect(() => {
        Menus(dispatch, 0, 99, null).then((data) => {   
            let items = [];
            data.menus.forEach(element => {
                items.push({
                    id: element._id,
                    name: element.nome
                  });
            });
            setMenuList(items);
        });
    }, [])

    const handleConferme = () => {

        if(name.length === 0){
            toast.error("Imposta un nome per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        setLoading(true);

        if(image){
             //Per prima cosa invia l'immagine a graphql e poi inserisci nel db tutto il resto.
            UploadFile(dispatch, image).then(data => {
                //Setta categoria nel db 
                handleOk(data.uploadFile);
            })
        }else{
            handleOk(null);
        }
       
       
    }

    const handleOk = (image) => {
        StoreCatMenu(dispatch, {nome: name, image: image, order: parseInt(order), ref_menu: menu.id}).then(data => { 
            setTimeout(() => {

                toast.success("Categoria creata", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: 'dark:bg-slate-800 dark:text-slate-100',
                    // Custom Icon
                    icon: <Check className='text-green-500 w-10' size={20} />,
                });

                setLoading(false);
                
                setMenu("");
                setName("");
                setOrder(null)
                setImage(null);
                navigate("/Menu/Categories");
            }, 1000);
        });
    }

    return <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-3'>
        <div className='mx-auto flex w-full max-w-2xl flex-col'>
            <div className='space-y-5 mt-10'>
                <div>
                    <p className="text-lg font-medium dark:text-slate-200">
                        Foto 
                    </p>
                    <p className="text-xs font-normal text-slate-600 dark:text-slate-300">Arricchisci il tuo menù aggiungendo una foto alla tua categoria</p>
                </div>
                <div className='relative flex flex-col items-center justify-center gap-4'>
                    <SingleUpload multiple={false} removeImage={() => {}} files={(file) => setImage(file[0])} />
                </div>
            </div>
            <div className="space-y-1 mt-10">
                <fieldset className='relative'>
                    <legend className="mb-6">
                        <p className="text-lg font-medium dark:text-slate-200" tag="h3">Aggiungi una nuova categoria</p>
                        <span className="text-xs font-normal text-slate-600 dark:text-slate-300">Inserisci i dettagli della nuova categoria che andrai ad aggiungere al tuo menù</span>
                    </legend>
                </fieldset>
                <div className='grid grid-cols-12 gap-4'>
                    <div className="col-span-6">
                        <label htmlFor="name" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Nome Categoria
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white sm:max-w-md px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="name"
                                    name="name"
                                    required
                                    onChange={(e) => { setName(e.target.value) }}
                                    type="text"
                                    placeholder="Ex: Pizze"
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 dark:text-slate-100 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <Notepad className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6">
                        <label htmlFor="select" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Menù
                        </label>
                        <div className="mt-2">
                            <Select list={menuList} name="select" onChange={setMenu} defaultValue={null} />
                        </div>
                    </div>

                    <div className="col-span-12">
                        <label htmlFor="order" className="flex text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            <span>Posizione della categoria</span>
                            <QuestionMark size={18} className='ml-2 text-primary' data-tooltip-id="tooltip" data-tooltip-content="Mostra questa categoria in cima alla lista settando posizione '1'" />
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white sm:max-w-md px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="order"
                                    name="order"
                                    onChange={(e) => { setOrder(e.target.value) }}
                                    type="number"
                                    min={0}
                                    max={10}
                                    placeholder="Mostra questa categoria in cima alla lista settando posizione '1'"
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-slate-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <ListNumbers className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="mt-10 grid grid-cols-12 gap-4">
                    <div className="col-span-6">
                        <Link
                            to="/Menu"
                            className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-slate-200 disabled:bg-slate-600/50 px-3 py-3  font-normal leading-6 text-black shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                        >
                            <span className={['transition-all', "translate-y-0 opacity-100"].join(" ")}>Indietro</span>
                        </Link>
                    </div>
                    <div className="col-span-6">
                        <button
                            onClick={handleConferme}
                            disabled={loading || name.length == 0 || menu.length == 0}
                            className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-indigo-600 disabled:bg-indigo-600/50 px-3 py-3  font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                            <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Conferma</span>
                        </button>
                    </div>
                </div>
        </div>
        <Toaster />
    </div>
}