import React, {useState, useEffect,useRef} from "react"
import {Plus} from '@phosphor-icons/react';
import { Budges } from "./index";

export function TagsComplete(props) {
    const [text, setText] = useState('Clicca per modificare');
    const [tags, setTags] = useState(props.setTags ? props.setTags : []);
    const [tagsProps, setTagsProps] = useState(props.setTags ? props.setTags : []);
    const [showInput, setShowInput] = useState();
    const inputRef = useRef(null);

    const handleBlur = () => { 
      setShowInput(false); 
    }; 
    useEffect(() => { 
      if (showInput) { 
        inputRef.current.focus(); 
      } 
    }, [showInput]);
    useEffect(() => { 
      props.tags(tagsProps)
    }, [tagsProps]);

    useEffect(() => { 
      setTags(props.setTags)
    }, [props.setTags]);

    const handleKeyDown = (e) => { 
      if (e.key === 'Enter' || e.key === 'Escape') { 
        setShowInput(false); 
      } 

      if(e.key === 'Enter'){
        setTags((prevState) => [...prevState, e.target.value]);
        setTagsProps((prevState) => [...prevState, e.target.value]);
      }
    };

    const handleChange = (e, id) => {
      if(e == "removed"){
        setTagsProps((prevItems) => prevItems.filter((_, i) => i != id));
        // setTagsProps((prevItems) => [ ...prevItems.slice(0, id), ...prevItems.slice(id + 1) ]);
      }
    }


    return (
        <div className='flex space-x-1'>
            {
            tags.map((e, index) => {
              return <Budges key={index} id={index} className="bg-gray-100 opacity-0" animationShow={true} remove={true} onChange={handleChange}>{e}</Budges>
            })
            }
            <div className='border border-dashed border-slate-300'>
                {
                 showInput ?  
                  <input type="text" className='leading-3 p-0 px-1 w-auto' onKeyUp={handleKeyDown} onBlur={handleBlur} ref={inputRef} />
                  :
                  <span className='text-xs px-2 py-1 rounded-md relative cursor-pointer flex items-center space-x-1 dark:text-slate-200' onClick={() => setShowInput(!showInput)}><Plus weight='bold' /> <span>Aggiungi</span></span>
                }
            </div>
        </div>
    )
  }
  