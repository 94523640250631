import React, {useState, useEffect} from 'react'
import Logo from '../../assets/images/logos/logo.svg.js';
import { X, SunDim, MoonStars,CheckCircle } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import TransitionAnim from '../../components/transition';


export default function ServicesStat(){
    const [toggleTheme, setToggleTheme] = useState(localStorage.getItem('theme') == "light" ? true : false);
    const [days, setDays] = useState([]);
    const [daysOffline, setDaysOffline] = useState([]);
    const [posts, setPosts] = useState([]); 
    const [uptime, setUptime] = useState(100); // Stato per memorizzare la percentuale di uptime

    useEffect(() => {
        const fetchPosts = async () => { 
            const response = await fetch(process.env.REACT_APP_SERVER_MEDIA_ENDPOINT + '/public/logs/downtime.json', { 
                method: 'GET', 
                headers: { 'Content-Type': 'application/json', } 
            }); 
            const data = await response.json(); 
            
            setDaysOffline(data);
        }; 
        fetchPosts();
    }, []);

    useEffect(() => {
        const newDivs = []; 
        const today = new Date();
        const offlineDates = daysOffline.map(item => new Date(item.timestamp).toISOString().split('T')[0]);
    
        for (let i = 90; i > -1; i--) { 
            const date = new Date(); 
            date.setDate(today.getDate() - i); 
            const dateString = date.toISOString().split('T')[0];
            // console.log(offlineDates);
            newDivs.push(
                <div 
                    data-tooltip-id="tooltip" 
                    data-tooltip-html={`<div className='flex flex-col'>
                        <p className='font-semibold text-md mb-1'>${offlineDates.includes(dateString) ? "Offline" : "Online"}</p>
                        <p className='text-xs'>${dateString}</p>
                        <p className='text-xs'>${offlineDates.includes(dateString) ? daysOffline.find(item => new Date(item.timestamp).toISOString().split('T')[0] === dateString).event : ""}</p>
                    </div>`} 
                    className={[
                        "dark:border-slate-800 group relative h-8 flex-1 shrink-0 cursor-pointer rounded-[2px] border-x border-white transition-colors duration-300", 
                        offlineDates.includes(dateString) ? "bg-orange-500 hover:bg-orange-300" : "bg-teal-500 hover:bg-teal-300"
                    ].join(" ")} 
                ></div>
            ); 
        } 
        setDays(newDivs); 

         // Calcola la percentuale di uptime
        const totalDays = 90;
        const offlineDaysCount = offlineDates.length;
        const uptimePercentage = ((totalDays - offlineDaysCount) / totalDays) * 100;
        setUptime(uptimePercentage.toFixed(3)); // Imposta la percentuale di uptime con 3 decimali

    
        document.documentElement.classList.add('overflow-y-auto');
        document.body.classList.add('dark:bg-slate-900');
        document.body.classList.add('bg-slate-100');
    }, [daysOffline]);

    const changeTemplate = (e) =>{

        const check = e.currentTarget.checked;
        
        setToggleTheme(check);

        if(check){
            // On page load or when changing themes, best to add inline in `head` to avoid FOUC
            document.documentElement.classList.remove('dark')
            document.documentElement.classList.add('light')
            localStorage.setItem('theme', 'light')
            
        }else{
            document.documentElement.classList.add('dark')
            document.documentElement.classList.remove('light')
            localStorage.setItem('theme', 'dark')
        }
    }




    return <div className=" bg-slate-100 dark:bg-slate-900 w-full h-full pl-4 pb-10 transition-all ">
            <div className="mx-auto max-w-3xl">
                <div className='flex w-full items-center justify-between py-5'>
                    <div>
                        <Link to="/" className=' group  text-slate-400 hover:text-primary-500 dark:text-slate-700 dark:hover:text-primary-500 transition-colors duration-300'>
                            <Logo className="fill-slate-400 dark:fill-slate-700 group-hover:fill-red-800 transition-all duration-500" w="40px " h="40px " />
                        </Link>
                    </div>
                    <div>
                        <span className="">
                            <label htmlFor="toggleDark" className="bg-white dark:bg-slate-800 cursor-pointer ring-1 ring-gray-600/20 dark:ring-gray-600/50 block relative rounded-full p-1 overflow-hidden w-[32px] h-[32px] focus-within:outline-2 focus-within:outline-offset-4 focus-within:outline-slate-400/50 focus-within:outline-dashed">
                                <input type="checkbox" id="toggleDark" className="peer/draft sr-only" onChange={changeTemplate} checked={toggleTheme} />
                                <SunDim size={25} weight="duotone" className="text-yellow-500 absolute -top-1 transition-all duration-300 opacity-0 peer-checked/draft:top-1 peer-checked/draft:opacity-100" />
                                <MoonStars size={25} weight="duotone" className="text-yellow-500 absolute top-1 transition-all opacity-100 duration-300 peer-checked/draft:-top-1 peer-checked/draft:opacity-0" />
                            </label>
                        </span>
                    </div>
                </div>

                <div className='mx-auto max-w-4xl space-y-12 py-12 pb-16'>
                    <div className="flex flex-col justify-center items-center gap-y-3">
                        <CheckCircle weight='duotone' size="35" className='text-teal-600' />
                        <div className='text-center'>
                            <h1 className='text-3xl font-medium mb-1 text-slate-800 dark:text-slate-100'>All services online</h1>
                            <p className="text-sm font-medium text-slate-500 dark:text-slate-400">
                                Dal 2 Dicembre 2024 alle 21:00 
                            </p>
                        </div>
                    </div>
                </div>

                <div className="px-8 py-5">
                    <div className="flex gap-16">
                        <span className="text-sm font-normal text-slate-500 dark:text-slate-400"> Service </span>
                        <span className="text-sm font-normal text-slate-500 dark:text-slate-400"> Tempo di attività negli ultimi 90 giorni </span>
                    </div>
                </div>

                <div className='bg-white dark:bg-slate-800 border border-slate-300 dark:border-slate-700 rounded-lg px-8 divide-y divide-slate-200 dark:divide-slate-700 '>
                    <div className=" py-8">
                        <div className="flex items-center justify-between gap-4">
                            <div className="mb-1 flex items-center gap-2 ">
                                <CheckCircle weight='duotone' size="18" className='text-teal-600 relative top-[1.5px]' />
                                <span className="text-md font-normal text-slate-800 dark:text-slate-100"> server-test.mynuapp.it </span>
                            </div>
                            <div>
                                <span className="text-sm font-normal text-teal-600 dark:text-teal-400"> {uptime}% uptime </span>
                            </div>
                        </div>
                        <div className="flex w-full items-center">
                            {
                                days   
                            }
                        </div>
                        <div className="text-slate-400 mt-1 flex items-center justify-between gap-4">
                            <div>
                                <span className="text-xs font-medium"> 90 giorni fa </span>
                            </div>
                            <div>
                                <span className="text-xs font-medium"> Oggi </span>
                            </div>
                        </div>
                    </div>
                    <div className=" py-8">
                        <div className="flex items-center justify-between gap-4">
                            <div className="mb-1 flex items-center gap-2 ">
                                <CheckCircle weight='duotone' size="18" className='text-teal-600 relative top-[1.5px]' />
                                <span className="text-md font-normal text-slate-800 dark:text-slate-100"> media-v2.mynuapp.it </span>
                            </div>
                            <div>
                                <span className="text-sm font-normal text-teal-600 dark:text-teal-400"> {uptime} uptime </span>
                            </div>
                        </div>
                        <div className="flex w-full items-center">
                            {
                                days   
                            }
                        </div>
                        <div className="text-slate-400 mt-1 flex items-center justify-between gap-4">
                            <div>
                                <span className="text-xs font-medium"> 90 giorni fa </span>
                            </div>
                            <div>
                                <span className="text-xs font-medium"> Oggi </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between my-10 mb-40 relative">
                    <div>
                        <Link to="https://www.mynuapp.it/policies/terms-of-use" className='text-sm hover:!text-red-500 text-slate-700 dark:text-slate-400 hover:underline mx-4'>Legal</Link>
                        <Link to="https://www.mynuapp.it/About" className='text-sm hover:!text-red-500 text-slate-700 dark:text-slate-400 hover:underline mx-4'>Chi siamo</Link>
                    </div>
                    <p className='text-sm text-slate-700 dark:text-slate-400'>&copy; 2024 MynuApp</p>
                </div>
            </div>
            <Tooltip id="tooltip" className="!p-2 text-xs !rounded-md" />
        </div>
}