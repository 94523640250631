import React, { useState } from 'react'
import { X,CheckCircle } from '@phosphor-icons/react';
import Loading from '../../../../../assets/svgs/loading.jsx';
import './style.css';
import { Toggle } from '../../../../../components/Toggles/Toggle.jsx';

export function Step5(props){
    const [iban, setIban] = useState(null);
    const [enableIban, setEnableIban] = useState(false);


    
    return <div>
        
            <div className=''>
                <h1 className='text-3xl font-normal'>Pagamento Retribuzione</h1>
                <p className='text-md font-normal text-slate-500 dark:text-slate-400 max-w-sm mt-2 leading-5'>Inserisci il metodo di retribuzione richiesto dall'utente, puoi anche non specificare questo campo.</p>
            </div>
            <div>
                <form >
                    <div className="space-y-12">
                        <div className=" my-10 w-full max-w-md ">
                            <div className="mt-5 grid grid-cols-12 gap-4">
                                <div className="sm:col-span-12">
                                    <label htmlFor="birthdate" className="block text-sm/6 font-medium text-slate-400">
                                        Metodo di pagamento
                                    </label>
                                    <div className="mt-2">
                                        <div className="">
                                            {/* <div className="flex items-center">
                                                <label for="toggle" className="mr-2">Accetto i termini e condizioni</label>
                                                <input type="checkbox" id="toggle" className="hidden" onClick={() => setEnableIban(!enableIban)} />
                                                <label for="toggle" className="flex items-center cursor-pointer">
                                                    <div className="relative">
                                                        <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                                                        <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                                                    </div>
                                                </label>
                                            </div> */}
                                            <div className="col-span-12 flex items-center space-x-2">
                                                <div className='text-slate-700 text-sm'>Accetta termini e condizioni</div>
                                                <div className='flex relative'>
                                                    <Toggle onClick={() => setEnableIban(!enableIban)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:col-span-12">
                                    <label htmlFor="birthdate" className="block text-sm/6 font-medium text-slate-400">
                                        IBAN
                                    </label>
                                    <div className="mt-2">
                                        <div disabled={!enableIban} className="disabled:bg-slate-100  sm:max-w-md px-2
                                                        transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed block w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <input
                                                id="iban"
                                                name="iban"
                                                disabled={!enableIban}
                                                onChange={(e) => setIban(e.target.value)}
                                                placeholder='Ex: IT20K0300203280548263372195'
                                                defaultValue={iban}
                                                type="text"
                                                className="disabled:bg-slate-100 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="mt-5 grid grid-cols-12 gap-4">
                                <div className="col-span-6">
                                    <button
                                        onClick={props.PreviusStep}
                                        
                                        className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-slate-100 disabled:bg-slate-600/50 px-3 py-3  font-normal leading-6 text-black shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                                    >
                                        <span className={['transition-all', "translate-y-0 opacity-100"].join(" ")}>Indietro</span>
                                    </button>
                                </div>
                                <div className="col-span-6">
                                    <button
                                        onClick={(e) => props.NextStep(e, iban)}
                                        disabled={false}
                                        className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-indigo-600 disabled:bg-indigo-600/50 px-3 py-3  font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', false ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                                        <span className={['transition-all', false ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Continua</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    </div>
}