import React,{ useEffect, useState} from 'react'
import { UserPlus,PencilSimple,CalendarDots  } from '@phosphor-icons/react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Budges } from '../../../components/badgets';
import { motion, AnimatePresence } from 'framer-motion';
import TeamList, {GetUserTeam} from '../../../GraphQl/Conections/Query.Teams';
import { useDispatch } from 'react-redux';

const isSubRoute = (pathname) => { return pathname.startsWith('/Teams/') && pathname !== '/Teams'; };

//Link immagini avatar teams
// https://s6.imgcdn.dev/YDW18l.png
// https://s6.imgcdn.dev/YDW7kh.png
// https://s6.imgcdn.dev/YDWBrV.png
// https://s6.imgcdn.dev/YDWGqK.png
// https://s6.imgcdn.dev/YDWruo.png

export default function Dashboard(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const subRoute = isSubRoute(location.pathname);
    const [people, setPeople] = useState([]);
    const [member, setMember] = useState(null);
    // const people = [
    //     {
    //       name: 'Leslie Alexander',
    //       username: 'leslie-alexander',
    //       email: 'leslie.alexander@example.com',
    //       role: 'Co-Founder / CEO',
    //       imageUrl:
    //         'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    //       lastSeen: '3h ago',
    //       birth_date: "11/10/1993",
    //       gender: "Female",
    //       phone: "327000000",
    //       creds: "IC3H7RUN",
    //       active: true,
    //       lastSeenDateTime: '2023-01-23T13:23Z',
    //     },
    //     {
    //       name: 'Michael Foster',
    //       username: 'michael-foster',
    //       email: 'michael.foster@example.com',
    //       role: 'Co-Founder / CTO',
    //       imageUrl:
    //         'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    //       lastSeen: '3h ago',
    //       birth_date: "11/10/1993",
    //       gender: "Maschio",
    //       phone: "327000000",
    //       creds: "IC3H7RUN",
    //       active: true,
    //       lastSeenDateTime: '2023-01-23T13:23Z',
    //     },
    //     {
    //       name: 'Dries Vincent',
    //       username: 'daries-vincent',
    //       email: 'dries.vincent@example.com',
    //       role: 'Business Relations',
    //       imageUrl:
    //         'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    //       lastSeen: null,
    //       birth_date: "11/10/1993",
    //       gender: "Maschio",
    //       phone: "327000000",
    //       creds: "IC3H7RUN",
    //       active: true,
    //     },
    //     {
    //       name: 'Lindsay Walton',
    //       username: 'lindsay-walton',
    //       email: 'lindsay.walton@example.com',
    //       role: 'Front-end Developer',
    //       imageUrl:
    //         'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    //       lastSeen: '3h ago',
    //       birth_date: "11/10/1993",
    //       gender: "Femmina",
    //       phone: "327000000",
    //       creds: "IC3H7RUN",
    //       active: true,
    //       lastSeenDateTime: '2023-01-23T13:23Z',
    //     },
    //     {
    //       name: 'Courtney Henry',
    //       username: 'courtney-henry',
    //       email: 'courtney.henry@example.com',
    //       role: 'Designer',
    //       imageUrl:
    //         'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    //       lastSeen: '3h ago',
    //       birth_date: "11/10/1993",
    //       gender: "Femmina",
    //       phone: "327000000",
    //       creds: "IC3H7RUN",
    //       active: false,
    //       lastSeenDateTime: '2023-01-23T13:23Z',
    //     },
    //     {
    //       name: 'Tom Cook',
    //       username: 'tom-cook',
    //       email: 'tom.cook@example.com',
    //       role: 'Director of Product',
    //       imageUrl:
    //         'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    //       lastSeen: null,
    //       birth_date: "11/10/1993",
    //       gender: "Maschio",
    //       creds: "IC3H7RUN",
    //       active: false,
    //       phone: "327000000",
    //     },
    //   ]
      
    useEffect(() => {
        TeamList(dispatch).then((data) => { 
            setPeople(data);
        });
    }, [dispatch])

    // useEffect(() => {
    //         const contentDiv = document.querySelectorAll('.anim-fade');
            
    //         // // Fai scomparire il contenuto attuale
    //         // contentDiv.classList.add('opacity-0');
            
    //         // Attendi la fine dell'animazione
    //         setTimeout(() => {
              
    //           // Fai riapparire il nuovo contenuto
    //           contentDiv.forEach(element => {
    //             element.classList.remove('opacity-0');
    //             element.classList.add('opacity-100');
    //           });
    //         }, 100); // La durata deve corrispondere a quella definita in Tailwind CSS
          
    // }, [subRoute])

    useEffect(() => {
        if(params.username == null) return
        GetUserTeam(dispatch, params.username).then((data) => { 
            setMember(data);
        });
    }, [ params.username])
    

    return <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-3'>
        <div className='grid grid-cols-12 gap-4 py-6'>
            <div className='col-span-12'>
                <div className="text-right">
                    <div className='w-full'>
                        <Link to={"/Calendar"} className='mr-3 transition-all duration-150 bg-white rounded-full border border-slate-300 text-sm py-1 px-4 inline-flex items-center justify-center hover:border-slate-300/50 hover:bg-white/50 focus-within:outline-offset-4 focus-within:outline-slate-300 focus-within:outline-dashed'>
                            <CalendarDots className='mr-2' weight="duotone" />organizza orari lavorativi
                        </Link>
                        <Link to={"/Teams/Add-member"} className='transition-all duration-150 bg-white rounded-full border border-slate-300 text-sm py-1 px-4 inline-flex items-center justify-center hover:border-slate-300/50 hover:bg-white/50 focus-within:outline-offset-4 focus-within:outline-slate-300 focus-within:outline-dashed'>
                            <UserPlus className='mr-2' weight="duotone" />Invite Member
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='grid grid-cols-12 gap-4'>
            <div className='ltablet:col-span-5 col-span-12 lg:col-span-5 '>
                <ul role="list" className="sticky top-10 gap-1 grid">
                    {people.map((person) => (
                        <li key={person.email} className="">
                            <Link to={"/Teams/" + person._id} className={["flex justify-between gap-x-6 p-4 rounded-xl hover:backdrop-blur-sm hover:bg-white/30", location.pathname === "/Teams/" + person.username ? "backdrop-blur-sm bg-white/30" : ""].join(" ")}>
                                <div className="flex min-w-0 gap-x-4">
                                    <img alt={"Avatar " + person.name + " " + person.surname} src={person.avatar}  data-tooltip-id="tooltip" data-tooltip-content={person.role} data-tooltip-place='right' className="h-12 w-12 flex-none rounded-full bg-gray-50" />
                                    <div className="min-w-0 flex-auto content-center">
                                        <p className="text-md font-semibold text-gray-900">{person.name}</p>
                                        <p className="truncate text-xs/5 text-gray-500">{person.email}</p>
                                    </div>
                                </div>
                                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                    <p className="text-xs text-gray-900 rounded-lg bg-white border border-slate-300 py-1 px-4">{person.role}</p>
                                    {
                                        person.active ? 
                                            null
                                        :
                                        <div className="mt-1 flex items-center gap-x-1.5">
                                            <div className="flex-none rounded-full bg-red-500/20 p-1">
                                                <div className="h-1.5 w-1.5 rounded-full bg-red-500" />
                                            </div>
                                            <p className="text-xs/5 text-gray-500">Disattivato</p>
                                        </div>
                                    }
                                    {/* {person.lastSeen ? (
                                        <p className="mt-1 text-xs/5 text-gray-500">
                                            Last seen <time dateTime={person.lastSeenDateTime}>{person.lastSeen}</time>
                                        </p>
                                    ) : (
                                        <div className="mt-1 flex items-center gap-x-1.5">
                                            <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                                <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                                            </div>
                                            <p className="text-xs/5 text-gray-500">Online</p>
                                        </div>
                                    )} */}
                                </div>
                            </Link>
                        </li>
                    ))}
                    </ul>
            </div>
            <div className='col-span-7'>
                <div className=''>
                    
                    {
                        subRoute ? 
                            <div className='relative '>
                                <AnimatePresence>
                                    <motion.div
                                        key={location.pathname}
                                        initial={{ opacity: 0, y: 20, zIndex: 1 }}
                                        animate={{ opacity: 1, y: 0, zIndex: 1 }}
                                        exit={{ opacity: 0, y: -20, zIndex: 0 }}
                                        transition={{ duration: 0.6 }}
                                        className="relative w-full bg-white rounded-lg border border-slate-300"
                                        style={{ position: "relative", top: 0, left: 0, width: "100%" }}
                                    >
                                        {
                                            member ?
                                                <div>
                                                    <div className='border-slate-200 dark:border-slate-800 flex gap-4 border-b p-6 sm:p-10'>
                                                        <div className="mask-avatar mask relative inline-flex justify-center w-16 h-16">
                                                            <img className="w-full h-full " src={member.avatar} alt="Avatar profile" />
                                                        </div>
                                                        <div>
                                                            <p className='text-xl text-slate-800 font-medium'>{member.name} {member.surname}</p>
                                                            <p className='text-md font-normal text-slate-400 mb-2 line-clamp-1'>{member.email}</p>
                                                            <Budges className="bg-violet-50 text-violet-700 ring-violet-600/20 font-normal">
                                                                {member.role}
                                                            </Budges>
                                                        </div>
                                                    </div>
                                                    <div className='p-6 sm:p-10'>
                                                        <div className="">
                                                            <dl className="divide-y divide-gray-100">
                                                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                                    <dt className="">
                                                                        <p className='text-sm/6 font-medium text-gray-900'>Informazioni Generali</p>
                                                                        <p className='text-xs text-slate-500 dark:text-slate-400'>Alcune informazioni che dobbiamo sapere su di te e per elaborare questioni legali.</p>
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                        <p className='pb-4 px-4 text-xs text-gray-900 font-medium'>Su di lui</p>
                                                                        <div className="divide-slate-200 dark:divide-slate-800 flex flex-col divide-y">
                                                                            <div className="group">
                                                                                <Link to={"/Teams/Update/" + member._id+ "#name"} className="rounded-sm text-slate-600 dark:text-slate-400 hover:bg-slate-200/50 dark:hover:bg-slate-700 flex items-center gap-4 p-4 text-sm transition-colors duration-300">
                                                                                    <div className='w-full'>
                                                                                        <h3 className="text-xs font-medium text-slate-400">Nome completo</h3>
                                                                                        <span className="text-sm font-normal"> {member.name} </span>
                                                                                    </div>
                                                                                    <PencilSimple className='' size="20" weight="duotone" />
                                                                                    <span className="text-xs font-semibold text-purple-500 opacity-0 transition-opacity duration-300 group-hover:opacity-100"> Edit </span>
                                                                                </Link>
                                                                            </div>
                                                                            <div className="group">
                                                                                <Link to={"/Teams/Update/" + member._id+ "#email"} className="rounded-sm text-slate-600 dark:text-slate-400 hover:bg-slate-200/50 dark:hover:bg-slate-700 flex items-center gap-4 p-4 text-sm transition-colors duration-300">
                                                                                    <div className='w-full'>
                                                                                        <h3 className="text-xs font-medium text-slate-400">Email</h3>
                                                                                        <span className="text-sm font-normal"> {member.email} </span>
                                                                                    </div>
                                                                                    <PencilSimple className='' size="20" weight="duotone" />
                                                                                    <span className="text-xs font-semibold text-purple-500 opacity-0 transition-opacity duration-300 group-hover:opacity-100"> Edit </span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                </div>
                                                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                                    <dt className="">
                                                                        <p className='text-sm/6 font-medium text-gray-900'>Informazioni personali</p>
                                                                        <p className='text-xs text-slate-500 dark:text-slate-400'>Alcune informazioni che dobbiamo conoscere su di lui per gestire questioni legali.</p>
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                        <p className='pb-4 px-4 text-xs text-gray-900 font-medium'>Informazioni aggiuntive</p>
                                                                        <div className="divide-slate-200 dark:divide-slate-800 flex flex-col divide-y">
                                                                            <div className="group">
                                                                                <Link to={"/Teams/Update/" + member._id + "#age_of_birth"} className="rounded-sm text-slate-600 dark:text-slate-400 hover:bg-slate-200/50 dark:hover:bg-slate-700 flex items-center gap-4 p-4 text-sm transition-colors duration-300">
                                                                                    <div className='w-full'>
                                                                                        <h3 className="text-xs font-medium text-slate-400">Data di nascita</h3>
                                                                                        <span className="text-sm font-normal"> {new Date(parseInt(member.date_of_birth)).getDate()} {new Date(parseInt(member.date_of_birth)).toLocaleString('it-IT', { month: 'long' })}  {new Date(parseInt(member.date_of_birth)).getFullYear()} </span>
                                                                                    </div>
                                                                                    <PencilSimple className='' size="20" weight="duotone" />
                                                                                    <span className="text-xs font-semibold text-purple-500 opacity-0 transition-opacity duration-300 group-hover:opacity-100"> Edit </span>
                                                                                </Link>
                                                                            </div>
                                                                            <div className="group">
                                                                                <Link to={"/Teams/Update/" + member._id+ "#phone"} className="rounded-sm text-slate-600 dark:text-slate-400 hover:bg-slate-200/50 dark:hover:bg-slate-700 flex items-center gap-4 p-4 text-sm transition-colors duration-300">
                                                                                    <div className='w-full'>
                                                                                        <h3 className="text-xs font-medium text-slate-400">Numero di cellulare</h3>
                                                                                        <span className="text-sm font-normal"> {member.phone} </span>
                                                                                    </div>
                                                                                    <PencilSimple className='' size="20" weight="duotone" />
                                                                                    <span className="text-xs font-semibold text-purple-500 opacity-0 transition-opacity duration-300 group-hover:opacity-100"> Edit </span>
                                                                                </Link>
                                                                            </div>
                                                                            <div className="group">
                                                                                <Link to={"/Teams/Update/" + member._id+ "#gender"} className="rounded-sm text-slate-600 dark:text-slate-400 hover:bg-slate-200/50 dark:hover:bg-slate-700 flex items-center gap-4 p-4 text-sm transition-colors duration-300">
                                                                                    <div className='w-full'>
                                                                                        <h3 className="text-xs font-medium text-slate-400">Genere</h3>
                                                                                        <span className="text-sm font-normal"> {member.gender == "m" ? "Maschio" : member.gender == "f" ? "Femmina" : "Altro"} </span>
                                                                                    </div>
                                                                                    <PencilSimple className='' size="20" weight="duotone" />
                                                                                    <span className="text-xs font-semibold text-purple-500 opacity-0 transition-opacity duration-300 group-hover:opacity-100"> Edit </span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                </div>
                                                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                                    <dt className="">
                                                                        <p className='text-sm/6 font-medium text-gray-900'>Ruolo</p>
                                                                        <p className='text-xs text-slate-500 dark:text-slate-400'>Mansione all'interno dell'attività aziendale.</p>
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                        <p className='pb-4 px-4 text-xs text-gray-900 font-medium'>Ruolo</p>
                                                                        <div className="divide-slate-200 dark:divide-slate-800 flex flex-col divide-y">
                                                                            <div className="group">
                                                                                <Link to={"/Teams/Update/" + member._id + "#role"} className="rounded-sm text-slate-600 dark:text-slate-400 hover:bg-slate-200/50 dark:hover:bg-slate-700 flex items-center gap-4 p-4 text-sm transition-colors duration-300">
                                                                                    <div className='w-full'>
                                                                                        <h3 className="text-xs font-medium text-slate-400">Ruolo</h3>
                                                                                        <span className="text-sm font-normal"> {member.role} </span>
                                                                                    </div>
                                                                                    <PencilSimple className='' size="20" weight="duotone" />
                                                                                    <span className="text-xs font-semibold text-purple-500 opacity-0 transition-opacity duration-300 group-hover:opacity-100"> Edit </span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                </div>

                                                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                                    <dt className="">
                                                                        <p className='text-sm/6 font-medium text-gray-900'>Credenziali</p>
                                                                        <p className='text-xs text-slate-500 dark:text-slate-400'>Modifica le credenziali per permettere l'accesso all'operatore nella sua app.</p>
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                        <p className='pb-4 px-4 text-xs text-gray-900 font-medium'>Credenziali d'accesso</p>
                                                                        <div className="divide-slate-200 dark:divide-slate-800 flex flex-col divide-y">
                                                                            <div className="group">
                                                                                <Link to={"/Teams/Update/" + member._id + "#password"} className="rounded-sm text-slate-600 dark:text-slate-400 hover:bg-slate-200/50 dark:hover:bg-slate-700 flex items-center gap-4 p-4 text-sm transition-colors duration-300">
                                                                                    <div className='w-full'>
                                                                                        <h3 className="text-xs font-medium text-slate-400">Password</h3>
                                                                                        <span className="text-sm font-normal"> **** </span>
                                                                                    </div>
                                                                                    <PencilSimple className='' size="20" weight="duotone" />
                                                                                    <span className="text-xs font-semibold text-purple-500 opacity-0 transition-opacity duration-300 group-hover:opacity-100"> Edit </span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                </div>

                                                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                                    <dt className="">
                                                                        <p className='text-sm/6 font-medium text-gray-900'>Metodo di pagamento</p>
                                                                        <p className='text-xs text-slate-500 dark:text-slate-400'>Queste credenziali sono confidenzialissime, si prega di non divulgare questa informazione altrui.</p>
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                        <p className='pb-4 px-4 text-xs text-gray-900 font-medium'>Metodo di pagamento</p>
                                                                        <div className="divide-slate-200 dark:divide-slate-800 flex flex-col divide-y">
                                                                            <div className="group">
                                                                                <Link to={"/Teams/Update/" + member._id + "#iban"} className="rounded-sm text-slate-600 dark:text-slate-400 hover:bg-slate-200/50 dark:hover:bg-slate-700 flex items-center gap-4 p-4 text-sm transition-colors duration-300">
                                                                                    <div className='w-full'>
                                                                                        <h3 className="text-xs font-medium text-slate-400">Tipo</h3>
                                                                                        <span className="text-sm font-normal">IBAN</span>
                                                                                    </div>
                                                                                    <PencilSimple className='' size="20" weight="duotone" />
                                                                                    <span className="text-xs font-semibold text-purple-500 opacity-0 transition-opacity duration-300 group-hover:opacity-100"> Edit </span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                </div>

                                                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                                    <dt className="">
                                                                        <p className='text-sm/6 font-medium text-gray-900'>Stato account</p>
                                                                        <p className='text-xs text-slate-500 dark:text-slate-400'>Visualizza lo stato dell'account, se attivo o da eliminare.</p>
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                                        <p className='pb-4 px-4 text-xs text-gray-900 font-medium'>Informazioni sullo stato</p>
                                                                        <div className="divide-slate-200 dark:divide-slate-800 flex flex-col divide-y">
                                                                            <div className="group">
                                                                                <Link to={"/Teams/Update/" + member._id + "#state"} className="rounded-sm text-slate-600 dark:text-slate-400 hover:bg-slate-200/50 dark:hover:bg-slate-700 flex items-center gap-4 p-4 text-sm transition-colors duration-300">
                                                                                    <div className='w-full'>
                                                                                        <h3 className="text-xs font-medium text-slate-400">Stato</h3>
                                                                                        <span className={["text-sm font-normal", member.active ? "" : "text-red-500"].join(" ")}> {member.active ? "Attivo" : "Disattivato"} </span>
                                                                                    </div>
                                                                                    <PencilSimple className='' size="20" weight="duotone" />
                                                                                    <span className="text-xs font-semibold text-purple-500 opacity-0 transition-opacity duration-300 group-hover:opacity-100"> Edit </span>
                                                                                </Link>
                                                                            </div>
                                                                            <div className="group">
                                                                                <Link to={"/Teams/Update/" + member._id+ "#removeMember"} className="rounded-sm text-slate-600 dark:text-slate-400 hover:bg-slate-200/50 dark:hover:bg-slate-700 flex items-center gap-4 p-4 text-sm transition-colors duration-300">
                                                                                    <div className='w-full'>
                                                                                        <h3 className="text-xs font-medium text-red-400">Elimina account</h3>
                                                                                        <span className="text-sm font-normal"> Clicca per eseguire l'azione </span>
                                                                                    </div>
                                                                                    <PencilSimple className='' size="20" weight="duotone" />
                                                                                    <span className="text-xs font-semibold text-purple-500 opacity-0 transition-opacity duration-300 group-hover:opacity-100"> Edit </span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                </div>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null
                                        }
                                    </motion.div>
                                </AnimatePresence>
                            </div>
                        :
                            <div className="p-14 text-center px-6 w-full bg-white rounded-lg border border-slate-300">
                                <p className='text-xl font-medium mb-1'>Niente da mostrare</p>
                                <p className='text-sm font-normal text-slate-400 mx-auto max-w-xs leading-tight'>
                                    Nessun membro selezionato. Seleziona uno dei membri della tua organizzazione per vedere maggiori dettagli.
                                </p>
                            </div>
                    }

                    
                </div>
            </div>
        </div>

        {/* {
            location.pathname === "/Teams/Add-member" ?
                <div className="bg-white fixed w-full h-full"></div>
            : ""
        } */}
    </div>
}