import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './reducers/clientReducer';
import settingsReducer from './reducers/settingsSlice'; // Devi creare questo file

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['settings'], // Salva SOLO le impostazioni
};

const rootReducer = combineReducers({
  user: userReducer, // NON verrà salvato in Redux Persist
  settings: settingsReducer, // Verrà salvato
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
