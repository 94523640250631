import React, { useEffect, useState, useRef} from 'react';
import './style.css'
import LogoMynu from '../../assets/images/logos/logo.png';
import LogoWhite from '../../assets/images/logos/logo-white.webp';
import {Storefront , CaretDown, User, SignOut, Gear, UserPlus,ClipboardText,CaretUp, Users,Article,Cube,HouseSimple } from '@phosphor-icons/react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import GestoreAvatar from '../../assets/images/avatars/mynu-gestore.png';
import isValidURL from '../../middleware/isValidUrl.jsx';
import { useDispatch } from 'react-redux';
import { clearUserInfo } from '../../redux/reducers/clientReducer';


export default function Aside(props){
    const dispatch = useDispatch();
    const [openAside, setOpenAside] = useState(false);
    const [compressAdise, setCompressAside] = useState(false);
    const [footerAside, setFooterAside] = useState(false);
    const asideRef = useRef(null); // Riferimento all'aside per verificare i clic
    const [touchStart, setTouchStart] = useState(0); // Rileva il punto di inizio dello swipe
    const [touchEnd, setTouchEnd] = useState(0); // Posizione finale del tocco

    const [menuOpen, setMenuOpen] = useState(false);
    const [menuOpenClosed, setMenuOpenClosed] = useState(false);
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [categoryOpenClosed, setCategoryOpenClosed] = useState(false);
    const [articleOpen, setArticleOpen] = useState(false);
    const [articleOpenClosed, setArticleOpenClosed] = useState(false);
    const [storageOpen, setStorageOpen] = useState(false);
    const [storageOpenClosed, setStorageOpenClosed] = useState(false);
    const [usersOpen, setUsersOpen] = useState(false);
    const [usersOpenClosed, setUsersOpenClosed] = useState(false);

    const { 
        activity_name = null, // Fallback a null
        activity_type = null,
        owner = null,
        owner_name = null,
        public_email = null,
        seo_url = null,
        active = false, // Fallback a false per i booleani
        Logo = false
    } = props.data || {}; // Fallback a un oggetto vuoto se props.data è null/undefined
    

    const params = useParams();

    const location = useLocation();

    const toggleBar = () => {
        setOpenAside(!openAside)
        props.asideNav(!openAside);
    }

    const compressAside = () =>{
        setCompressAside(!compressAdise);
        props.compressAsideNav(!compressAdise);
    }

    const handleLogout = (e) => {
        e.preventDefault();

        dispatch(clearUserInfo());

        toast.success("Account disconnesso", {
            duration: 4000,
            position: 'top-center',
            // Styling
            style: {},
            className: '',
        });
        localStorage.removeItem('client');
        localStorage.removeItem('persist:root');
        // document.cookie.split(";").forEach((cookie) => {
        //     document.cookie = cookie
        //         .replace(/^ +/, "")
        //         .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
        //   });
        setTimeout(() => {
            window.location.href = "/Auth";
        }, 1000)
    }

    // Funzione per chiudere l'aside se il clic è fuori
    const handleClickOutside = (event) => { 
        if (asideRef.current && !asideRef.current.contains(event.target)) {
            setOpenAside(!openAside)
            props.asideNav(!openAside);
        }
    };

    // Aggiungere il listener per i clic all'esterno del componente
    useEffect(() => { 
        if (openAside) {
            document.removeEventListener('touchstart', handleClickOutside);
        } else {
            document.addEventListener('touchstart', handleClickOutside);
        }

        return () => {
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [openAside]);

    useEffect(() => { 
        const handleResize = () => { 
            /*
                sm (640px)	max-width: 640px;
                md (768px)	max-width: 768px;
                lg (1024px)	max-width: 1024px;
                xl (1280px)	max-width: 1280px;
                2xl (1536px)	max-width: 1536px;
            */
           
           if(window.innerWidth <= 1024){
                setCompressAside(false); 
                props.compressAsideNav(false);
                setOpenAside(true)
                props.asideNav(true);
            }else{
                setCompressAside(window.innerWidth <= 1280); 
                props.compressAsideNav(window.innerWidth <= 1280);
                setOpenAside(false)
                props.asideNav(false);
            }
            
        };
        window.addEventListener('resize', handleResize); 
        
        handleResize(); // Chiamata iniziale per impostare lo stato corretto 
        return () => { 
            window.removeEventListener('resize', handleResize); 
        }; 
    }, []);

    useEffect(() => {
        if(!openAside) {
    
            return;
        };
        
        // Gestione degli eventi touch a livello globale
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchmove', handleTouchMove); // Aggiungi anche il listener per touchmove

        return () => { 
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
        }; 
    },[touchStart, openAside])



    // Gestione dello swipe
    const handleTouchStart = (e) => { 
        const touchStartPos = e.touches[0].clientX; 
        setTouchStart(touchStartPos);
    };

    const handleTouchMove = (e) => {
        const touchEndPos = e.touches[0].clientX; // Aggiorna la posizione finale del tocco mentre l'utente scorre
        // setTouchEnd(touchEndPos);
        const touchDifference = touchEndPos - touchStart;
        if (touchDifference > 100) {
            setOpenAside(false)
            props.asideNav(false);
        } else if (touchDifference < -50) {
            setOpenAside(true)
            props.asideNav(true);
        }

    };
    
    
    // 
    return <aside ref={asideRef} className={['fixed  w-52 top-0 bottom-0 bg-black dark:bg-black/50 rounded-2xl m-4 ease-in duration-100 z-10', props.className, openAside ? '-left-56' : "left-0", compressAdise ? "!w-16 hover:!w-52 hover:backdrop-blur-sm hover:bg-black/40 group !-left-0" : ""].join(" ")} >
        <div className="flex flex-col w-full">
            <div className='w-full top-0'>
                <div className="flex h-16 justify-between px-3 justify-items-center">
                    {
                        !compressAdise ?     
                            <div className='self-center'>
                                <img
                                    className="h-8 dark:hidden"
                                    src={LogoMynu}
                                    alt="MynuApp"
                                />
                                <img
                                    className="h-8 hidden dark:block"
                                    src={LogoWhite}
                                    alt="MynuApp"
                                />
                            </div>
                        : ""
                    }
                    <div className='self-center'>
                        <button className='hover:bg-white/10 p-2 rounded-full ease-in duration-100' onClick={compressAside}>
                            {/* {
                                compressAdise ? 
                                    <ArrowLineRight size={25} className="text-white" weight="duotone" />
                                :
                                    <ArrowLineLeft size={25} className="text-white" weight="duotone" />
                            } */}

                            <div class={["relative size-5", !compressAdise ? "scale-90" : ""].join(" ")}>
                                <span class={["bg-white absolute block h-0.5 w-full transition-all duration-300 top-0.5", !compressAdise ? "-rotate-45 rtl:rotate-45 max-w-[75%] top-0" : ""].join(" ")}></span>
                                <span class={["bg-white absolute top-1/2 block h-0.5 w-full max-w-[50%] transition-all duration-300", !compressAdise ? "opacity-0 translate-x-4 rtl:-translate-x-4" : ""].join(" ")}></span>
                                <span class={["bg-white absolute block h-0.5 w-full transition-all duration-300 bottom-0", !compressAdise ? "rotate-45 rtl:-rotate-45 max-w-[75%] bottom-1.5" : ""].join(" ")}></span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div className='w-full h-full overflow-x-hidden scrollbar-h'>
                <ul className='mx-3'>
                    <li>
                        <Link to="/" className=' py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center justify-between ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl transition-all'>
                            <div className='flex place-items-center'>
                                <HouseSimple size={25} className={["text-white group-hover:w-[25px] transition-all duration-300", compressAdise ? "w-4 " : "w-[25px]"].join(" ")} weight="duotone" /> <span className={['ml-3 transition-all duration-300 group-hover:w-auto group-hover:ml-3', compressAdise ? "w-0 overflow-hidden ml-0" : "block"].join(" ")}>Home</span> 
                            </div>
                        </Link>
                    </li> 
                    <li>
                        <Link to="/Menu" onClick={(e) => { 
                                e.preventDefault(); 
                                
                                if(menuOpen){
                                    setMenuOpen(!menuOpen); 
                                    setTimeout(() => setMenuOpenClosed(!menuOpenClosed), 100); 
                                }else{
                                    setMenuOpenClosed(!menuOpenClosed)
                                    setTimeout(() => {
                                        setMenuOpen(!menuOpen);
                                    }, 100); 
                                }
                            }} className=' py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center justify-between ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl transition-all'>
                            <div className='flex place-items-center'>
                                <ClipboardText size={25} className={["text-white group-hover:w-[25px] transition-all duration-300", compressAdise ? "w-4 " : "w-[25px]"].join(" ")} weight="duotone" /> <span className={['ml-3 transition-all duration-300 group-hover:w-auto group-hover:ml-3', compressAdise ? "w-0 overflow-hidden ml-0" : "block"].join(" ")}>Menu</span> 
                            </div>
                            {
                                !menuOpen ? 
                                    <CaretDown size={13} className="text-white" />
                                :
                                    <CaretUp size={13} className="text-white" />
                            }
                        </Link>
                        <div className={['bg-zinc-800 py-2 my-2 rounded-xl ease-in duration-100 transition-all ', menuOpen ? "opacity-100 " : "opacity-0 " , menuOpenClosed ? "block" : "hidden", compressAdise ? "px-2 group-hover:px-3" : "px-3"].join(" ")}>
                            <li>
                                <Link to="/Menu" className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                                    <span className={[' group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Lista Menù</span> 
                                </Link>    
                            </li>
                            <li>
                                <Link to="/Menu/Add" className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                                    <span className={[' group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Aggiungi Menù</span> 
                                </Link>    
                            </li>
                        </div>
                    </li> 

                    <li>
                        <Link to="/Menu/Categories" onClick={(e) => { 
                                e.preventDefault(); 
                                
                                if(categoryOpen){
                                    setCategoryOpen(!categoryOpen); 
                                    setTimeout(() => setCategoryOpenClosed(!categoryOpenClosed), 100); 
                                }else{
                                    setCategoryOpenClosed(!categoryOpenClosed)
                                    setTimeout(() => {
                                        setCategoryOpen(!categoryOpen);
                                    }, 100); 
                                }
                            }} className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center justify-between ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl transition-all'>
                            <div className='flex place-items-center transition-all'>
                                <Cube size={25} className={["text-white group-hover:w-[25px] transition-all duration-300", compressAdise ? "w-4" : "w-[25px]"].join(" ")} weight="duotone" /> <span className={['ml-3 transition-all duration-300 group-hover:w-auto group-hover:ml-3 text-nowrap', compressAdise ? "w-0 overflow-hidden ml-0" : "block"].join(" ")}>Categorie Menù</span>
                            </div>
                            {
                                !categoryOpen ? 
                                    <CaretDown size={13} className="text-white" />
                                :
                                    <CaretUp size={13} className="text-white" />
                            }
                        </Link>
                        <div className={['bg-zinc-800 py-2 my-2 rounded-xl ease-in duration-100 transition-all', categoryOpen ? "opacity-100" : "opacity-0", categoryOpenClosed ? "block" : "hidden", compressAdise ? "px-2 group-hover:px-3" : "px-3"].join(" ")}>
                            <li>
                                <Link to="/Menu/Categories" className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                                    <span className={[' group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Lista Categorie</span> 
                                </Link>    
                            </li>
                            <li>
                                <Link to="/Menu/Categories/Add" className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                                    <span className={[' group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Aggiungi Categoria</span> 
                                </Link>    
                            </li>
                        </div>
                    </li> 
                    
                    <li>
                        <Link to="/Menu/Articles" onClick={(e) => { 
                                e.preventDefault(); 
                                
                                if(articleOpen){
                                    setArticleOpen(!articleOpen); 
                                    setTimeout(() => setArticleOpenClosed(!articleOpenClosed), 100); 
                                }else{
                                    setArticleOpenClosed(!articleOpenClosed)
                                    setTimeout(() => {
                                        setArticleOpen(!articleOpen);
                                    }, 100); 
                                }
                            }} className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center justify-between ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                            <div className='flex place-items-center'>
                                <Article size={25} className={["text-white group-hover:w-[25px] transition-all duration-300", compressAdise ? "w-4" : "w-[25px]"].join(" ")} weight="duotone" /> <span className={['ml-3 transition-all duration-300 group-hover:w-auto group-hover:ml-3 text-nowrap', compressAdise ? "w-0 overflow-hidden ml-0" : "block"].join(" ")}>Articoli Menù</span> 
                            </div>
                            {
                                !articleOpen ? 
                                    <CaretDown size={13} className="text-white" />
                                :
                                    <CaretUp size={13} className="text-white" />
                            }
                        </Link>
                        <div className={['bg-zinc-800 py-2 my-2 rounded-xl ease-in duration-100 transition-all', articleOpen ? "opacity-100" : "opacity-0 ", articleOpenClosed ? "block" : "hidden", compressAdise ? "px-2 group-hover:px-3" : "px-3"].join(" ")}>
                            <li>
                                <Link to="/Menu/Articles" className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                                    <span className={[' group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Lista Articoli</span> 
                                </Link>    
                            </li>
                            <li>
                                <Link to="/Menu/Articles/Add" className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                                    <span className={[' group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Aggiungi Articolo</span> 
                                </Link>    
                            </li>
                        </div>
                    </li> 
                    {
                        props.data && props.data.storageActive ? 
                        <li>
                            <Link to="/Storage" onClick={(e) => { 
                                    e.preventDefault(); 
                                    
                                    if(storageOpen){
                                        setStorageOpen(!storageOpen); 
                                        setTimeout(() => setStorageOpenClosed(!storageOpenClosed), 100); 
                                    }else{
                                        setStorageOpenClosed(!storageOpenClosed)
                                        setTimeout(() => {
                                            setStorageOpen(!storageOpen);
                                        }, 100); 
                                    }
                                }} className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center justify-between ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                                <div className='flex place-items-center'>
                                    <Storefront  size={25} className={["text-white group-hover:w-[25px] transition-all duration-300", compressAdise ? "w-4" : "w-[25px]"].join(" ")} weight="duotone" /> <span className={['ml-3 transition-all duration-300 group-hover:w-auto group-hover:ml-3 text-nowrap', compressAdise ? "w-0 overflow-hidden ml-0" : "block"].join(" ")}>Magazzino</span> 
                                </div>
                                {
                                    !storageOpen ? 
                                        <CaretDown size={13} className="text-white" />
                                    :
                                        <CaretUp size={13} className="text-white" />
                                }
                            </Link>
                            <div className={['bg-zinc-800 py-2 my-2 rounded-xl ease-in duration-100 transition-all', storageOpen ? "opacity-100" : "opacity-0 ", storageOpenClosed ? "block" : "hidden", compressAdise ? "px-2 group-hover:px-3" : "px-3"].join(" ")}>
                                <li>
                                    <Link to="/Storage" className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                                        <span className={[' group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Lista Magazzino</span> 
                                    </Link>    
                                </li>
                                <li>
                                    <Link to="/Storage/Add" className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                                        <span className={[' group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Aggiungi Articolo</span> 
                                    </Link>    
                                </li>
                            </div>
                        </li> 
                        : null
                    }
                    {
                        props.data && props.data.teamsManagment ?
                            <li>
                                <Link to="/Teams" onClick={(e) => { 
                                        e.preventDefault(); 
                                        
                                        if(usersOpen){
                                            setUsersOpen(!usersOpen); 
                                            setTimeout(() => setUsersOpenClosed(!usersOpenClosed), 100); 
                                        }else{
                                            setUsersOpenClosed(!usersOpenClosed)
                                            setTimeout(() => {
                                                setUsersOpen(!usersOpen);
                                            }, 100); 
                                        }
                                    }} className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center justify-between ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl transition-all'>
                                    <div className='flex place-items-center transition-all'>
                                        <Users size={25} className={["text-white group-hover:w-[25px] transition-all duration-300", compressAdise ? "w-4" : "w-[25px]"].join(" ")} weight="duotone" /> <span className={['ml-3 transition-all duration-300 group-hover:w-auto group-hover:ml-3 text-nowrap', compressAdise ? "w-0 overflow-hidden ml-0" : "block"].join(" ")}>Teams</span>
                                    </div>
                                    {
                                        !usersOpen ? 
                                            <CaretDown size={13} className="text-white" />
                                        :
                                            <CaretUp size={13} className="text-white" />
                                    }
                                </Link>
                                <div className={['bg-zinc-800 py-2 my-2 rounded-xl ease-in duration-100 transition-all', usersOpen ? "opacity-100" : "opacity-0", usersOpenClosed ? "block" : "hidden", compressAdise ? "px-2 group-hover:px-3" : "px-3"].join(" ")}>
                                    <li>
                                        <Link to="/Teams" className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                                            <span className={[' group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Lista Operatori</span> 
                                        </Link>    
                                    </li>
                                    <li>
                                        <Link to="/Teams/Add-member" className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                                            <span className={[' group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Aggiungi Operatore</span> 
                                        </Link>    
                                    </li>
                                    <li>
                                        <Link to="/Calendar" className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                                            <span className={[' group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Calendario</span> 
                                        </Link>    
                                    </li>
                                </div>
                            </li> 
                        : null
                    }
                </ul>
            </div>
            <div className='w-full'>
                <div onClick={() => setFooterAside(!footerAside)} className={['bg-zinc-900 dark:bg-black text-white flex place-items-center ease-in duration-100 hover:bg-zinc-800  select-none cursor-pointer h-12', footerAside ? "p-3 m-2 mb-1 rounded-xl": "rounded-b-2xl p-4 ", compressAdise ? " justify-center group-hover:justify-start" : ""].join(" ")}>
                    <div className={['group-hover:mr-3', compressAdise ? "mr-0" : "mr-3"].join(" ")}>
                        <img src={Logo.length == 0 || Logo == null ? GestoreAvatar : isValidURL(Logo) ? Logo : process.env.REACT_APP_SERVER_IMAGE_ENDPOINT + "/public/images/dump/small-" + Logo} className='rounded-full w-6 h-6 bg-pink-200' alt="" />
                    </div>
                    <div className={['leading-3 group-hover:block transition-all duration-300 group-hover:w-auto  text-nowrap', compressAdise ? "w-0 overflow-hidden " : ""].join(" ")}>
                        <div className='font-semibold text-xs'>{owner_name}</div>
                        <span className='text-xs text-gray-500'>{activity_type}</span>
                    </div>
                </div>
                <ul className={['mx-3 ease-in duration-300 overflow-hidden', footerAside ? 'h-[174.8px] ' : 'h-0 '].join(" ")}>
                    <li>
                        <Link to="/Profile" className='p-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 hover:bg-zinc-800 rounded-xl'>
                            <User size={20} className="text-white" weight="duotone" /> <span className={['ml-3 group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Profilo</span> 
                        </Link>
                    </li>
                    <li>
                        <Link to="/Settings" className='p-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 hover:bg-zinc-800 rounded-xl'>
                            <Gear size={20} className="text-white" weight="duotone" /> <span className={['ml-3 group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Impostazioni</span> 
                        </Link>
                    </li>
                    <li className="divide border-b border-gray-600 -mx-3 my-2"></li>
                    <li>
                        <Link to="/" onClick={handleLogout} className='p-3 my-2 w-full text-red-400 flex text-sm font-semibold place-items-center ease-in duration-100 hover:bg-zinc-800 rounded-xl'>
                            <SignOut size={20} className="text-red-400"  /> <span className={['ml-3 group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Esci</span> 
                        </Link>
                    </li>
                </ul>
            </div>
            {/* <div className='w-full bottom-0 overflow-x-hidden'>
                <ul className='mx-3'>
                    <li>
                        <Link to="" className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 bg-zinc-900 hover:bg-zinc-800 rounded-xl'>
                            <SquaresFour size={25} className="text-white" weight="duotone" /> <span className={['ml-3 group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Dashboard</span> 
                        </a>
                    </li>
                    <li>
                        <Link to="" className='py-2 px-3 my-2 w-full text-white flex text-sm font-semibold place-items-center ease-in duration-100 hover:bg-zinc-800 rounded-xl'>
                            <SquaresFour size={25} className="text-white" weight="duotone" /> <span className={['ml-3 group-hover:block', compressAdise ? "hidden" : "block"].join(" ")}>Dashboard</span> 
                        </a>
                    </li>
                </ul>
            </div> */}
        </div>

        <div className={['navigation-bar absolute inset-y-1/2 -mt-20 h-40 w-4 group cursor-pointer -right-4', compressAdise ? "invisible" : "visible"].join(" ")} onClick={toggleBar} data-tooltip-place='right' data-tooltip-id="tooltip" data-tooltip-content="Toggle Aside">
            <div className=' bg-gray-950/40 dark:bg-gray-100/40 w-2 h-40 rounded relative left-1 scale-[.8] group-hover:scale-[.9] group-hover:left-2 ease-in duration-100'></div>
        </div>
        <Toaster />
    </aside>
}