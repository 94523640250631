import { gql } from '@apollo/client';

export const MutationNotific = gql`mutation Mutation($storeNotific: StoreNotific) {
    setNorific(StoreNotific: $storeNotific) {
      id
      title
      text
      user
      visualizzed
      createAt
      updateAt
    }
  }`;

export const Notifics = gql`query Query {
    notifics {
      id
      title
      text
      user
      visualizzed
      createAt
      updateAt
    }
  }`;
export const NotificsSettings = gql`query Query {
  clientNotificSettings {
    client
    transition_pay_end
    operation_out
    transition_error
    balance_undere
    new_update
    new_access_session
    new_password_request
    offer
    storage_article_hccp_t_live
    storage_article_quantity_finished
    storage_article_expired
  }
}`;
export const AllNotificsVisualizzed = gql`mutation Mutation {
  setAllVisualizzedNorific {
    id
    title
    text
    user
    visualizzed
    createAt
    updateAt
  }
}`;

export const UpdateNotificSettings = gql`mutation Mutation($inputClientNotificsSettings: InputClientNotificsSettings) {
  updateClientNotificsSettings(InputClientNotificsSettings: $inputClientNotificsSettings) {
    client
    transition_pay_end
    operation_out
    transition_error
    balance_undere
    new_update
    new_access_session
    new_password_request
    offer
    storage_article_hccp_t_live
    storage_article_quantity_finished
    storage_article_expired
  }
}`;

export const TrashAllNotific = gql`mutation SetAllTrashNotific {
  setAllTrashNotific {
    id
    title
    text
    user
    type
    visualizzed
    createAt
    updateAt
  }
}`;