import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom';
import {PencilSimple,CirclesThreePlus,Trash,Warning,CellTower, Check,CaretLeft } from '@phosphor-icons/react';
// import Datepicker from "react-tailwindcss-datepicker";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import {getArticle} from '../../../../GraphQl/Conections/Query.Storage.js';
import {DeleteArticle} from '../../../../GraphQl/Conections/Mutation.Storage.js';
import { Tooltip } from 'react-tooltip'

import makeAnimated from 'react-select/animated';
import {Datepicker} from '../../../../components/Calendar';

import Panetteria from '../../../../assets/images/categories/bakery.png';
import PesceECarne from '../../../../assets/images/categories/fishandMeat.png';
import Frutta from '../../../../assets/images/categories/fruit.png';
import Verdura from '../../../../assets/images/categories/lettuce.png';
import Carne from '../../../../assets/images/categories/meat.png';
import Bevande from '../../../../assets/images/categories/soft-drink.png';

const CatImage = (image) => {

    switch (parseInt(image)) {
        case 1:
            return Panetteria
            break;
        case 2:
            return PesceECarne
            break;
        case 3:
            return Frutta
            break;
        case 4:
            return Bevande
            break;
        default:
            return Panetteria
            break;
    }
}

export default function AddStorage(props) { 
    const dispatch = useDispatch();    
    const navigate = useNavigate();
    const params = useParams();
    const {id} = params;
    const animatedComponents = makeAnimated();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(null);
    const [category, setCategory] = useState(null);
    const [maxQuantity, setMaxQuantity] = useState(null);
    const [pricePurchase, setPricePurchase] = useState(0);
    const [priceSelling, setPriceSelling] = useState(0);
    const [lotto, setLotto] = useState(null);
    const [hccp, setHccp] = useState(null);
    const [unity, setUnity] = useState(null);
    const [insertion, setInsertion] = useState(null);
    const [description, setDescription] = useState(null);
    const [quantity, setQuantity] = useState(0);
    
    const [image, setImage] = useState();
    const [categoryList, setCategoriList] = useState([
        {
          "id": 0,
          "name": "Carne, Pesci e Uova",
          "image": ""
        },
        {
          "id": 1,
          "name": "Latte e Derivati",
          "image": ""
        },
        {
          "id": 2,
          "name": "Cereali e Derivati, Tuberi",
          "image": ""
        },
        {
          "id": 3,
          "name": "Legumi",
          "image": ""
        },
        {
          "id": 4,
          "name": "Grassi e Oli da Condimento",
          "image": ""
        },
        {
          "id": 5,
          "name": "Ortaggi",
          "image": ""
        },
        {
          "id": 6,
          "name": "Frutta",
          "image": ""
        },
        {
          "id": 7,
          "name": "Verdura",
          "image": ""
        },
        {
            "id": 8,
            "name": "Altro",
            "image": <CirclesThreePlus size={24} weight="duotone" />
        }
      ]);
    const [frozen, setFrozen] = useState( false);
    const [visibletoggle, setVisibleToggle] = useState(false);
    const [otherCatBool, setOtherCatBool] = useState(false);

    const [expired, setExpired] = useState(null);
    

    useEffect(() => {
        
        getArticle(dispatch, id).then((data) => { 
            setName(data.name);
            setCategory(data.category);
            setPricePurchase(data.pricePurchase);
            setPriceSelling(data.priceSelling);
            setLotto(data.lotto);
            setHccp(data.hccp);
            setUnity(data.unity);
            setInsertion(data.insertion);
            setDescription(data.descriptions);
            setQuantity(data.quantity);
            setMaxQuantity(data.maxQuantity);
            setImage(data.image);
            setFrozen(data.frozen);
            setExpired(data.expired);
        });
        
    }, [dispatch])

    const handleDelete = (e,item) =>{ 
        e.preventDefault();

        DeleteArticle(dispatch, id).then(data => { 
            toast.success("Articolo eliminato", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <Check className='text-green-500 w-10' size={20} />,
            });
            
            navigate("/Storage")
        });
    }


    const percentageStorage = (20 * maxQuantity) / 100;
    const date = new Date();
    const dataProd = new Date(parseInt(insertion));

    let day = String(dataProd.getDate()).padStart(2, '0'); 
    let month = String(dataProd.getMonth() + 1).padStart(2, '0'); 
    let year = dataProd.getFullYear();

    const edataProd = new Date(parseInt(expired));
    
    let eday = String(edataProd.getDate()).padStart(2, '0'); 
    let emonth = String(edataProd.getMonth() + 1).padStart(2, '0'); 
    let eyear = edataProd.getFullYear();

    // Calcola la differenza in millisecondi
    let timeDifference = edataProd - date;

    // Converti la differenza in giorni
    let daysToExpiry = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Verifica se il prodotto scade tra un certo numero di giorni
    let daysBeforeAlert = 5; // Numero di giorni prima della scadenza per l'avviso
    
    
    return <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-3'>
        <div className='mx-auto flex w-full max-w-2xl flex-col'>
            <div className='space-y-5 mt-10'>
                <div className='flex justify-between'>
                    <div className='flex justify-center space-x-2'>
                        <img src={CatImage(image)} className='rounded-full w-8 h-8 select-none bg-white dark:bg-slate-700' alt="" />
                        <p className="text-xl font-medium dark:text-slate-200">
                            {name}
                        </p>
                    </div>
                    <div className='flex justify-center space-x-3'>
                        <Link
                            to={"/Storage"}
                            className="flex gap-x-1 items-center justify-center focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed bg-white dark:bg-slate-800 rounded-full px-2 py-2 text-sm text-gray-700 dark:text-slate-100 hover:!text-violet-500 transition-all hover:bg-slate-100 dark:hover:bg-white/10  data-[focus]:text-gray-900 data-[focus]:forced-color-adjust-none data-[focus]:forced-colors:bg-[Highlight] data-[focus]:forced-colors:text-[HighlightText]"
                        >
                            
                            <div><CaretLeft size="15" /></div>
                            <div className='text-xs'>Torna indietro</div>
                        </Link>
                        <Link
                            to={"/Storage/Update/" + id}
                            data-tooltip-id="tooltip"
                            data-tooltip-content="Modifica elemento"
                            className="flex gap-x-4 items-center focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed bg-white dark:bg-slate-800 rounded-full px-2 py-2 text-sm text-gray-700 dark:text-slate-100 hover:!text-violet-500 transition-all hover:bg-slate-100 dark:hover:bg-white/10  data-[focus]:text-gray-900 data-[focus]:forced-color-adjust-none data-[focus]:forced-colors:bg-[Highlight] data-[focus]:forced-colors:text-[HighlightText]"
                        >
                            <PencilSimple size="15" weight='duotone' />
                        </Link>
                        <Link
                            to={"#"}
                            // onClick={handleDelete}
                            // data-tooltip-id="tooltip"
                            // data-tooltip-content="Rimuovi elemento"
                            id="clickable"
                            className="flex gap-x-4 items-center focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed bg-white dark:bg-slate-800 rounded-full px-2 py-2 text-sm text-gray-700 dark:text-slate-100 hover:!text-violet-500 transition-all hover:bg-slate-100 dark:hover:bg-white/10  data-[focus]:text-gray-900 data-[focus]:forced-color-adjust-none data-[focus]:forced-colors:bg-[Highlight] data-[focus]:forced-colors:text-[HighlightText]"
                        >
                            <Trash size="15" weight='duotone' />
                        </Link>
                    </div>
                </div>
                <div className='bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-900 rounded-md'>
                    <div className='border-slate-200 dark:border-slate-700 flex flex-col justify-between gap-y-8 w-full border-b p-8 sm:flex-row sm:items-center'>
                        <div className='space-y-3 w-full'>
                            <div className='text-slate-800 dark:text-slate-200 font-medium text-lg'>Informazioni</div>
                            <div>
                                <div className='text-slate-400 text-xs font-semibold uppercase pb-1 relative'>Descrizione:</div>
                                <p className='text-sm dark:text-slate-100'>{description}</p>
                            </div>
                            <div className='grid grid-cols-12'>
                                <div className='col-span-6'>
                                    <div className='text-slate-400 text-xs font-semibold uppercase pb-1 relative'>Lotto:</div>
                                    <p className='text-sm dark:text-slate-100'>{lotto == null ? <p className='text-sm underline dark:text-slate-100'>Non specificato</p> : ""}</p>
                                </div>
                                <div className='col-span-6'>
                                    <div className='text-slate-400 text-xs font-semibold uppercase pb-1 relative'>Categoria:</div>
                                    <p className='text-sm dark:text-slate-100'>{category}</p>
                                </div>
                            </div>
                            <div>
                                <div className='text-slate-400 text-xs font-semibold uppercase pb-1 relative'>Magazzino:</div>
                                <p className='text-sm dark:text-slate-100'>Disponibile: {quantity} su {maxQuantity}</p>
                                {
                                    quantity <= Math.round(percentageStorage) ? 
                                        <div className='flex space-x-2 mt-2'>
                                            <Warning size={20} weight="duotone" className='text-orange-500' />
                                            <small className='text-red-600 underline '>Questo prodotto sta per terminare</small>
                                        </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className='border-slate-200 dark:border-slate-700 flex flex-col justify-between gap-y-8 w-full border-b p-8 sm:flex-row sm:items-center'>
                        <div className='space-y-3 w-full'>
                            <div className='text-slate-800 dark:text-slate-200 font-medium text-lg'>Hccp</div>
                            <div className='col-span-12'>
                                <div className='text-slate-400 text-xs font-semibold uppercase pb-1 relative'>Temperatura di conservazione:</div>
                                <p className='text-sm dark:text-slate-100'>{hccp} °C</p>
                            </div>
                            <div className='col-span-12'>
                                <div className='text-slate-400 text-xs font-semibold uppercase pb-1 relative'>Prodotto congelato:</div>
                                <p className='text-sm dark:text-slate-100'>{frozen}</p>
                            </div>
                            <div className='grid grid-cols-12'>
                                <div className='col-span-6'>
                                    <div className='text-slate-400 text-xs font-semibold uppercase pb-1 relative'>Data di produzione:</div>
                                    <p className='text-sm dark:text-slate-100'>{day}/{month}/{year}</p>
                                </div>
                                <div className='col-span-6'>
                                    <div className='text-slate-400 text-xs font-semibold uppercase pb-1 relative'>Data di scadenza:</div>
                                    <p className={['text-sm dark:text-slate-100', date >= edataProd ? "text-red-500 font-medium" : ""].join(" ")}>{eday}/{emonth}/{eyear}</p>
                                </div>
                                
                            </div>
                            
                            {
                                (daysToExpiry <= daysBeforeAlert) && daysToExpiry > 0 ? 
                                    <div className='flex space-x-2 mt-2'>
                                        <Warning size={20} weight="duotone" className='text-orange-500' />
                                        <small className='text-red-600 underline '>Occhio! questo prodotto sta per scadere {daysToExpiry} giorni</small>
                                    </div>
                                :  null
                            }

                            {
                                date.getDate() == edataProd.getDate() && date.getMonth() == edataProd.getMonth() && date.getFullYear() == edataProd.getFullYear() ? 
                                    <div className='flex space-x-2 mt-2'>
                                        <Warning size={20} weight="duotone" className='text-orange-500' />
                                        <small className='text-orange-600 underline '>Occhio! questo prodotto scade in giornata</small>
                                    </div>
                                : date >= edataProd ? 
                                    <div className='flex space-x-2 mt-2'>
                                        <Warning size={20} weight="duotone" className='text-orange-500' />
                                        <small className='text-red-600 underline '>Occhio! questo prodotto è scaduto</small>
                                    </div>
                                : null
                            }
                        </div>
                    </div>
                    <div className='border-slate-200 dark:border-slate-700 flex flex-col justify-between w-full gap-y-8 border-b p-8 sm:flex-row sm:items-center'>
                        <div className='space-y-3 w-full'>
                            <div className='text-slate-800 dark:text-slate-200 font-medium text-lg'>Costi</div>
                            <div className='col-span-12'>
                                <div className='text-slate-400 text-xs font-semibold uppercase pb-1 relative'>Prezzo di acquisto:</div>
                                {
                                    pricePurchase == null ?
                                        <p className='text-sm underline dark:text-slate-100'>Non specificato</p>
                                        :
                                        <p className='text-sm dark:text-slate-100'>{pricePurchase} €</p>
                                }
                                
                            </div>
                            <div className='col-span-12'>
                                <div className='text-slate-400 text-xs font-semibold uppercase pb-1 relative'>Prezzo di vendita:</div>
                                {
                                    priceSelling == null ?
                                        <p className='text-sm underline dark:text-slate-100'>Non specificato</p>
                                        :
                                        <p className='text-sm dark:text-slate-100'>{priceSelling} €</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='border-slate-200 dark:border-slate-700 flex flex-col justify-between gap-y-8  p-8 sm:flex-row sm:items-center'>
                        <div className='space-y-3 w-full'>
                            <div className='text-slate-800 dark:text-slate-200 font-medium text-lg flex justify-between items-center'>
                                <div>Monitoraggio</div>
                                <Link to="https://www.mynuapp.it/Help/stato-di-connessione-telegestione-gestionale-002/" target="_blank" className='flex items-center space-x-2 text-blue-600 !text-red-600 hover:underline'><CellTower size={14} weight="duotone" /><small className='text-xs font-light'> Connessione assente</small></Link>
                            </div>
                            <div className='col-span-12'>
                                <div>Contatta l'aministrazione MynuApp tramite email per saperne di più</div>
                                <div>Vedi le informazioni relative alla telegestione <Link to="https://www.mynuapp.it/Help/come-attivare-la-telegestione-sul-gestionale-001/" className='hover:underline text-blue-600' target='_blank'>Qui nella sessione Help</Link></div>
                            </div>
                            {/* <div className='col-span-12'>
                                <div className='text-slate-400 text-xs font-semibold uppercase pb-1 relative'>Temperatura attuale prodotto:</div>
                                <p className='text-sm dark:text-slate-100'>7 °C</p>
                                <div className='flex space-x-2 mt-2'>
                                    <Warning size={20} weight="duotone" className='text-orange-500' />
                                    <small className='text-red-600 underline '>Occhio alla temperatura del tuo prodotto è al di sopra della temperatura impostata</small>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Toaster />
        <Tooltip anchorSelect="#clickable" clickable className='flex flex-col items-center'> 
            <div>Sei sicuro di voler eliminare?</div>
            <div>
                <button onClick={handleDelete} className='bg-green-700 text-white rounded-md w-14 hover:bg-green-600'>Si</button>
            </div>
        </Tooltip>
    </div>
}