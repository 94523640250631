// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: null, // Memorizzeremo qui i dati dell'utente
    calendar: [], // Aggiungi il campo calendar nello stato iniziale
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    clearUserInfo: (state) => {
      state.userInfo = null;
      state.calendar = []; // Resetta anche il calendario quando l'utente viene disconnesso
    },
    updateCalendar: (state, action) => {
      state.calendar = action.payload;
    },
    logout: (state) => {
      state.userInfo = null;
      state.calendar = [];
    },
  },
});

export const { setUserInfo, clearUserInfo, updateCalendar, logout } = userSlice.actions;
export default userSlice.reducer;
