import React, {useState, useEffect} from 'react'
import Stacked from '../../components/Layout/Main/stacked';
import Search from '../../components/Search';
import { Tooltip } from 'react-tooltip'
import AsideNav from '../../components/Aside';
import TransitionAnim from '../../components/transition';
import { useSelector, useDispatch } from 'react-redux'; 
import ClientInfoProtected from '../../GraphQl/Conections/Query.ClientInfoProtected';
import { setUserInfo } from '../../redux/reducers/clientReducer';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';


export default function Layout(props) {
    const dispatch = useDispatch(); 
    const location = useLocation();
    const user = useSelector(state => state.user.userInfo); 

    const [showSearch, setShowSearch] = useState(false);
    const [asideNavToggle, setAsideNavToggle] = useState(false);
    const [compressAsideNavVar, setCompressAsideNavVar] = useState(false);
    const [dropModal, setDropModal] = useState(false);
    
    const handleShowSearch = (e) => {
        setShowSearch(e);
    }
    
    const toggleAside = (val) => {
        setAsideNavToggle(val);
    }

    const compressAsideNav = (val) => {
        setCompressAsideNavVar(val);
    }

    const childrenWithProps = React.Children.map(props.children, (child) => {

        if (React.isValidElement(child)) {
            return React.cloneElement(child, { asideNavToggle, compressAsideNavVar, user });
        }
        return child;
    });
    
    // useEffect(() => {
    //     let deferredPrompt;

    //     window.addEventListener('beforeinstallprompt', (e) => {
    //         e.preventDefault();
    //         deferredPrompt = e;

    //         const InstallApp = async (t) => {
    //             toast.dismiss(t.id);
    //             if (deferredPrompt) {
    //                 // Mostra il prompt di installazione
    //                 deferredPrompt.prompt();
    //                 // Attendi la scelta dell'utente
    //                 const { outcome } = await deferredPrompt.userChoice;
    //                 if (outcome === 'accepted') {
    //                     console.log('Utente ha accettato di installare');
    //                 } else {
    //                     console.log('Utente ha rifiutato l\'installazione');
    //                 }
    //                 // Resetta l'evento per evitare duplicazioni
    //                 deferredPrompt = null;
    //             }
    //         }

    //         // Mostra un pulsante per installare l'app
    //         toast((t) => (
    //             <span>
    //               Installalo sul tuo dispositivo?
    //               <button onClick={() => InstallApp(t)} className="px-3 rounded-lg py-1 ml-4 bg-green-100 hover:bg-green-200 border border-green-200">
    //                 Installa
    //               </button>
    //             </span>
    //           ));
    //     });
    // }, [])

    useEffect(() => {
        ClientInfoProtected(dispatch, user).then(data => { 
            if (data && !user) {
                dispatch(setUserInfo(data));
            }
            
        });

    }, [user, dispatch])

    // if (loading) return <p>Loading...</p>; 
    // if (error) return <p>Error: {error.message}</p>; 
    

    
    document.body.classList.add('bg-slate-100');
    document.body.classList.add('dark:bg-slate-900')
    // document.documentElement.classList.add('overflow-y-scroll');

    // useEffect(() => {
    //     console.log(dropModal);

    // }, [dropModal])


    return <div className="flex">
                <div className='flex flex-[1_0_auto] flex-col relative h-full w-full '>
                    <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu  blur-3xl sm:-top-80 h-fit"
                    aria-hidden="true"
                    >
                        <div
                            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                            style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                    
                    <AsideNav className={["flex flex-auto", location.pathname === "/Settings" ? "opacity-0" : ""].join(" ")} asideNav={toggleAside} compressAsideNav={compressAsideNav} data={user} />
                    
                    <main className={['flex flex-auto transition-all duration-300', asideNavToggle ? "pl-0" : "pl-60", compressAsideNavVar ? "!pl-20" : ""].join(" ")}>
                        <div className='w-full relative'>
                            <div className={[asideNavToggle ? "relative" : "absolute md:relative", compressAsideNavVar ? "relative" : "absolute md:relative"].join(" ")}>
                                <Stacked className={props.topBar ? "" : "hidden"} iconSearch={handleShowSearch} asideNavToggle={asideNavToggle} closeDropModal={(e) => setDropModal(e)} data={user} />
                                <TransitionAnim>
                                    <div className={['transition-all duration-300 ', dropModal ? "top-80" : props.topBar ? "top-20" : "top-0"].join(" ")}>
                                        {childrenWithProps}
                                    </div>
                                </TransitionAnim>
                                <Search handleShow={() => handleShowSearch} show={showSearch} />
                            </div>
                        </div>
                    </main>
                    <Tooltip id="tooltip" className="!p-2 text-xs !rounded-md" />
                </div>
            </div>
}