import React, { useState, useEffect } from 'react'
import { useNavigate,useParams } from 'react-router-dom';
import {Textbox ,Notepad,Blueprint,X,CurrencyEur,Thermometer,Archive,CirclesThreePlus,Check } from '@phosphor-icons/react';
// import Datepicker from "react-tailwindcss-datepicker";
import {Toggle} from '../../../../components/Toggles';
import Loading from '../../../../assets/svgs/loading.jsx';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Select from '../../../../components/Select';
import { useDispatch } from 'react-redux';
import {UpdateArticle} from '../../../../GraphQl/Conections/Mutation.Storage.js';
import {getArticle} from '../../../../GraphQl/Conections/Query.Storage.js';

import makeAnimated from 'react-select/animated';
import {Datepicker} from '../../../../components/Calendar';

import Panetteria from '../../../../assets/images/categories/bakery.png';
import PesceECarne from '../../../../assets/images/categories/fishandMeat.png';
import Frutta from '../../../../assets/images/categories/fruit.png';
import Verdura from '../../../../assets/images/categories/lettuce.png';
import Carne from '../../../../assets/images/categories/meat.png';
import Bevande from '../../../../assets/images/categories/soft-drink.png';

export default function AddStorage(props) { 
    const dispatch = useDispatch();    
    const navigate = useNavigate();
    const params = useParams();
    const {id} = params;
    const animatedComponents = makeAnimated();
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState(null);
    const [category, setCategory] = useState(null);
    const [categoryName, setCategoryName] = useState(null);
    const [note, setNote] = useState(null);
    const [pricePurchase, setPricePurchase] = useState(0);
    const [priceSelling, setPriceSelling] = useState(0);
    const [lotto, setLotto] = useState(null);
    const [hccp, setHccp] = useState(null);
    const [unity, setUnity] = useState(null);
    const [insertion, setInsertion] = useState(null);
    const [description, setDescription] = useState(null);
    const [quantity, setQuantity] = useState(0);
    
    const [image, setImage] = useState();
    const [categoryList, setCategoriList] = useState([
        {
          "id": "Carne, Pesci e Uova",
          "name": "Carne, Pesci e Uova",
          "image": ""
        },
        {
          "id": "Latte e Derivati",
          "name": "Latte e Derivati",
          "image": ""
        },
        {
          "id": "Cereali e Derivati, Tuberi",
          "name": "Cereali e Derivati, Tuberi",
          "image": ""
        },
        {
          "id": "Legumi",
          "name": "Legumi",
          "image": ""
        },
        {
          "id": "Grassi e Oli da Condimento",
          "name": "Grassi e Oli da Condimento",
          "image": ""
        },
        {
          "id": "Ortaggi",
          "name": "Ortaggi",
          "image": ""
        },
        {
          "id": "Frutta",
          "name": "Frutta",
          "image": ""
        },
        {
          "id": "Verdura",
          "name": "Verdura",
          "image": ""
        },
        {
            "id": 8,
            "name": "Altro",
            "image": <CirclesThreePlus size={24} weight="duotone" />
        }
      ]);
    const [frozen, setFrozen] = useState( false);
    const [visibletoggle, setVisibleToggle] = useState(false);
    const [otherCatBool, setOtherCatBool] = useState(false);

    const [expired, setExpired] = useState(null);
    


    useEffect(() => {
        
        getArticle(dispatch, id).then((data) => { 
            setName(data.name);
            setCategoryName(data.category);
            setPricePurchase(data.pricePurchase);
            setPriceSelling(data.priceSelling);
            setLotto(data.lotto);
            setHccp(data.hccp);
            setUnity(data.unity);
            setInsertion(data.insertion);
            setNote(data.descriptions);
            setQuantity(data.quantity);
            
            setImage(data.image);
            setFrozen(data.frozen);
            setExpired(data.expired);
            
        });
    }, [dispatch])
    
    const handleConferme = () => {
        
        if(name.length === 0){
            toast.error("Imposta un nome per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        if(categoryName.length === 0){
            toast.error("Imposta una categoria per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        if(quantity === 0 || quantity == null){
            toast.error("Imposta una quantità per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }
        if(unity == null){
            toast.error("Imposta un unità per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }
        
        if(expired == null || expired == ""){
            toast.error("Imposta una data di scadenza per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        if(quantity > 1000){
            toast.error("Hai superato il valore massimo di quantità", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        setLoading(true);

        // if(image){
        //      //Per prima cosa invia l'immagine a graphql e poi inserisci nel db tutto il resto.
        //     UploadFile(dispatch, image).then(data => {
        //         //Setta categoria nel db 
        //         handleOk(data.uploadFile);
        //     })
        // }else{
        //     handleOk(null);
        // }
       
        handleOk();
    }

    const handleOk = () => {

        UpdateArticle(dispatch, {
            _id: id,
            name: name,
            image: image.toString(),
            descriptions: description,
            lotto: lotto,
            expired: expired,
            insertion: insertion,
            openPackage: null,
            pricePurchase: parseInt(pricePurchase),
            priceSelling: parseInt(priceSelling),
            quantity: parseInt(quantity),
            maxQuantity: 1000,
            unity: unity,
            frozen: typeof frozen == "boolean" ? frozen : frozen.target.checked ? true : false,
            hccp: hccp,
            category: categoryName
        }).then(data => { 
            setTimeout(() => {

                toast.success("Articolo aggiornato nel magazzino", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: 'dark:bg-slate-800 dark:text-slate-100',
                    // Custom Icon
                    icon: <Check className='text-green-500 w-10' size={20} />,
                });
                setLoading(false);
            }, 1000);
        });
        
    }

    const handleChooseCategory = (e) => {
        
        if(e.id === 8){
            setOtherCatBool(true);
        }else{
            setCategory(e);
            setCategoryName(e.name);
            setOtherCatBool(false);
        }
    }

    const selectImage = (img) => {
        setImage(img);
    }

    return <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-3'>
        <div className='mx-auto flex w-full max-w-2xl flex-col'>
            <div className='space-y-5 mt-10'>
                <div>
                    <p className="text-lg font-medium dark:text-slate-200">
                        Scegli immagine 
                    </p>
                    <p className="text-xs font-normal text-slate-600 dark:text-slate-300">Scegli un immagine che categorizza il tuo prodotto</p>
                </div>
                {/* <div className='relative flex flex-col items-center justify-center gap-4'>
                    <SingleUpload multiple={false} removeImage={() => {}} files={(file) => setImage(file[0])} />
                </div> */}
                <div className="relative grid grid-cols-12 justify-items-center">
                    <div className="col-span-6 md:col-span-3">
                        <div className={['mask-avatar mask w-14 h-14 bg-gray-700 scale-125 transition-all', image == 1 ? "opacity-100" : "opacity-0"].join(" ")}></div>
                        <div onClick={() => selectImage(1)} className={["select-none mask-avatar mask absolute top-0 inline-flex justify-center w-14 h-14 cursor-pointer hover:scale-110 transition-all", image == 1 ? "scale-110" : ""].join(" ")}>
                            <img className="w-full h-full" src={Panetteria} alt="" />
                        </div> 
                    </div>
                    <div className="col-span-6 md:col-span-3">
                        <div className={['mask-avatar mask w-14 h-14 bg-gray-700 scale-125 transition-all', image == 2 ? "opacity-100" : "opacity-0"].join(" ")}></div>
                        <div onClick={() => selectImage(2)} className={["select-none mask-avatar mask absolute top-0 inline-flex justify-center w-14 h-14 cursor-pointer hover:scale-110 transition-all", image == 2 ? "scale-110" : ""].join(" ")}>
                            <img className="w-full h-full " src={PesceECarne} alt="" />
                        </div> 
                    </div>
                    <div className="col-span-6 md:col-span-3">
                        <div className={['mask-avatar mask w-14 h-14 bg-gray-700 scale-125 transition-all', image == 3 ? "opacity-100" : "opacity-0"].join(" ")}></div>
                        <div onClick={() => selectImage(3)} className={["select-none mask-avatar mask absolute top-0 inline-flex justify-center w-14 h-14 cursor-pointer hover:scale-110 transition-all", image == 3 ? "scale-110" : ""].join(" ")}>
                            <img className="w-full h-full" src={Frutta} alt="" />
                        </div> 
                    </div>
                    <div className="col-span-6 md:col-span-3">
                        <div className={['mask-avatar mask w-14 h-14 bg-gray-700 scale-125 transition-all', image == 4 ? "opacity-100" : "opacity-0"].join(" ")}></div>
                        <div onClick={() => selectImage(4)} className={["select-none mask-avatar mask absolute top-0 inline-flex justify-center w-14 h-14 cursor-pointer hover:scale-110 transition-all", image == 4 ? "scale-110" : ""].join(" ")}>
                            <img className="w-full h-full" src={Bevande} alt="" />
                        </div> 
                    </div>
                </div>
            </div>
            <div className="space-y-6 mt-10">
                <fieldset className='relative'>
                    <legend className="mb-0">
                        <p className="text-lg font-medium dark:text-slate-200" tag="h3">Informazioni Prodotto</p>
                        <span className="text-xs font-normal text-slate-600 dark:text-slate-300">Inserisci i dettagli del nuovo Prodotto</span>
                    </legend>
                </fieldset>
                <div className='grid grid-cols-12 gap-4'>
                    <div className="col-span-12">
                        <label htmlFor="name" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Nome Prodotto
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="name"
                                    name="name"
                                    required
                                    onChange={(e) => { setName(e.target.value) }}
                                    defaultValue={name}
                                    type="text"
                                    placeholder="Ex: Pomodori"
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <Notepad className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12">
                        <label htmlFor="order" className="flex text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            <span>Note Prodotto</span>
                        </label>
                        <div className="mt-2">
                            <div className='flex relative'>
                                <textarea
                                    id="descriptions"
                                    name="descriptions"
                                    type="text"
                                    placeholder="Breve descrizione del prodotto (opzionale)"
                                    required
                                    onChange={(e) => setNote(e.target.value)}
                                    defaultValue={note}
                                    rows={4}
                                    className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >{note}</textarea>
                                <label htmlFor="descriptions" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                    <Textbox  className=' absolute top-2 left-2' size="20" weight='duotone' />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                        <label htmlFor="cat_select" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Categoria 
                        </label>
                        <div className="mt-2">
                            <Select defaultValue={categoryName} list={categoryList} name="cat_select" onChange={handleChooseCategory}  />
                        </div>
                    </div>
                    {
                        otherCatBool || categoryList.filter(item => item.id == categoryName).length == 0 ? 
                        <div className="col-span-12 md:col-span-6">
                            <label htmlFor="name" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                Altra categoria
                            </label>
                            <div className="mt-2">
                                <div className="flex gap-2 justify-center items-center bg-white px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                    <input
                                        id="categoryName"
                                        name="categoryName"
                                        required
                                        onChange={(e) => { setCategoryName(e.target.value) }}
                                        defaultValue={categoryName}
                                        type="text"
                                        placeholder="Ex: Ortaggi"
                                        className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                    />
                                    <CirclesThreePlus className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                                </div>
                            </div>
                        </div>
                        : null
                    }

                    <div className="col-span-12 md:col-span-6">
                        <label htmlFor="quantity" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Quantità
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                
                                <input
                                    id="quantity"
                                    name="quantity"
                                    required
                                    onChange={(e) => { setQuantity(e.target.value) }}
                                    type="number"
                                    min="0"
                                    max={1000}
                                    placeholder="0 / 1000"
                                    defaultValue={quantity}
                                    className="peer  order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <Archive className='text-gray-400 peer-invalid:text-red-600 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                        <label htmlFor="unity" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Unità 
                        </label>
                        <div className="mt-2">
                            <Select defaultValue={unity} list={[{id: "kg", name: "Kg"}, {id: "pz", name: "Pezzo"}, {id: "l", name: "Litro"}]} name="unity" onChange={(e) => setUnity(e.id)}  />
                        </div>
                    </div>
                </div>

                <fieldset className='relative'>
                    <legend className="mt-0">
                        <p className="text-lg font-medium dark:text-slate-200" tag="h3">Temperature e Scadenze</p>
                        <span className="text-xs font-normal text-slate-600 dark:text-slate-300">Inserisci le temperature di conservazione e le date di scadenza secondo le normative HCCP</span>
                    </legend>
                </fieldset>

                <div className='col-span-12 grid grid-cols-12 gap-x-4'>
                    <div className="col-span-6">
                        <label htmlFor="unity_sel" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Data di produzione prodotto (optional)
                        </label>
                        <div className="mt-2">
                            <Datepicker defaultValue={insertion} value={(e) => setInsertion(e)} />
                        </div>
                    </div>
                    <div className="col-span-6">
                        <label htmlFor="unity_sel" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Data di scadenza prodotto
                        </label>
                        <div className="mt-2">
                            <Datepicker  defaultValue={expired} value={(e) => setExpired(e)} />
                        </div>
                    </div>
                </div>
                <div className='col-span-12 grid grid-cols-12 gap-x-6'>
                    <div className="col-span-6">
                        <label htmlFor="temperature" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Temperatura di conservazione
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="temperature"
                                    name="temperature"
                                    required
                                    onChange={(e) => { setHccp(e.target.value) }}
                                    defaultValue={hccp}
                                    type="number"
                                    placeholder="0 °C"
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <Thermometer className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 ">
                        <label htmlFor="frozen-product" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Prodotto congelato
                        </label>
                        <div className='flex relative mt-3'>
                            <Toggle name="frozen-product" status={frozen} onClick={setFrozen} />
                        </div>
                    </div>
                </div>

                <div className="col-span-12 md:col-span-6">
                    <label htmlFor="lotto" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                        Lotto di produzione
                    </label>
                    <div className="mt-2">
                        <div className="flex gap-2 justify-center items-center bg-white px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <input
                                id="lotto"
                                name="lotto"
                                required
                                onChange={(e) => { setLotto(e.target.value) }}
                                defaultValue={lotto}
                                type="text"
                                placeholder="Lotto: "
                                className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                            />
                            <Blueprint className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                        </div>
                    </div>
                </div>

                <fieldset className='relative'>
                    <legend className="mt-0">
                        <p className="text-lg font-medium dark:text-slate-200" tag="h3">Acquisti e vendita</p>
                        <span className="text-xs font-normal text-slate-600 dark:text-slate-300">I campi relativi al costo di acquisto e vendita. Questi campi sono opzionali, ma ci aiutano a migliorare il nostro servizio. Tieni presente che i dati inseriti non verranno esposti o condivisi con terzi.</span>
                    </legend>
                </fieldset>

                <div className='col-span-12 grid grid-cols-12 gap-x-4'>
                    <div className="col-span-12 md:col-span-6">
                        <label htmlFor="pricePurchase" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Prezzo acquisto prodotto, riferito al campo "Unità"
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="pricePurchase"
                                    name="pricePurchase"
                                    required
                                    onChange={(e) => { setPricePurchase(e.target.value) }}
                                    defaultValue={pricePurchase}
                                    type="text"
                                    placeholder="Prezzo di acquisto per unità (optional)"
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <CurrencyEur className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-span-12 md:col-span-6">
                        <label htmlFor="priceSelling" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Prezzo vendita prodotto, riferito al campo "Unità"
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="priceSelling"
                                    name="priceSelling"
                                    required
                                    onChange={(e) => { setPriceSelling(e.target.value) }}
                                    defaultValue={priceSelling}
                                    type="text"
                                    placeholder="Prezzo di vendita per unità (optional)"
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <CurrencyEur className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
            <div className="mt-10 grid grid-cols-12 gap-4">
                    <div className="col-span-6">
                        <Link
                            to="/Storage"
                            className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-slate-200 disabled:bg-slate-600/50 px-3 py-3  font-normal leading-6 text-black shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                        >
                            <span className={['transition-all', "translate-y-0 opacity-100"].join(" ")}>Annulla</span>
                        </Link>
                    </div>
                    <div className="col-span-6"> 
                        <button
                            onClick={handleConferme}
                            disabled={loading || name != null && name.length == 0 }
                            className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-indigo-600 disabled:bg-indigo-600/50 px-3 py-3  font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                            <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Aggiorna</span>
                        </button>
                    </div>
                </div>
        </div>
        <Toaster />
    </div>
}