import React, { useState, useEffect, useRef } from 'react'
import { CalendarBlank, CaretLeft, CaretRight, CaretDoubleLeft , CaretDoubleRight,X } from '@phosphor-icons/react'

const months = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
const Month = (month) => {
    return months[month];
}

export function Datepicker(props){
    const [datePFocused, setDatePFocused] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [nextDate, setNextDate] = useState(new Date());
    const [isSliding, setIsSliding] = useState(false);
    const [openSetMonth, setOpenSetMonth] = useState(false);
    const [openSetYear, setOpenSetYear] = useState(false);
    const [disabledDate, setDisabledDate] = useState(true);
    const [slideDirection, setSlideDirection] = useState('');
    const [startYear, setStartYear] = useState(2025);
    const [menuPosition, setMenuPosition] = useState('bottom');
    const [date, setDate] = useState("");
    const datepickerRef = useRef(null);
    const menuRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        props.value(currentDate);
    }, [date]);

    useEffect( () => {
        if(props.defaultValue){
            if(typeof props.defaultValue == "string"){
                console.log(new Date(parseInt(props.defaultValue)), props.defaultValue);
                setCurrentDate(new Date(parseInt(props.defaultValue)));
            }else{
                setCurrentDate(new Date(props.defaultValue));
            }
        }
    }, [props.defaultValue])

    useEffect(() => {
        setNextDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    }, [currentDate]);

    const handlePosition = () => {
        if (menuRef.current && datepickerRef.current) {
            const menuHeight = 359.8; // Altezza prevista del menu
            const rect = datepickerRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            if (rect.bottom + menuHeight > windowHeight) {
                setMenuPosition('top');
            } else {
                setMenuPosition('bottom');
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datepickerRef.current && !datepickerRef.current.contains(event.target) && !menuRef.current.contains(event.target)) {
                setDatePFocused(false);
                setOpenSetMonth(false);
                setOpenSetYear(false);
                setTimeout(() => {
                    setDisabledDate(true);
                }, 100);
                if(inputRef.current){
                    inputRef.current.blur();
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [datepickerRef, menuRef]);

    const generateCalendar = (date) => {
        const startDay = (new Date(date.getFullYear(), date.getMonth(), 1).getDay() + 6) % 7; // Modifica per far iniziare da lunedì
        const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        const daysInPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
        const calendar = [];
        let day = 1;

        for (let i = 0; i < 6; i++) {
            const week = [];
            for (let j = 0; j < 7; j++) {
                if (i === 0 && j < startDay) {
                    week.push({ day: daysInPrevMonth - startDay + j + 1, currentMonth: false });
                } else if (day > daysInMonth) {
                    week.push({ day: day - daysInMonth, currentMonth: false });
                    day++;
                } else {
                    week.push({ day: day, currentMonth: true });
                    day++;
                }
            }
            calendar.push(week);
        }
        return calendar;
    };

    const chooseDay = (day, month, year) => {
        console.log(`Giorno scelto: ${day}, Mese: ${month + 1}, Anno: ${year}`);
        // Puoi aggiungere qui la logica per gestire la scelta del giorno
        setDate(day + "/" + month + 1 + "/" + year);
        setDatePFocused(false);
        setOpenSetMonth(false);
        setOpenSetYear(false);
        setTimeout(() => {
            setDisabledDate(true);
        }, 100);
        if(inputRef.current){
            inputRef.current.blur();
        }
    };

    const chooseMonth = () =>{
        setOpenSetMonth(!openSetMonth);
        setOpenSetYear(false);
    }

    const chooseYear = () =>{
        setOpenSetYear(!openSetYear);
        setOpenSetMonth(false);
    }

    const setChooseMonth = (e) => {
        setCurrentDate(new Date(currentDate.getFullYear(), e, 1));
        setOpenSetMonth(false);
    }

    const handlePrevMonth = () => {
        setSlideDirection('left');
        setIsSliding(true);
        setTimeout(() => {
            setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
            setIsSliding(false);
        }, 300);
    };

    const handleNextMonth = () => {
        setSlideDirection('right');
        setIsSliding(true);
        setTimeout(() => {
            setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
            setIsSliding(false);
        }, 300);
    };

    const generateYears = (startYear) => {
        const years = [];
        for (let i = 0; i < 12; i++) {
            years.push(startYear + i);
        }
        return years;
    };
    const handlePrevYearSet = () => {
        setStartYear(startYear - 12);
    };

    const handleNextYearSet = () => {
        setStartYear(startYear + 12);
    };

    const calendar = generateCalendar(currentDate);
    const nextCalendar = generateCalendar(nextDate);
    const years = generateYears(startYear);

    return <div className="relative">
            <div ref={datepickerRef} className="relative flex gap-2 justify-center items-center bg-white px-2 transition-all dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <input
                    ref={inputRef}
                    id="calendar"
                    name="calendar"
                    type="text"
                    placeholder={currentDate.toLocaleDateString()} 
                    defaultValue={date}
                    required
                    onFocus={() => {
                        setDisabledDate(false);
                        setTimeout(() => {
                            handlePosition();
                            setDatePFocused(true);
                        }, 100);
                    }}
                    // onBlur={() => setDatePFocused(false)}
                    // onMouseEnter={() => setDatePFocused(true)}
                    // onMouseLeave={() => setDatePFocused(false)}
                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                />
                <button onClick={() => { setDate("") }} className={['order-last transition-all duration-300', date.length > 0 ? "opacity-100" : "opacity-0"].join(" ")}><X className=' text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' /></button>
                <CalendarBlank className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
            </div>
            <div ref={menuRef} className={['transition-all ease-out duration-300 absolute z-10 mt-[1px] text-sm lg:text-xs 2xl:text-sm mb-2.5 mt-2.5 block translate-y-0 ', datePFocused ? "opacity-100" : "opacity-0", disabledDate ? "hidden" : "block",menuPosition === 'top' ? 'bottom-full mb-2' : 'top-full mt-2'].join(" ")} onMouseDown={(e) => e.preventDefault()}>
                <div className='mt-2.5 shadow-sm border border-gray-300 px-1 py-0.5 bg-white dark:bg-slate-800 dark:text-white dark:border-slate-600 rounded-lg'>
                    <div className='flex flex-col lg:flex-row py-2'>
                        <div className='flex items-stretch flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-1.5 md:pl-1 pr-2 lg:pr-1'>
                            <div className="w-full md:w-[296px] md:min-w-[296px]">
                                <div className="flex justify-between items-center space-x-1.5 border border-gray-300 dark:border-gray-700 rounded-md px-2 py-1.5">
                                    <div className='flex-none'>
                                        <button 
                                            className={['w-full tracking-wide dark:text-white/70 dark:hover:bg-white/10 dark:focus:bg-white/10 transition-all duration-300 px-3 py-[0.55rem] uppercase hover:bg-gray-100 rounded-md focus:ring-1 focus:ring-indigo-500/50 focus:bg-indigo-100/50', openSetYear ? "hidden" : "block"].join(" ")}
                                            onClick={handlePrevMonth}
                                        >
                                            <CaretLeft size="20" />
                                        </button>
                                        <button 
                                            className={['w-full tracking-wide dark:text-white/70 dark:hover:bg-white/10 dark:focus:bg-white/10 transition-all duration-300 px-3 py-[0.55rem] uppercase hover:bg-gray-100 rounded-md focus:ring-1 focus:ring-indigo-500/50 focus:bg-indigo-100/50', openSetYear ? "block" : "hidden"].join(" ")}
                                            onClick={handlePrevYearSet}
                                        >
                                            <CaretDoubleLeft size="20" />
                                        </button>
                                    </div>
                                    <div className='flex flex-1 items-center space-x-1.5'>
                                        <div className="w-1/2">
                                            <button onClick={chooseMonth} className={['w-full tracking-wide dark:text-white/70 dark:hover:bg-white/10 dark:focus:bg-white/10 transition-all duration-300 px-3 py-[0.55rem] uppercase hover:bg-gray-100 rounded-md focus:ring-1 focus:ring-indigo-500/50 focus:bg-indigo-100/50 font-medium truncate', openSetMonth ? "outline-slate-400 outline-2 outline-offset-2 outline-dashed" : ""].join(" ")}>{Month(currentDate.getMonth())}</button>
                                        </div>
                                        <div className="w-1/2">
                                            <button onClick={chooseYear} className={['w-full tracking-wide dark:text-white/70 dark:hover:bg-white/10 dark:focus:bg-white/10 transition-all duration-300 px-3 py-[0.55rem] uppercase hover:bg-gray-100 rounded-md focus:ring-1 focus:ring-indigo-500/50 focus:bg-indigo-100/50 font-medium', openSetYear ? "outline-slate-400 outline-2 outline-offset-2 outline-dashed" : ""].join(" ")}>{currentDate.getFullYear()}</button>
                                        </div>
                                    </div>
                                    <div>
                                        <button 
                                            className={['w-full tracking-wide dark:text-white/70 dark:hover:bg-white/10 dark:focus:bg-white/10 transition-all duration-300 px-3 py-[0.55rem] uppercase hover:bg-gray-100 rounded-md focus:ring-1 focus:ring-indigo-500/50 focus:bg-indigo-100/50', openSetYear ? "hidden" : "block"].join(" ")}
                                            onClick={handleNextMonth}
                                        >
                                            <CaretRight size="20" />
                                        </button>
                                        <button 
                                            className={['w-full tracking-wide dark:text-white/70 dark:hover:bg-white/10 dark:focus:bg-white/10 transition-all duration-300 px-3 py-[0.55rem] uppercase hover:bg-gray-100 rounded-md focus:ring-1 focus:ring-indigo-500/50 focus:bg-indigo-100/50', openSetYear ? "block" : "hidden"].join(" ")}
                                            onClick={handleNextYearSet}
                                        >
                                            <CaretDoubleRight size="20" />
                                        </button>
                                    </div>
                                </div>
                                <div className="relative overflow-hidden px-0.5 sm:px-2 mt-0.5 min-h-[285px]">
                                    <div className={['absolute inset-0 transition-all duration-300 w-full h-full bg-white dark:bg-slate-800 dark:text-slate-100 z-10', openSetMonth ? "block": "hidden"].join(" ")}>
                                        <div className='px-0.5 sm:px-2 mt-0.5 min-h-[285px]'>
                                            <div className="w-full grid grid-cols-2 gap-2 mt-2">
                                                {
                                                    months.map((item, index) => {

                                                        return <button onClick={() => setChooseMonth(index)} className={['w-full tracking-wide dark:text-white/60 dark:hover:bg-white/10 dark:focus:bg-white/10 transition-all duration-300 px-3 py-[0.55rem] uppercase hover:bg-gray-100 rounded-md focus:ring-1 focus:ring-indigo-500/50 focus:bg-indigo-100/50', currentDate.getMonth() == index ? "font-medium bg-gray-50 dark:bg-white/5 outline-slate-400 outline-2 outline-offset-2 outline-dashed" : ""].join(" ")}>{item}</button>
                                                    })
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className={['absolute inset-0 transition-all duration-300 w-full h-full bg-white dark:bg-slate-800 dark:text-slate-100 z-10', openSetYear ? "block": "hidden"].join(" ")}>
                                        <div className='px-0.5 sm:px-2 mt-0.5 min-h-[285px]'>
                                            <div className="w-full grid grid-cols-2 gap-2 mt-2">
                                                {
                                                    years.map((year) => (
                                                        <button onClick={() => {setCurrentDate(new Date(year, currentDate.getMonth(), 1)); setOpenSetYear(false); setOpenSetMonth(false); }} className={['w-full tracking-wide dark:text-white/60 dark:hover:bg-white/10 dark:focus:bg-white/10 transition-all duration-300 px-3 py-[0.55rem] uppercase hover:bg-gray-100 rounded-md focus:ring-1 focus:ring-indigo-500/50 focus:bg-indigo-100/50', currentDate.getFullYear() == year ? "font-medium bg-gray-50 dark:bg-white/5 outline-slate-400 outline-2 outline-offset-2 outline-dashed" : ""].join(" ")}>{year}</button>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`absolute inset-0 transition-all duration-300 ${isSliding ? (slideDirection === 'left' ? 'translate-x-2 opacity-0' : '-translate-x-2 opacity-0') : ' opacity-100'}`}>
                                        <div className="grid grid-cols-7 border-b border-gray-300 dark:border-gray-700 py-2">
                                            {['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'].map(day => (
                                                <div key={day} className='tracking-wide text-gray-500 text-center'>{day}</div>
                                            ))}
                                        </div>
                                        <div className='grid grid-cols-7 gap-y-0.5 my-1'>
                                            {calendar.map((week, i) => (
                                                week.map((dayObj, j) => (
                                                    <button 
                                                        key={`${i}-${j}`} 
                                                        onClick={() => chooseDay(dayObj.day, currentDate.getMonth(), currentDate.getFullYear())} 
                                                        className={`flex items-center justify-center w-12 h-12 lg:w-10 lg:h-10 focus:bg-violet-500 focus:text-white focus:font-medium focus:rounded-full ${dayObj.currentMonth ? '' : 'text-gray-400'} `}> {/**${dayObj.day == currentDate.getDate() ? 'bg-violet-500 text-white font-medium rounded-full' : ''} */}
                                                        {dayObj.day}
                                                    </button>
                                                ))
                                            ))}
                                        </div>
                                    </div>
                                    <div className={`absolute inset-0 transition-all duration-300  ${isSliding ? (slideDirection === 'left' ? 'translate-x-2 opacity-0' : '-translate-x-2 opacity-0') : ' opacity-0 hidden'}`}>
                                        <div className="grid grid-cols-7 border-b border-gray-300 dark:border-gray-700 py-2">
                                            {['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'].map(day => (
                                                <div key={day} className='tracking-wide text-gray-500 text-center'>{day}</div>
                                            ))}
                                        </div>
                                        <div className='grid grid-cols-7 gap-y-0.5 my-1'>
                                            {nextCalendar.map((week, i) => (
                                                week.map((dayObj, j) => (
                                                    <button 
                                                        key={`${i}-${j}`} 
                                                        onClick={() => chooseDay(dayObj.day, nextDate.getMonth(), nextDate.getFullYear())} 
                                                        className={`flex items-center justify-center w-12 h-12 lg:w-10 lg:h-10 focus:bg-violet-500 focus:text-white focus:font-medium focus:rounded-full ${dayObj.currentMonth ? '' : 'text-gray-400'}`}>
                                                        {dayObj.day}
                                                    </button>
                                                ))
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
}