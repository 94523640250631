import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { List } from '@phosphor-icons/react'
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function DropDown1(props) {

  return (
    <Menu as="div" className="relative inline-block text-left pr-2">
      <div>
        <Menu.Button className={classNames(props.active ? "bg-slate-900/10 text-slate-700 dark:bg-slate-100/20 dark:text-slate-100" : "text-slate-700 dark:hover:bg-slate-100/10 dark:text-slate-400 hover:bg-slate-900/10 hover:text-slate-700", "inline-flex w-full justify-center gap-x-1.5 rounded-full px-3 py-2 text-sm font-medium")}>
          {props.name}
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-slate-700 dark:text-slate-100" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={["absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:backdrop-blur-sm dark:bg-slate-700/30 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none", props.direction == 'left' ? "left-0" : props.direction == 'right' ? "right-0" : props.direction == 'center' ? "-inset-x-1/2" : "right-0"].join(" ")}>
          <div className="py-1">
            {
              props.list.map((item) => {

                if(item.link === null){
                  return <div className='border-t border-slate-200 my-1'></div>
                }

                return <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={item.link}
                      className={classNames(
                        active ? 'bg-slate-100 text-slate-900 dark:bg-slate-100/30 dark:text-slate-100' : 'text-slate-700 dark:text-slate-100',
                        ['block px-4 py-2 text-sm flex leading-5 items-center m-2 rounded-md', item.class].join(" ")
                      )}
                    >
                      {item.icon}
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              }
              )
            }
            
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
