// 'use client'

// import { useState, useEffect } from 'react'
// import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
// import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

// export default function Select(props) {

//   if(props.list === undefined){
//     throw new Error("Imposta una list [...] al componente Select");
//   }
//   if(props.onChange === undefined){
//     throw new Error("Imposta uno l'atttributo onChange per il callback");
//   }

//   const placeholder = props.placeholder == undefined || props.placeholder == "" ? "Seleziona Elemento" : props.placeholder;

//   const [selected, setSelected] = useState(props.list.length > 0 ? props.list[0] : {name: placeholder})
//   const [loading, setLoading] = useState(false)
  // useEffect(() => {
  //   setSelected(props.defaultValue === undefined || props.defaultValue == null || props.defaultValue == "" ? {name: placeholder} : props.defaultValue);
  //   setTimeout(() => {
  //     setLoading(true);
  //   }, 100);
    
  // }, [props.defaultValue])

//   const handleSelect = (e) => { 
//     // e.image = typeof e.image == "string" ? e.image : "";
//     if (selected.id !== e.id) {
      
//       setSelected(e);
//       props.onChange(e);
//     }
//   }
  
//   return (
//     <Listbox value={selected} onChange={handleSelect} disabled={props.disabled == undefined ? false : props.disabled} className="bg-transparent ">
//       {/* <Label className="block text-sm/6 font-medium text-slate-900">Assigned to</Label> */}
//         <div className="relative mt-2">
          // <ListboxButton className="disabled:bg-slate-100 relative w-full cursor-default rounded-md bg-white dark:bg-slate-900 dark:text-slate-100 py-1.5 pl-3 pr-10 text-left text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 focus:outline-none focus:ring-2 dark:ring-slate-600 focus:ring-indigo-500 sm:text-sm/6">
          //       <span className="flex items-center">
                    
          //           <span className="ml-3 block truncate">{selected.name}</span>

                  // <div className={["animate-pulse flex space-x-4", loading ? "" : ""].join(" ")}>
                  //   <div className="flex-1 space-y-6 py-1">
                  //     <div className="space-y-3 my-1">
                  //       <div className="h-2 bg-slate-200 rounded"></div>
                  //     </div>
                  //   </div>
                  // </div>
          //     </span>
          //   <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
          //     <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-slate-400" />
          //   </span> 
          // </ListboxButton>

//           <ListboxOptions
//             transition
//             className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white dark:bg-slate-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
//           >
//             {props.list.map((person) => (
//               <ListboxOption
//                 key={person.id}
//                 value={person}
//                 className="group relative select-none py-2 pl-3 pr-9 text-slate-900 cursor-pointer dark:text-slate-100 data-[focus]:bg-indigo-600 data-[focus]:text-white"
//               >
//                 <div className="flex items-center">
//                   {typeof person.image == "string" ? person.image ? <img alt="" src={person.image} className="h-5 w-5 flex-shrink-0 rounded-full" /> : <></>: person.image}
//                   <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
//                     {person.name}
//                   </span>
//                 </div>

//                 <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
//                   <CheckIcon aria-hidden="true" className="h-5 w-5" />
//                 </span>
//               </ListboxOption>
//             ))}
//           </ListboxOptions>
//       </div>
//     </Listbox>
//   )
// }


'use client'

import { useState, useMemo, useCallback, useEffect } from 'react';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

export default function Select(props) {
  const placeholder = props.placeholder == undefined || props.placeholder == "" ? "Seleziona Elemento" : props.placeholder;
  const [selected, setSelected] = useState({ name: placeholder });
  const [loading, setLoading] = useState(false)
  const memoizedList = useMemo(() => props.list || [], [props.list]);

  useEffect(() => { 
    if (props.defaultValue != null && props.defaultValue !== "" && memoizedList.length > 0) { 
      const defaultItem = memoizedList.find(item => item.id === props.defaultValue); 
      if (defaultItem) { 
       
        setSelected(defaultItem); 
      } 
      setLoading(true); 
    } 
  }, [props.defaultValue, memoizedList]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(true);
  //     if(props.defaultValue != null && props.defaultValue != "" && memoizedList != undefined){
  //       console.log(memoizedList,props.defaultValue);
  //         setSelected(memoizedList.filter(item => item.id == props.defaultValue)[0]);
  //     }
  //   }, 100);
  // }, [props.defaultValue])
  const handleSelect = useCallback((e) => {
    if (selected.id !== e.id) {
      setSelected(e);
      props.onChange?.(e); // Usa onChange solo se definito
    }
  }, [selected, props]);

  
  return (
    <Listbox value={selected} onChange={handleSelect} id={props.id != null || props.id != undefined ? props.id : null} disabled={!!props.disabled} className="bg-transparent">
      <div className="relative mt-2">
        <ListboxButton className="disabled:bg-slate-100 relative w-full cursor-default rounded-md bg-white dark:bg-slate-900 dark:text-slate-100 py-1.5 pl-3 pr-10 text-left text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 focus:outline-none focus:ring-2 dark:ring-slate-600 focus:ring-indigo-500 sm:text-sm/6">
          <span className="flex items-center">
            <span className="ml-3 block truncate">{selected?.name}</span>
            <div className={["animate-pulse flex space-x-4", loading ? "" : ""].join(" ")}>
              <div className="flex-1 space-y-6 py-1">
                <div className="space-y-3 my-1">
                  <div className="h-2 bg-slate-200 rounded"></div>
                </div>
              </div>
            </div>
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
            <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-slate-400" />
          </span>
        </ListboxButton>

        <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white dark:bg-slate-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm">
          {memoizedList.map((person) => (
            <ListboxOption
              key={person.id}
              value={person}
              className="group relative select-none py-2 pl-3 pr-9 text-slate-900 cursor-pointer dark:text-slate-100 data-[focus]:bg-indigo-600 data-[focus]:text-white"
            >
              <div className="flex items-center">
                {typeof person.image === "string" && person.image ? (
                  <img alt="" src={person.image} className="h-5 w-5 flex-shrink-0 rounded-full" />
                ) : person.image}
                <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">{person.name}</span>
              </div>
              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                <CheckIcon aria-hidden="true" className="h-5 w-5" />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}
