import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group'; // Importa i componenti necessari
import './style.css';

const isSubRoute = (pathname) => { 
  return pathname.startsWith('/Teams/') && pathname !== '/Teams' || 
         pathname.startsWith('/Profile/') && pathname !== '/Profile' ||
         pathname.startsWith('/Settings/') && pathname !== '/Settings'; 
};

function RouteTransition({ children }) {
  const location = useLocation();
  const subRoute = isSubRoute(location.pathname);
//   useEffect(() => {
//     // Avvia la transizione all'inizio del cambio di rotta
//     // document.startViewTransition(() => {});
//     // if (document.startViewTransition) {
//     //     document.startViewTransition(() => {
//     //         // Carica o sostituisci il contenuto
//     //     });
//     // } else {
//     //     // Fallback: applichiamo una classe CSS per animare l'entrata/uscita
//     //     document.body.classList.add('transition-active');
//     //     setTimeout(() => document.body.classList.remove('transition-active'), 300);
//     // }
    
//   }, [location]);


  return <TransitionGroup>
            <CSSTransition 
              key={location.key} 
              style={{position: 'absolute', width: '100%'}} 
              classNames="fade" 
              timeout={200}
              appear={!subRoute} 
              enter={!subRoute} 
              exit={!subRoute}
            >
              {children}
            </CSSTransition>
        </TransitionGroup>
}

export default RouteTransition;
