import React from 'react'
import { Link } from 'react-router-dom';
import BreadCrumb from '../../../components/breadcrumb';
import Desk from './components/Desk';
import { CaretRight } from '@phosphor-icons/react';

const desks = [
    {
        name: "Tavolo 1",
        active: true,
        status: 'libero',
        clients: 0, 
        orders: 0,
        int: 0,
        all: 0,
        link: '',
        tot: 0
    },{
        name: "Tavolo 2",
        active: true,
        status: 'prenot',
        clients: 4, 
        orders: 0,
        int: 0,
        all: 0,
        link: '',
        tot: 0
    },{
        name: "Tavolo 3",
        active: true,
        status: 'occupied',
        clients: 4, 
        orders: 1,
        int: 1,
        all: 0,
        link: '',
        tot: 100
    }
    ,{
        name: "Tavolo 4",
        active: true,
        status: 'end',
        clients: 4, 
        orders: 1,
        int: 1,
        all: 0,
        link: '',
        tot: 100
    }

    ,{
        name: "Tavolo 5",
        active: true,
        status: 'end',
        clients: 4, 
        orders: 1,
        int: 1,
        all: 0,
        link: '',
        tot: 100
    }
    ,{
        name: "Tavolo 6",
        active: true,
        status: 'end',
        clients: 4, 
        orders: 1,
        int: 1,
        all: 0,
        link: '',
        tot: 100
    }
    ,{
        name: "Tavolo 7",
        active: true,
        status: 'end',
        clients: 4, 
        orders: 1,
        int: 1,
        all: 0,
        link: '',
        tot: 100
    }
    
]

const concat = [{
    tables: [0,1,2],
    bg: {background: 'rgb(254 252 232)'}
},{
    tables: [3,5],
    bg: {background: 'rgb(250 245 255)'}
}]

export default function Room(props) {

    return <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-3'>
        <BreadCrumb breadcrumbs={[{id: 0, name: "Home", href: "#"}]} name="Sala 1" href="#" />
        
        <div className="my-4">
            <div className='flex justify-center'>
                <h1 className='text-2xl font-semibold text-center grow'>Sala 1</h1>
                <Link to="" className='inline-flex font-semibold text-md items-center text-gray-700 border border-gray-500 pl-4 px-2 py-1 rounded-full'>Sala 2 <CaretRight className='mt-0.5 ml-2' /></Link>
            </div>

            <div className="grid grid-cols-12 gap-4">
                <div className="mt-10 grid grid-cols-4 gap-2 col-span-9">
                    {
                        desks.map((item, i) => {
                            let c;
                            concat.forEach(element => {
                                if(element.tables.includes(i)){
                                    c = element.bg;
                                }
                            });

                            
                            return <Desk concat={c} key={i} name={item.name} active={item.active} status={item.status} clients={item.clients} orders={item.orders} int={item.int} all={item.all} link={item.link} tot={item.tot} />
                        })
                    }
                </div>
                <div className='col-span-3'>
                    <div className='bg-white shadow-md mt-10 h-full rounded-lg'>
                        <ul className=''>
                            <li>
                                <p className='block p-3 text-center font-semibold'>Informazioni Sala</p>
                            </li>
                            <li>
                                <p className='block font-normal p-3 border-b border-gray-100'>Numero massimo tavoli: <span className='font-semibold'>10</span></p>
                            </li>
                            <li>
                                <p className='block font-normal p-3 border-b border-gray-100'>Numero massimo ospiti: <span className='font-semibold'>40</span></p>
                            </li>
                            <li>
                                <p className='block font-normal p-3 border-b border-gray-100'>Clienti in sala: <span className='font-semibold'>10</span></p>
                            </li>
                            <li>
                                <p className='block font-normal p-3 border-b border-gray-100'>Tavoli liberi: <span className='font-semibold'>5</span></p>
                            </li>
                        </ul>
                        <ul className='mt-4'>
                            <li>
                                <p className='block p-3 text-center font-semibold'>Statistiche consumi</p>
                            </li>
                            <li>
                                <p className='block font-normal p-3 border-b border-gray-100'>Pasti totali: <span className='font-semibold'>100</span></p>
                            </li>
                            <li>
                                <p className='block font-normal p-3 border-b border-gray-100'>Guadagno lordo: <span className='font-semibold'>1300 €</span></p>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}