import {client} from '../../config.apollo';
// import { useNavigate } from 'react-router-dom';
import {
    updateClientInfo as TypeDefsUpdateClientInfo,
    addClientCalendar,
    removeClientCalendar,
    addGalleryImage,
    removeGalleryImage,
    clientInfoProtectedFull
    } from '../Query';
    
import {X} from '@phosphor-icons/react'
import {LogOut} from '../../components/RequireAuth';
import toast, { Toaster } from 'react-hot-toast';
import { clearUserInfo, setUserInfo, updateCalendar } from '../../redux/reducers/clientReducer';
import { empty } from '@apollo/client';

export function UpdateClientInfo(dispatch, variables, user = {}, skip = null){
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        if(Object.keys(variables).length === 0){
            myResolve(variables);
            return;
        }

        try {
            const { loading, error, data } = await client.mutate({ mutation: TypeDefsUpdateClientInfo, 
                variables: {
                    storeClientInfoInput: variables
                }
            });
            // Ottieni lo stato attuale dell'utente
            if(!user){
                myResolve(data.clientInfoUpdate); // when successful
                return;
            } 
            const currentUserInfo = user; 
            // Combina le nuove informazioni con quelle esistenti 
            const updatedUserInfo = { ...currentUserInfo, ...variables }; 
            // Aggiorna lo stato dell'utente con le nuove informazioni 
            dispatch(setUserInfo(updatedUserInfo));

            myResolve(data.clientInfoUpdate); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};
export function AddClientCalendar(dispatch, variables, skip = null){
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        if(Object.keys(variables).length === 0){
            myResolve(variables);
            return;
        }

        try {
            const { loading, error, data } = await client.mutate({ mutation: addClientCalendar, 
                variables: {
                    storeClientCalendarInput: variables
                },
                update: (cache, { data: { clientCalendarStore } }) => {
                    const existingData = cache.readQuery({ query: clientInfoProtectedFull });
                    const newCalendar = existingData?.clientInfoProtected?.calendar ? [...existingData.clientInfoProtected.calendar, clientCalendarStore] : [clientCalendarStore];
                    cache.writeQuery({
                        query: clientInfoProtectedFull,
                        data: { clientInfoProtected: { ...existingData.clientInfoProtected, calendar: newCalendar } },
                    });
                },
            });
            myResolve(data.clientCalendarStore); // when successful
            
            // Aggiorna lo stato Redux
            // console.log(data.clientCalendarStore);
            // dispatch(updateCalendar(data.clientCalendarStore));
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};
export function RemoveClientCalendar(dispatch, variables, skip = null){
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        if(Object.keys(variables).length === 0){
            myResolve(variables);
            return;
        }


        try {
            const { loading, error, data } = await client.mutate({ mutation: removeClientCalendar, 
                variables: {
                    storeClientCalendarInput: {
                        giorno: variables.day
                    }
                }
            });
            myResolve(data.clientCalendarDelete); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export function AddGalleryImage(dispatch, variables, skip = null){
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        if(Object.keys(variables).length === 0){
            myResolve(variables);
            return;
        }

        try {
            const { loading, error, data } = await client.mutate({ mutation: addGalleryImage, 
                variables: {
                    galleryPush: variables
                }
            });
            myResolve(data.clientGalleryStore); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export function RemoveGalleryImage(dispatch, variables, skip = null){
    
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        if(Object.keys(variables).length === 0){
            myResolve(variables);
            return;
        }

        try {
            const { loading, error, data } = await client.mutate({ mutation: removeGalleryImage, 
                variables: {
                    galleryDel: {
                        _id: variables
                    }
                }
            });
            myResolve(data.clientGalleryDelete); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};