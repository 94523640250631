import React, {useState, useEffect} from 'react'
import Modal from '../../../../components/Modal';
import {Timer, X, Check} from '@phosphor-icons/react'
import {useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loading from '../../../../assets/svgs/loading.jsx';
import TimePicker from "react-time-picker";
import {AddClientCalendar, RemoveClientCalendar} from '../../../../GraphQl/Conections/Mutation.ClientInfo.js';
import toast, { Toaster } from 'react-hot-toast';

// Converti i timestamp in date leggibili
// Converti i timestamp in date leggibili 
const convertTimestampToDate = (timestamp) => { 
    if(timestamp == null) return timestamp;
    if (typeof timestamp === 'string' || typeof timestamp === 'number') { 
        // return new Date(parseInt(timestamp)).toISOString(); 
        return new Date(parseInt(timestamp)).toISOString();
    } 
    
    return timestamp.toISOString();
};
  
// Confronta i due oggetti 
const compareObjects = (obj1, obj2) => { 
    if(obj1 === undefined || obj2 === undefined) return false;

    let obj1Chiuso = false;
    let obj2Chiuso = false;
    if(obj1.chiuso != null){
        obj1Chiuso = obj1.chiuso;
    }
    if(obj2.chiuso != null){
        obj2Chiuso = obj2.chiuso;
    }
    return ( 
        obj1.giorno === obj2.giorno && 
        obj1Chiuso === obj2Chiuso && 
        convertTimestampToDate(obj1.ora_apert) === convertTimestampToDate(obj2.ora_apert) && 
        convertTimestampToDate(obj1.ora_chius) === convertTimestampToDate(obj2.ora_chius) && 
        convertTimestampToDate(obj1.ora_apert_sec_turno) === convertTimestampToDate(obj2.ora_apert_sec_turno) && 
        convertTimestampToDate(obj1.ora_chius_sec_turno) === convertTimestampToDate(obj2.ora_chius_sec_turno) 
    ); 
};

// Verifica se l'oggetto esiste già nell'array
const checkChanges = (vars, user, i) => { 
    // user.some(element => {
    //     console.log(compareObjects(vars, element), element, vars);
    // });
    return user.some(e => e.giorno == vars.giorno ? compareObjects(e, vars) : false);
};
const convertString = (str) => { 
    // Converti tutti i caratteri in minuscolo 
    let lowerCaseStr = str.toLowerCase(); // Normalizza la stringa e rimuovi i segni diacritici 
    let normalizedStr = lowerCaseStr.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); 
    return normalizedStr; 
};

const isDate = (value) => {
    return value instanceof Date && !isNaN(value);
}

export function Calendar(props){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const [scrollPosition, setScrollPosition] = useState(false);
    const [selectDay, setSelectDay] = useState(null);
    const [firstTimeOpen, setFirstTimeOpen] = useState(null);
    const [firstTimeClose, setFirstTimeClose] = useState(null);
    const [secondTimeOpen, setSecondTimeOpen] = useState(null);
    const [secondTimeClose, setSecondTimeClose] = useState(null);
    const [closedDay, setClosedDay] = useState(null);
    const [turnWeek, setTurnWeek] = useState([]);
    const [turnWeekRemoved, setTurnWeekRemoved] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [editSave, setEditSave] = useState(false); 
    const openModal = () => setIsModalOpen(true); 
    const closeModal = () => setIsModalOpen(false);

    const convertToTimeZone = (timestamp) => {
        const date = new Date(parseInt(timestamp));
        
        // Verifica se la data è valida
        if (!isNaN(date.getTime())) {
          // Ottieni la data e l'ora nel tuo fuso orario
          return date;
        } else {
          return null;
        }
        
    };

    const convertTimeToDate = (timeString) => {
        if(timeString === null) return null;
        const [hours, minutes] = timeString.split(':');
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
      };
      
    useEffect(() => { 
        if(props.user.clienti_calends != undefined || props.user.clienti_calends != null){
            let newTurns = [];
            props.user.clienti_calends.map(element => {
            const newTurn = { 
                    times: [ 
                    { timeOpen: convertToTimeZone(element.ora_apert), timeClose: convertToTimeZone(element.ora_chius) }, 
                    { timeOpen: convertToTimeZone(element.ora_apert_sec_turno), timeClose: convertToTimeZone(element.ora_chius_sec_turno) } 
                    ], 
                    day: element.giorno,
                    closed: element.chiuso
                }; 
                newTurns.push(newTurn);
            });
            setTurnWeek([...turnWeek, ...newTurns]);

        }
       
    }, [props.user]);

    const saveModal = () => {
        // Controlla se il giorno esiste già nell'array turnWeek 

        const newTurn = { 
            times: [ 
                { timeOpen: convertTimeToDate(firstTimeOpen), timeClose: convertTimeToDate(firstTimeClose), }, 
                { timeOpen: convertTimeToDate(secondTimeOpen), timeClose: convertTimeToDate(secondTimeClose), } 
            ], 
            chiuso: false,
            day: selectDay
        }; 
        // setTurnWeek([...turnWeek, newTurn]);

        // Trova l'indice del giorno esistente 
        const dayIndex = turnWeek.findIndex(turn => turn.day === selectDay); 
        if (dayIndex !== -1) { 
            // Aggiorna solo i turni che sono cambiati 
            const updatedTimes = turnWeek[dayIndex].times.map((time, index) => { 
                if (index === 0 && (time.timeOpen !== firstTimeOpen || time.timeClose !== firstTimeClose)) { 
                    return { timeOpen: firstTimeOpen, timeClose: firstTimeClose }; 
                } else if (index === 1 && (time.timeOpen !== secondTimeOpen || time.timeClose !== secondTimeClose)) { 
                    return { timeOpen: secondTimeOpen, timeClose: secondTimeClose }; 
                } return time; 
            }); 
            
            const updatedTurnWeek = [...turnWeek]; 
            updatedTurnWeek[dayIndex] = { 
                ...updatedTurnWeek[dayIndex], times: updatedTimes 
            }; 
            setTurnWeek(updatedTurnWeek); 
        } else { 
            // Aggiungi un nuovo turno 
            setTurnWeek([...turnWeek, newTurn]); 
        }

        setFirstTimeOpen(null);
        setFirstTimeClose(null);
        setSecondTimeOpen(null);
        setSecondTimeClose(null);

        setIsModalOpen(false);
        setClosedDay(false);
        setEditSave(true);
    }
    const handleScroll = () => { 
        const position = window.pageYOffset;
        const Tpos = document.querySelector('.t-pos');
        const rect = Tpos.getBoundingClientRect();
        
        setScrollPosition(rect.top < 0); 
        
    }; 
    
    useEffect(() => { 
        document.body.addEventListener('scroll', handleScroll); 
        return () => { 
            document.body.removeEventListener('scroll', handleScroll); 
        }; 
    }, []);

    const handleDoubleClick = (e, day) => {
        console.log("click", e, day, turnWeek.filter(e => e.day == day));

        if(turnWeek.filter(e => e.day == day && checkTimes(e.times) ).length == 0){
            if(turnWeek.filter(e => e.day == day && e.closed ).length == 0)
                setIsModalOpen(true)
        }
    }

    const checkTimes = (times)  => {
        // Controlla se entrambi gli oggetti times[0] e times[1] esistono e non hanno valori nulli
        return times[0] && times[0].timeOpen && times[0].timeClose &&
               times[1] && times[1].timeOpen && times[1].timeClose;
    }
    
    
    const handleClick = (e, day) => {
        
        setSelectDay(day);
    }

    const save = () =>{
        if(turnWeekRemoved.length > 0){
            turnWeekRemoved.forEach(day => {

                
                RemoveClientCalendar(dispatch, day).then(data => { 
                    setTimeout(() => {
        
                        toast.success("Giorno rimosso dal calendario", {
                            duration: 4000,
                            position: 'top-center',
                            // Styling
                            style: {},
                            className: 'dark:bg-slate-800 dark:text-slate-100',
                            // Custom Icon
                            icon: <Check className='text-green-500 w-10' size={20} />,
                        });
        
                        setLoading(false);
                        // navigate("/Menu/Articles");
                    }, 1000);
                });
            });
        }

        turnWeek.forEach((day, i) => {

            const vars = {
                giorno: day.day,
                chiuso: day.times[0].timeOpen == null && day.times[0].timeClose == null && day.times[1].timeOpen == null && day.times[1].timeClose == null ? true : false,
                ora_apert: day.times[0].timeOpen != null ? day.times[0].timeOpen : null,
                ora_chius: day.times[0].timeClose != null ? day.times[0].timeClose : null,
                ora_apert_sec_turno: day.times[1].timeOpen != null ? day.times[1].timeOpen : null,
                ora_chius_sec_turno: day.times[1].timeClose != null ? day.times[1].timeClose : null
            };
            const changes = checkChanges(vars, props.user.clienti_calends, i); 
// console.log(vars);
            if(changes == true){
                return;
            }

            AddClientCalendar(dispatch, vars).then(data => { 
                setTimeout(() => {
    
                    toast.success("Giorno aggiunto al calendario", {
                        duration: 4000,
                        position: 'top-center',
                        // Styling
                        style: {},
                        className: 'dark:bg-slate-800 dark:text-slate-100',
                        // Custom Icon
                        icon: <Check className='text-green-500 w-10' size={20} />,
                    });
    
                    setLoading(false);
                    // navigate("/Menu/Articles");
                }, 1000);
            });
        });

        setEditSave(false);
    }

    const removeDate = (time) => {

        setTurnWeekRemoved([...turnWeekRemoved, time]);
        const filteredTurnWeek = turnWeek.filter(turn => turn.day !== time.day);
        setTurnWeek(filteredTurnWeek);
        setEditSave(true);
    }

    const removeEdits = () => {
        navigate("/Profile/Calendar");
        setEditSave(false)
    }

    const week = ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"]
   
    return <div>
        <div className='flex items-center justify-between p-4'>
            <div>
                <p className='text-sm font-medium uppercase dark:text-slate-100'>Calendario apertura settimanale</p>
                <p className="text-slate-400 text-xs dark:text-slate-300">Doppio click con il mouse sinistro nelle colonne per settare i giorni lavorativi.</p>
            </div>
            <div className='flex items-center gap-2 t-pos'>
                <button disabled={!editSave}  onClick={removeEdits} className='disabled:bg-slate-100 disabled:border-slate-100 rounded-md py-2 px-5 text-slate-800 bg-white border border-slate-300 w-24 transition-colors duration-300'>Cancella</button>
                <button
                    onClick={save}
                    disabled={loading || !editSave}
                    className="disabled:bg-violet-200 disabled:border-violet-200 rounded-md min-w-32 py-2 px-5 text-slate-100 bg-violet-500 border-violet-500 hover:bg-violet-500/50 hover:border-violet-500/50 relative transition-colors duration-300"
                >
                    <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                    <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Salva</span>
                </button>
            </div>
        </div>
        <div className='p-4'>
            <div className="overflow-x-auto pb-2">
                <div className=' text-green-700 text-xs flex items-center space-x-1'>
                    {
                        editSave ? 
                        <>
                            <Check /> <span>Calendario modificato, salva per confermare le modifiche oppure annulla le modifiche</span>
                        </>
                            :
                            <>&nbsp;</>
                    }
                </div>
                <div className='mx-auto space-y-12 py-0'>
                    <div className='flex flex-nowrap '>
                        {
                            week.map(item => {
                                return <div className='w-full group' >
                                    <div className='border-b border-slate-200 px-1 py-2 dark:text-slate-300'>{item}</div>
                                    <div  className={['relative flex flex-col gap-4 h-96 px-2 py-1 border-r border-slate-200 group-last:border-none  transition-all cursor-pointer hover:outline-slate-400 hover:outline-2 hover:outline-offset-4 hover:outline-dashed ', selectDay === item ? "outline-slate-400 outline-2 outline-offset-4 outline-dashed" : ""].join(" ")} onClick={(e) => handleClick(e, item)} onDoubleClick={(e) => handleDoubleClick(e, item)}>
                                        {turnWeek.map(turn => { 
                                            if(convertString(turn.day) == convertString(item)){ 
                                                if(turn.closed){
                                                    return <div className='w-full h-3/6 bg-red-200 rounded-md border border-red-300 p-2'>
                                                        <p className='text-xs font-medium text-red-500'>Chiuso</p>
                                                        <button onClick={() => removeDate(turn)} className='text-red-600 w-full border border-red-300 rounded-md hover:bg-red-300 mt-2'>Elimina</button>
                                                    </div>
                                                }else
                                                return turn.times.map(time => { 
                                                                    if(time.timeOpen != null && time.timeClose != null){
                                                                        return <div className='w-full h-3/6 bg-slate-200 rounded-md border border-slate-300 p-2 relative'>
                                                                                    <p className='text-xs font-medium text-slate-500'>{time.timeOpen.getHours()}:{time.timeOpen.getMinutes()} a {time.timeClose.getHours()}:{time.timeClose.getMinutes()}</p>
                                                                                    <button onClick={() => removeDate(turn)} className='text-slate-600 w-full border border-slate-300 rounded-md hover:bg-slate-300 mt-2'>Elimina</button>
                                                                                </div>
                                                                    }else if(time.timeOpen != null && time.timeClose == null || time.timeOpen == null && time.timeClose != null){
                                                                        return <div className='w-full h-3/6 bg-slate-200 rounded-md border border-slate-300 p-2 relative'>
                                                                                    <p className='text-xs font-medium text-slate-500'>{time.timeOpen != null ? isDate(time.timeOpen) ? time.timeOpen.getHours() : time.timeOpen : null}:{time.timeOpen != null ? isDate(time.timeOpen) ? time.timeOpen.getMinutes() : "" : null} a {time.timeClose != null ? isDate(time.timeClose) ? time.timeClose.getHours() : time.timeClose : null}:{time.timeClose != null ? isDate(time.timeClose) ? time.timeClose.getMinutes() : "" : null}</p>
                                                                                    <button onClick={() => removeDate(turn)} className='text-slate-600 w-full border border-slate-300 rounded-md hover:bg-slate-300 mt-2'>Elimina</button>
                                                                                </div>
                                                                    }
                                                    }
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className={[' fixed p-4 left-2/4 -translate-x-2/4 bg-white gap-4 flex border border-slate-200 rounded-lg transition-all shadow-md', scrollPosition ? "opacity-100 bottom-3" : "opacity-0 -bottom-20"].join(" ")}>
            <button disabled={!editSave} onClick={removeEdits} className='disabled:bg-slate-100 disabled:border-slate-100 rounded-md py-2 px-5 text-slate-800 bg-white border border-slate-300 w-24 transition-colors duration-300'>Cancella</button>
            <button
                onClick={save}
                disabled={loading || !editSave}
                className="disabled:bg-violet-200 disabled:border-violet-200 rounded-md min-w-32 py-2 px-5 text-slate-100 bg-violet-500 border-violet-500 hover:bg-violet-500/50 hover:border-violet-500/50 relative transition-colors duration-300"
            >
                <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Salva</span>
            </button>
        </div>

        <Modal isOpen={isModalOpen} onClose={closeModal} onSave={saveModal} title={'Imposta ora "' + selectDay + '"'}> 
            <div className="grid grid-cols-12 gap-4">
                <div className='col-span-6'>
                    <p className="text-md font-normal dark:text-slate-300">
                        Apertura primo turno
                    </p>
                    <div className='flex relative'>
                        <input
                            id="firstTimeOpen"
                            name="firstTimeOpen"
                            type="time"
                            // min="06:00"
                            // max="13:00"
                            disabled={turnWeek.filter(e => e.day == selectDay && e.times[0].timeOpen).length > 0 || closedDay}
                            // onChange={(e) => { const value = e.target.value; if (value >= '06:00' && value <= '13:00') { setFirstTimeOpen(value); } else { alert('L\'orario di apertura deve essere tra le 06:00 e le 13:00'); } }}
                            onChange={(e) => setFirstTimeOpen(e.target.value)}
                            className="peer pl-9 transition-all dark:bg-slate-900 disabled:bg-slate-300 disabled:dark:bg-slate-6 dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        
                        <label htmlFor="firstTimeOpen" className='peer-focus-within:text-indigo-600 text-slate-600'>
                            <Timer  className=' absolute top-2 left-2' size="20" weight='duotone' />
                        </label>
                        {/* <TimePicker
                            onChange={(e) => console.log(e)}
                            disableClock={true}
                            minTime="06:00"
                            maxTime="12:00"
                            className="w-full"
                        /> */}
                    </div>
                </div>

                <div className='col-span-6'>
                    <p className="text-md font-normal dark:text-slate-300">
                        Chiusura primo turno
                    </p>
                    <div className='flex relative'>
                        <input
                            id="firstTimeClose"
                            name="firstTimeClose"
                            type="time"
                            disabled={turnWeek.filter(e => e.day == selectDay && e.times[0].timeClose).length > 0 || closedDay}
                            onChange={(e) => setFirstTimeClose(e.target.value)}
                            // onChange={(e) => { const value = e.target.value; if (value >= '06:00' && value <= '13:00') { setFirstTimeClose(value); } else { alert('L\'orario di apertura deve essere tra le 06:00 e le 13:00'); } }}
                            className="peer pl-9 transition-all dark:bg-slate-900 disabled:bg-slate-300 disabled:dark:bg-slate-600 dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <label htmlFor="firstTimeClose" className='peer-focus-within:text-indigo-600 text-slate-600'>
                            <Timer  className=' absolute top-2 left-2' size="20" weight='duotone' />
                        </label>
                    </div>
                </div>

                <div className='col-span-6'>
                    <p className="text-md font-normal dark:text-slate-300">
                        Apertura secondo turno
                    </p>
                    <div className='flex relative'>
                        <input
                            id="secondTimeOpen"
                            name="secondTimeOpen"
                            type="time"
                            disabled={turnWeek.filter(e => e.day == selectDay && e.times[1].timeOpen).length > 0 || closedDay}
                            onChange={(e) => setSecondTimeOpen(e.target.value)}
                            className="peer pl-9 transition-all dark:bg-slate-900 disabled:bg-slate-300 disabled:dark:bg-slate-6 dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <label htmlFor="secondTimeOpen" className='peer-focus-within:text-indigo-600 text-slate-600'>
                            <Timer  className=' absolute top-2 left-2' size="20" weight='duotone' />
                        </label>
                    </div>
                </div>

                <div className='col-span-6'>
                    <p className="text-md font-normal dark:text-slate-300">
                        Chiusura secondo turno
                    </p>
                    <div className='flex relative'>
                        <input
                            id="secondTimeClose"
                            name="secondTimeClose"
                            type="time"
                            disabled={turnWeek.filter(e => e.day == selectDay && e.times[1].timeClose).length > 0 || closedDay}
                            onChange={(e) => setSecondTimeClose(e.target.value)}
                            className="peer pl-9 transition-all dark:bg-slate-900 disabled:bg-slate-300 disabled:dark:bg-slate-6 dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <label htmlFor="secondTimeClose" className='peer-focus-within:text-indigo-600 text-slate-600'>
                            <Timer  className=' absolute top-2 left-2' size="20" weight='duotone' />
                        </label>
                    </div>
                </div>

                <div className='col-span-12'>
                    <div className="flex gap-x-2">
                        <button onClick={() => setClosedDay(!closedDay)} className='rounded-md py-2 px-5 text-slate-800 bg-red-300 border border-red-300 hover:bg-red-50 w-full'>{!closedDay ? "Chiudi" : "Apri"}</button>                
                    </div>
                </div>
            </div>
        </Modal>
    </div>
}