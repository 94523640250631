import {client} from '../../config.apollo';
// import { useNavigate } from 'react-router-dom';
import { Menus, Menu, Cat_menuGestional as QueryCatMenuGestional, cat_menuFromId as QueryCatMenuFromId, articlesGestional as QueryArticlesGestional, articleFromId as QueryArticleFromId } from '../Query';
import {X} from '@phosphor-icons/react'
import {LogOut} from '../../components/RequireAuth';
import toast, { Toaster } from 'react-hot-toast';
import { clearUserInfo } from '../../redux/reducers/clientReducer';

const fetchData = (dispatch, page, pageSize, filterSearch, skip = null) => {
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        try {
            const { loading, error, data } = await client.query({ 
                query: Menus,  
                variables:{
                    menuPagination: {
                        page: page,
                        pageSize: pageSize,
                    },
                    filterName: filterSearch
                },
                fetchPolicy: 'no-cache' // Escludi la cache
            }, {});
            myResolve(data.menusGestional); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export default fetchData;

export function getMenufromId(dispatch, id,skip = null){
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        try {
            const { loading, error, data } = await client.query({ 
                query: Menu,  
                variables:{
                    menuId: id
                }
            }, {});
            myResolve(data.menuGestional); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export function Cat_menuGestional(dispatch, page, pageSize, filterSearch = null, skip = null){
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }
        const filterCatMenu = {};
        if(filterSearch != null){
            const {name, order, ref_menu} = filterSearch;
    
            if(name){
                filterCatMenu.nome = name;
            }
    
            if(order){
                filterCatMenu.order = order;
            }
    
            if(ref_menu){
                filterCatMenu.ref_menu = ref_menu;
            }
    
    
        }
        

        try {
            const { loading, error, data } = await client.query({ 
                query: QueryCatMenuGestional,  
                variables:{
                    categoryMenuPagination: {
                        page: page,
                        pageSize: pageSize,
                    },
                    filterCatMenu: filterCatMenu
                },
                fetchPolicy: 'no-cache' // Escludi la cache
            }, {});
            myResolve(data.cat_menuGestional); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export function Cat_menuFromId(dispatch, id,skip = null){
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        try {
            const { loading, error, data } = await client.query({ 
                query: QueryCatMenuFromId,  
                variables:{
                    catMenuId: id,
                    menuPagination: {
                        page: 0,
                        pageSize: 99
                    }
                }
            }, {});
            myResolve(data); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export function ArticleFromId(dispatch, id,skip = null){
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        try {
            const { loading, error, data } = await client.query({ 
                query: QueryArticleFromId,  
                variables:{
                    articleId: id
                },
                fetchPolicy: 'no-cache' // Escludi la cache
            }, {});
            myResolve(data.articleGestional); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export function ArticlesGestional(dispatch, page, pageSize, filterSearch = null, filterMenu = null, filterCat = null, skip = null){
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        try {
            const { loading, error, data } = await client.query({ 
                query: QueryArticlesGestional,  
                variables:{
                    articlePagination: {
                        page: page,
                        pageSize: pageSize,
                    },
                    filterName: filterSearch,
                    filterMenu: filterMenu,
                    filterCat: filterCat
                },
                fetchPolicy: 'no-cache' // Escludi la cache
            }, {});
            
            myResolve(data.articlesGestional); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export function ArticleGestional(dispatch, id,skip = null){
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        try {
            const { loading, error, data } = await client.query({ 
                query: QueryCatMenuFromId,  
                variables:{
                    catMenuId: id,
                    menuPagination: {
                        page: 0,
                        pageSize: 99
                    }
                }
            }, {});
            myResolve(data); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

