import { gql } from '@apollo/client';

export const ClientStat = gql`query Query {
    clientStat {
      client
      views
      viewsRealTime
      reservations
      orders
    }
  }`;