import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import 'moment/locale/it';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {CalendarDot , DotsSixVertical } from '@phosphor-icons/react';

import "./App.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

class App extends Component {
    state = {
        events: [
            // {
            //     id: 1,
            //     start: moment().toDate(),
            //     end: moment().add(1, "hour").toDate(),
            //     title: "Esempio evento",
            // },
        ],
        selectedEvent: null,
    };
    
    componentDidMount() {
        moment.locale('it');
        window.addEventListener('keydown', this.handleKeyDownDeleteEvent);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDownDeleteEvent);
    }

    componentDidUpdate(prevProps) {


        if(this.props.eventChange != null){
            // this.setState({events: this.props.eventChange})
            if (prevProps.eventChange !== this.props.eventChange) {
                this.setState(prevState => {
                    const updatedEvents = prevState.events.map(event => {
                        if (event.id === this.props.eventChange.id) {
                            return this.props.eventChange;
                        }
                        return event;
                    });
                    return { events: updatedEvents };
                });
            }
        }

        
        if (this.props.eventRemove && prevProps.eventRemove !== this.props.eventRemove) {
            this.setState(prevState => ({
                events: prevState.events.filter(event => event.id !== this.props.eventRemove.id),
                selectedEvent: null,
            }));
        }
        

    }

//   onEventResize = (data) => {
//     const { start, end } = data;

//     this.setState((state) => {
//       state.events[0].start = start;
//       state.events[0].end = end;
//       return { events: [...state.events] };
//     });
//   };

    onEventResize = ({ event, start, end }) => {
        const updatedEvent = { ...event, start, end };
        this.setState((prevState) => ({
        events: prevState.events.map((existingEvent) =>
            existingEvent.id === event.id ? updatedEvent : existingEvent
        ),
        }));
    };
  

    onEventDrop = ({ event, start, end, isAllDay }) => {
        const { events } = this.state;
    
        const updatedEvent = { ...event, start, end, isAllDay };
    
        const nextEvents = events.map(existingEvent =>
            existingEvent.id === event.id ? updatedEvent : existingEvent
        );

        this.setState({
            events: nextEvents,
        });
        // this.props.eventSelected(event);
    };

    addEvent = (slotInfo) => {
        const { start } = slotInfo;
        const end = moment(start).add(1, "hour").toDate();
        const newEvent = {
            id: this.state.events.length + 1,
            start,
            end,
            title: "Nuovo Evento",
        };
        this.setState((prevState) => ({
            events: [...prevState.events, newEvent],
        }));
    };

    handleSelectSlot = (slotInfo) => {
        if (this.selectTimeout) {
            clearTimeout(this.selectTimeout);
            this.selectTimeout = null;
            this.addEvent(slotInfo);
        } else {
            this.selectTimeout = setTimeout(() => {
                this.selectTimeout = null;
                // Azione per il clic singolo (se necessario)
            }, 250); // Intervallo di tempo per distinguere tra clic singolo e doppio clic
        }
    };


    handleKeyDownDeleteEvent = (e) => {
        if (e.key === 'Delete' && this.state.selectedEvent) {
            this.setState((prevState) => ({
                events: prevState.events.filter((event) => event.id !== prevState.selectedEvent.id),
                selectedEvent: null,
            }));
        }
    };
    handleSelectEvent = (event) => {
        this.setState({ selectedEvent: event });
        this.props.eventSelected(event);
    };

    render() {

        const { minH = 0, maxH = 23,eventSelected, eventUnselected } = this.props;

        
        return (
        <div className="App">
            <DnDCalendar
                defaultDate={moment().toDate()}
                defaultView="week"
                events={this.state.events}
                localizer={localizer}
                onEventDrop={this.onEventDrop}
                onEventResize={this.onEventResize}
                onSelectSlot={this.handleSelectSlot}
                onSelectEvent={this.handleSelectEvent}
                onDrillDown={() => {}} // Disabilita la navigazione alla vista "Day"
                resizable
                selectable
                step={30}
                style={{ height: "100vh" }}
                showMultiDayTimes={true}
                toolbar={false}
                scrollToTime={moment().toDate()} // Scorri automaticamente fino all'orario attuale
                formats={{
                    dayFormat: 'ddd',  // Formato del giorno (es. Lunedì)
                    timeGutterFormat: 'H[h]', // Elimina i decimali (ad esempio, 12:00)
                    eventTimeRangeFormat: 'HH:mm', // Elimina i decimali sugli eventi
                }}
                components={{
                    event: ({ event }) => {
                        return (
                            <div className="py-2">
                                <div className="flex">
                                    <div>
                                        <DotsSixVertical className="text-slate-800" size="17" />
                                    </div> 
                                    <div>
                                        <div className="text-slate-800 dark:text-slate-200 line-clamp-2 text-xs">
                                            {event.title}
                                        </div>
                                        <div className="text-xs text-slate-400">{moment(event.start).format('HH:mm')} a {moment(event.end).format('HH:mm')}</div>
                                    </div>
                                </div>
                            </div>
                        );
                    },
                    week: {
                        header: ({ date, localizer }) => {
                            const Today = moment().format('ddd');
                            const header = localizer.format(date, 'ddd'); // Formatta solo il nome del giorno (es. "Mon")
                            
                            return <div className={["text-slate-900 dark:text-slate-400 pointer-events-none flex h-[54px] items-center gap-2 p-3 text-sm ", header == Today ? "opacity-100" : "opacity-70"].join(" ")}>
                                    <div className={[header == Today ? "w-24 py-1 bg-slate-200 dark:bg-slate-700 rounded-full flex justify-center items-center font-medium" : ""].join(" ")}>
                                        {header.charAt(0).toUpperCase() + header.slice(1)}
                                        {
                                            header == Today ? 
                                                <CalendarDot className="w-4 h-4 ml-2 text-primary" weight="duotone" />
                                            : null
                                        }
                                    </div>
                                </div>;
                        },
                    },
                }}
                  min={moment().hours(minH).minutes(0).seconds(0).toDate()} // Imposta l'orario minimo (08:00)
                  max={moment().hours(maxH).minutes(0).seconds(0).toDate()} // Imposta l'orario massimo (18:00)
            />
        </div>
        );
    }
}

export default App;
