import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Layout from './Layout';
import { useDispatch } from 'react-redux';
import { clearUserInfo } from '../redux/reducers/clientReducer';
import toast, { Toaster } from 'react-hot-toast';
import {ClientToken} from '../GraphQl/Conections/Query.ClientInfoProtected';
import Cookies from 'js-cookie';
import LoadingComponent from './Loading';

const isAuthenticated = async (dispatch) => {
    // Verifica se il token di autenticazione esiste nel localStorage
    const token = localStorage.getItem("client");
    let auth = false;

    if (token == null) return false;

    // Se il controllore non esiste allora invia un ping al server per i dati cliente
    const t = await ClientToken(dispatch).then(data => { 
        return data.active;
    }).catch(() => {
        return false;
    });

    // console.log(t);

    // Aggiungi qui la logica per verificare se il token è valido o scaduto
    auth = t; // Imposta auth in base al risultato di ClientToken

    return auth;
};

export const LogOut = () => {
    const dispatch = useDispatch();

    dispatch(clearUserInfo());

    toast.success("Account disconnesso", {
        duration: 4000,
        position: 'top-center',
        // Styling
        style: {},
        className: '',
    });
    localStorage.removeItem('client');

    setTimeout(() => {
        window.location.href = "/Auth";
    }, 1000)

}


const RequireAuth = ({ Component, restricted, page = true, topBar = true }, ...props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const animCookie = Cookies.get("cookie_animation") === 'true' || false;
    const animTimeCookie = Cookies.get("cookie_animation_time") || 3000;


    useEffect(() => {
        const checkAuth = async () => {
            const auth = await isAuthenticated(dispatch);
            setIsAuthenticatedUser(auth);

            if(animCookie){
                setTimeout(() => {
                    setLoading(false);
                }, animTimeCookie);
            }else{
                setLoading(false);
            }
        };
        checkAuth();
    }, [dispatch]);

    // const isAuthenticatedUser = isAuthenticated(dispatch);

    // if (loading) {
    //     // Puoi mostrare un loader o nulla mentre l'autenticazione è in corso
    //     if(animCookie){
    //         return <LoadingComponent />
    //     }else return null;
    // }

    if (loading) {
        // Puoi mostrare un loader o nulla mentre l'autenticazione è in corso
        if(animCookie){
            return <LoadingComponent />
        }else return null;
    }

    
    
    if (restricted && isAuthenticatedUser) {
        // Se l'utente è già autenticato e la pagina è restritta, reindirizzalo alla home
        if (location.pathname !== "/") {
            return <Navigate to="/" state={{ from: location }} />;
        }
    } else {
        // Se l'utente non è autenticato e la pagina è restritta, reindirizzalo alla pagina di autenticazione
        if (!isAuthenticatedUser && page) {
            if(location.pathname != "/Auth" && !location.pathname.includes("/Auth/Restore") && location.pathname != "/Server-status" ){
                return <Navigate to="/Auth" state={{ from: location }} />;
            }else{
                return <Component navigate={navigate} {...props} />;
            }
        }
    }
    // console.log(isAuthenticatedUser);

    // Restituisce il componente richiesto se l'utente è autenticato o se la pagina non è restrittiva
    return isAuthenticatedUser ? <Layout topBar={topBar}><Component navigate={navigate} {...props} /></Layout> : null;
};

export default RequireAuth;
