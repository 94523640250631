import React, { useState, useEffect } from 'react'
import { X,CheckCircle } from '@phosphor-icons/react';
import Loading from '../../../../../assets/svgs/loading.jsx';

export function Step3(props){
    const [birthDate, setBirthDate] = useState(null);
    const [phone, setPhone] = useState(null);
    const [gender, setGender] = useState(null);

    useEffect(() => { 
        if(props.data.gender != null){
            
            const maleInput = document.getElementById(props.data.gender); 
            maleInput.checked = true; 
        }

    }, [props.data.role]);
    
    return <div>
            <div className=''>
                <h1 className='text-3xl font-normal'>Dati personali</h1>
                <p className='text-md font-normal text-slate-500 dark:text-slate-400 max-w-sm mt-2 leading-5'>Inserisci dati personali relativi all'utente </p>
            </div>
            <div>
                <form >
                    <div className="space-y-12">
                        <div className=" my-10 w-full max-w-md ">
                            <div className="mt-5 grid grid-cols-12 gap-4">
                                <div className="sm:col-span-12">
                                    <label htmlFor="birthdate" className="block text-sm/6 font-medium text-slate-400">
                                        Data di nascita
                                    </label>
                                    <div className="mt-2">
                                        <div className="  sm:max-w-md px-2
                                                        transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed block w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <input
                                                id="birthdate"
                                                name="birthdate"
                                                onChange={(e) => setBirthDate(e.target.value)}
                                                defaultValue={props.data.birthDate}
                                                required
                                                type="date"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-12">
                                    <label htmlFor="phone" className="block text-sm/6 font-medium text-slate-400">
                                        Numero di cellulare
                                    </label>
                                    <div className="mt-2">
                                        <div className="  sm:max-w-md px-2
                                                        transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed block w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <input
                                                id="phone"
                                                name="phone"
                                                onChange={(e) => setPhone(e.target.value)}
                                                defaultValue={props.data.phone}
                                                required
                                                type="tel"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:col-span-12">
                                    <label htmlFor="username" className="block text-sm/6 font-medium text-slate-400">
                                        Genere
                                    </label>
                                    <div className="mt-2">
                                        <div className="sm:max-w-md px-2 transition-all block w-full rounded-md border-0 py-0 text-gray-900  sm:text-sm sm:leading-6">
                                            <label className="inline-flex items-center"> 
                                                <input type="radio" name="gender" id="male" onChange={(e) => setGender(e.target.value)} value="male" defaultChecked={props.data.gender} className="form-radio text-indigo-600" /> 
                                                <span className="ml-2">Maschio</span> 
                                            </label> 
                                            <label className="inline-flex items-center ml-6"> 
                                                <input type="radio" name="gender" id="female" onChange={(e) => setGender(e.target.value)} value="female"defaultChecked={props.data.gender} className="form-radio text-indigo-600" /> 
                                                <span className="ml-2">Femmina</span> 
                                            </label>
                                            <label className="inline-flex items-center ml-6"> 
                                                <input type="radio" name="gender" id="none" onChange={(e) => setGender(e.target.value)} value="none"defaultChecked={props.data.gender}  className="form-radio text-indigo-600" /> 
                                                <span className="ml-2">Non specificato</span> 
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 grid grid-cols-12 gap-4">
                                <div className="col-span-6">
                                    <button
                                        onClick={props.PreviusStep}
                                        
                                        className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-slate-100 disabled:bg-slate-600/50 px-3 py-3  font-normal leading-6 text-black shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                                    >
                                        <span className={['transition-all', "translate-y-0 opacity-100"].join(" ")}>Indietro</span>
                                    </button>
                                </div>
                                <div className="col-span-6">
                                    <button
                                        onClick={(e) => props.NextStep(e, birthDate, phone, gender)}
                                        disabled={false}
                                        className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-indigo-600 disabled:bg-indigo-600/50 px-3 py-3  font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', false ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                                        <span className={['transition-all', false ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Continua</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    </div>
}