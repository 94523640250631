import React, { useState } from 'react'
import {Password, X,Check } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { Toggle } from '../../../../components/Toggles';
import Loading from '../../../../assets/svgs/loading.jsx';
import toast, { Toaster } from 'react-hot-toast';
import { gql } from '@apollo/client';
import {client} from '../../../../config.apollo.js';

export function Security(){
    const [authQrCode, setAuthQrCode] = useState();
    const [loading, setLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [strength, setStrength] = useState([0, 0, 0, 0]);

    const save = async () => {
        if(oldPassword.length === 0){
            toast.error("Imposta la tua vecchia password", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        if(newPassword.length == 0){
            toast.error("Imposta una nuova password", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }
        if(newPassword != repeatNewPassword){
            toast.error("Le due password non coincidono", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        try {
            const { loading, error, data } = await client.mutate({
                mutation: gql`
                    mutation Mutation($requestChangePassword: RequestChangePassword) {
                        clientChangePassword(RequestChangePassword: $requestChangePassword) {
                            email
                        }
                    }
                `,
                variables: { 
                    requestChangePassword: {
                        oldPassword: oldPassword,
                        newPassword: repeatNewPassword
                    }
                }
            });

            if(data){
                if(data.clientChangePassword){
                    toast.success("Password modificata con successo", {
                        duration: 4000,
                        position: 'top-center',
                        // Styling
                        style: {},
                        className: 'dark:bg-slate-800 dark:text-slate-100',
                        // Custom Icon
                        icon: <Check className='text-green-500 w-10' size={20} />,
                    });
                }
            }
            
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });
        }

    }

    const generatePassword = (e) => {
        e.preventDefault();
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
        let newPassword = '';
        for (let i = 0; i < 12; i++) {
        newPassword += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        setNewPassword(newPassword);
        evaluateStrength(newPassword);
    };

    const handlePassword = e => {
        const value = e.target.value;
        setNewPassword(value);
        evaluateStrength(value);
    }
    
    const evaluateStrength = (password) => {
        const strengthArray = [0, 0, 0, 0];
      
        // Prima barra: testo con soli caratteri o soli numeri
        if (/^[A-Za-z]+$/.test(password) || /^[0-9]+$/.test(password)) {
            strengthArray[0] = 1;
        }
      
        // Seconda barra: numeri più lettere
        if (/[A-Za-z]/.test(password) && /[0-9]/.test(password)) {
            strengthArray[0] = 1;
            strengthArray[1] = 1;
        }
      
        // Terza barra: numeri, lettere (inclusi maiuscoli) e caratteri speciali con più di 6 caratteri
        if (password.length > 6 && /[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && /[^A-Za-z0-9]/.test(password)) {
            strengthArray[1] = 1;
            strengthArray[2] = 1;
        }
      
        // Quarta barra: numeri, lettere (inclusi maiuscoli) e caratteri speciali con più di 8 caratteri
        if (password.length > 8 && /[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && /[^A-Za-z0-9]/.test(password)) {
            strengthArray[2] = 1;
            strengthArray[3] = 1;
        }
      
        setStrength(strengthArray);
    };
      

    return <div className='divide-slate-200 dark:divide-slate-800 space-y-20 py-6'>
        
        <div className="grid gap-8 md:grid-cols-12">
            <div className="md:col-span-4">
                <h3 className='text-md font-medium text-slate-800 dark:text-slate-100 mb-1'>Account</h3>
                <p className="text-xs font-normal text-slate-500 dark:text-slate-400">Setta la tua password per poter accedere sul gestionale, ricorda di non condividerla con nessuno.</p>
            </div>
            <div className="md:col-span-8 space-y-5">
                <div>
                    <p className="text-xs font-medium border-slate-200 dark:border-slate-800 text-slate-800 dark:text-slate-100 border-b px-4 pb-4">
                        Accesso sicurezza
                    </p>
                </div>
                <div className='px-4'>
                    <div>
                        <h3 className="text-xs font-medium text-slate-400 mb-2">
                            Password Attuale
                        </h3>
                    </div>
                    <div className='flex relative'>
                        
                        <input
                            id="actualPassword"
                            name="actualPassword"
                            type="password"
                            onChange={e => setOldPassword(e.target.value)}
                            placeholder='Password attuale'
                            required
                            className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <label htmlFor="actualPassword" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                            <Password className=' absolute top-2 left-2' size="20"  />
                        </label>
                    </div>
                </div>
                <div className='px-4'>
                    <div>
                        <h3 className="text-xs font-medium text-slate-400 mb-2">
                            Nuova password
                        </h3>
                    </div>
                    <div className='flex relative'>
                        
                        <input
                            id="newPassword"
                            name="newPassword"
                            type="text"
                            value={newPassword}
                            onChange={handlePassword}
                            placeholder='Nuova password'
                            required
                            className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <label htmlFor="newPassword" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                            <Password className=' absolute top-2 left-2' size="20"  />
                        </label>
                    </div>
                </div>
                <div className='px-4'>
                    <div className='space-x-4 flex'>
                        <div className={`w-full h-1 rounded-md ${strength[0] ?  strength[3] ? 'bg-green-500' : 'bg-red-500' : 'bg-slate-300'}`}></div>
                        <div className={`w-full h-1 rounded-md ${strength[1] ? strength[3] ? 'bg-green-500' :  'bg-orange-500' : 'bg-slate-300'}`}></div>
                        <div className={`w-full h-1 rounded-md ${strength[2] ? strength[3] ? 'bg-green-500' :  'bg-yellow-500' : 'bg-slate-300'}`}></div>
                        <div className={`w-full h-1 rounded-md ${strength[3] ? 'bg-green-500' : 'bg-slate-300'}`}></div>
                    </div>
                </div>

                <div className='px-4'>
                    <div>
                        <h3 className="text-xs font-medium text-slate-400 mb-2">
                            Ripeti nuova password
                        </h3>
                    </div>
                    <div className='flex relative'>
                        
                        <input
                            id="repeatpass"
                            name="repeatpass"
                            type="text"
                            onChange={e =>setRepeatNewPassword(e.target.value)}
                            placeholder='Ripeti nuova password'
                            required
                            className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <label htmlFor="repeatpass" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                            <Password className=' absolute top-2 left-2' size="20"  />
                        </label>
                    </div>
                </div>

                <div className='px-4'>
                    <div>
                        <h3 className="text-xs font-medium text-slate-400 mb-2">
                            Genera una nuova password con la tecnologia AI
                        </h3>
                    </div>
                    <div className='flex relative justify-between items-center'>
                        <Link onClick={generatePassword} className='text-md text-primary dark:text-primary dark:hover:bg-slate-100/20 hover:underline hover:bg-slate-100' to="">Genera password</Link>
                        <button
                            onClick={save}
                            disabled={loading}
                            className="disabled:bg-violet-200 disabled:border-violet-200 rounded-md min-w-32 py-1 px-5 text-slate-100 bg-violet-500 border-violet-500 hover:bg-violet-500/50 hover:border-violet-500/50 relative transition-colors duration-300"
                        >
                            <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                            <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Salva</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div className="grid gap-8 md:grid-cols-12">
            <div className="md:col-span-4">
                <h3 className='text-md font-medium text-slate-800 dark:text-slate-100 mb-1'>2 Fattori</h3>
                <p className="text-xs font-normal text-slate-500 dark:text-slate-400">Reimposta o modifica il metodo di autenticazione (ad esempio Authy o Google Authenticator) per questo account.</p>
            </div>
            <div className="md:col-span-8 space-y-5">
                <div>
                    <p className="text-xs font-medium border-slate-200 dark:border-slate-800 text-slate-800 dark:text-slate-100 border-b px-4 pb-4">
                        2 Fattori
                    </p>
                </div>
                <div className=' space-y-5 divide-y divide-slate-200 dark:divide-slate-800'>
                    <div className='md:flex relative px-4'>
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center order-last md:order-none">
                                <div className='flex relative'>
                                    <Toggle disabled onClick={() => setAuthQrCode(!authQrCode)} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex flex-col relative end'>
                                    <h3 className="text-xs font-medium text-slate-400 mb-0">
                                        App
                                    </h3>
                                    <p className="text-md text-slate-600 dark:text-slate-400">
                                        Accesso tramite App ufficiale MynuApp 
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='md:flex relative px-4 py-4'>
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center order-last md:order-none">
                                <div className='flex relative'>
                                    <Toggle disabled onClick={() => setAuthQrCode(!authQrCode)} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex flex-col relative end'>
                                    <h3 className="text-xs font-medium text-slate-400 mb-0">
                                        Google 2FA
                                    </h3>
                                    <p className="text-md text-slate-600 dark:text-slate-400">
                                        Accedi tramite Google Two-Factor 
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="grid gap-8 md:grid-cols-12">
            <div className="md:col-span-4">
                <h3 className='text-md font-medium text-slate-800 dark:text-slate-100 mb-1'>Notifiche sicurezza</h3>
                <p className="text-xs font-normal text-slate-500 dark:text-slate-400">Ricevi notifche ad ogni accesso sul tuo gestionale.</p>
            </div>
            <div className="md:col-span-8 space-y-5">
                <div>
                    <p className="text-xs font-medium border-slate-200 dark:border-slate-800 text-slate-800 dark:text-slate-100 border-b px-4 pb-4">
                        Nofiche tramite Email
                    </p>
                </div>
                <div className=' space-y-5 divide-y divide-slate-200 dark:divide-slate-800'>
                    <div className="px-4">
                        <Link to="/Settings/Notifications#notyAccess" className='text-md text-primary dark:text-primary dark:hover:bg-slate-100/20 hover:underline hover:bg-slate-100'>
                            Visualizza in notifiche  
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
}