import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import {Toggle} from '../../../../components/Toggles';
import {updateSettingsNotific } from '../../../../GraphQl/Conections/Mutation.Notifics';
import {notificSettings} from '../../../../GraphQl/Conections/Query.Notific';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export function Notifications(){
    const dispatch = useDispatch();
    const location = useLocation();
    const [changePasswordToggle, setChangePasswordToggle] = useState(false);
    const [newSessionToggle, setNewSessionToggle] = useState(false);
    const [offerToggle, setOfferToggle] = useState(false);
    const [newUpdateToggle, setUpdateToggle] = useState(false);
    const [balance_undere, setBalanceUnderToggle] = useState(false);
    const [transition_pay_end, setTransitionPayEndToggle] = useState(false);
    const [operation_out, setOperationOutToggle] = useState(false);
    const [transition_error, setTransitionErrorToogle] = useState(false);
    const [storage_article_quantity_finished, setArticleQuantityFinished] = useState(false);
    const [storage_article_expired, setArticleExpired] = useState(false);
    
    
    useEffect(() => {
        notificSettings(dispatch).then(data => { 
            setNewSessionToggle(data.new_access_session);
            setChangePasswordToggle(data.new_password_request);
            setOfferToggle(data.offer);
            setUpdateToggle(data.new_update);
            setBalanceUnderToggle(data.balance_undere);
            setTransitionPayEndToggle(data.transition_pay_end);
            setOperationOutToggle(data.operation_out);
            setTransitionErrorToogle(data.transition_error);
            setArticleQuantityFinished(data.storage_article_quantity_finished);
            setArticleExpired(data.storage_article_expired);
        });
    }, []);

    useEffect(() => {
        // console.log(location.hash);
    }, [location]);

    const handleSessionToggle = () => {
        updateSettingsNotific(dispatch, {new_access_session: !newSessionToggle}).then(data => { 
            setNewSessionToggle(!newSessionToggle);
        });
    }
    const handleChangePasswordToggle = () => {
        updateSettingsNotific(dispatch, {new_password_request: !changePasswordToggle}).then(data => { 
            setChangePasswordToggle(!changePasswordToggle);
        });
    }
    const handleOfferToggle = () => {
        updateSettingsNotific(dispatch, {offer: !offerToggle}).then(data => { 
            setOfferToggle(!offerToggle);
        });
    }
    const handleUpdateToggle = () => {
        updateSettingsNotific(dispatch, {new_update: !newUpdateToggle}).then(data => { 
            setUpdateToggle(!newUpdateToggle);
        });
    }
    const handlebalanceUnderToggle = () => {
        updateSettingsNotific(dispatch, {balance_undere: !balance_undere}).then(data => { 
            setBalanceUnderToggle(!balance_undere);
        });
    }

    const handleTransitionPayEndToggle = () => {
        updateSettingsNotific(dispatch, {transition_pay_end: !transition_pay_end}).then(data => { 
            setTransitionPayEndToggle(!transition_pay_end);
        });
    }
    const handleOperationOutToggle = () => {
        updateSettingsNotific(dispatch, {operation_out: !operation_out}).then(data => { 
            setOperationOutToggle(!operation_out);
        });
    }
    const handleTransitionErrorToggle = () => {
        updateSettingsNotific(dispatch, {transition_error: !transition_error}).then(data => { 
            setTransitionErrorToogle(!transition_error);
        });
    }

    const handleStorageQuantityFinishedToggle = () => {
        updateSettingsNotific(dispatch, {storage_article_quantity_finished: !storage_article_quantity_finished}).then(data => { 
            setArticleQuantityFinished(!storage_article_quantity_finished)
        });
    }

    const handleStorageExpiredToggle = () => {
        updateSettingsNotific(dispatch, {storage_article_expired: !storage_article_expired}).then(data => { 
            setArticleExpired(!storage_article_expired)
        });
    }
    

    return <div className='divide-slate-200 dark:divide-slate-800 space-y-20 py-6'>
        
        <div className="grid gap-8 md:grid-cols-12">
            <div className="md:col-span-4">
                <h3 className='text-md font-medium text-slate-800 dark:text-slate-100 mb-1'>Attività account</h3>
                <p className="text-xs font-normal text-slate-500 dark:text-slate-400">Personalizza le notifiche e-mail che desideri ricevere.</p>
            </div>
            <div className="md:col-span-8 space-y-5">
                <div className=' space-y-5 divide-y divide-slate-200 dark:divide-slate-800'>
                    <div className='md:flex relative px-4'>
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center order-last md:order-none">
                                <div className='flex relative'>
                                    <Toggle onClick={handleTransitionPayEndToggle} status={transition_pay_end} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex flex-col relative end'>
                                    <h3 className="text-xs font-medium text-slate-400 mb-0">
                                        In arrivo
                                    </h3>
                                    <p className="text-md text-slate-600 dark:text-slate-400">
                                        Transazioni in entrata 
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='md:flex relative px-4 py-4'>
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center order-last md:order-none">
                                <div className='flex relative'>
                                    <Toggle onClick={handleOperationOutToggle} status={operation_out} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex flex-col relative end'>
                                    <h3 className="text-xs font-medium text-slate-400 mb-0">
                                        In uscita
                                    </h3>
                                    <p className="text-md text-slate-600 dark:text-slate-400">
                                        Operazioni in uscita 
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='md:flex relative px-4 py-4'>
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center order-last md:order-none">
                                <div className='flex relative'>
                                    <Toggle onClick={handleTransitionErrorToggle} status={transition_error} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex flex-col relative end'>
                                    <h3 className="text-xs font-medium text-slate-400 mb-0">
                                        Falliti
                                    </h3>
                                    <p className="text-md text-slate-600 dark:text-slate-400">
                                        Transazioni fallite 
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    
                </div>
            </div>
        </div>

        <div className={["grid gap-8 md:grid-cols-12", location.hash == "#notyAccess" ? "bg-yellow-50 dark:bg-yellow-500/10 animate-pulse-once duration-400 rounded-lg" : ""].join(" ")} id="notyAccess">
            <div className="md:col-span-4">
                <h3 className='text-md font-medium text-slate-800 dark:text-slate-100 mb-1'>Accesso account</h3>
                <p className="text-xs font-normal text-slate-500 dark:text-slate-400">Ricevi notifche ad ogni accesso sul tuo gestionale.</p>
            </div>
            <div className="md:col-span-8 space-y-5">
                {/* <div>
                    <p className="text-xs font-medium border-slate-200 dark:border-slate-800 text-slate-800 dark:text-slate-100 border-b px-4 pb-4">
                        Coming Soon
                    </p>
                </div> */}
                <div className=' space-y-5 divide-y divide-slate-200 dark:divide-slate-800'>
                    <div className='md:flex relative px-4'>
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center order-last md:order-none">
                                <div className='flex relative'>
                                    <Toggle onClick={handleSessionToggle} status={newSessionToggle} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex flex-col relative end'>
                                    <h3 className="text-xs font-medium text-slate-400 mb-0">
                                        Sessione
                                    </h3>
                                    <p className="text-md text-slate-600 dark:text-slate-400">
                                        Durante una nuova sessione d'accesso 
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='md:flex relative px-4 py-4'>
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center order-last md:order-none">
                                <div className='flex relative'>
                                    <Toggle onClick={handleChangePasswordToggle} status={changePasswordToggle} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex flex-col relative end'>
                                    <h3 className="text-xs font-medium text-slate-400 mb-0">
                                        Password
                                    </h3>
                                    <p className="text-md text-slate-600 dark:text-slate-400">
                                        Durante il cambio password 
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    
                </div>
            </div>
        </div>

        <div className="grid gap-8 md:grid-cols-12">
            <div className="md:col-span-4">
                <h3 className='text-md font-medium text-slate-800 dark:text-slate-100 mb-1'>Aggiornamenti di MynuApp</h3>
                <p className="text-xs font-normal text-slate-500 dark:text-slate-400">Rimani aggiornato sulle nuove fantastiche funzionalità dei prodotti o sugli eventi che potrebbero piacerti.</p>
            </div>
            <div className="md:col-span-8 space-y-5">
                <div className=' space-y-5 divide-y divide-slate-200 dark:divide-slate-800'>
                    <div className='md:flex relative px-4'>
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center order-last md:order-none">
                                <div className='flex relative'>
                                    <Toggle onClick={handleUpdateToggle} status={newUpdateToggle} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex flex-col relative end'>
                                    <h3 className="text-xs font-medium text-slate-400 mb-0">
                                        Tratti somatici
                                    </h3>
                                    <p className="text-md text-slate-600 dark:text-slate-400">
                                        Nuove funzionalità 
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='md:flex relative px-4 py-4'>
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center order-last md:order-none">
                                <div className='flex relative'>
                                    <Toggle onClick={handleOfferToggle} status={offerToggle} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex flex-col relative end'>
                                    <h3 className="text-xs font-medium text-slate-400 mb-0">
                                        Offerte
                                    </h3>
                                    <p className="text-md text-slate-600 dark:text-slate-400">
                                        Offerte speciali 
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="grid gap-8 md:grid-cols-12">
            <div className="md:col-span-4">
                <h3 className='text-md font-medium text-slate-800 dark:text-slate-100 mb-1'>Saldo basso</h3>
                <p className="text-xs font-normal text-slate-500 dark:text-slate-400">Ti invieremo un'email quando il saldo di uno dei tuoi account scende al di sotto dell'importo che hai impostato nel tuo account.</p>
            </div>
            <div className="md:col-span-8 space-y-5">
                <div className=' space-y-5 divide-y divide-slate-200 dark:divide-slate-800'>
                    <div className='md:flex relative px-4'>
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center order-last md:order-none">
                                <div className='flex relative'>
                                    <Toggle onClick={handlebalanceUnderToggle} status={balance_undere} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex flex-col relative end'>
                                    <h3 className="text-xs font-medium text-slate-400 mb-0">
                                        Allerta bassa
                                    </h3>
                                    <p className="text-md text-slate-600 dark:text-slate-400">
                                        Il saldo scende sotto i 200,00€
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        <div className="grid gap-8 md:grid-cols-12">
            <div className="md:col-span-4">
                <h3 className='text-md font-medium text-slate-800 dark:text-slate-100 mb-1'>Magazzino</h3>
                <p className="text-xs font-normal text-slate-500 dark:text-slate-400">Ti invieremo un'email quando le quanità dei tuoi articoli sta per terminare oppure la data di scadenza si sta avvicinando.</p>
            </div>
            <div className="md:col-span-8 space-y-5">
                <div className=' space-y-5 divide-y divide-slate-200 dark:divide-slate-800'>
                    <div className='md:flex relative px-4'>
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center order-last md:order-none">
                                <div className='flex relative'>
                                    <Toggle onClick={handleStorageQuantityFinishedToggle} status={storage_article_quantity_finished} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex flex-col relative end'>
                                    <h3 className="text-xs font-medium text-slate-400 mb-0">
                                        Quanità articolo
                                    </h3>
                                    <p className="text-md text-slate-600 dark:text-slate-400">
                                        La quantità scende al di sotto del 20% 
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='md:flex relative px-4 py-4'>
                        <div className='grid grid-cols-12 gap-4 '>
                            <div className="col-span-12 sm:col-span-2 place-self-center order-last md:order-none">
                                <div className='flex relative'>
                                    <Toggle onClick={handleStorageExpiredToggle} status={storage_article_expired} />
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-10">
                                <div className='flex flex-col relative end'>
                                    <h3 className="text-xs font-medium text-slate-400 mb-0">
                                        Data di Scadenza articolo
                                    </h3>
                                    <p className="text-md text-slate-600 dark:text-slate-400">
                                        La data di scadenza dell'articolo <br /> sta per avvicinarsi o è già scaduto
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
}