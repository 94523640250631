import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { DotsThree, Trash,PencilSimple} from '@phosphor-icons/react';
import './style.css';

export function DropTable(props) {
    const [isOpen, setIsOpen] = useState(false); 
    const handleOpen = () => { 
        setIsOpen(!isOpen); 
    }; 
    
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton onClick={handleOpen} id="menuButton" className="drp-gd transition-all inline-flex w-full justify-center gap-x-1.5 rounded-full  px-2 py-2 text-sm font-semibold text-gray-900 dark:text-slate-400 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 focus:outline-offset-4 focus:outline-slate-400/50 focus:outline-dashed">
                    <DotsThree className={[" "].join(" ")} weight='bold' size="20" />
                </MenuButton>
            </div>

            <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:backdrop-blur-sm dark:bg-slate-700/30 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="py-2 px-2 gap-y-2 flex flex-col">
                    {
                        props.childrenBefore != undefined && props.childrenBefore.length > 0 && props.childrenBefore.map((child, index) => {
                            return (
                                <MenuItem key={index}>
                                    <Link
                                        to={child.to}
                                        className="flex gap-x-4 items-center rounded-md px-4 py-2 text-sm text-gray-700 hover:!text-violet-500 dark:text-slate-100 transition-colors hover:bg-slate-100 dark:hover:bg-white/10  data-[focus]:text-gray-900 data-[focus]:forced-color-adjust-none data-[focus]:forced-colors:bg-[Highlight] data-[focus]:forced-colors:text-[HighlightText]"
                                    >
                                        
                                        {child.icon}
                                        <div className='flex flex-col'>
                                            <span className='text-slate-900 dark:text-slate-200 font-medium text-xs'>{child.title}</span>
                                            <span className='text-slate-600 dark:text-slate-300 font-normal text-xs'>{child.subtitle}</span>
                                        </div>
                                    </Link>
                                </MenuItem>
                            )
                        })
                    }
                    <MenuItem>
                        <Link
                            to={props.handleEdit}
                            className="flex gap-x-4 items-center rounded-md px-4 py-2 text-sm text-gray-700 dark:text-slate-100 hover:!text-violet-500 transition-colors hover:bg-slate-100 dark:hover:bg-white/10  data-[focus]:text-gray-900 data-[focus]:forced-color-adjust-none data-[focus]:forced-colors:bg-[Highlight] data-[focus]:forced-colors:text-[HighlightText]"
                        >
                            
                            <PencilSimple size="20" weight='duotone' />
                            <div className='flex flex-col'>
                                <span className='text-slate-900 dark:text-slate-200 font-medium text-xs'>Modifica</span>
                                <span className='text-slate-600 dark:text-slate-300 font-normal text-xs'>Gestisci elemento</span>
                            </div>
                        </Link>
                    </MenuItem>
                    <div class="border-t border-black border-opacity-5" role="none"></div>
                    <MenuItem>
                        <a
                        href="#"
                        onClick={props.handleDelete}
                        className="flex gap-x-4 items-center rounded-md px-4 py-2 text-sm hover:text-red-700 transition-colors hover:bg-slate-100"
                        >
                            <Trash size="20" weight='duotone' />
                            <div className='flex flex-col'>
                                <span className='text-slate-900 dark:text-slate-200 font-medium text-xs'>Elimina</span>
                                <span className='text-slate-600 dark:text-slate-300 font-normal text-xs'>Elimina dalla lista</span>
                            </div>
                        </a>
                    </MenuItem>
                    
                </div>
            </MenuItems>
        </Menu>
    )
}
