import React, {useState, useEffect} from "react"
import {X} from '@phosphor-icons/react';

export function Budges(props) {
    const [animStart, setAnimStart] = useState(false);


    const handleRemove = (e) => {
      // const parent = e.currentTarget.parentNode;
      // parent.classList.add("opacity-0");
      // parent.classList.add("scale-75");
      setTimeout(() => {
        // parent.remove();
        props.onChange("removed", props.id)
      }, 300);
    }

    useEffect(() => {
      if(props.animationShow){
        setTimeout(() => {
          setAnimStart(true);
        }, 50);
      }
    }, [])

    

    return (
      <>
        <span className={["inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 w-auto transition-all duration-300", props.className, props.animationShow ? "opacity-0" : "", animStart ? "opacity-100" : "opacity-0"].join(" ")}>
          {props.children} {props.remove ? <button onClick={handleRemove} className="ml-2 transition-all duration-300 text-slate-600 hover:text-slate-800 "><X className="" /></button> : <></>}
        </span>
        {/* <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
          Badge
        </span>
        <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
          Badge
        </span>
        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
          Badge
        </span>
        <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
          Badge
        </span>
        <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
          Badge
        </span>
        <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
          Badge
        </span>
        <span className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">
          Badge
        </span> */}
      </>
    )
  }
  