import {client} from '../../config.apollo';
// import { useNavigate } from 'react-router-dom';
import { Notifics as queryNotifics, NotificsSettings as queryNotificSettings } from '../Query';
import {X} from '@phosphor-icons/react'
import toast, { Toaster } from 'react-hot-toast';
import { clearUserInfo } from '../../redux/reducers/clientReducer';

export const Notifics = (dispatch, page, pageSize, filterSearch, skip = null) => {
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        try {
            const { loading, error, data } = await client.query({ 
                query: queryNotifics, 
                fetchPolicy: 'no-cache' // Escludi la cache
            }, {});
            myResolve(data.notifics); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};

export const notificSettings = (dispatch, skip = null) => {
    // const navigate = useNavigate();
    return new Promise(async (myResolve, myReject) => {
        if (skip) { //Query saltata
            return;
        }

        try {
            const { loading, error, data } = await client.query({ 
                query: queryNotificSettings, 
                // fetchPolicy: 'no-cache' // Escludi la cache
            }, {});
            myResolve(data.clientNotificSettings); // when successful
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <X className='text-red-500 w-10' size={20} />,
            });
            const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
            if(errorCode === "EXPIRED_TOKEN"){

                dispatch(clearUserInfo());
            
                toast.success("Account disconnesso", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: '',
                });
                localStorage.removeItem('client');
            
                setTimeout(() => {
                    window.location.href = "/Auth";
                }, 1000)
            }
            myReject(error); // when error
        }
    });
};