import { Fragment, useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import {ArrowsClockwise , Bell, Plus,Dot,Door,GearSix,User,MagnifyingGlass,MoonStars,Sun,PresentationChart,Trash,BuildingOffice,ArrowBendUpRight,QrCode, Gear  } from '@phosphor-icons/react';
import {DropDown1} from '../../Dropdowns/index.jsx';
import LogoMynu from '../../../assets/images/logos/logo.png';
import { Budges } from '../../badgets/index.jsx';
import DropModal from '../../DropModal/index.jsx';
import { useDispatch } from 'react-redux';
import { clearUserInfo } from '../../../redux/reducers/clientReducer.jsx';
import toast, { Toaster } from 'react-hot-toast';
import GestoreAvatar from '../../../assets/images/avatars/mynu-gestore.png';
import isValidURL from '../../../middleware/isValidUrl.jsx';
import {Notifics } from '../../../GraphQl/Conections/Query.Notific.js';
import {setAllNotificationVisualizzed, setAllNotificationTrash } from '../../../GraphQl/Conections/Mutation.Notifics.js';

let navigation_ = [
  { name: 'Home', href: '/', enable: true, current: true },
  { name: 'Sale', href: '/Room', enable: false, current: false, dropdown: <DropDown1 className="px-3" name={"Sala"} direction={"left"} list={[{name: "Sala 1", link: '/Room/sala1'}, {link: null},{name: "Aggiungi Sala", link: '/Room/Add',  icon:  <Plus className="h-4 w-4 mr-3 text-slate-500" weight="duotone"  aria-hidden="true" />}]} />},
  { name: 'Teams', href: '/Teams', enable: false, current: false,class: '!ml-0' },
  { name: 'Menù', href: '/Menu', enable: true, current: false , dropdown: <DropDown1 name={"Menù"} direction={"left"} list={[{name: "Gestione Menù", link: '/Menu'},{name: "Gestione Categorie", link: '/Menu/categories'},{name: "Gestione Articoli", link: '/Menu/Articles'}]} />},
  { name: 'Coupon', href: '/Coupons', enable: false, current: false,class: '!ml-0' },
  { name: 'Magazzino', href: '/Storage', enable: false, current: false,  },
]
const userNavigation = [
  { name: 'Profilo', href: '/Profile', icon: <User className="h-4 w-4 mr-3 text-slate-500 dark:text-slate-300" weight="duotone" aria-hidden="true" />},
  { name: 'Impostazioni', href: '/Settings', icon: <GearSix className="h-4 w-4 mr-3 text-slate-500 dark:text-slate-300" weight="duotone" aria-hidden="true" />},
  { name: 'Aggiorna v' + process.env.REACT_APP_VERSION, href: '/Settings/Updates', className: "text-green-600", icon: <ArrowsClockwise className="h-4 w-4 mr-3 text-green-500" weight="duotone" aria-hidden="true" />},
  { name: 'Desk', href: '/Desk', className: "text-green-600", icon: <QrCode className="h-4 w-4 mr-3 text-slate-500 dark:text-slate-300" weight="duotone" aria-hidden="true" />},
  // { name: 'Esci', href: () => LogOut(), className: "text-red-500", icon: <Door className="h-4 w-4 mr-3 text-red-500" weight="duotone" aria-hidden="true" />},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example(props) {
  const dispatch = useDispatch();
    const location = useLocation();
    const [navigation, setMenuItems] = useState(navigation_);
    const [serverStat, setServerStat] = useState(true);
    const [notifics, setNotifics] = useState([]);

    const [notyShow, setNotyShow] = useState(false);
    
    const [toggleTheme, setToggleTheme] = useState(localStorage.getItem('theme') == "light" ? true : false);

    const { 
      activity_name = null, // Fallback a null
      activity_type = null,
      owner = null,
      owner_name = null,
      public_email = null,
      seo_url = null,
      active = false, // Fallback a false per i booleani
      Logo = false
    } = props.data || {}; // Fallback a un oggetto vuoto se props.data è null/undefined

    useEffect(() => {
        const pathname = location.pathname;
        const updatedMenuItems = navigation.map(item => {
            const hrefParts = item.href ? item.href.split('/') : [];
            const pathnameParts = pathname ? pathname.split('/') : [];
            
            if (
                (hrefParts[1] === pathnameParts[1] || (item.href === '/' && pathname === '/')) &&
                hrefParts.length > 1 &&
                pathnameParts.length > 1
              ) {
                return { ...item, current: true };
            } else {
                return { ...item, current: false };
            }
        });
        setMenuItems(updatedMenuItems);
    }, [location.pathname]);

    useEffect(() => {
      
    }, []);

    const handleOpenNotifics = () => {
      Notifics(dispatch).then(data => { 
        setNotifics(data);
      });
    }

    const setNotificVisualizzed = (e) => {
      e.preventDefault();
      if(notifics.length == 0) return;

      setAllNotificationTrash(dispatch).then(data => { 
        setNotifics(data);
      });
    }

    const changeTemplate = (e) =>{

        const check = e.currentTarget.checked;
        
        setToggleTheme(check);

        if(check){
            // On page load or when changing themes, best to add inline in `head` to avoid FOUC
            document.documentElement.classList.remove('dark')
            document.documentElement.classList.add('light')
            localStorage.setItem('theme', 'light')
            
        }else{
            document.documentElement.classList.add('dark')
            document.documentElement.classList.remove('light')
            localStorage.setItem('theme', 'dark')
        }
    }


    const setNotificTrash = () => {
      setAllNotificationTrash(dispatch).then(data => { 
        setNotifics(data);
      });
    }

    const LogOut = () => {
    
        dispatch(clearUserInfo());
    
        toast.success("Account disconnesso", {
            duration: 4000,
            position: 'top-center',
            // Styling
            style: {},
            className: '',
        });
        localStorage.removeItem('client');
        localStorage.removeItem('persist:root');
    
        setTimeout(() => {
            window.location.href = "/Auth";
        }, 1000)

    }

  return (
        <Disclosure as="nav" className={["relative", props.className].join(" ")}>
          {({ open }) => (
            <>
              <div className={["relative w-full transition-all duration-300 load"].join(" ")}>
                <div className="flex h-16 items-center justify-center rounded-2xl px-4 mt-0 pr-0">
                  {/* shadow-xl bg-white/50 */}
                  <div className="">
                    <div className="flex items-center">
                      <button
                            type="button"
                            onClick={props.setCalendar}
                            data-tooltip-id="tooltip" data-tooltip-content="Impostazioni Calendario"
                            className="z-[11] flex relative rounded-full hover:bg-slate-800/10 p-1 text-slate-700 hover:text-slate-700  focus:outline-none "
                        >
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Settings Calendar</span>
                            {/* <span className='font-semibold mx-2 text-xs relative my-1'>Ctrl + K</span> */}
                            <Gear className="h-4 w-4 m-1" weight="duotone" aria-hidden="true" />
                        </button>
                        {/* <button
                            type="button"
                            onClick={changeTemplate}
                            data-tooltip-id="tooltip" data-tooltip-content="Passa alla modalità notte"
                            className="disabled:opacity-50 flex relative rounded-full hover:bg-slate-800/10 p-1 text-yellow-500 hover:text-yellow-500  focus:outline-none "
                        >
                            <MoonStars className="h-4 w-4 m-1" weight="duotone" aria-hidden="true" />
                            <Sun className="h-4 w-4 m-1 hidden" weight="duotone" aria-hidden="true" />
                        </button> */}
                        <label htmlFor="toggleDark" data-tooltip-id="tooltip" data-tooltip-content={"Passa alla modalità notte"} className="z-[11] cursor-pointer hover:bg-slate-800/10 block relative rounded-full p-1 overflow-hidden w-[32px] h-[32px] ">
                            <input type="checkbox" id="toggleDark" className="peer/draft sr-only" onChange={changeTemplate} checked={toggleTheme} />
                            <Sun size={25} weight="duotone" className="h-4 w-4 m-1 text-yellow-500 absolute -top-1 transition-all duration-300 opacity-0 peer-checked/draft:top-1 peer-checked/draft:opacity-100" />
                            <MoonStars size={25} weight="duotone" className="h-4 w-4 m-1 text-yellow-500 absolute top-1 transition-all opacity-100 duration-300 peer-checked/draft:-top-1 peer-checked/draft:opacity-0" />
                        </label>
                        <button
                            type="button"
                            onClick={props.iconSearch}
                            data-tooltip-id="tooltip" data-tooltip-content="Ricerca con Ctrl + K"
                            className="z-[11] flex relative rounded-full hover:bg-slate-800/10 p-1 text-slate-700 hover:text-slate-700  focus:outline-none "
                        >
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">View notifications</span>
                            {/* <span className='font-semibold mx-2 text-xs relative my-1'>Ctrl + K</span> */}
                            <MagnifyingGlass className="h-4 w-4 m-1" weight="duotone" aria-hidden="true" />
                        </button>
                      {/* <button
                        type="button"
                        onClick={actionDropModal}
                        className="z-[11] ml-2 relative rounded-full hover:bg-slate-800/10 p-1 text-slate-700 hover:text-slate-700  focus:outline-none "
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                        <Bell className="h-4 w-4 m-1" weight="duotone" aria-hidden="true" />
                      </button> */}
                      
                      <button
                        type="button"
                        className="ml-2 z-[11] hidden relative rounded-full hover:bg-slate-800/10 p-1 text-slate-700 hover:text-slate-700  focus:outline-none "
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                        
                      </button>
                      <Menu as="div" className="relative ml-2 rounded-full">
                        <div>
                          <Menu.Button onClick={handleOpenNotifics} className="z-[11] relative rounded-full hover:bg-slate-800/10 p-1 text-slate-700 hover:text-slate-700  focus:outline-none ">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">View notifications</span>
                            <Bell className="h-4 w-4 m-1" weight="duotone" aria-hidden="true" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 min-w-80 origin-top-right rounded-lg bg-white dark:backdrop-blur-sm dark:bg-slate-700/50 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="roudned-lg mx-2 my-2 rounded-lg">
                              <div className={['border-b border-slate-200 flex justify-between px-2',notifics.length == 0 ? "hidden": ""].join(" ")}>
                                <Link to="" onClick={setNotificVisualizzed} className='text-xs mb-2 dark:text-slate-200 dark:hover:text-primary text-slate-800 hover:text-primary'>Segna come letti</Link>
                                <Link to="" onClick={setNotificTrash} className='text-xs mb-2 dark:text-slate-200 dark:hover:text-primary text-slate-800 hover:text-primary' data-tooltip-id="tooltip" data-tooltip-content="Rimuovi tutto"><Trash size="16" /></Link>
                              </div>
                              {notifics.map((item) => (
                                <Menu.Item key={item.id} >
                                  {({ active }) => {
                                      const time = new Date(parseInt(item.createAt));
                                      
                                      return (
                                        <Link
                                          onClick={(e) => {e.preventDefault()}}
                                          className={classNames(
                                            active ? 'bg-slate-50 dark:bg-slate-600/40 rounded-md' : '',
                                            item.visualizzed == true ? '': 'bg-slate-100 dark:bg-slate-600/40 rounded-md',
                                            'px-0 py-2 text-sm text-slate-700 dark:text-slate-100 w-full flex leading-5 items-center rounded-md' + (item.className === undefined ? "" : item.className)
                                          )}
                                        >
                                            <div className="relative flex items-center w-full">
                                              <div className="rounded-md w-full  ">
                                                  <div className="flex w-full items-center justify-center gap-2 relative px-2">
                                                      <Dot size="32" weight="duotone" className={['text-green-800 absolute z-10 -top-1 -left-1 transition-opacity duration-300',item.visualizzed === true ? "opacity-0" : ""].join(" ")} />
                                                      <div className='w-10 h-10 overflow-hidden rounded-full flex items-center bg-white dark:bg-slate-800'>
                                                          <img src={item.user ? item.user.avatar : LogoMynu} className="max-w-full rounded-full object-cover shadow-sm dark:border-transparent" alt="" role="none"></img>
                                                      </div>
                                                      <div className='w-full items-center justify-between flex'>
                                                        <p className='text-slate-700 dark:text-slate-300'>
                                                          {item.title}
                                                        </p>
                                                        <span className='text-slate-500 dark:text-slate-500 text-sm'>{time.getHours() + ":" + time.getMinutes()}</span>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                        </Link>
                                      )
                                  }}
                                </Menu.Item>
                                
                              ))}
                              {
                                notifics.length == 0 ? 
                                  <div className='text-sm text-center text-slate-700 dark:text-slate-300 mt-2'>Non ci sono notifiche</div>
                                  :
                                  <></>
                              }
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-2">
                        <div>
                          <Menu.Button className="z-[11] h-8 w-8 relative flex max-w-xs items-center rounded-full text-sm focus:outline-none hover:ring-4 ring-white dark:ring-slate-600 transition-all outline-slate-400 outline-offset-4 hover:outline-double">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>
                            <img className="h-8 w-8 rounded-full" src={Logo.length == 0 || Logo == null ? GestoreAvatar : isValidURL(Logo) ? Logo : process.env.REACT_APP_SERVER_IMAGE_ENDPOINT + "/public/images/dump/small-" + Logo} alt="" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 min-w-80 origin-top-right rounded-lg bg-white dark:backdrop-blur-sm dark:bg-slate-700/50 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className='flex text-sm text-slate-700 dark:text-slate-100 mb-1 items-center p-5'>
                              <div className='relative flex size-14 rounded-full mr-2'>
                                <img src={Logo.length == 0 || Logo == null ? GestoreAvatar : isValidURL(Logo) ? Logo : process.env.REACT_APP_SERVER_IMAGE_ENDPOINT + "/public/images/dump/small-" + Logo} className='max-w-full rounded-full object-cover shadow-sm dark:border-transparent' alt="" />
                              </div>
                              <div>
                                <p className='text-md font-medium '>{owner_name}</p>
                                <p className='text-sm font-normal text-nowrap truncate'>{public_email}</p>
                              </div>
                            </div>
                            <div className="roudned-lg bg-slate-100 dark:bg-slate-950/50 mx-2 my-2 mb-1 rounded-lg">
                              {userNavigation.map((item) => (
                                <Menu.Item key={item.name} >
                                  {({ active }) => {
                                    if(typeof item.href === "function"){
                                      return (
                                        <Link
                                          onClick={() => LogOut()}
                                          className={classNames(
                                            active ? 'bg-slate-50 dark:bg-slate-100/40' : '',
                                            'px-4 py-2 text-sm text-slate-700 dark:text-slate-100 flex leading-5 items-center first:rounded-t-lg last:rounded-b-lg' + (item.className === undefined ? "" : item.className)
                                          )}
                                        >
                                          {item.icon}
                                          {item.name}
                                        </Link>
                                      )
                                    }else{
                                      return (
                                        <Link
                                          to={item.href}
                                          className={classNames(
                                            active ? 'bg-slate-50 dark:bg-slate-100/40' : '',
                                            'px-4 py-2 text-sm text-slate-700 dark:text-slate-100 flex leading-5 items-center first:rounded-t-lg last:rounded-b-lg' + (item.className === undefined ? "" : item.className)
                                          )}
                                        >
                                          {item.icon}
                                          {item.name}
                                        </Link>
                                      )
                                    }
                                  }}
                                </Menu.Item>
                                
                              ))}
                              <Link
                                to={"https://mynuapp.it/Menu/" + seo_url}
                                target="_blank"
                                className={classNames(
                                  'px-4 py-2 text-sm flex justify-between leading-5 items-center first:rounded-t-lg last:rounded-b-lg hover:bg-slate-50 hover:dark:bg-slate-100/40 text-slate-700 dark:text-slate-100'
                                )}
                              >
                                <div className='flex items-center'>
                                  <BuildingOffice className="h-4 w-4 mr-3 " weight="duotone" aria-hidden="true" />
                                  Visualizza Menù
                                </div>
                                <ArrowBendUpRight className="h-4 w-4 mr-3 " weight="duotone" aria-hidden="true" />
                              </Link>
                              <Link
                                onClick={() => LogOut()}
                                className={classNames(
                                  'px-4 py-2 text-sm dark:text-slate-100 flex leading-5 items-center first:rounded-t-lg last:rounded-b-lg hover:bg-slate-50 hover:dark:bg-slate-100/40 text-red-500'
                                )}
                              >
                                <Door className="h-4 w-4 mr-3 text-red-500" weight="duotone" aria-hidden="true" />
                                Esci
                              </Link>
                            </div>
                              <div className=" mx-2 my-2 mb-1 rounded-lg">
                                <Link
                                  to={"/Server-status"}
                                  className={classNames(
                                    'hover:bg-slate-50 dark:hover:bg-slate-100/40 px-4 py-2 text-sm text-slate-700 dark:text-slate-100 flex leading-5 items-center first:rounded-t-lg last:rounded-b-lg justify-between '
                                  )}
                                >
                                  <div className='flex items-center'>
                                    <PresentationChart className="h-4 w-4 mr-3 text-slate-500 dark:text-slate-300" weight="duotone" aria-hidden="true" />
                                    <span>
                                      Server Status 
                                    </span>
                                  </div>
                                  {
                                    serverStat ? 
                                      <Budges className="ml-2 bg-green-50 text-green-700 ring-green-600/20 roudned-full opacity-40">Online</Budges>
                                    :
                                      <Budges className="ml-2 bg-red-50 text-red-700 ring-red-600/20 roudned-full opacity-40">Offline</Budges>
                                  }
                                </Link>
                              </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        
        </Disclosure>
  )
}
