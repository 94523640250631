import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes ,Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import './input.css';
import 'tailwindcss/tailwind.css'
// import {Provider as MenuProvider} from './components/Context';
import RequireAuth from './components/RequireAuth';
import {client} from './config.apollo';
import reportWebVitals from './reportWebVitals';
import { Login,Restore } from './pages/Auth';
import Teams from './pages/Panel/Teams'
import TeamsMemberUpdate from './pages/Panel/Teams/Update'
import Room from './pages/Panel/Room'
import {Menu, Articles, Categories, AddMenu, AddCategory, AddArticle, UpdateMenu, UpdateCatMenu, UpdateArticle} from './pages/Panel/Menu'
import Storage from './pages/Panel/Storage'
import StorageViewArticle from './pages/Panel/Storage/View'
import AddStorage from './pages/Panel/Storage/Add'
import Profile from './pages/Panel/Profile'
import Settings from './pages/Panel/Settings'
import Dashboard from './pages/Panel/Dashboard';
import FinalRestore from './pages/Auth/restoreSecondStep';
import Error404 from './pages/errors/404';
import AddMember from './pages/Panel/Teams/Components/AddMember';
import Calendar from './pages/Panel/Calendar';
import ServicesStat from './pages/Public/ServicesStat';
import ErrorBoundary from './components/ErrorBoundary';
import UpdateStorage from './pages/Panel/Storage/Update';
import Desk from './pages/Panel/Desk';

import { Provider } from 'react-redux'; 
import { PersistGate } from 'redux-persist/integration/react'; 
import { store, persistor } from './redux/store';
import './assets/css/g.style.css';

document.documentElement.classList.toggle(
	'dark',
	localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
)
document.documentElement.classList.toggle(
	'light',
	localStorage.theme === 'light' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: light)').matches)
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<Provider store={store}> 
				<PersistGate loading={null} persistor={persistor}>
					<BrowserRouter>
						<ErrorBoundary>
							{/* <MenuProvider> */}
								<Routes>
									<Route path="/" element={<RequireAuth Component={Dashboard} page={true} restricted={false} />} />
									<Route path="/Rooms" element={<RequireAuth Component={Dashboard} page={true} restricted={false} />} />
									<Route path="/Room/Add" element={<RequireAuth Component={Room} page={true} restricted={false} />} />
									<Route path="/Room/:id" element={<RequireAuth Component={Room} page={true} restricted={false} />} />
									<Route path="/Teams" element={<RequireAuth Component={Teams} page={true} restricted={false} />} />
									<Route path="/Teams/:username" element={<RequireAuth Component={Teams} page={true} restricted={false} />} />
									<Route path="/Teams/Update/:username" element={<RequireAuth Component={TeamsMemberUpdate} page={true} restricted={false} />} />
									<Route path="/Teams/Add-member" element={<RequireAuth Component={AddMember} page={true} restricted={false} />} />
									<Route path="/Menu" element={<RequireAuth Component={Menu} page={true} restricted={false} />} />
									<Route path="/Menu/Add" element={<RequireAuth Component={AddMenu} page={true} restricted={false} />} />
									<Route path="/Menu/Update/:id" element={<RequireAuth Component={UpdateMenu} page={true} restricted={false} />} />
									<Route path="/Menu/Categories" element={<RequireAuth Component={Categories} page={true} restricted={false} />} />
									<Route path="/Menu/Categories/Update/:id" element={<RequireAuth Component={UpdateCatMenu} page={true} restricted={false} />} />
									<Route path="/Menu/Categories/Add" element={<RequireAuth Component={AddCategory} page={true} restricted={false} />} />
									<Route path="/Menu/Articles" element={<RequireAuth Component={Articles} page={true} restricted={false} />} />
									<Route path="/Menu/Articles/Search/*" element={<RequireAuth Component={Articles} page={true} restricted={false} />} />
									<Route path="/Menu/Articles/Add" element={<RequireAuth Component={AddArticle} page={true} restricted={false} />} />
									<Route path="/Menu/Articles/Update/:id" element={<RequireAuth Component={UpdateArticle} page={true} restricted={false} />} />
									<Route path="/Coupons" element={<RequireAuth Component={Dashboard} page={true} restricted={false} />} />
									<Route path="/Storage" element={<RequireAuth Component={Storage} page={true} restricted={false} />} />
									<Route path="/Storage/View/:id" element={<RequireAuth Component={StorageViewArticle} page={true} restricted={false} />} />
									<Route path="/Storage/Update/:id" element={<RequireAuth Component={UpdateStorage} page={true} restricted={false} />} />
									<Route path="/Storage/Add" element={<RequireAuth Component={AddStorage} page={true} restricted={false} />} />
									<Route path="/Calendar" element={<RequireAuth Component={Calendar} page={true} restricted={false} topBar={false} />} />
									<Route path="/Desk" element={<RequireAuth Component={Desk} page={true} restricted={false} />} />
									
									<Route path="/Profile" element={<RequireAuth Component={Profile} page={true} restricted={false} />} />
									<Route path="/Profile/Services" element={<RequireAuth Component={Profile} page={true} restricted={false} />} />
									<Route path="/Profile/Gallery" element={<RequireAuth Component={Profile} page={true} restricted={false} />} />
									<Route path="/Profile/Calendar" element={<RequireAuth Component={Profile} page={true} restricted={false} />} />
									<Route path="/Profile/Social" element={<RequireAuth Component={Profile} page={true} restricted={false} />} />
									<Route path="/Settings" element={<RequireAuth Component={Settings} page={true} restricted={false} />} />
									<Route path="/Settings/Security" element={<RequireAuth Component={Settings} page={true} restricted={false} />} />
									<Route path="/Settings/Notifications" element={<RequireAuth Component={Settings} page={true} restricted={false} />} />
									<Route path="/Settings/Updates" element={<RequireAuth Component={Settings} page={true} restricted={false} />} />
									<Route path="/Settings/System" element={<RequireAuth Component={Settings} page={true} restricted={false} />} />

									<Route path="/Auth" element={<RequireAuth Component={Login} page={true} restricted={true} />} />
									<Route path="/Auth/Restore" element={<RequireAuth Component={Restore} page={true} restricted={false} />} />
									<Route path="/Auth/Restore/:token" element={<RequireAuth Component={FinalRestore} page={true} restricted={false} />} />

									<Route path="/Server-status" element={<ServicesStat />} />

									<Route path="/*" element={<RequireAuth Component={Error404} page={true} restricted={false} />} />
								</Routes>
							{/* </MenuProvider> */}
						</ErrorBoundary>
					</BrowserRouter>
				</PersistGate>
			</Provider>
		</ApolloProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();