import React, {useState} from 'react'
import toast, { Toaster } from 'react-hot-toast';
import {X, Check , ExclamationMark, MoonStars ,SunDim } from '@phosphor-icons/react';
import { gql } from '@apollo/client';
import {client} from '../../config.apollo';
import Logo from '../../assets/images/logos/logo.svg.js';
import Loading from '../../assets/svgs/loading.jsx';
import { Link } from 'react-router-dom';


export const Restore = () =>{
    const [email, setEmail] = useState("");
    const [toggleTheme, setToggleTheme] = useState(localStorage.getItem('theme') == "light" ? true : false);
    const [authLoading, setAuthLoading] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.currentTarget.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(email.length <4){
            toast.error('Inserisci una email valida', {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                icon: <ExclamationMark className='text-orange-500 w-10' size={20} />,
              });
            return;
        }


        try {
            const { loading, error, data } = await client.mutate({
                mutation: gql`
                    mutation Mutation($restorePassword: RestorePassword) {
                        clientResorePassword(RestorePassword: $restorePassword) {
                            _id
                        }
                    }
                `,
                variables: { 
                    restorePassword: {
                        email: email,
                    }
                }
            });

            if(data){
                if(data.clientResorePassword){
                    toast.success("Riceverai una email all'indirizzo indicato", {
                        duration: 4000,
                        position: 'top-center',
                        // Styling
                        style: {},
                        className: 'dark:bg-slate-800 dark:text-slate-100',
                        // Custom Icon
                        icon: <Check className='text-green-500 w-10' size={20} />,
                    });
                }
            }
            
        } catch (error) {
            console.error("ERROR", error.message);
            toast.error(error.message, {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });
        }
        
    }

    const  changeTemplate = (e) =>{
        const check = e.currentTarget.checked;
        
        setToggleTheme(check);

        if(check){
            // On page load or when changing themes, best to add inline in `head` to avoid FOUC
            document.documentElement.classList.remove('dark')
            document.documentElement.classList.add('light')
            localStorage.setItem('theme', 'light')
        }else{
            document.documentElement.classList.add('dark')
            document.documentElement.classList.remove('light')
            localStorage.setItem('theme', 'dark')
        }
    }

    // if(localStorage.getItem('client') != null){
    //     window.location.href="/"
    // }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center overflow-y-hidden dark:bg-slate-800">

            <div className='flex h-screen w-full flex-col items-center md:flex-col '>
                <div className="mx-auto flex container max-w-xs md:max-w-full md:w-3/4 items-center justify-between pt-8">
                    <a href="/" className=" group  text-slate-400 hover:text-primary-500 dark:text-slate-700 dark:hover:text-primary-500 transition-colors duration-300">
                        {/* <img
                            className="mx-auto h-10 w-auto grayscale opacity-50 transition-all duration-700"
                            src={Logo}
                            alt="MynuApp"
                        /> */}
                        <Logo className="fill-slate-400 dark:fill-slate-700 group-hover:fill-red-800 transition-all duration-500" w="40px " h="40px " />
                    </a>
                <div>
                    <span className="">
                        <label htmlFor="toggleDark" className="cursor-pointer ring-1 ring-gray-600/40 block relative rounded-full p-1 overflow-hidden w-[32px] h-[32px] focus-within:outline-2 focus-within:outline-offset-4 focus-within:outline-slate-400/50 focus-within:outline-dashed">
                            <input type="checkbox" id="toggleDark" className="peer/draft sr-only" onChange={changeTemplate} checked={toggleTheme} />
                            <SunDim size={25} weight="duotone" className="text-yellow-500 absolute -top-1 transition-all duration-300 opacity-0 peer-checked/draft:top-1 peer-checked/draft:opacity-100" />
                            <MoonStars size={25} weight="duotone" className="text-yellow-500 absolute top-1 transition-all opacity-100 duration-300 peer-checked/draft:-top-1 peer-checked/draft:opacity-0" />
                        </label>
                    </span>
                </div>
            </div>
                <div className='flex h-screen w-full flex-col items-center md:flex-row '>
                    <div className='overflow-y-auto flex h-screen w-full px-6 md:mx-auto md:w-1/2 md:max-w-md lg:max-w-full lg:px-16 xl:w-1/3 xl:px-12'>
                        
                        <div className='mx-auto flex size-full max-w-xs flex-col '>
                            
                            <div className='mx-auto flex size-full max-w-xs flex-col py-0 md:py-5'>

                                <div className="w-full">
                                    <div className="w-full mb-6 text-center">
                                        <h2 className="mt-10 text-center text-4xl font-semibold leading-9 text-gray-900 dark:text-gray-100 dark:font-medium">
                                            Recupera password
                                        </h2>
                                        <p className=' text-gray-500 dark:text-slate-400 text-md mt-3'>Segui le istruzioni ricevute tramite email</p>
                                    </div>
                                    <form className="space-y-6" onSubmit={handleSubmit}>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-600 dark:text-slate-400">
                                                Email
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    onChange={handleEmailChange}
                                                    value={email}
                                                    autoComplete="email"
                                                    required
                                                    className="transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>


                                        <div>
                                            <button
                                                type="submit"
                                                disabled={authLoading}
                                                className="relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-indigo-600 disabled:bg-indigo-600/50 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', authLoading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                                                <span className={['transition-all', authLoading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Recupera password</span>
                                            </button>
                                        </div>
                                    </form>

                                    <hr className="border-slate-200 dark:border-slate-700 my-6 w-full border-t" />

                                    <p className="mt-0 flex justify-between items-center text-sm text-gray-500 dark:text-slate-400">
                                        <span className='text-slate-400 font-medium'>Falso allarme?</span>
                                        <Link to="/Auth" className="font-semibold leading-6 text-indigo-600 dark:text-indigo-500 hover:dark:text-indigo-400 hover:text-indigo-500">
                                            Accedi
                                        </Link>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Toaster />
            </div>
        </div>
    )
  }
  