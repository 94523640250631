import React,{ useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom';
import {User, ToggleRight, CalendarDots, Images, InstagramLogo} from '@phosphor-icons/react'
import { motion, AnimatePresence } from 'framer-motion';
import { General, Services, Calendar, Gallery, Social} from './SubPages';
import Image from '../../../components/Image';
import { useDispatch } from 'react-redux';
import {ClientFullInfo} from '../../../GraphQl/Conections/Query.ClientInfoProtected';
import isValidURL from '../../../middleware/isValidUrl.jsx';

const isSubRoute = (pathname) => { return pathname.startsWith('/Profile/') && pathname !== '/Profile'; };

export default function Dashboard(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const subRoute = isSubRoute(location.pathname);
    const [client, setClient] = useState({});

    const {owner_name, activity_name, Logo, activity_type} = props.user;
    
    
    useEffect(() => {
        ClientFullInfo(dispatch).then((data) => { 
            setClient(data);
        });
    }, [])

    const Render = (key, userDefault) => {

        switch (key) {
            case "/":
                return <General user={client} />
                break;
            case "/Profile/Services":
                return <Services user={client} />
                break;
            case "/Profile/Social":
                return <Social user={client} />
                break;
            case "/Profile/Calendar":
                return <Calendar user={client} />
                break;
            case "/Profile/Gallery":
                return <Gallery user={client} />
                break;
            default:
                return <General user={client} />
                break;
        }
    }

    return <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-3 '>
        <div className='grid gap-8 sm:grid-cols-12 justify-items-center md:justify-items-stretch'>
            <div className='col-span-12 sm:col-span-4 '>
                <div className='sticky top-5'>
                    <div className='flex w-full items-center gap-2 '>
                        <div className="relative flex size-14 rounded-full mr-2" role="none">
                            {/* <img src={client.Logo == null ? null : isValidURL(client.Logo) ? client.Logo : process.env.REACT_APP_SERVER_IMAGE_ENDPOINT + "/public/images/dump/small-" + client.Logo} className="max-w-full rounded-full object-cover shadow-sm dark:border-transparent w-20 h-20 bg-white" alt="" role="none" /> */}
                            <Image speedAnim={0} anim={false} src={Logo == null ? null : isValidURL(Logo) ? Logo : process.env.REACT_APP_SERVER_IMAGE_ENDPOINT + "/public/images/dump/small-" + Logo} className="max-w-full rounded-full object-cover shadow-sm dark:border-transparent"  alt="" />
                        </div>
                        <div>
                            <p className='font-semibold text-md dark:text-slate-100'>{owner_name}</p>
                            <p className="text-sm text-slate-600 dark:text-slate-300">{activity_name}</p>
                        </div>
                    </div>
                    <div className='mt-8 max-w-[240px]'>
                        <ul className="space-y-1 font-sans text-sm ">
                            <li>
                                <Link to="/Profile" className={["flex items-center gap-2 rounded-lg p-3 transition-colors duration-300", location.pathname === "/Profile" ? "text-purple-800 dark:text-purple-500 !bg-purple-500/10" : "text-slate-600 dark:text-slate-200 hover:text-slate-600 dark:hover:text-slate-200  hover:bg-slate-50/50 dark:hover:bg-white/30"].join(" ")} aria-current="page">
                                    <User weight='duotone' size="18"/>
                                    <span>General</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/Profile/Social" className={["flex items-center gap-2 rounded-lg p-3 transition-colors duration-300", location.pathname === "/Profile/Social" ? "text-purple-800 dark:text-purple-500 !bg-purple-500/10" : "text-slate-600 dark:text-slate-200 hover:text-slate-600 dark:hover:text-slate-200 hover:bg-slate-50/50 dark:hover:bg-white/30"].join(" ")} aria-current="page">
                                    <InstagramLogo weight='duotone' size="18"/>
                                    <span>Social</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/Profile/Services" className={["flex items-center gap-2 rounded-lg p-3 transition-colors duration-300", location.pathname === "/Profile/Services" ? "text-purple-800 dark:text-purple-500 !bg-purple-500/10" : "text-slate-600 dark:text-slate-200 hover:text-slate-600 dark:hover:text-slate-200 hover:bg-slate-50/50 dark:hover:bg-white/30"].join(" ")} aria-current="page">
                                    <ToggleRight weight='duotone' size="20"/>
                                    <span>Servizi</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/Profile/Calendar" className={["flex items-center gap-2 rounded-lg p-3 transition-colors duration-300", location.pathname === "/Profile/Calendar" ? "text-purple-800 dark:text-purple-500 !bg-purple-500/10" : "text-slate-600 dark:text-slate-200 hover:text-slate-600 dark:hover:text-slate-200 hover:bg-slate-50/50 dark:hover:bg-white/30"].join(" ")} aria-current="page">
                                    <CalendarDots weight='duotone' size="20"/>
                                    <span>Calendario Settimanale</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/Profile/Gallery" className={[" flex items-center gap-2 rounded-lg p-3 transition-colors duration-300", location.pathname === "/Profile/Gallery" ? "text-purple-800 dark:text-purple-500 !bg-purple-500/10" : "text-slate-600 dark:text-slate-200 hover:text-slate-600 dark:hover:text-slate-200 hover:bg-slate-50/50 dark:hover:bg-white/30"].join(" ")} aria-current="page">
                                    <Images weight='duotone' size="20"/>
                                    <span>Galleria</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='col-span-12 sm:col-span-8'>
                <div className=''>
                    
                    {
                    <div className='relative mb-24'>
                        <AnimatePresence>
                            <motion.div
                                key={location.pathname}
                                initial={{ opacity: 0, y: 20, zIndex: 1 }}
                                animate={{ opacity: 1, y: 0, zIndex: 1 }}
                                // exit={{ opacity: 0, y: -20, zIndex: 0 }}
                                transition={{ duration: 0.6 }}
                                className="relative w-full bg-white dark:bg-slate-800 dark:border-slate-900 rounded-lg border border-slate-300"
                                style={{ position: "relative", top: 0, left: 0, width: "100%" }}
                                appear={!subRoute} 
                                enter={!subRoute} 
                                exit={!subRoute}
                            >
                            {
                                Render(location.pathname, props.user)
                            }
                            </motion.div>
                        </AnimatePresence>
                    </div>
                    }

                    
                </div>
            </div>
        </div>
    </div>
}