import React, { useState } from 'react'
import { X,CheckCircle } from '@phosphor-icons/react';
import Loading from '../../../../../assets/svgs/loading.jsx';
import './style.css';
import { Toggle } from '../../../../../components/Toggles/Toggle.jsx';

export function Step6(props){
    const [active, setActive] = useState(true)

    console.log(props.data);
    
    return <div>
        
            <div className=''>
                <h1 className='text-3xl font-normal'>Riepilogo Account</h1>
                <p className='text-md font-normal text-slate-500 dark:text-slate-400 max-w-sm mt-2 leading-5'>Controlla che i dati inseriti siano corretti, dopodiché conferma.</p>
            </div>
            <div>
                <form >
                    <div className="space-y-12">
                        <div className=" my-10 w-full max-w-md ">
                            <div className="mt-5 grid grid-cols-12 gap-4">
                                <div className="sm:col-span-12">
                                    { Object.keys(props.data).map(item => {
                                        
                                        return <div className='grid grid-cols-12'>
                                            <span className='col-span-4 text-md text-slate-600 font-medium py-2 mr-4'>{item}</span>
                                            <span className='col-span-8 text-md text-slate-900 font-normal py-2'>{props.data[item] == null ? "Non impostato" : props.data[item]}</span>
                                        </div>
                                    }) }
                                </div>

                                <div className="sm:col-span-12">
                                    <label htmlFor="birthdate" className="block text-sm/6 font-medium text-slate-400">
                                        Attiva account già da ora
                                    </label>
                                    <div className="mt-2">
                                        {/* <div className="">
                                            <div className="flex items-center">
                                                <label for="toggle" className="mr-2">Attiva</label>
                                                <input type="checkbox" id="toggle" className="hidden" defaultChecked={true} onClick={() => setActive(!active)} />
                                                    <label for="toggle" className="flex items-center cursor-pointer">
                                                        <div className="relative">
                                                            <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                                                            <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                                                        </div>
                                                    </label>
                                                </div>

                                        </div> */}
                                        <div className="col-span-12 flex items-center space-x-2">
                                            <div className='text-slate-700 text-sm'>Attiva</div>
                                            <div className='flex relative'>
                                                <Toggle onClick={() => setActive(!active)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            <div className="mt-5 grid grid-cols-12 gap-4">
                                <div className="col-span-6">
                                    <button
                                        onClick={props.PreviusStep}
                                        
                                        className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-slate-100 disabled:bg-slate-600/50 px-3 py-3  font-normal leading-6 text-black shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                                    >
                                        <span className={['transition-all', "translate-y-0 opacity-100"].join(" ")}>Indietro</span>
                                    </button>
                                </div>
                                <div className="col-span-6">
                                    <button
                                        onClick={(e) => props.NextStep(e, active)}
                                        disabled={false}
                                        className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-indigo-600 disabled:bg-indigo-600/50 px-3 py-3  font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', false ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                                        <span className={['transition-all', false ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Conferma</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    </div>
}