import React from 'react';
import ApexCharts from 'react-apexcharts'
import './style.css';

class ApexChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            chartWidth: window.innerWidth,
            series: [{
                name: "STOCK ABC",
                data: props.data.data
            }],
            options: {
                
                series: [{
                    name: 'series1',
                    data: [31, 40, 28, 51, 42, 109, 100]
                }, {
                    name: 'series2',
                    data: [11, 32, 45, 32, 34, 52, 41]
                }],
                chart: {
                    toolbar: {
                        show: false 
                    },
                    height: props.height,
                    type: 'area',
                    zoom: {
                        enabled: false
                    },
                    background: false,
                },
                grid: {
                    show: false,
                    row: false,
                    column: false,
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },   
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },  
                },
                markers: false,
                dataLabels: {
                    enabled: false,

                },
                stroke: {
                    curve: 'smooth',
                    width: 2
                },
                xaxis: {
                    type: 'datetime',
                    categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
                },
                yaxis:{
                    show: false
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
                fill: {
                    colors: ['#8b5cf6'],
                },
                colors: ['#8b5cf6']
              },
        
        
        };
        
        // window.addEventListener('resize', this.handleResize);
    }


    render() {
        return <div className={this.props.className}>
            <ApexCharts options={this.state.options} series={this.state.series} type="area" width={"100%"} height={320} />
        </div>;
    }
  }

  export default ApexChart;