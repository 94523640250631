import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { MagnifyingGlass, Check, CaretRight, CaretLeft, X, MagnifyingGlassPlus, MagnifyingGlassMinus, ArrowsClockwise, ArrowsCounterClockwise } from '@phosphor-icons/react';
import { DropTable } from '../../../../components/Dropdowns';
import Menus, { ArticlesGestional, Cat_menuGestional } from '../../../../GraphQl/Conections/Query.Menus';
import { DeleteArticle } from '../../../../GraphQl/Conections/Mutation.Menus.js';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import Image from '../../../../components/Image';
import Select from '../../../../components/Select';

function isValidURL(text) {
    try {
        new URL(text);
        return true;
    } catch (e) {
        return false;
    }
}


const parsePathParams = (pathParams) => { 
    if(pathParams === undefined) return {};
    const segments = pathParams.split('/');
    const filters = {};
    for (let i = 0; i < segments.length; i += 2) {
        filters[segments[i]] = segments[i + 1];
    }
    return filters;
};

export function Articles() {
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const [itemSel, setItemSel] = useState(0);
    const [pages, setPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [maxPage, setMaxPage] = useState(10);
    const [menus, setMenus] = useState([]);
    const [deletingId, setDeletingId] = useState(null);
    const [filterSearch, setFilterSearch] = useState("");
    const [filterSearchCat, setFilterSearchCat] = useState("");
    const [filterSearchMen, setFilterSearchMen] = useState("");
    const [finalTimeOutKeyDown, setFinalTimeOutKeyDown] = useState(filterSearch);
    const totalPages = Math.ceil((totalCount) / maxPage);
    const [menuList, setMenuList] = useState([]);
    const [categoryList, setCategoriList] = useState([]);

    useEffect(() => {
        let fsm = "";
        let fsc = "";
        if(filterSearchMen){
            fsm = filterSearchMen.id;
        }

        if(filterSearchCat){
            fsc = filterSearchCat.id;
        }

        ArticlesGestional(dispatch, pages, maxPage, filterSearch, fsm, fsc).then((data) => {
            setTotalCount(data.totalCount);
            const updatedData = data.articles.map(element => {
                return { ...element, checked: false };
            });
            
            setMenus(updatedData);
        });
    }, [dispatch, pages, maxPage, finalTimeOutKeyDown, filterSearchCat, filterSearchMen]);

    useEffect(() => {
        Menus(dispatch, 0, 99, null).then((data) => {   
            let items = [];
            data.menus.forEach(element => {
                items.push({
                    id: element._id,
                    name: element.nome
                  });
            });
            setMenuList(items);
        });
    }, [dispatch])

    useEffect(() => { 
        if(filterSearchMen){ 
            Cat_menuGestional(dispatch, 0, 99, {ref_menu: filterSearchMen.name}).then((data) => {   
                let items = [];
                data.cat_menu.forEach(element => {
                    items.push({
                        id: element._id,
                        name: element.nome,
                        image: element.image
                    });
                });
                setCategoriList(items);
            });
        }
        
    }, [filterSearchMen])

    useEffect(() => {
        const handler = setTimeout(() => {
            setFinalTimeOutKeyDown(filterSearch);
        }, 1000);
        return () => {
            clearTimeout(handler);
        };
    }, [filterSearch]);

    const pagination = () => {
        let pag = [];
        for (let n = 0; n <= totalPages - 1; n++) {
            pag.push(n);
        }

        return pag.map((n) => <Link to={"/Menu#" + n} onClick={(e) => { e.preventDefault(); setPages(n) }} className={[pages == n ? "bg-violet-500 w-9 h-9 rounded-xl text-sm text-white flex justify-center items-center shadow-sm" : "bg-white hover:bg-slate-100 dark:bg-slate-800 dark:border-0 dark:text-slate-400  w-9 h-9 rounded-xl text-sm text-slate-800 flex justify-center items-center border border-slate-200"].join(" ")}>
            {n + 1}
        </Link>
        )
    }

    const paginationMin = () => {
        if (pages > 0) {
            setPages(pages - 1)
        }
    }

    const paginationPlus = () => {
        if (pages <= totalPages - 1) {
            setPages(pages + 1)
        }
    }

    const handleDelete = (e, item) => {
        e.preventDefault();
        DeleteArticle(dispatch, item._id).then(data => {
            setDeletingId(item._id);
            toast.success("Articolo eliminato", {
                duration: 4000,
                position: 'top-center',
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                icon: <Check className='text-green-500 w-10' size={20} />,
            });
            setTimeout(() => {
                setMenus(prevMenus => prevMenus.filter(menu => menu._id !== item._id));
            }, 300);

        });
    }

    const handleCheckAll = (e) => {
        const updatedItems = menus.map(item => ({ ...item, checked: e.target.checked }));
        setMenus(updatedItems);
        if (e.target.checked) {
            setItemSel(menus.length);
        } else {
            setItemSel(0);
        }

    };
    const handleCheck = (e, id) => {
        const updatedItems = menus.map(item => item._id === id ? { ...item, checked: !item.checked } : item);
        setMenus(updatedItems);

        const itemLength = updatedItems.filter(item => item.checked === true).length;

        if (itemLength >= menus.length) {
            document.querySelector('#checkAll').checked = true;
        } else {
            document.querySelector('#checkAll').checked = false;
        }

        if (e.target.checked) {
            setItemSel(itemLength);
        } else {
            setItemSel(itemLength);
        }

    }

    const filters = parsePathParams(params['*']);

    return <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-3'>
        <div className='flex justify-between'>
            <div className='flex space-x-2'>
                {/* {JSON.stringify(filters, null, 2)} */}
                <div className="mt-2 ">
                    <div className="relative mt-2 rounded-md shadow-sm group">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ">
                            <MagnifyingGlass className="text-gray-500 group-focus:text-purple-900  sm:text-lg" />
                        </div>
                        <input
                            id="filter"
                            name="filter"
                            value={filterSearch}
                            onChange={(e) => setFilterSearch(e.target.value)}
                            type="text"
                            placeholder="Filtra Menù"
                            className="pl-10 transition-all dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-inset focus:ring-1 focus:ring-slate-300 sm:text-sm sm:leading-6"
                        />
                        {
                            filterSearch.length > 0 ?
                                <button onClick={(e) => setFilterSearch("")} className="hover:bg-slate-200/50 rounded-full cursor-pointer absolute inset-y-0 right-0 flex items-center h-7 w-7 z-10 mt-1 mr-1 justify-center">
                                    <X className="text-gray-500  sm:text-lg" />
                                </button>
                                : <></>
                        }
                    </div>
                </div>
                <div className='mt-2'>
                    <div className="relative mt-2 rounded-md shadow-sm group">
                        <Select list={menuList} name="menu_select" onChange={setFilterSearchMen} placeholder="Sel. Menù" defaultValue={null} />
                    </div>
                </div>
                <div className='mt-2'>
                    <div className="relative mt-2 rounded-md shadow-sm group">
                        <Select list={categoryList} disabled={!filterSearchMen} name="menu_select" onChange={setFilterSearchCat} placeholder="Sel. Categoria" />
                    </div>
                </div>
                <div className='mt-2'>
                    <div className="relative mt-2 rounded-md shadow-sm group">
                        {
                            filterSearchCat || filterSearchMen ?
                                <button onClick={(e) => {setFilterSearchMen(""); setFilterSearchCat("")} }  className="bg-white px-3 py-2 mt-2 transition-all dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed block w-full rounded-full border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-inset focus:ring-1 focus:ring-slate-300 sm:text-sm sm:leading-6">
                                    <X className='my-[3px]' />
                                </button>
                            : null
                        }
                    </div>
                </div>
            </div>
            <div className=''>
                <select
                    name=""
                    id=""
                    onChange={(e) => setMaxPage(parseInt(e.target.value))}
                    className="mt-2 transition-all dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-inset focus:ring-1 focus:ring-slate-300 sm:text-sm sm:leading-6"
                >
                    <option value="10">10 per pagina</option>
                    <option value="25">25 per pagina</option>
                    <option value="50">50 per pagina</option>
                    <option value="100">100 per pagina</option>
                </select>
            </div>
        </div>
        <div className='grid grid-cols-12 gap-4 mt-6'>
            <div className='w-full bg-white border border-slate-100 dark:border-0 col-span-12 rounded-md shadow-md'>
                <table className='divide-slate-200 dark:divide-slate-700 min-w-full table-fixed divide-y w-full '>
                    <thead>
                        <tr>
                            <th className='text-slate-700 dark:text-slate-400 text-left font-sans font-semibold tracking-wider text-xs uppercase border-slate-200 dark:border-slate-700 last:border-e-none dark:bg-slate-800 border-r bg-white px-4 py-5 p-4 truncate'>Immagine</th>
                            <th className='text-slate-700 dark:text-slate-400 text-left font-sans font-semibold tracking-wider text-xs uppercase border-slate-200 dark:border-slate-700 last:border-e-none dark:bg-slate-800 border-r bg-white px-4 py-5 p-4 truncate'>Nome</th>
                            <th className='text-slate-700 dark:text-slate-400 text-left font-sans font-semibold tracking-wider text-xs uppercase border-slate-200 dark:border-slate-700 last:border-e-none dark:bg-slate-800 border-r bg-white px-4 py-5 p-4 truncate'>Categoria</th>
                            <th className='text-slate-700 dark:text-slate-400 text-left font-sans font-semibold tracking-wider text-xs uppercase border-slate-200 dark:border-slate-700 last:border-e-none dark:bg-slate-800 border-r bg-white px-4 py-5 p-4 truncate'>Menù</th>
                            <th className='text-slate-700 dark:text-slate-400 text-left font-sans font-semibold tracking-wider text-xs uppercase border-slate-200 dark:border-slate-700 last:border-e-none dark:bg-slate-800 border-r bg-white px-4 py-5 p-4 truncate'>Visibilità</th>
                            <th className='text-slate-700 dark:text-slate-400 font-sans font-semibold tracking-wider text-xs uppercase border-slate-200 dark:border-slate-700 last:border-e-none dark:bg-slate-800 border-r-none bg-white px-4 py-5 text-end truncate'>Action</th>
                        </tr>
                    </thead>
                    <tbody className='divide-slate-200 dark:divide-slate-700 dark:bg-slate-800 divide-y bg-white'>
                        {
                            itemSel > 0 ?
                                <tr className="">
                                    <td colSpan="5" className="font-alt whitespace-nowrap text-sm text-slate-800 dark:text-white py-4 bg-green-100 text-green-700 dark:bg-green-700 dark:text-green-100 p-4">
                                        Hai selezionato {itemSel} item dei {menus.length} items totali.
                                        <a onClick={handleCheckAll} className="outline-none hover:underline focus:underline">Clicca qui per disattivare</a>
                                    </td>
                                </tr>
                                : null
                        }
                        {
                            menus.map(item => {

                                return <tr key={item._id} className={['hover:bg-slate-50 dark:hover:bg-slate-900 transition-all duration-300  ', deletingId == item._id ? " opacity-0" : "", location.hash.replace('#', '') == item._id ? "bg-yellow-500/20 animate-pulse" : ""].join(" ")}>
                                    <td className='font-alt whitespace-nowrap text-sm text-slate-800 dark:text-white p-4 '>
                                        {
                                            item.photo ?
                                                <PhotoProvider
                                                    maskOpacity={0.5}
                                                    maskClassName="!bg-slate-800/70 dark:!bg-slate-900/80 "
                                                    toolbarRender={({ onScale, scale, rotate, onRotate }) => {
                                                        return (
                                                            <>
                                                                <div className='mx-2 flex justify-center items-center'><MagnifyingGlassPlus className="text-slate-300 cursor-pointer hover:text-white" size="21" onClick={() => onScale(scale + 1)} /></div>
                                                                <div className='mx-2 flex justify-center items-center'>
                                                                    <MagnifyingGlassMinus className="text-slate-300 cursor-pointer hover:text-white" size="21" onClick={() => onScale(scale - 1)} />
                                                                </div>
                                                                <div className='mx-2 flex justify-center items-center'>
                                                                    <ArrowsClockwise className="text-slate-300 cursor-pointer hover:text-white" size="21" onClick={() => onRotate(rotate + 90)} />
                                                                </div>
                                                                <div className='mx-2 flex justify-center items-center'>
                                                                    <ArrowsCounterClockwise className="text-slate-300 cursor-pointer hover:text-white" size="21" onClick={() => onRotate(rotate - 90)} />
                                                                </div>
                                                            </>
                                                        );
                                                    }}
                                                >
                                                    <PhotoView src={isValidURL(item.photo) ? item.photo : process.env.REACT_APP_SERVER_IMAGE_ENDPOINT + "/public/images/original/" + item.photo}>
                                                        <img src={isValidURL(item.photo) ? item.photo : process.env.REACT_APP_SERVER_IMAGE_ENDPOINT + "/public/images/dump/small-" + item.photo} style={{ objectFit: 'cover' }} className='w-8 h-8 md:w-12 md:h-12 rounded-full ' alt="" />
                                                    </PhotoView>
                                                </PhotoProvider>
                                                : <></>
                                        }
                                    </td>
                                    <td className='font-alt whitespace-nowrap text-sm text-slate-500 dark:text-white p-4 truncate'>
                                        <span className="font-medium">{item.name}</span>
                                    </td>
                                    <td className='font-alt whitespace-nowrap text-sm text-slate-800 dark:text-white p-4 truncate'>
                                        {item.cat_menu ? <Link to={"/Menu/Categories#" + item.cat_menu._id} className="font-medium text-indigo-500 hover:underline">{item.cat_menu.nome}</Link> : <></>}
                                    </td>
                                    <td className='font-alt whitespace-nowrap text-sm text-slate-800 dark:text-white p-4 truncate'>
                                        {item.cat_menu ? item.cat_menu.ref_menu ? <Link to={"/Menu#" + item.cat_menu.ref_menu._id} className="font-medium text-indigo-500 hover:underline">{item.cat_menu.ref_menu.nome}</Link> : <></> : <></>}
                                    </td>
                                    <td className='font-alt whitespace-nowrap text-sm text-slate-800 dark:text-white p-4 truncate'>
                                        {
                                            item.Show ? <Check /> : <X />
                                        }
                                    </td>
                                    <td className='font-alt whitespace-nowrap text-sm text-slate-800 dark:text-white p-4 '>
                                        <div className='flex justify-end'>
                                            <DropTable handleDelete={(e) => handleDelete(e, item)} item={item} handleEdit={"/Menu/Articles/Update/" + item._id} />
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
        <div className='flex justify-between mt-6 md:flex-row flex-col space-y-2'>
            <div>
                <div className='border border-slate-200 dark:border-slate-600 dark:bg-slate-600 p-1 rounded-xl flex gap-1'>
                    {
                        pagination()
                    }
                </div>
            </div>
            <div>
                <div className='border border-slate-200 dark:border-slate-600 dark:bg-slate-600 p-1 rounded-xl flex gap-1'>
                    <button onClick={paginationMin} disabled={pages == 0} className=" disabled:cursor-not-allowed bg-white dark:bg-slate-800 dark:border-0 dark:text-slate-400 hover:bg-slate-100 md:w-9 w-full h-9 rounded-xl text-sm text-slate-600 flex justify-center items-center border border-slate-200 ">
                        <CaretLeft />
                    </button>
                    <button onClick={paginationPlus} disabled={pages == totalPages - 1} className=" disabled:cursor-not-allowed bg-white dark:bg-slate-800 dark:border-0 dark:text-slate-400 hover:bg-slate-100 md:w-9 w-full h-9 rounded-xl text-sm text-slate-600 flex justify-center items-center border border-slate-200">
                        <CaretRight />
                    </button>
                </div>
            </div>
        </div>
    </div>
}