import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom';
import {X} from '@phosphor-icons/react';

export default function Modal({ isOpen, onClose, onSave, children, title, bgBlur, onCloseLabel, onCloseButtonDisabled, sizeWindow }) {
    const [animClose, setAnimClose] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    
    useEffect(() => { 
        if (isOpen) {  
            // setIsVisible(true); 
            setTimeout(() => setIsVisible(true), 300); // Durata dell'animazione 
        } else { 
            setTimeout(() => {setIsVisible(false); setAnimClose(false);}, 300); // Durata dell'animazione 
        } 
    }, [isOpen]);
    
    if (!isOpen) return null;
    if(isOpen){
        // document.querySelector('.navigation-bar').click();
        // document.documentElement.classList.remove('overflow-y-scroll');
    }

    const closeModal = () => {
        setAnimClose(true);
        setTimeout(() => {
            onClose();
        }, 300);
    }

    const saveModal = () => {
        setAnimClose(true);
        setTimeout(() => {
            onSave();
        }, 300);
    }

    return ReactDOM.createPortal(<div className={['fixed inset-0 flex items-center justify-center z-50 transition-all duration-300', isVisible ? "opacity-100" : "opacity-0"].join(" ")}>
        <div className={['bg-slate-800/70 dark:bg-slate-900/80 z-10 fixed inset-0 bg-black transition-all duration-300', animClose ? "opacity-0" : "opacity-100", bgBlur ? "backdrop-blur-sm" : "backdrop-blur-none"].join(" ")} onClick={closeModal}></div>
        <div className={["dark:bg-slate-800 w-full bg-white text-left align-middle shadow-xl transition-all duration-300 rounded-md z-20", animClose ? "opacity-0" : "opacity-100" , sizeWindow ? sizeWindow : "max-w-md"].join(" ")}> 
            <div className="flex w-full items-center justify-between p-4 md:p-6">
                <h3 className="font-heading text-slate-900 text-lg font-medium leading-6 dark:text-white">{title}</h3>
                <button onClick={closeModal} type="button" className=" rounded-full hover:bg-slate-100 p-2 text-slate-700 outline-slate-400 outline-2 outline-offset-4 outline-dashed">
                    <X size="16" weight='bold' />
                </button>
            </div>
            <div className='p-4 md:p-6'>
                {children}
            </div>
            <div className='flex w-full items-center gap-x-2 justify-between'>
                {
                    onCloseButtonDisabled ? 
                    null : 
                        <div className="p-4 md:p-6">
                            <div className="flex gap-x-2">
                                <button onClick={closeModal} className='rounded-md py-2 px-5 text-slate-800 bg-white border border-slate-300 w-24 hover:bg-slate-50'>{onCloseLabel ? onCloseLabel : "Annulla"}</button>
                            </div>
                        </div>
                }
                {
                    onSave ? 
                        <div className='p-4 md:p-6'>
                            <div className="flex gap-x-2">
                                <button onClick={saveModal} className='rounded-md py-2 px-5 text-slate-100 bg-violet-500 border-violet-500 hover:bg-violet-500/50 hover:border-violet-500/50 w-24'>Salva</button>
                            </div>
                        </div>
                    : null
                }

            </div>
        </div>
    </div>, document.body // Monta il componente nel body
    )
}