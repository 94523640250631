import React, { useState } from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  transition: opacity ${(props) => (props.speedAnim ? props.speedAnim : 0.5)}s ease-in-out;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  filter: blur(10px);
  transition: opacity ${(props) => (props.speedAnim ? props.speedAnim : 0.5)}s ease-in-out;
  opacity: ${(props) => (props.loaded ? 0 : 1)};
`;

const Image = ({ src, alt, className, speedAnim, anim }) => {
  const [loaded, setLoaded] = useState(anim ? false : true);

  return (
    <ImageContainer>
      <StyledImage
        src={src}
        alt={alt}
        className={className}
        speedAnim={speedAnim}
        loaded={loaded}
        onLoad={() => setLoaded(true)}
      />
      <Placeholder loaded={loaded} speedAnim={speedAnim} />
    </ImageContainer>
  );
};

export default Image;
