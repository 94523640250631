import React from 'react'
import { Link } from 'react-router-dom';
import { UsersFour,ListDashes,ExclamationMark,CurrencyEur } from '@phosphor-icons/react';
import { Link as IconLink } from '@phosphor-icons/react';
import { WithDot } from '../../../../../components/badgets/withdot';

export default function Desk(props) {
    console.log(props.concat);
    return <div className={[" rounded-md min-h-52 shadow-lg relative p-4 cursor-pointer select-none bg-white"].join(" ")} style={props.concat != undefined ? props.concat : {}}>
            <div className="absolute top-2 left-2"> 
                {
                    props.concat ? <span className=''><IconLink className='text-gray-700 mr-1' /> </span> : ""
                }
            </div>
            <div className="absolute top-2 right-2">
                {
                    props.status == "end" ? 
                        <WithDot name="Richiesta conto" type={"allert"} className="" />
                    : props.status == "libero" ? 
                        <WithDot name="Libero" type={"success"} className="ml-2" />   
                    : props.status == "prenot" ? 
                        <WithDot name="Prenotato" type={"info"} className="ml-2" />   
                    : props.status == "occupied" ? 
                        <WithDot name="Occupato" type={"error"} className="ml-2" />   
                    : ""
                }
                {
                    /* props.active ? <WithDot name="Attivo" type={"success"} className="ml-2" /> : <WithDot name="Non disponibile" type={"error"} className="ml-2" />    */
                }
            </div>
            <div className='mt-7'>
                <p className='text-center font-semibold text-md'>{props.name}</p>

                <div className='grid gap-1 grid-cols-2 mt-2'>
                    <div className='flex items-center'>
                        <UsersFour className='text-gray-600 mr-2' size="20" />
                        <span>Ospiti <b>{props.clients}</b></span>
                    </div>
                    <div className='flex items-center'>
                        <ListDashes className='text-gray-600 mr-2' size="20" />
                        <span>Ordini <b>{props.orders}</b></span>
                    </div>
                </div>
                <div className='grid gap-1 grid-cols-2 mt-2 hidden'>
                    <div className='flex items-center'>
                        <ExclamationMark className='text-gray-600 mr-2' size="20" />
                        <span>Intolleranze <b>{props.int}</b></span>
                    </div>
                    <div className='flex items-center'>
                        <ExclamationMark className='text-gray-600 mr-2' size="20" />
                        <span>Allergie <b>{props.all}</b></span>
                    </div>
                </div>
                <div className='grid gap-1 grid-cols-1 my-5'>
                    <div className='flex items-center justify-center '>
                        <Link to={props.link} className="py-1 px-4 bg-green-300 text-sm rounded-md cursor-pointer pointer-events-auto">Mostra tavolo</Link>
                    </div>
                </div>
                <div className='grid gap-1 grid-cols-2 mt-2'>
                    <div className='flex items-center '>
                        <CurrencyEur className='text-gray-600 mr-2' size="20" />
                        <span>Tot. <b>{props.tot}</b></span>
                    </div>
                </div>
            </div>
            
        </div>
}