import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import {Textbox ,Notepad,Check,X,CurrencyEur,Plus,Chalkboard,Info } from '@phosphor-icons/react';
import { Budges } from '../../../../components/badgets/normal.jsx';
import {Toggle} from '../../../../components/Toggles';
import Loading from '../../../../assets/svgs/loading.jsx';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Select from '../../../../components/Select';
import SingleUpload from '../../../../components/Uploader/single.jsx';
import { useDispatch } from 'react-redux';
import Menus, {Cat_menuGestional} from '../../../../GraphQl/Conections/Query.Menus';
import Allergens from '../../../../GraphQl/Conections/Query.Allergens';
import {StoreArticle} from '../../../../GraphQl/Conections/Mutation.Menus.js';
import UploadFile from '../../../../GraphQl/Conections/Mutation.UploadFile.jsx';
import ReactSelect from 'react-select'
import {TagsComplete} from '../../../../components/badgets';
import makeAnimated from 'react-select/animated';
import Autocomplete from '../../../../components/Autocomplete';
import Modal from '../../../../components/Modal';
import getStorageList from '../../../../GraphQl/Conections/Query.Storage.js';
import {UpdateArticle} from '../../../../GraphQl/Conections/Mutation.Storage.js';

const options = [
  { value: 'apple', label: 'Apple' },
  { value: 'banana', label: 'Banana' },
  { value: 'cherry', label: 'Cherry' },
  { value: 'date', label: 'Date' },
  { value: 'elderberry', label: 'Elderberry' },
  // Aggiungi altre opzioni qui
];


const IngredientsStorage = ({id, options, itemCallback, removeItemCallback}) => {

    const [quantity, setQuantity] = useState(0);
    const [maxQuantity, setMaxQuantity] = useState(0);
    const [item, setItem] = useState(null);
    const [unity, setUnity] = useState(null);

    const autoCompleteChange = (e) => {
        if(e == undefined) return;
        setMaxQuantity(e.quantity)
        setUnity(e.unity);
        setItem(e);
    };

    useEffect(() => {
        itemCallback(parseFloat(quantity), item);
    }, [quantity, item])

    
    return <>
                <div className='col-span-6'>
                    <label htmlFor="ingredients2" className="text-sm/6 font-medium text-slate-600 dark:text-slate-200 flex justify-between items-center">
                        <span>Ingrediente</span>
                    </label>
                    <div className="mt-2">
                        {/* <ReactSelect 
                            className='bg-white px-1 py-1 transition-all dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:text-sm '
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[options[1], options[2]]}
                            isMulti
                            options={options}                          
                        /> */}
                        <Autocomplete options={options} defaults={[]} onChange={autoCompleteChange} />
                    </div>
                </div>
                <div className='col-span-6 grid grid-cols-12 gap-4'>
                    <div className='col-span-5'>
                        <label htmlFor="ingredients2" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Quantità
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="price"
                                    name="price"
                                    required
                                    onChange={(e) => { setQuantity(e.target.value) }}
                                    type="number"
                                    min="0"
                                    step={.5}
                                    max={maxQuantity}
                                    placeholder="0"
                                    defaultValue={0}
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-slate-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <Chalkboard  className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                    <div className='col-span-6'>
                        <label htmlFor="ingredients2" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Unità
                        </label>
                        <div className="mt-2">
                            <Select list={[{
                                id: "pz",
                                name: "Pezzo"
                            },{
                                id: "kg",
                                name: "KG"
                            }, {
                                id: "lt",
                                name: "Litri"
                            }]} name="menu_select" placeholder="Seleziona Unità" onChange={(e) => console.log(e)} defaultValue={unity} />
                        </div>
                    </div>
                    <div className='col-span-1'>
                        <label htmlFor="ingredients2" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            &nbsp;
                        </label>
                        <div className="flex justify-center items-center mt-[10px]">
                            <button onClick={() => removeItemCallback(id)} className={[id == 0 ? "hidden" : "", "flex justify-center items-center px-2 py-2"].join(" ")}><X className='text-primary' weigth="bold" data-tooltip-id="tooltip" data-tooltip-content="Rimuovi ingrediente dalla lista" /></button>
                        </div>
                    </div>
                </div>
            </>
}

export function AddArticle(props) { 
    const dispatch = useDispatch();    
    const navigate = useNavigate();
    const animatedComponents = makeAnimated();
    const bozze = JSON.parse(localStorage.getItem('article_bozze'));

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(bozze ? bozze.name : "");
    const [getAllergens, setAllergens] = useState([]);
    const [allergenList, setAllergenList] = useState(bozze ? bozze.allergen : []);
    const [menu, setMenu] = useState(bozze ? bozze.menu : "");
    const [category, setCategory] = useState(bozze ? bozze.category : "");
    const [note, setNote] = useState(bozze ? bozze.note : "");
    const [order, setOrder] = useState(null);
    const [price, setPrice] = useState(bozze ? bozze.price : "");
    const [image, setImage] = useState();
    const [menuList, setMenuList] = useState([]);
    const [categoryList, setCategoriList] = useState([]);
    const [ingredientsStorage, setIngredientsStorage] = useState([{
        id: 0
    }]);
    const [glutenfree, setGluten] = useState(bozze ? bozze.glutenfree : false);
    const [lactosefree, setLactose] = useState(bozze ? bozze.lactosefree : false);
    const [vegan_ok, setVegan] = useState(bozze ? bozze.vegan_ok : false);
    const [vegetarian_ok, setVegetarian] = useState(bozze ? bozze.vegetarian_ok : false);
    const [frozen, setFrozen] = useState(bozze ? bozze.frozen : false);
    const [prodotto_fresco_active, setFresco] = useState(bozze ? bozze.prodotto_fresco_active : false);
    const [visibletoggle, setVisibleToggle] = useState(bozze ? bozze.visibletoggle : true);
    const [ingredients, setIngredients] = useState(bozze ? bozze.ingredients: []);
    const [modal, setModal] = useState(false);
    const [storage, setStorage ] = useState([]);
    const [ingredientstorage, setIngredientStorage ] = useState([]);

    useEffect(() => {
        Menus(dispatch, 0, 99, null).then((data) => {   
            let items = [];
            data.menus.forEach(element => {
                items.push({
                    id: element._id,
                    name: element.nome
                  });
            });
            setMenuList(items);
        });
        Allergens(dispatch, null).then((data) => {   
            let items = [];
            setAllergens(data);
        });
        getStorageList(dispatch, 0, 100, "", "").then((data) => { 
            let items = [];
            
            if(data == null || data.storage.length == 0) return;
            data.storage.forEach(element => {
                items.push({
                    value: element._id,
                    label: element.name, 
                    quantity: element.quantity,
                    maxQuantity: element.maxQuantity,
                    unity: element.unity
                });
            });
            setStorage(items);
        });

        localStorage.removeItem('article_bozze');
    }, [])

    useEffect(() => {
        if(menu){
            Cat_menuGestional(dispatch, 0, 99, {ref_menu: menu.name}).then((data) => {   
                let items = [];
                data.cat_menu.forEach(element => {
                    items.push({
                        id: element._id,
                        name: element.nome,
                        image: element.image
                      });
                });
                setCategoriList(items);
            });
        }
        
    }, [menu])

    // useEffect(() => {
    //     localStorage.setItem('article_bozze', JSON.stringify({
    //         name: name, 
    //         menu: menu, 
    //         category:category, 
    //         note: note, 
    //         allergen: allergenList, 
    //         price: price, 
    //         ingredients: ingredients,
    //         glutenfree: glutenfree,
    //         lactosefree: lactosefree,
    //         vegan_ok: vegan_ok,
    //         vegetarian_ok: vegetarian_ok,
    //         frozen: frozen,
    //         prodotto_fresco_active: prodotto_fresco_active,
    //         visibletoggle: visibletoggle
    //     }))

    //     console.log(menu);
    // }, [name, menu, category, allergenList, price, ingredients, note,glutenfree,lactosefree,vegan_ok,vegetarian_ok,frozen,prodotto_fresco_active,visibletoggle])

    const handleConferme = () => {


        if(name.length === 0){
            toast.error("Imposta un nome per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        if(menu.length === 0 && category.length === 0){
            toast.error("Imposta un menù ed una categoria per procedere", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        if(props.user.storageActive){
            if(ingredientstorage.filter(item => item.quantity == 0).length > 0){
                toast.error("Setta una quantità sull'ingrediente dal tuo magazzino", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: 'dark:bg-slate-800 dark:text-slate-100',
                    // Custom Icon
                    icon: <X className='text-red-500 w-10' size={20} />,
                });
    
                return;
            }
        }

        setLoading(true);

        if(image){
             //Per prima cosa invia l'immagine a graphql e poi inserisci nel db tutto il resto.
            UploadFile(dispatch, image).then(data => {
                //Setta categoria nel db 
                handleOk(data.uploadFile);
            })
        }else{
            handleOk(null);
        }
       
       
    }

    const handleOk = (image) => {

        StoreArticle(dispatch, {nome: name, menu: menu.id, 
                                    category:category.id, 
                                    note: note, 
                                    allergen: allergenList, 
                                    price: parseFloat(price), 
                                    ingredients: ingredients,
                                    ingredientsStorage: ingredientstorage.map(({ maxQuantity, ...rest }) => rest),
                                    glutenfree: glutenfree,
                                    lactosefree: lactosefree,
                                    vegan_ok: vegan_ok,
                                    vegetarian_ok: vegetarian_ok,
                                    frozen: frozen,
                                    prodotto_fresco_active: prodotto_fresco_active,
                                    visibletoggle: visibletoggle, 
                                    image: image
                                }).then(data => { 
            setTimeout(() => {

                toast.success("Articolo creato", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: 'dark:bg-slate-800 dark:text-slate-100',
                    // Custom Icon
                    icon: <Check className='text-green-500 w-10' size={20} />,
                });

                setLoading(false);
                setName("");
                setAllergenList([]);
                setMenu("");
                setCategory("");
                setNote("");
                setPrice("");
                setImage("");
                setIngredientsStorage([]);
                setGluten(false);
                setLactose(false);
                setVegan(false);
                setVegetarian(false);
                setFrozen(false);
                setFresco(false);
                setVisibleToggle(true);
                setIngredients([]);
                setIngredientStorage([]);
                

                // if(ingredientstorage.length > 0){
                //     let n = 0;
                //     ingredientstorage.forEach(element => {
                //         UpdateArticle(dispatch, {
                //             _id: element.id,
                //             quantity: parseInt(element.maxQuantity - element.quantity)
                //         });
                //         n++;
                //     });
                    
                //     if(n == ingredientstorage.length){
                //         navigate("/Menu/Articles");
                //     }
                // }else{
                    navigate("/Menu/Articles");
                // }

                
            }, 1000);
        });
    }

    const handleAllergens = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setAllergenList((prevItems) => [...prevItems, value]);
        } else {
            setAllergenList((prevItems) => prevItems.filter((item) => item !== value));
        }
    };

    const handleAddIngredientStorage = () => {
        const newStorage = { id: ingredientsStorage.length + 1 };
        setIngredientsStorage((prevStorage) => [...prevStorage, newStorage]);
    }
    const removeIngredientStorage = (id) => {
        setIngredientsStorage((prevStorage) =>
            prevStorage.filter((item) => item.id !== id)
        );
    }

    const handleIngredients = (e) => {
        setIngredients(e);
    }
    const setArticleStorage = (quantity, item) => {
        if(item == undefined) return;
    
        const newStorage = { id: item.value, name: item.label, quantity: quantity, maxQuantity: item.maxQuantity };
        setIngredientStorage((prevStorage) => {
            const existingItemIndex = prevStorage.findIndex(storage => storage.id === newStorage.id);
            if (existingItemIndex !== -1) {
                const updatedStorage = [...prevStorage];
                updatedStorage[existingItemIndex] = {
                    ...updatedStorage[existingItemIndex],
                    quantity: newStorage.quantity
                };
                return updatedStorage;
            } else {
                return [...prevStorage, newStorage];
            }
        });


        setIngredients((prevStorage) => {
            if (!prevStorage.includes(item.label)) {
              return [...prevStorage, item.label];
            }
            return prevStorage;
        });          
    }


    return <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-3'>
        <div className='mx-auto flex w-full max-w-2xl flex-col'>
            <div className='space-y-5 mt-10'>
                <div>
                    <p className="text-lg font-medium dark:text-slate-200">
                        Foto 
                    </p>
                    <p className="text-xs font-normal text-slate-600 dark:text-slate-300">Arricchisci il tuo menù aggiungendo una foto al tuo prodotto</p>
                </div>
                <div className='relative flex flex-col items-center justify-center gap-4'>
                    <SingleUpload multiple={false} removeImage={() => {}} files={(file) => setImage(file[0])} />
                </div>
            </div>
            <div className="space-y-1 mt-10">
                <fieldset className='relative'>
                    <legend className="mb-6">
                        <p className="text-lg font-medium dark:text-slate-200" tag="h3">Aggiungi un nuovo articolo</p>
                        <span className="text-xs font-normal text-slate-600 dark:text-slate-300">Inserisci i dettagli del nuovo articolo</span>
                    </legend>
                </fieldset>
                <div className='grid grid-cols-12 gap-4'>
                    <div className="col-span-12">
                        <label htmlFor="name" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Nome Articolo
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white px-2 transition-all  dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="name"
                                    name="name"
                                    required
                                    onChange={(e) => { setName(e.target.value) }}
                                    defaultValue={name}
                                    type="text"
                                    placeholder="Ex: Pizza Margherita"
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1  dark:text-slate-100 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <Notepad className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6">
                        <label htmlFor="menu_select" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Menù
                        </label>
                        <div className="mt-2">
                            <Select list={menuList} name="menu_select" onChange={setMenu} defaultValue={menu} />
                        </div>
                    </div>
                    <div className="col-span-6">
                        <label htmlFor="cat_select" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Categoria
                        </label>
                        <div className="mt-2">
                            <Select list={categoryList} name="cat_select" disabled={!menu} onChange={setCategory} defaultValue={category} />
                        </div>
                    </div>

                    <div className="col-span-12">
                        <label htmlFor="order" className="flex text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            <span>Note Prodotto</span>
                        </label>
                        <div className="mt-2">
                            <div className='flex relative'>
                                <textarea
                                    id="descriptions"
                                    name="descriptions"
                                    type="text"
                                    placeholder="Breve descrizione del prodotto (opzionale)"
                                    required
                                    onChange={(e) => setNote(e.target.value)}
                                    rows={4}
                                    className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >{note}</textarea>
                                <label htmlFor="descriptions" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                    <Textbox  className=' absolute top-2 left-2' size="20" weight='duotone' />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-12 mt-4">
                        <label htmlFor="order" className="flex text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            <span>Allergeni</span>
                        </label> 
                        <div className="grid grid-cols-12 gap-2 mt-2">
                            {
                                getAllergens.map(item => { 
                                    return <div className='col-span-4 '>
                                            <label className=" space-x-2 flex  items-center">
                                                <input name="client_type_filter" onChange={handleAllergens} defaultChecked={allergenList ? allergenList.filter(e => e == item._id).length > 0 : false} className="peer transition-all dark:text-slate-600 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed rounded-sm border-0 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600" type="checkbox" value={item._id} />
                                                <span className='peer-disabled:text-slate-500 dark:text-slate-300'>{item.title}</span>
                                            </label>
                                        </div>
                                })
                            }
                        </div>
                    </div>
                    <div className="col-span-12">
                        <label htmlFor="price" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                            Prezzo Articolo
                        </label>
                        <div className="mt-2">
                            <div className="flex gap-2 justify-center items-center bg-white px-2 transition-all  dark:bg-slate-900  focus-within:outline-slate-400 focus-within:outline-offset-4 focus-within:outline-dashed w-full rounded-md border-0 py-0 dark:text-slate-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <input
                                    id="price"
                                    name="price"
                                    required
                                    onChange={(e) => { setPrice(e.target.value) }}
                                    type="number"
                                    min="0"
                                    step=".01"
                                    max="99"
                                    placeholder="0"
                                    defaultValue={price}
                                    className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-slate-100 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                />
                                <CurrencyEur className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 grid grid-cols-12 gap-4">
                        <div className='col-span-12 flex justify-between'>
                            <div  className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                Ingredienti
                            </div>
                        </div>
                        <TagsComplete tags={handleIngredients} setTags={ingredients} />
                    </div>
                    <div className="col-span-12 grid grid-cols-12 gap-4">
                        <div className='col-span-12 flex justify-between'>
                            <div  className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                Ingredienti da magazzino
                            </div>
                            <div className="text-sm/6 font-medium text-slate-600 dark:text-slate-200 flex justify-center items-center space-x-2">
                                <button onClick={handleAddIngredientStorage} className='hover:text-indigo-600' data-tooltip-id="tooltip" data-tooltip-content="Aggiungendo un articolo come ingrediente sarà scalato all'interno del magazzino la quantità utilizzata in base all'unità"> 
                                    <Info size={20} className />
                                </button>
                                <button onClick={handleAddIngredientStorage} className='hover:text-indigo-600' data-tooltip-id="tooltip" data-tooltip-content="Clicca per aggiungere ingredienti dal tuo magazzino"> 
                                    <Plus size={20} className />
                                </button>
                            </div>
                        </div>
                        
                        {
                            ingredientsStorage.map((item, index) => <IngredientsStorage id={item.id} options={storage} itemCallback={setArticleStorage} removeItemCallback={removeIngredientStorage} />)
                        }
                    </div>
                    <div className="col-span-12 space-y-1 mt-10">
                        <fieldset className='relative'>
                            <legend className="mb-6">
                                <p className="text-lg font-medium dark:text-slate-200" tag="h3">Toggles</p>
                                <span className="text-xs font-normal text-slate-600 dark:text-slate-300">Attiva servizi supplementari</span>
                            </legend>
                        </fieldset>
                        <div className="grid grid-cols-12">
                            <div className='col-span-4'>
                                <label htmlFor="glutenfree" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Senza glutine
                                </label>
                                <div className="mt-2">
                                    <Toggle name="glutenfree" status={glutenfree} onClick={(e) => setGluten(e.target.checked)} />
                                </div>
                            </div>
                            <div className='col-span-4'>
                                <label htmlFor="lactosefree" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Senza lattosio
                                </label>
                                <div className="mt-2">
                                    <Toggle name="lactosefree" status={lactosefree} onClick={(e) => setLactose(e.target.checked)} />
                                </div>
                            </div>
                            <div className='col-span-4'>
                                <label htmlFor="vegan_ok" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Prodotto vegano
                                </label>
                                <div className="mt-2">
                                    <Toggle name="vegan_ok" status={vegan_ok} onClick={(e) => setVegan(e.target.checked)} />
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12">
                            <div className='col-span-4'>
                                <label htmlFor="vegetarian_ok" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Prodotto Vegetariano
                                </label>
                                <div className="mt-2">
                                    <Toggle name="vegetarian_ok" status={vegetarian_ok} onClick={(e) => setVegetarian(e.target.checked)} />
                                </div>
                            </div>
                            <div className='col-span-4'>
                                <label htmlFor="frozen" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Prodotto congelato
                                </label>
                                <div className="mt-2">
                                    <Toggle name="frozen" status={frozen} onClick={(e) => setFrozen(e.target.checked)} />
                                </div>
                            </div>
                            <div className='col-span-4'>
                                <label htmlFor="prodotto_fresco_active" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Prodotto fresco
                                </label>
                                <div className="mt-2">
                                    <Toggle name="prodotto_fresco_active" status={prodotto_fresco_active} onClick={(e) => setFresco(e.target.checked)} />
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12">
                            <div className='col-span-12'>
                                <label htmlFor="visibletoggle" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Rendi visibile articolo nel menù
                                </label>
                                <div className="mt-2">
                                    <Toggle name="visibletoggle" status={true} onClick={(e) => setVisibleToggle(e.target.checked)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="mt-10 grid grid-cols-12 gap-4">
                    <div className="col-span-6">
                        <Link
                            to="/Menu"
                            onClick={(e) => {e.preventDefault(); setModal(true) }}
                            className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-slate-200 disabled:bg-slate-600/50 px-3 py-3  font-normal leading-6 text-black shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                        >
                            <span className={['transition-all', "translate-y-0 opacity-100"].join(" ")}>Annulla</span>
                        </Link>
                    </div>
                    <div className="col-span-6">
                        <button
                            onClick={handleConferme}
                            disabled={loading || name.length == 0 || menu.length == 0}
                            className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-indigo-600 disabled:bg-indigo-600/50 px-3 py-3  font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                            <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Conferma</span>
                        </button>
                    </div>
                </div>
        </div>
        <Toaster />
        <Modal isOpen={modal} onClose={() => setModal(false)} onSave={() => {localStorage.removeItem('article_bozze'); navigate('/Menu/Articles') }} title={'Vuoi eliminare i salvataggi?'}>
            <p className='text-md dark:text-slate-200 '>Confermando questo popup confermi l'eliminazione dei dati autosalvati durante la compilazione di questo form.</p>
        </Modal>
    </div>
}