
import React, { useEffect, useState, useRef } from 'react'
import MynuLogo from '../../assets/images/previewImage.webp';
import {Plus,Image, X} from '@phosphor-icons/react';
import ImagePrev from '../Image'

const SingleUpload = (props) => {
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [image, setImage] = useState(null);
    const dragAndDrop = props.dragAndDrop  != undefined ? props.dragAndDrop : true;

    const dragg = (files) => {
        setFiles(files);
    }
    const inputDrop = e => {
        setFiles(e.target.files);
    }

    const removeImage = () => {
        setFiles([]);
        setImage(null);
        props.removeImage(true);
    }

    useEffect(() => {
        if(props.multiple == false){
            if(files === undefined) return;
            const file = files[0]; 
            if (file) { 
                const reader = new FileReader(); 
                reader.onloadend = () => { 
                    setImage(reader.result); 
                }; 
                reader.readAsDataURL(file); 
                props.files(files);
            }
        }else{
            console.error("Non esiste l'opzione multipla su questo uploader")
        }
    }, [files])

    useEffect(() => {
        setImage(props.defaultValue != undefined ? props.defaultValue : null);
    }, [props.defaultValue])
    

    return <div className={["group/avatar-upload relative", props.className != undefined ? props.className : ""].join(" ")}>
                {
                    dragAndDrop ?
                        <DragAndDrop dragging={dragg} />
                    : <></>
                }
                <div className={["relative", props.classNameParentPreview != undefined ? props.classNameParentPreview : "size-24"].join(" ")}>
                    <ImagePrev src={image == null ? props.defaultWatermark != undefined ? props.defaultWatermark : MynuLogo : image} alt="Upload preview" speedAnim={.3} className={[props.classNamePreview != undefined ? props.classNamePreview : "bg-slate-200 select-none shadow-lg dark:bg-slate-700/60 size-24 rounded-full object-cover object-center"].join("")} />
                    <div className={["absolute bottom-0 end-0 z-20", props.classNameButtons != undefined ? props.classNameButtons : ""].join(" ")}>
                        <div className="relative" data-nui-tooltip="Upload image">
                            {
                                files.length > 0 || image != null ? 
                                    <button onClick={removeImage} type="button" data-tooltip-id="tooltip" data-tooltip-content="Rimuovi immagine" className="rounded-full bg-white border border-slate-300 p-2 select-none transition-all focus-within:outline-slate-300 focus-within:outline-offset-2 focus-within:outline-dashed">
                                        <X />
                                    </button>
                                :
                                    <button onClick={() => fileInputRef.current.click()} type="button" data-tooltip-id="tooltip" data-tooltip-content="Clicca per selezionare" className="rounded-full bg-white border border-slate-300 p-2 select-none transition-all focus-within:outline-slate-300 focus-within:outline-offset-2 focus-within:outline-dashed">
                                        <Plus />
                                    </button>
                            }

                        </div>
                    </div>
                </div>
                <input type="file" accept="image/*" onChange={inputDrop} ref={fileInputRef} className="hidden" multiple={props.multiple === undefined ? false : props.multiple} id="avatar-upload" />
            </div>
}

export default SingleUpload;


export function DragAndDrop(props){
    const [isDragging, setIsDragging] = useState(false);
    const [animDragging, setAnimDragging] = useState(false);

    useEffect(() => {
        const handleDragEnter = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(true);
            setTimeout(() => {
                setAnimDragging(true);
            }, 51);
            // console.log("enter drag");
        };

        const handleDragOver = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(true);
            // setTimeout(() => {
            //     setAnimDragging(true);
            // }, 51);
            // console.log("enter over");
        };

        const handleDrop = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setAnimDragging(false);
            setTimeout(() => {
                setIsDragging(false);
            }, 250);
            // Gestisci i file qui
            const files = e.dataTransfer.files;
            props.dragging(files);
        };

        const handleDragLeave = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(false);
            
        };

        document.addEventListener('dragenter', handleDragEnter);
        document.addEventListener('dragover', handleDragOver);
        document.addEventListener('drop', handleDrop);
        // document.addEventListener('dragleave', handleDragLeave);

        return () => {
            document.removeEventListener('dragenter', handleDragEnter);
            document.removeEventListener('dragover', handleDragOver);
            document.removeEventListener('drop', handleDrop);
            // document.removeEventListener('dragleave', handleDragLeave);
        };
    }, []);

    return (
        <div className={['fixed z-30 top-0 left-0 w-full items-center justify-center h-full bg-slate-200/30 dark:bg-slate-300/40 backdrop-blur-sm transition-opacity duration-200 ', isDragging ? "flex" : "hidden", animDragging ? "opacity-100" : "opacity-0"].join(" ")}>
            <div className="min-w-96 min-h-64 p-3 bg-slate-100 dark:bg-slate-900 absolute rounded-lg outline-slate-300 outline-offset-4 outline-dashed border border-slate-300 space-y-5 flex-col flex items-center justify-center ">
                <div>
                    <Image size={60} weight="duotone" className='text-primary' />
                </div>
                <div className='text-md text-slate-600'>Rilascia qui il tuo file</div>
            </div>
        </div>
    );
};
