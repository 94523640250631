import React from 'react'
import {At} from '@phosphor-icons/react';

export function Personal(props){
// console.log(props);
    return <div className='divide-slate-200 dark:divide-slate-800 space-y-20 py-6'>
        <div className="grid gap-8 md:grid-cols-12">
            <div className="md:col-span-4">
                <h3 className='text-md font-medium text-slate-800 dark:text-slate-100 mb-1'>Contatti</h3>
                <p className="text-xs font-normal text-slate-500 dark:text-slate-400">Dove preferisci essere contattato? </p>
            </div>
            <div className="md:col-span-8">
                <div>
                    <div>
                        <h3 className="text-xs font-medium text-slate-400 mb-2">
                            Email
                        </h3>
                    </div>
                    <div className='flex relative'>
                        
                        <input
                            id="completeName"
                            name="completeName"
                            type="text"
                            disabled
                            defaultValue={props.user ? props.user.public_email : ""}
                            placeholder='mario.rossi@mail.it'
                            required
                            className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <label htmlFor="completeName" className='order-1 peer-focus-within:text-indigo-600 text-slate-600'>
                            <At className=' absolute top-2 left-2' size="20"  />
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
}