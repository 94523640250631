import React, {useState, useEffect} from 'react'
import {FacebookLogo, TwitterLogo, InstagramLogo, TiktokLogo, YoutubeLogo,X, Check } from '@phosphor-icons/react'
import {useNavigate} from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../../../../assets/svgs/loading.jsx';
import {UpdateClientInfo} from '../../../../GraphQl/Conections/Mutation.ClientInfo.js';
import { useDispatch } from 'react-redux';

const checkChanges = (vars, user, newV = false) => { 
    const changes = {}; 
    for (const key in vars) { 
        if (vars[key] != user[key]) { 
            if(newV){
                changes[key] = vars[key]; 
            }else{
                changes[key] = { 
                    oldValue: vars[key], 
                    newValue: user[key] 
                }; 
            }
        } 
    } return changes; 
};

export function Social(props){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [scrollPosition, setScrollPosition] = useState(false);
    const [loading, setLoading] = useState(false);

    const [facebook_link, setSocialF] = useState("");
    const [tiktok_link, setSocialT] = useState("");
    const [instagram_link, setSocialI] = useState("");
    const [youtube_link, setSocialY] = useState("");

    useEffect(() => { 
        setSocialF(props.user.facebook_link != undefined ? props.user.facebook_link : "");
        setSocialT(props.user.tiktok_link != undefined ? props.user.tiktok_link : "");
        setSocialI(props.user.instagram_link != undefined ? props.user.instagram_link : "");
        setSocialY(props.user.youtube_link != undefined ? props.user.youtube_link : "");
    }, [props.user]);

    const handleScroll = () => { 
        const position = window.pageYOffset;
        const Tpos = document.querySelector('.t-pos');
        const rect = Tpos.getBoundingClientRect();
        
        setScrollPosition(rect.top < 0); 
        
    }; 
    
    useEffect(() => { 
        document.body.addEventListener('scroll', handleScroll); 
        return () => { 
            document.body.removeEventListener('scroll', handleScroll); 
        }; 
    }, []);
    

    const save = () => {

        setLoading(true);


        const vars = {
            facebook_link: facebook_link,
            tiktok_link: tiktok_link,
            instagram_link: instagram_link,
            youtube_link: youtube_link
        };
        const changes = checkChanges(vars, props.user, true); 

        UpdateClientInfo(dispatch, changes, props.user).then(data => { 
            setTimeout(() => {

                toast.success("Social aggiornati", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: 'dark:bg-slate-800 dark:text-slate-100',
                    // Custom Icon
                    icon: <Check className='text-green-500 w-10' size={20} />,
                });

                setLoading(false);
                // navigate("/Menu/Articles");
            }, 1000);
        });

    }


    return <div>
        <div className='flex items-center justify-between p-4'>
            <div>
                <p className='text-sm font-medium uppercase dark:text-slate-100'>Account Social</p>
                <p className="text-slate-400 text-xs dark:text-slate-300">Modifica i link social della tua attività</p>
            </div>
            <div className='flex items-center gap-2 t-pos'>
                <button onClick={() => navigate('/Profile')} className='rounded-md py-2 px-5 text-slate-800 bg-white border border-slate-300 w-24'>Cancella</button>
                <button
                    onClick={save}
                    disabled={loading}
                    className="rounded-md min-w-32 py-2 px-5 text-slate-100 bg-violet-500 border-violet-500 hover:bg-violet-500/50 hover:border-violet-500/50 relative"
                >
                    <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                    <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Salva</span>
                </button>
            </div>
        </div>
        <div className='p-4'>
            <div className='mx-auto max-w-lg space-y-12 py-8'>
                
                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Pagina facebook
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Fatti conoscere anche sui social</p>
                    </div>
                    <div className='relative flex flex-col  gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4'>
                            <div className="col-span-12">
                                <div className='flex relative'>
                                    <input
                                        id="facebook_link"
                                        name="facebook_link"
                                        type="text"
                                        defaultValue={facebook_link}
                                        onChange={(e) => setSocialF(e.target.value)}
                                        placeholder='Es: https://www.facebook.com/search/top?q=mynù italia'
                                        required
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="facebook_link" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                        <FacebookLogo className=' absolute top-2 left-2' size="20" weight='duotone' />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Account Instagram
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Fatti conoscere anche sui social</p>
                    </div>
                    <div className='relative flex flex-col  gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4'>
                            <div className="col-span-12">
                                <div className='flex relative'>
                                    <input
                                        id="instagram_link"
                                        name="instagram_link"
                                        type="text"
                                        defaultValue={instagram_link}
                                        onChange={(e) => setSocialI(e.target.value)}
                                        placeholder='Es: https://www.facebook.com/search/top?q=mynù italia'
                                        required
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="instagram_link" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                        <InstagramLogo className=' absolute top-2 left-2' size="20" weight='duotone' />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Account TikTok
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Fatti conoscere anche sui social</p>
                    </div>
                    <div className='relative flex flex-col  gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4'>
                            <div className="col-span-12">
                                <div className='flex relative'>
                                    <input
                                        id="tiktok_link"
                                        name="tiktok_link"
                                        defaultValue={tiktok_link}
                                        onChange={(e) => setSocialT(e.target.value)}
                                        type="text"
                                        placeholder='Es: https://www.tiktok.com/@mynuappitalia'
                                        required
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="tiktok_link" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                        <TiktokLogo className=' absolute top-2 left-2' size="20" weight='duotone' />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className='space-y-5'>
                    <div>
                       <p className="text-md font-medium dark:text-slate-100">
                            Account Youtube
                        </p>
                        <p className="text-slate-400 text-xs dark:text-slate-300">Fatti conoscere anche sui social</p>
                    </div>
                    <div className='relative flex flex-col  gap-4'>
                        
                        <div className='grid grid-cols-12 gap-4'>
                            <div className="col-span-12">
                                <div className='flex relative'>
                                    <input
                                        id="youtube_link"
                                        name="youtube_link"
                                        defaultValue={youtube_link}
                                        onChange={(e) => setSocialY(e.target.value)}
                                        type="text"
                                        placeholder='Es: https://www.youtube.com/@MynuAppItalia'
                                        required
                                        className="peer pl-9 transition-all dark:bg-slate-900  dark:text-slate-100 focus-within:outline-offset-4 focus-within:outline-slate-400 focus-within:outline-dashed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <label htmlFor="youtube_link" className='peer-focus-within:text-indigo-600 text-slate-600'>
                                        <YoutubeLogo className=' absolute top-2 left-2' size="20" weight='duotone' />
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


                <div className={[' fixed p-4 left-2/4 -translate-x-[121px] bg-white gap-4 flex border border-slate-200 rounded-lg transition-all shadow-md', scrollPosition ? "opacity-100 bottom-3" : "opacity-0 -bottom-20"].join(" ")}>
                    <button onClick={() => navigate('/Profile')} className='rounded-md py-2 px-5 text-slate-800 bg-white border border-slate-300 w-24'>Cancella</button>
                    <button
                        onClick={save}
                        disabled={loading}
                        className="rounded-md min-w-32 py-2 px-5 text-slate-100 bg-violet-500 border-violet-500 hover:bg-violet-500/50 hover:border-violet-500/50 relative"
                    >
                        <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                        <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Salva</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
}