import React, {useState, useEffect} from 'react'
import { useParams, Link, useLocation } from 'react-router-dom';
import  {GetUserTeam} from '../../../../GraphQl/Conections/Query.Teams';
import {UpdateMemberTeam} from '../../../../GraphQl/Conections/Mutation.Teams';
import { useDispatch } from 'react-redux';
import {User, Phone, City, At, GenderMale , GenderFemale, GenderNeuter, Password,Wallet, X, Check } from '@phosphor-icons/react';
import {Datepicker} from '../../../../components/Calendar';
import Select from '../../../../components/Select';
import { Toggle } from '../../../../components/Toggles/Toggle.jsx';
import Loading from '../../../../assets/svgs/loading.jsx';
import toast, { Toaster } from 'react-hot-toast';

//Link immagini avatar teams
// https://s6.imgcdn.dev/YDW18l.png
// https://s6.imgcdn.dev/YDW7kh.png
// https://s6.imgcdn.dev/YDWBrV.png
// https://s6.imgcdn.dev/YDWGqK.png
// https://s6.imgcdn.dev/YDWruo.png

export default function UpdateMember(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [name, setName] = useState(null);
    const [surname, setSurname] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [finalResidence, setFinalResidence] = useState(null);
    const [residence, setResidence] = useState(null);
    const [city, setCity] = useState(null);
    const [province, setProvince] = useState(null);
    const [cap, setCap] = useState(null);
    const [birthday, setBirthday] = useState(null);
    const [password, setPassword] = useState(null);
    const [gender, setGender] = useState(null);
    const [repeatPassword, setRepeatPassword] = useState(null);
    const [iban, setIban] = useState(null);
    const [active, setActive] = useState(null);
    const [role, setRole] = useState(null);

    useEffect(() => {
        if(params.username == null) return
        GetUserTeam(dispatch, params.username).then((data) => { 
            setImage(data.avatar);
            setName(data.name);
            setSurname(data.surname);
            setFinalResidence(data.residence);
            setBirthday(data.date_of_birth);
            setEmail(data.email);
            setPhone(data.phone);
            setGender(data.gender);
            setRole(data.role);
            setIban(data.iban);
            setActive(data.active);
        });
    }, [params.username, dispatch]);

    // useEffect(() => {
    //     console.log(`${residence}, ${cap} ${city} ${province}`);
    // }, [residence, city, province, cap]);

    useEffect(() => {
        if(finalResidence == null || finalResidence == undefined) return;
        const [residence, rest] = finalResidence.split(", ");
        if(rest == undefined) {
            setResidence(residence);
        }else {
            const [cap, city, province] = rest.split(" ");
            setResidence(residence);
            setCity(city);
            setCap(cap);
            setProvince(province);
        }
    }, [finalResidence])
    
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({block: "center", behavior: 'smooth' });
                
            }
        }
    }, [location]);

    const generatePass = () => {
        const length = 8; 
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-='; 
        let newPassword = ''; 
        for (let i = 0; i < length; i++) { 
            const randomIndex = Math.floor(Math.random() * charset.length); 
            newPassword += charset[randomIndex]; 
        } 
        setPassword(newPassword);
        document.querySelector('#password').type = "text"
    }

    const selectImage = (img) => {
        setImage(img);
    }


    const handleConferme = () => {
        if(name.length == 0){
            toast.error("Imposta un nome all'operatore", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        if(surname.length == 0){
            toast.error("Imposta un cognome all'operatore", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        if(email.length == 0){
            toast.error("Imposta un cognome all'operatore", {
                duration: 4000,
                position: 'top-center',
                // Styling
                style: {},
                className: 'dark:bg-slate-800 dark:text-slate-100',
                // Custom Icon
                icon: <X className='text-red-500 w-10' size={20} />,
            });

            return;
        }

        setLoading(true);

        UpdateMemberTeam(dispatch, {
            _id: params.username,
            avatar: image,
            name: name, 
            surname: surname,
            email: email,
            phone: phone,
            residence: `${residence}, ${cap} ${city} ${province}`,
            date_of_birth: birthday,
            password: password,
            iban: iban,
            role: role,
            gender:gender,
            active: active,
        }).then((data) => { 
            setTimeout(() => {

                toast.success("Membro aggiornato", {
                    duration: 4000,
                    position: 'top-center',
                    // Styling
                    style: {},
                    className: 'dark:bg-slate-800 dark:text-slate-100',
                    // Custom Icon
                    icon: <Check className='text-green-500 w-10' size={20} />,
                });
                setLoading(false);
            }, 1000);
        });

    }


    return (
        <div className='space-y-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 p-3 pt-8'>
            <div className="mx-auto flex w-full max-w-2xl flex-col">
                <div className='my-8 '>
                    <div className="relative grid grid-cols-12 justify-items-center">
                        <div className="col-span-6 md:col-span-3">
                            <div className={['mask-avatar mask w-14 h-14 bg-gray-700 scale-125 transition-all', image == "https://s6.imgcdn.dev/YDW18l.png" ? "opacity-100" : "opacity-0"].join(" ")}></div>
                            <div onClick={() => selectImage("https://s6.imgcdn.dev/YDW18l.png")} className={["select-none mask-avatar mask absolute top-0 inline-flex justify-center w-14 h-14 cursor-pointer hover:scale-110 transition-all", image == 1 ? "scale-110" : ""].join(" ")}>
                                <img className="w-full h-full" src={"https://s6.imgcdn.dev/YDW18l.png"} alt="" />
                            </div> 
                        </div>
                        <div className="col-span-6 md:col-span-3">
                            <div className={['mask-avatar mask w-14 h-14 bg-gray-700 scale-125 transition-all', image == "https://s6.imgcdn.dev/YDW7kh.png" ? "opacity-100" : "opacity-0"].join(" ")}></div>
                            <div onClick={() => selectImage("https://s6.imgcdn.dev/YDW7kh.png")} className={["select-none mask-avatar mask absolute top-0 inline-flex justify-center w-14 h-14 cursor-pointer hover:scale-110 transition-all", image == 2 ? "scale-110" : ""].join(" ")}>
                                <img className="w-full h-full " src={"https://s6.imgcdn.dev/YDW7kh.png"} alt="" />
                            </div> 
                        </div>
                        <div className="col-span-6 md:col-span-3">
                            <div className={['mask-avatar mask w-14 h-14 bg-gray-700 scale-125 transition-all', image == "https://s6.imgcdn.dev/YDWBrV.png" ? "opacity-100" : "opacity-0"].join(" ")}></div>
                            <div onClick={() => selectImage("https://s6.imgcdn.dev/YDWBrV.png")} className={["select-none mask-avatar mask absolute top-0 inline-flex justify-center w-14 h-14 cursor-pointer hover:scale-110 transition-all", image == 3 ? "scale-110" : ""].join(" ")}>
                                <img className="w-full h-full" src={"https://s6.imgcdn.dev/YDWBrV.png"} alt="" />
                            </div> 
                        </div>
                        <div className="col-span-6 md:col-span-3">
                            <div className={['mask-avatar mask w-14 h-14 bg-gray-700 scale-125 transition-all', image == "https://s6.imgcdn.dev/YDWGqK.png" ? "opacity-100" : "opacity-0"].join(" ")}></div>
                            <div onClick={() => selectImage("https://s6.imgcdn.dev/YDWGqK.png")} className={["select-none mask-avatar mask absolute top-0 inline-flex justify-center w-14 h-14 cursor-pointer hover:scale-110 transition-all", image == 4 ? "scale-110" : ""].join(" ")}>
                                <img className="w-full h-full" src={"https://s6.imgcdn.dev/YDWGqK.png"} alt="" />
                            </div> 
                        </div>
                        {/* <div className="col-span-6 md:col-span-3">
                            <div className={['mask-avatar mask w-14 h-14 bg-gray-700 scale-125 transition-all', image == 5 ? "opacity-100" : "opacity-0"].join(" ")}></div>
                            <div onClick={() => selectImage(5)} className={["select-none mask-avatar mask absolute top-0 inline-flex justify-center w-14 h-14 cursor-pointer hover:scale-110 transition-all", image == 4 ? "scale-110" : ""].join(" ")}>
                                <img className="w-full h-full" src={"https://s6.imgcdn.dev/YDWruo.png"} alt="" />
                            </div> 
                        </div> */}
                    </div>
                </div>
                <div className='space-y-12'>
                    {/* Informazioni Generali */}
                    <fieldset className='relative'>
                        <legend class="mb-6">
                            <p class="text-md font-medium dark:text-white" tag="h3">Informazioni Generali</p>
                            <span class="text-xs font-normal text-slate-600 dark:text-slate-300">Alcune informazioni che dobbiamo sapere su di te.</span>
                        </legend>
                        <div className='grid grid-cols-12 gap-4'>
                            <div className="col-span-12 sm:col-span-6">
                                <label htmlFor="name" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Nome
                                </label>
                                <div className="mt-2">
                                    <div className="flex gap-2 justify-center items-center sm:max-w-md px-2 transition-all bg-white dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <input
                                            id="name"
                                            name="name"
                                            onChange={(e) => setName(e.currentTarget.value)}
                                            type="text"
                                            required
                                            defaultValue={name}
                                            placeholder="Ex: Mario"
                                            className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                        />
                                        <User className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <label htmlFor="surname" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Cognome
                                </label>
                                <div className="mt-2">
                                    <div className="flex gap-2 justify-center items-center sm:max-w-md px-2 transition-all bg-white dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <input
                                            id="surname"
                                            name="surname"
                                            onChange={(e) => setSurname(e.currentTarget.value)}
                                            type="text"
                                            required
                                            defaultValue={surname}
                                            placeholder="Ex: Rossi"
                                            className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                        />
                                        <User className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-12">
                                <label htmlFor="email" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Email
                                </label>
                                <div className="mt-2">
                                    <div className=" flex gap-2 justify-center items-center px-2 transition-all bg-white dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <input
                                            id="email"
                                            name="email"
                                            onChange={(e) => setEmail(e.currentTarget.value)}
                                            type="email"
                                            required
                                            defaultValue={email}
                                            placeholder="Ex: Rossi"
                                            className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                        />
                                        <At className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    {/* Informazioni personali */}
                    <fieldset className='relative'>
                        <legend class="mb-6">
                            <p class="text-md font-medium dark:text-white" tag="h3">Informazioni personali</p>
                            <span class="text-xs font-normal text-slate-600 dark:text-slate-300">Alcune informazioni che dobbiamo sapere su di te.</span>
                        </legend>

                        <div className='grid grid-cols-12 gap-4'>
                            <div className="col-span-12">
                                <label htmlFor="age_of_birth" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Data di nascita
                                </label>
                                <div className="mt-2">
                                    <Datepicker id="age_of_birth" defaultValue={birthday} value={(e) => setBirthday(e.getTime())} />
                                </div>
                            </div>

                            <div className="col-span-12">
                                <label htmlFor="phone" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Numero di cellulare
                                </label>
                                <div className="mt-2">
                                    <div className="flex gap-2 justify-center items-center px-2 transition-all bg-white dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <input
                                            id="phone"
                                            name="phone"
                                            onChange={(e) => setPhone(e.currentTarget.value)}
                                            type="text"
                                            required
                                            defaultValue={phone}
                                            placeholder="Ex: Rossi"
                                            className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                        />
                                        <Phone className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-12">
                                <label htmlFor="residence" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Via / Piazza
                                </label>
                                <div className="mt-2">
                                    <div className="flex gap-2 justify-center items-center px-2 transition-all bg-white dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <input
                                            id="residence"
                                            name="residence"
                                            onChange={(e) => setResidence(e.currentTarget.value)}
                                            type="text"
                                            required
                                            defaultValue={residence}
                                            placeholder="Via Victor Hugo 2"
                                            className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                        />
                                        <City className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-12 sm:col-span-4">
                                <label htmlFor="city" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Città
                                </label>
                                <div className="mt-2">
                                    <div className="flex gap-2 justify-center items-center sm:max-w-md px-2 transition-all bg-white dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <input
                                            id="city"
                                            name="city"
                                            onChange={(e) => setCity(e.currentTarget.value)}
                                            type="text"
                                            required
                                            defaultValue={city}
                                            placeholder="Milano"
                                            className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                        />
                                        <City className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-4">
                                <label htmlFor="province" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Provincia
                                </label>
                                <div className="mt-2">
                                    <div className="flex gap-2 justify-center items-center sm:max-w-md px-2 transition-all bg-white dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <input
                                            id="province"
                                            name="province"
                                            onChange={(e) => setProvince(e.currentTarget.value.toUpperCase())}
                                            type="text"
                                            maxLength="2"
                                            required
                                            defaultValue={province}
                                            placeholder="MI"
                                            className="uppercase peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                        />
                                        <City className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-4">
                                <label htmlFor="postal_code" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    C. Postale
                                </label>
                                <div className="mt-2">
                                    <div className="flex gap-2 justify-center items-center sm:max-w-md px-2 transition-all bg-white dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <input
                                            id="postal_code"
                                            name="postal_code"
                                            onChange={(e) => setCap(e.currentTarget.value)}
                                            type="text"
                                            required
                                            defaultValue={cap}
                                            placeholder="20123"
                                            className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                        />
                                        <City className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-12">
                                <label htmlFor="gender" id="gender" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Genere
                                </label>
                                <div className="mt-2">
                                    <div className="grid grid-cols-12 gap-4">
                                        <div className="col-span-4 relative">
                                            <input type="radio" onChange={e => setGender('m')} className='peer absolute inset-0 z-20 size-full cursor-pointer appearance-none opacity-0' name="gender" value="m" checked={gender == "m"} />
                                            <div className={['rounded-md text-slate-400 peer-checked:bg-indigo-500/20 peer-checked:border peer-checked:border-indigo-500 peer-checked:!text-indigo-500 relative border px-2 py-6 flex flex-col justify-center items-center cursor-pointer transition-all'].join(" ")}>
                                                <GenderMale size='20' weight="duotone" />
                                                <span>Maschio</span>
                                            </div>
                                        </div>
                                        <div className="col-span-4 relative">
                                            <input type="radio" onChange={e => setGender('f')} className='peer absolute inset-0 z-20 size-full cursor-pointer appearance-none opacity-0' name="gender" value="f" checked={gender == "f"} />
                                            <div className={['rounded-md text-slate-400 peer-checked:bg-indigo-500/20 peer-checked:border peer-checked:border-indigo-500 peer-checked:!text-indigo-500 relative border px-2 py-6 flex flex-col justify-center items-center cursor-pointer transition-all'].join(" ")}>
                                                <GenderFemale size='20' weight="duotone" />
                                                <span>Femmina</span>
                                            </div>
                                        </div>
                                        <div className="col-span-4 relative">
                                            <input type="radio" onChange={e => setGender('o')} className='peer absolute inset-0 z-20 size-full cursor-pointer appearance-none opacity-0' name="gender" value="o" checked={gender == "o"} />
                                            <div className={['rounded-md text-slate-400 peer-checked:bg-indigo-500/20 peer-checked:border peer-checked:border-indigo-500 peer-checked:!text-indigo-500 relative border px-2 py-6 flex flex-col justify-center items-center cursor-pointer transition-all'].join(" ")}>
                                                <GenderNeuter size='20' weight="duotone" />
                                                <span>Altro</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </fieldset>

                    {/* Ruolo */}
                    <fieldset className='relative'>
                        <legend class="mb-6">
                            <p class="text-md font-medium dark:text-white" tag="h3">Ruolo</p>
                            <span class="text-xs font-normal text-slate-600 dark:text-slate-300">Mansione all'interno dell'attività aziendale.</span>
                        </legend>

                        <div className='grid grid-cols-12 gap-4'>

                            <div className="col-span-12">
                                <label htmlFor="role" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Ruolo
                                </label>
                                <div className="mt-2">
                                    <Select id="role" list={[
                                        {
                                            "id": "direttore-sala",
                                            "name": "Direttore di sala",
                                            "image": ""
                                        },
                                        {
                                            "id": "cameriere",
                                            "name": "Cameriere",
                                            "image": ""
                                        },
                                        {
                                            "id": "hostes-sala",
                                            "name": "Hostes di sala",
                                            "image": ""
                                        },
                                        {
                                            "id": "barman-barlady",
                                            "name": "Barman / Barlady",
                                            "image": ""
                                        },
                                    ]} name="role" defaultValue={role} onChange={(e) => setRole(e.id)}  />
                                </div>
                            </div>

                        </div>

                    </fieldset>

                    {/* Credenziali */}
                    <fieldset className='relative'>
                        <legend class="mb-6">
                            <p class="text-md font-medium dark:text-white" tag="h3">Credenziali</p>
                            <span class="text-xs font-normal text-slate-600 dark:text-slate-300">Modifica le credenziali per permettere l'accesso all'operatore nella sua app.</span>
                        </legend>

                        <div className="mt-5 grid grid-cols-12 gap-4">
                            <div className="sm:col-span-6">
                                <label htmlFor="password" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Password
                                </label>
                                <div className="mt-2">
                                    <div className="flex gap-2 justify-center items-center sm:max-w-md px-2 transition-all bg-white dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <input
                                            id="password"
                                            name="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            type="password"
                                            required
                                            defaultValue={password}
                                            placeholder="Password"
                                            className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                        />
                                        <Password className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                                    </div>
                                </div>

                                
                            </div>
                            <div className="sm:col-span-6">
                                <label htmlFor="generate" className="block text-sm/6 font-medium text-slate-400">
                                    &nbsp;
                                </label>
                                <div className="mt-2">
                                    <button
                                        onClick={generatePass}
                                        className="text-xs relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-orange-600 disabled:bg-orange-600/50 px-3 py-2 h-full font-normal leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                    >
                                        Genera password
                                    </button>
                                </div>
                            </div>

                            <div className="col-span-12">
                                <label htmlFor="repeatPassword" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Ripeti Password
                                </label>
                                <div className="mt-2">
                                    <div className="flex gap-2 justify-center items-center px-2 transition-all bg-white dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <input
                                            id="repeatPassword"
                                            name="repeatPassword"
                                            onChange={(e) => setRepeatPassword(e.target.value)}
                                            type="password"
                                            required
                                            defaultValue={repeatPassword}
                                            placeholder="Ripeti password"
                                            className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                        />
                                        <Password className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </fieldset>

                    {/* Metodo di pagamento */}
                    <fieldset className='relative'>
                        <legend class="mb-6">
                            <p class="text-md font-medium dark:text-white" tag="h3">Metodo di pagamento</p>
                            <span class="text-xs font-normal text-slate-600 dark:text-slate-300">Queste credenziali sono confidenzialissime, si prega di non divulgare questa informazione altrui.</span>
                        </legend>

                        <div className="mt-5 grid grid-cols-12 gap-4">
                            <div className="col-span-12">
                                <label htmlFor="password" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Metodo di pagamento (Optional)
                                </label>
                                <div className="mt-2">
                                    <div className="flex gap-2 justify-center items-center px-2 transition-all bg-white dark:bg-slate-900 dark:text-slate-100 focus-within:outline-slate-400 focus-within:outline-offset-2 focus-within:outline-2 focus-within:outline-dashed w-full rounded-md border-0 py-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <input
                                            id="iban"
                                            name="iban"
                                            onChange={(e) => setIban(e.target.value)}
                                            type="text"
                                            required
                                            defaultValue={iban}
                                            placeholder="Ex: IT60X0542811101000000123456"
                                            className="peer order-last block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6 w-full"
                                        />
                                        <Wallet className='text-gray-400 peer-focus:text-indigo-600 transition-colors' size="18" weight='bold' />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </fieldset>

                    {/* Stato account */}
                    <fieldset className='relative' id="state">
                        <legend class="mb-6">
                            <p class="text-md font-medium dark:text-white" tag="h3">Stato account</p>
                            <span class="text-xs font-normal text-slate-600 dark:text-slate-300">Visualizza lo stato dell'account, se attivo o da eliminare.</span>
                        </legend>

                        <div className="mt-5 grid grid-cols-12 gap-4">
                            <div className="col-span-12">
                                <label htmlFor="password" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Stato
                                </label>
                                <div className="mt-2 flex justify-center items-center">
                                    <div className="col-span-12 flex items-center space-x-4">
                                        <div className='text-slate-700 text-sm'>{active ? "Attivo" : "Disattivato"}</div>
                                        <div className='flex relative'>
                                            <Toggle onClick={() => setActive(!active)} status={active} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5 grid grid-cols-12 gap-4">
                            <div className="col-span-12">
                                <label htmlFor="removeMember" id="removeMember" className="block text-sm/6 font-medium text-slate-600 dark:text-slate-200">
                                    Elimina membro
                                </label>
                                <div className='text-slate-400 text-xs'>
                                    Vuoi davvero eliminare questo utente?
                                </div>
                                <div className="mt-2 flex justify-center items-center">
                                    <div className="col-span-12 flex items-center space-x-2">
                                        <button className='w-36 px-4 py-2 bg-red-500 hover:bg-red-600 transition-all rounded-md text-white' onClick={() => console.log("elimina utent")}>Elimina</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                </div>


                <div className="mt-10 relative ">
                    <div className='grid grid-cols-12 gap-4 '>
                        <div className="col-span-6 ">
                            <Link
                                to="/Teams"
                                className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-slate-200 disabled:bg-slate-600/50 px-3 py-3  font-normal leading-6 text-black shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                            >
                                <span className={['transition-all', "translate-y-0 opacity-100"].join(" ")}>Torna alla lista</span>
                            </Link>
                        </div>
                        <div className="col-span-6">
                            <button
                                onClick={handleConferme}
                                disabled={loading || name != null && name.length == 0 || 
                                    email != null && email.length == 0 || 
                                    role != null && role.length == 0
                                 }
                                className="text-md relative flex w-full transition-all overflow-hidden justify-center rounded-md bg-indigo-600 disabled:bg-indigo-600/50 px-3 py-3  font-normal leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                <span className={['flex justify-center items-center absolute -translate-x-2/4 left-2/4 transition-all', loading ? "translate-y-0 opacity-100" : "-translate-y-6 opacity-0"].join(" ")}><Loading /> Attendi...</span>
                                <span className={['transition-all', loading ? "-translate-y-6 opacity-0" : "translate-y-0 opacity-100"].join(" ")}>Conferma</span>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}