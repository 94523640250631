import React, {useState, useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Question, ClipboardText , ArrowSquareOut } from '@phosphor-icons/react'
import SearchQuery from '../../GraphQl/Conections/Query.Search';
import { useDispatch } from 'react-redux';
import LogoMynu from '../../assets/images/logos/logo.webp';

const defaultList = [ {
    title: "Help",
    list: [{
        name: "Come creare un menù",
        url: "https://www.mynuapp.it/Help/come-posso-creare-un-menu-001/",
        externalLink: true,
        icon: <Question className="flex-none dark:text-gray-200 text-gray-900/40 w-5 h-5" />,
        category: "help"
    },
    {
        name: "Come posso creare una nuova categoria?",
        url: "https://www.mynuapp.it/Help/come-posso-creare-una-nuova-categoria-001/",
        externalLink: true,
        icon: <Question className="flex-none dark:text-gray-200 text-gray-900/40 w-5 h-5" />,
        category: "help"
    },
    {
        name: "Posso modificare il mio abbonamento?",
        url: "https://www.mynuapp.it/Help/posso-modificare-il-mio-abbonamento-001/",
        externalLink: true,
        icon: <Question className="flex-none dark:text-gray-200 text-gray-900/40 w-5 h-5" />,
        category: "help"
    }]
}]

export default function Search(props){
    const dispatch = useDispatch();
    const location = useLocation();
    const [mshow, setMsShow] = useState(false);
    const [show, setShow] = useState(false);
    const [finalTimeOutKeyDown, setFinalTimeOutKeyDown] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [loadingList, setLoadingList] = useState(false);
    const [list, setList] = useState([...defaultList ]);
    

    useEffect(() => {
        if(finalTimeOutKeyDown.length == 0 || finalTimeOutKeyDown == undefined || finalTimeOutKeyDown == null){
            return;
        }
        
        SearchQuery(dispatch, finalTimeOutKeyDown).then((data) => { 
            // setList(data);
            let structures = [...defaultList];
            if (data.articles.length > 0) {
                structures.push({
                    title: "Articoli",
                    list: data.articles
                });
            }
            if (data.menus.length > 0) {
                structures.push({
                    title: "Menù",
                    list: data.menus
                });
            }
            if (data.cat_menus.length > 0) {
                structures.push({
                    title: "Categorie Menù",
                    list: data.cat_menus
                });
            }
            setList(structures);
            setLoadingList(false);
        });
    }, [finalTimeOutKeyDown]);

    useEffect(() => { 
        const handler = setTimeout(() => { 
            setFinalTimeOutKeyDown(inputValue); 
            
            
        }, 1000); 
        // Imposta il timeout a 1 secondo 
        return () => { 
            clearTimeout(handler); 
            if(inputValue.length > 0){
                setLoadingList(true);
            }
        }; 
    }, [inputValue]); 
    
    const handleChange = (e) => {
        setInputValue(e.target.value);
    }

    useEffect(() => {
        props.handleShow(false);
        setShow(false);
        setTimeout(() => {
            setMsShow(false);
            clearTimeout();
        }, 155);  
    }, [location]);

    useEffect(() => {
        if (inputValue) {
            const filteredSuggestions = list.map(section => ({
                ...section,
                list: section.list.filter(item =>
                    item.name && item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                    item.nome && item.nome.toLowerCase().includes(inputValue.toLowerCase())
                )
            })).filter(section => section.list.length > 0);
            setSuggestions(filteredSuggestions);
        } else {
            if (inputValue.length === 0) {
                const helpSuggestions = list.map(section => ({
                    ...section,
                    list: section.list.filter(item => item.category === "help")
                })).filter(section => section.list.length > 0);
                setSuggestions(helpSuggestions);
                setLoadingList(false);
            } else {
                setSuggestions([]);
            }
        }
    }, [inputValue, list]);

    useEffect(() => {
        if(props.show === false){ 
            setTimeout(() => {
                props.handleShow(false);
                setMsShow(false);
                clearTimeout();
            }, 155);  
        }else{ 
            setMsShow(true);
            setTimeout(() => {
                setShow(true);
                clearTimeout();
                //autofocus su input search
                document.getElementById("input-search").focus();
            }, 155);
        }
        // console.log(props.show , "aspertura");
        
    }, [props.show])

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.ctrlKey && event.key === 'k') {  // tasto ctrl + K
                event.preventDefault();
                setMsShow(true);
                setTimeout(() => {
                    setShow(true);
                    clearTimeout();
                    //autofocus su input search
                    document.getElementById("input-search").focus();
                }, 155);
            }
            if (event.keyCode === 27) { // 27 è il codice per il tasto "Esc"
                props.handleShow(false);
                setShow(false);
                setTimeout(() => {
                    setMsShow(false);
                    clearTimeout();
                }, 155);  
            }
        };
    
        // Aggiungi il listener quando il componente viene montato
        window.addEventListener('keydown', handleEsc);

        // Rimuovi il listener quando il componente viene smontato
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []); // Assicurati di passare un array vuoto come secondo argomento per assicurarti che l'effetto venga eseguito solo una volta durante il montaggio del componente


    const handleClose = (e) => {
        if(e.target.className){
            if(e.target.className.includes("uscbry33")){
                props.handleShow(false);
                setShow(false);
                setTimeout(() => {
                    setMsShow(false);
                    clearTimeout();
                }, 155);  
            }
        }
        
    }
    const touchClose = (e) => {
        props.handleShow(false);
        setShow(false);
        setTimeout(() => {
            setMsShow(false);
            clearTimeout();
        }, 155);  
    }


    
    return <div className={["md:p-20 p-3 overflow-y-auto w-full inset-0 fixed bg-gray-500/45 filter-blur uscbry33 z-40", show === false ? "opacity-0" : "", mshow === false ? "hidden" : ""].join(" ")} onClick={handleClose}>
            <div className="ring-1 ring-black/5 bg-white/70 dark:bg-slate-900/70 shadow-2xl rounded-xl overflow-hidden transform-ftp max-w-2xl mx-auto" id="headlessui-dialog-panel-2">
                <div className="relative">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="text-gray-900/80 dark:text-gray-400 w-5 h-5 inset-3.5 left-4 absolute pointer-events-none">
                        <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd"></path>
                    </svg>
                    <input id="input-search" className="outline-none ring-0 focus:ring-0 text-gray-900 dark:text-gray-400 pr-4 pl-11 z-10 md:z-auto bg-transparent placeholder:text-gray-800 border-0 w-full h-12" placeholder="Search..." value={inputValue} onChange={handleChange} type="text" />
                    <span className='absolute right-0 mx-4 my-3 text-gray-900/80 dark:text-gray-400 md:pointer-events-none z-20 md:z-auto' onTouchStart={touchClose}>Esc</span>
                </div>
                <ul className="border-t border-gray-100/10 overflow-y-auto scroll-py-2 max-h-80 ">
                    {
                        suggestions.map((itemC, indexC) => {
                            return <li key={indexC} className="p-2" role="none">
                                <h2 className="text-gray-900 dark:text-gray-400 font-semibold text-xs px-3 mt-4 mb-2" role="none">{itemC.title}</h2>
                                <ul className="text-gray-700 dark:text-gray-200 text-sm" role="none">
                                    {
                                        itemC.list.map((item, index) => {
                                            return <li key={index} >
                                                <Link to={item.url != undefined ? item.url : itemC.title == "Articoli" ? "/Menu/Articles#" + item._id : itemC.title == "Menù" ? "/Menu#" + item._id : itemC.title == "Categorie Menù" ? "/Menu/Categories#" + item._id : "/"} className='cursor-pointer py-2 px-3 rounded-md items-center select-none flex hover:bg-gray-900/5 '>
                                                    {item.icon != null ? item.icon : 
                                                        item.photo == undefined ? <ClipboardText className="flex-none text-gray-900/40 dark:text-gray-200 w-5 h-5" /> :
                                                        <img className="flex-none text-gray-900/40 w-5 h-5 object-cover rounded-full" src={item.photo == "" ? LogoMynu : item.photo} alt="" />
                                                    }
                                                    <span className="overflow-hidden text-ellipsis whitespace-nowrap flex-auto ml-3">{item.name || item.nome}</span>
                                                    {item.externalLink ? <ArrowSquareOut  className="flex-none text-gray-900/40 dark:text-gray-200 w-5 h-5" /> : <></>}
                                                </Link>
                                            </li>
                                        })
                                    }
                                </ul>
                            </li>
                        })
                    }
                    {
                        loadingList ? <>
                            <li className="p-2 animate-pulse" role="none">
                                <h2 className="text-gray-900 font-semibold text-xs mx-3 mt-4 mb-2 w-28 h-2 bg-gray-900/5 rounded" role="none">&nbsp;</h2>
                                <ul className="text-gray-700 text-sm" role="none">
                                    <li className='cursor-default py-2 mx-3 rounded-md items-center select-none flex  '>
                                        <div className="flex-none text-gray-900/40 w-5 h-5 object-cover rounded-full bg-gray-900/5 "></div>
                                        <span className="overflow-hidden text-ellipsis whitespace-nowrap ml-3 h-2 bg-gray-900/5 rounded w-44 block">&nbsp;</span>
                                    </li>
                                </ul>
                            </li>
                        </> : <></>
                    }

                </ul>
            </div>
        </div>
}